<template>
  <div class="flex flex--100 restaurant-categories-grid">
    <div class="flex flex--100 flex--x-align-center flex--y-align-center header">
        <primary-logo :restaurant-id="restaurantId" />
    </div>
    <div class="flex flex--100 categories-container">
      <div class="flex flex--100 flex--x-align-center flex--y-align-center take-away-box">
        <div class="flex flex--100 flex--x-align-center">
              <h2 class="take-away-box__title">{{ $t("generic.whereToEat") }}</h2>
        </div>
          <div class="flex flex--100 flex--x-align-center">
             <div class="flex flex--y-align-center flex--direction-column option" @click="selectDeliveryLocation(OrderDeliveryLocation.RESTAURANT)">
              <svg class="flex option__icon" xmlns="http://www.w3.org/2000/svg" version="1.0" width="817.000000pt" height="1280.000000pt" viewBox="0 0 817.000000 1280.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path d="M6620 12781 c-357 -97 -712 -592 -948 -1323 -174 -537 -267 -1124 -279 -1758 -8 -416 13 -603 93 -852 115 -356 300 -596 698 -907 186 -145 255 -211 284 -273 21 -43 22 -57 22 -364 0 -723 -41 -1680 -110 -2554 -29 -357 -61 -692 -140 -1465 -103 -1001 -114 -1132 -131 -1543 -13 -303 -4 -574 26 -823 19 -148 67 -391 96 -480 90 -274 276 -431 514 -432 280 0 483 196 550 533 61 310 80 580 72 1030 -7 396 -24 640 -117 1665 -124 1367 -158 1843 -195 2710 -17 404 -40 1511 -33 1624 8 139 69 214 301 372 240 163 374 273 471 389 193 230 299 483 353 836 36 235 19 871 -32 1271 -149 1151 -568 2031 -1088 2288 -148 73 -278 91 -407 56z"/>
                <path d="M2750 12748 c-60 -137 -131 -711 -184 -1493 -46 -677 -60 -1048 -60 -1645 -1 -465 2 -563 17 -667 21 -147 54 -258 113 -376 58 -115 95 -169 261 -378 150 -189 210 -277 253 -374 74 -164 91 -316 71 -596 -7 -90 -46 -515 -87 -944 -147 -1548 -189 -2043 -233 -2780 -41 -674 -46 -830 -46 -1505 0 -717 7 -879 56 -1230 50 -357 110 -517 239 -633 290 -261 732 -100 858 313 24 78 66 324 88 510 21 184 29 691 15 964 -17 318 -34 520 -111 1296 -158 1587 -203 2272 -231 3470 -14 602 -9 909 15 995 38 135 93 226 256 420 175 210 286 398 358 610 70 203 87 456 79 1165 -6 504 -11 658 -42 1145 -57 912 -155 1779 -200 1767 -33 -9 -51 -255 -80 -1122 -32 -964 -52 -1308 -91 -1580 -25 -173 -51 -243 -87 -238 -76 11 -114 392 -137 1388 -30 1271 -42 1507 -84 1548 -44 44 -64 -208 -91 -1123 -31 -1060 -56 -1447 -110 -1680 -40 -178 -110 -179 -150 -3 -47 206 -63 540 -75 1576 -12 1012 -24 1237 -65 1237 -40 -1 -68 -312 -105 -1175 -46 -1061 -75 -1461 -121 -1655 -37 -153 -89 -154 -124 -2 -51 221 -70 606 -85 1762 -12 870 -21 1075 -52 1075 -5 0 -18 -19 -28 -42z"/>
                <path d="M604 12690 c-94 -22 -172 -140 -237 -360 -85 -282 -133 -672 -173 -1392 -21 -366 -30 -2277 -15 -3238 12 -821 16 -860 131 -1291 73 -276 80 -326 80 -593 0 -475 -35 -1222 -80 -1731 -41 -458 -77 -759 -174 -1465 -116 -847 -130 -981 -130 -1320 -1 -294 12 -415 63 -626 54 -218 104 -320 203 -409 239 -215 667 -165 842 99 65 97 114 291 143 556 27 255 8 608 -62 1145 -13 105 -40 309 -59 455 -90 686 -147 1302 -181 1960 -20 406 -38 1099 -33 1340 l4 205 36 105 c20 58 99 238 176 400 192 403 262 584 326 835 85 336 100 603 76 1340 -58 1796 -279 3196 -591 3741 -101 176 -235 271 -345 244z"/>
                </g>
              </svg>
              <span class="option__name">{{ $t("pickUp.dineIn") }}</span>
          </div>
            <div class="flex flex--y-align-center flex--direction-column option" @click="selectDeliveryLocation(OrderDeliveryLocation.TAKE_AWAY)">
                <svg class="flex option__icon" xmlns="http://www.w3.org/2000/svg" version="1.0" width="1280.000000pt" height="547.000000pt" viewBox="0 0 1280.000000 547.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,547.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path d="M5805 5449 c-1660 -82 -3159 -498 -4038 -1121 -572 -406 -862 -897 -808 -1367 17 -142 62 -295 121 -411 l20 -40 -34 0 -34 0 -7 -63 c-7 -66 -3 -160 10 -212 11 -42 145 -255 161 -255 7 0 22 -14 34 -30 12 -17 28 -30 35 -30 8 0 19 -9 25 -20 6 -11 17 -20 25 -20 8 0 22 -11 31 -25 9 -14 41 -42 71 -62 29 -19 77 -62 105 -94 28 -33 59 -59 69 -59 9 0 26 -16 38 -35 11 -19 28 -35 38 -36 10 0 33 -18 51 -41 48 -61 268 -263 342 -316 36 -25 101 -74 145 -108 393 -301 735 -465 1265 -603 206 -53 636 -150 890 -200 412 -80 340 -76 1260 -76 1331 0 2271 47 3043 150 736 99 1189 256 1757 610 102 64 236 146 298 182 61 37 114 75 117 84 3 9 16 20 29 23 93 23 711 583 752 681 9 22 24 51 34 65 41 57 113 348 126 504 3 39 21 116 40 171 214 638 -116 1250 -956 1770 -103 63 -421 231 -510 269 -419 179 -746 291 -1155 396 -692 178 -1378 278 -2193 320 -230 11 -956 11 -1197 -1z"/>
                </g>
                </svg>
                <span class="option__name">{{ $t("generic.takeAway") }}</span>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RestaurantProcessor } from "@/utilities";
import PrimaryLogo from "@/components/utilities/PrimaryLogo";
import { isKiosk } from "@/main";
import { OrderDeliveryLocation } from "@/orders/RestaurantOrder";

export default {
  name: "DeliveryLocationSelect",
  props: {
      restaurantPresentation: {
        type: Object,
      },
      selectedScene: {
        type: String,
      },
    },
  components: {
      PrimaryLogo,
  },
  emits: [
      "select",
  ],
  data () {
    return {
      OrderDeliveryLocation,
      selectedDeliveryLocation: -1,
    };
  },
  computed: {
    restaurantProcessor() {
      return new RestaurantProcessor({
        restaurantPresentation: this.restaurantPresentation,
      });
    },
    restaurantId () {
        return this.restaurantProcessor.restaurant.id;
    },
    selectedSceneObj() {
      let result;
      if (this.selectedScene !== "all") {
        result = this.restaurantProcessor.getSceneById(Number(this.selectedScene));
      }
      return result;
    },
    isKiosk() {
      return isKiosk;
    },
  },
  methods: {
    selectDeliveryLocation (deliveryLocation) {
      this.selectedDeliveryLocation = deliveryLocation;
      setTimeout(() => {
        this.$emit("select", deliveryLocation);
      }, 200);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
@import "~@/css/flex.scss";

.restaurant-categories-grid {
  position: relative;
  background-color: rgb(245, 245, 245);
  max-height: 92vh; /* Constrained height */
  overflow-y: auto; /* Enable vertical scrolling */

  .take-away-box {
    height: 100%;

    &__title {
        margin: 0 0 74px 0;

        font-weight: 800;
        font-size: 32px;
        letter-spacing: 0.04rem;
        color: rgb(28, 28, 28);
    }

    .option {
      position: relative;
      will-change: transform;
      transition: transform 300ms;
      cursor: pointer;

      width: 80vw;
      padding: 50px 80px;
      margin: 0 0 50px 0;
      background-color: #8B6F50;
      border-radius: 12px;
      box-shadow: 0 0 50px 2px rgba(0, 0, 0, 0.05);

      &__icon {
          margin-bottom: 30px;

          width: 92px;
          height: 92px;

          fill: rgb(28, 28, 28);
      }
      &__name {
          font-weight: 700;
          font-size: 24px;
          text-transform: uppercase;
          letter-spacing: 0.02rem;
          color: rgb(28, 28, 28);
      }

      & + & {
          margin-left: 50px;
      }

      &:active {
          transform: scale(0.9);
      }

      &--secondary {
          margin-top: 22px;
          padding: 30px 50px;
      }

      .wechat-pay-icon {
          width: 206px;
      }
      :deep(.wechat-pay-icon *) {
          fill: rgb(0, 0, 0);
      }
    }
  }

  .header {
    overflow: hidden;
    height: 25%;
    z-index: 3;
    background-color: $primary-brand-color;
  }

  .primary-logo {
    width: 262px;
    height: auto;

    fill: rgb(255, 255, 255);
}

  .categories-container {
    z-index: 2;
    height: 75%;
    overflow: auto;
  }

  .restaurant-category {
    width: 50%;
    box-sizing: border-box;
    padding: 10px;
    cursor: pointer;
    touch-action: manipulation;

    &:active {
      opacity: 0.8;
    }

    .product-image-container {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 170px;
        background-color: #fefefe;
        border-radius: 6px;
        border: 3px solid rgba(0, 0, 0, 0);
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
      

      .product-image {
        margin: 5px;
        border-radius: 6px;
        border: 3px solid rgba(0, 0, 0, 0);
        width: 100%;
        height: auto;
        object-fit: cover;
        max-height: 150px;
      }

      .preview {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        &__image {
            width: 24%;
            max-height: 250px;
            border-radius: 6px;

            z-index: 2;
        }
        &__image:nth-child(1) {
            position: relative;
            right: -10px;
        }
        &__image:nth-child(2) {
            width: 31%;

            z-index: 3;
        }
        &__image:nth-child(3) {
            position: relative;
            left: -10px;
        }
      }
    }

    .category-name {
        padding: 12px;
        text-align: center;
        color: rgb(20, 20, 20);
        font-size: 16px;
        font-weight: 600;
        width: 100%;
        background-color: #fefefe;
    }
  }
}
</style>
