<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="flex flex--100 mobile-restaurant-product-list">
        <div class="flex flex--100 flex--y-align-center header">
            <primary-logo :restaurant-id="restaurantId" v-if="restaurantId != '6'" />
            <splide :options="splideOptions" ref="categorySlider">
                <splide-slide v-for="category in listedCategories" :key="category.id">
                    <div class="slide-category" :class="getCategorySlideModifiers(category.id)"
                        @click="selectCategory(category.id)">
                        {{ restaurantProcessor.getCategoryLocalizedName(category.id) }}
                    </div>
                </splide-slide>
            </splide>
        </div>
        <div class="flex flex--100 main">
            <div class="flex flex--100 flex--x-align-center latest-order" v-if="latestOrderDailyId">
                <span class="latest-order__text">
                    {{ $t("order.lastOrder") }}
                    <span class="latest-order__link" @click="gotoStatus">
                        #{{ latestOrderDailyId }}
                    </span>
                </span>
            </div>
            <template v-if="selectedCategoryId !== -1">
            <!-- <products> -->
            <div class="flex flex--100 category" v-for="category in listedCategories" :key="category.id" ref="categories">
                <span class="flex flex--100 category__title">
                    {{ restaurantProcessor.getCategoryLocalizedName(category.id) }}
                </span>
                <div class="flex flex--100">
                    <template v-for="product in restaurantProcessor.getSortedPopularProductsByCategoryId(category.id)">
                        <restaurant-fixed-menu
                            :restaurant-presentation="restaurantPresentation"
                            :key="'fixed menu '+product.id+product.discountStrategies"
                            :product="restaurantProcessor.getFixedMenuById(product.id)"
                            :lite="true"
                            v-model:model-value="selectedProducts[product.id]"
                            v-if="restaurantProcessor.productIsFixedMenu(product.id)"
                        />
                        <restaurant-custom-product
                            :restaurant-presentation="restaurantPresentation"
                            :key="'custom product '+product.id+product.discountStrategies"
                            :product="restaurantProcessor.getCustomProductById(product.id)"
                            :lite="true"
                            v-model:model-value="selectedProducts[product.id]"
                            v-else-if="restaurantProcessor.productIsCustomProduct(product.id)"
                        />
                        <restaurant-product
                            :restaurant-presentation="restaurantPresentation"
                            :key="'product '+ product.id+product.discountStrategies"
                            :product="product"
                            :lite="false"
                            :layout="RestaurantProductLayout.MOBILE"
                            :value="selectedProducts[product.id]"
                            @update-volume="updateVolume"
                            v-else
                        />
                    </template>
                </div>
            </div>
                <!-- </products> -->
            </template>
        </div>
    </div>
</template>

<script>
import RestaurantProduct from "@/components/RestaurantProduct";
import RestaurantFixedMenu from "@/components/RestaurantFixedMenu";
import PrimaryLogo from "@/components/utilities/PrimaryLogo";
import { RestaurantProcessor, getLatestOrders, } from "@/utilities";
import { cloneDeep } from "lodash/lang";
import RestaurantCustomProduct from "@/components/RestaurantCustomProduct";
import { RestaurantProductLayout } from "@/components/RestaurantProduct";
export default {
    name: "MobileRestaurantProductList",
    props: {
        restaurantPresentation: {
            type: Object,
        },
        selectedProducts: {
            type: Object,
        },
        insideRestaurant: {
            type: Boolean,
        },
        tableNumber: {
            type: String,
        }
    },
    emits: [ "update:selectedProducts", "update-volume", ],
    components: {
        PrimaryLogo,
        RestaurantProduct,
        RestaurantFixedMenu,
        RestaurantCustomProduct,
    },
    data () {
        return {
            selectedCategoryId: -1,
            latestOrderDailyId: undefined,
            latestOrderId: undefined,
            RestaurantProductLayout,
        };
    },
    computed: {
        splideOptions () {
            return {
                type: "slide",
                width: "100%",
                arrows: false,
                pagination: false,
                drag: true,
                trimSpace: "move",
                autoWidth: true,
                padding: "28px",
            };
        },

        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        restaurantId () {
            return this.restaurantProcessor.restaurant.id;
        },

        listedCategories () {
            return this.restaurantProcessor.listedCategories;
        },

        firstListedCategoryId () {
            return this.restaurantProcessor.firstListedCategoryId;
        },
        // </restaurant>
    },
    methods: {
        updateVolume (value, productId) {
            const selectedProductsCopy = cloneDeep(this.selectedProducts);
            selectedProductsCopy[productId] = value;
            this.$emit("update:selectedProducts", selectedProductsCopy);
            this.$emit("update-volume", {
                id: productId,
                volume: value.selectedVolume,
            });
        },

        getCategorySlideModifiers (id) {
            return {
                "slide-category--selected": id === this.selectedCategoryId,
            };
        },

        selectCategorySlide (categoryId) {
            const categoryIndex = this.listedCategories.findIndex((category) => category.id === categoryId);

            if (categoryIndex === -1) {
                return;
            }

            this.$refs.categorySlider.go(categoryIndex);
        },

        selectCategory (categoryId) {
            const categoryIndex = this.listedCategories.findIndex((category) => category.id === categoryId);

            if (categoryIndex === -1) {
                return;
            }

            const categoriesReference = this.$refs.categories;

            if (!categoriesReference) {
                return;
            }

            const categoryElement = categoriesReference[categoryIndex];

            if (!categoryElement) {
                return;
            }

            categoryElement.scrollIntoView({
                block: "start",
                inline: "start",
            });
            this.selectCategorySlide(categoryId);

            this.selectedCategoryId = categoryId;
        },

        selectFirstCategory () {
            this.selectedCategoryId = this.firstListedCategoryId;
        },

        updateLatestOrder () {
            const latestOrders = getLatestOrders();
            if (latestOrders && latestOrders.length > 0) {
                this.latestOrderDailyId = Number.parseInt(latestOrders[0].dailyId);
                this.latestOrderId = Number.parseInt(latestOrders[0].id);
            }
        },

        gotoStatus () {
            const path = `/restaurant/${this.restaurantPresentation.restaurant.id}/mobile/orders`;
            const query = {
                fromHome: "true",
                insideRestaurant: this.insideRestaurant ? "true" : "false",
            };
            if (this.tableNumber) {
                query.tableNumber = this.tableNumber;
            }
            this.$router.push({ path, query, });
        },

        async forceUpdate () {
            const id = this.selectedCategoryId;
            this.selectFirstCategory();
            await this.$nextTick();
            this.selectedCategoryId = id;
        },

        async forceUpdateById (id, discountStrategies) {
            this.restaurantProcessor.products.find((p) => p.id === id).discountStrategies = discountStrategies;
        }
    },
    created () {
        this.selectFirstCategory();
        this.updateLatestOrder();
    },
    mounted () {
        const categoriesReference = this.$refs.categories ?? [];
        const categories = [ ...categoriesReference, ];

        // checks window
        function isWindow( obj ) {
            return obj != null && obj === obj.window;
        }

        // returns corresponding window
        function getWindow( elem ) {
            return isWindow( elem ) ? elem : elem.nodeType === 9 && elem.defaultView;
        }

        function offset( elem ) {

            var docElem, win,
                box = { top: 0, left: 0 },
                doc = elem && elem.ownerDocument;

            docElem = doc.documentElement;

            if ( typeof elem.getBoundingClientRect !== typeof undefined ) {
                box = elem.getBoundingClientRect();
            }
            win = getWindow( doc );
            return {
                top: box.top + win.pageYOffset - docElem.clientTop,
                left: box.left + win.pageXOffset - docElem.clientLeft
            };
        }

        function elementIsVisible(element, container, partial) {
            var contHeight = container.offsetHeight,
                elemTop = offset(element).top - offset(container).top,
                elemBottom = elemTop + element.offsetHeight;
            return (elemTop >= 0 && elemBottom <= contHeight) ||
                (partial && ((elemTop < 0 && elemBottom > 0 ) || (elemTop > 0 && elemTop <= contHeight)))
        }

        this.$el.getElementsByClassName("main")[0].addEventListener("scroll", () => {
            let lastVisibleCategoryId = this.selectedCategoryId;

            for (const category of categories) {
                if (elementIsVisible(category, category.parentNode, true)) {
                    lastVisibleCategoryId = this.listedCategories[categories.indexOf(category)].id;

                    break;
                }
            }

            this.selectCategorySlide(lastVisibleCategoryId);
            this.selectedCategoryId = lastVisibleCategoryId;
        });
    }
}
</script>

<style lang="scss" scoped>
@import "~@splidejs/splide/dist/css/themes/splide-default.min.css";
@import "~@/css/globals.scss";

.mobile-restaurant-product-list {

}

.header {
    overflow: hidden;

    position: relative;

    height: 16%;

    background-color: rgb(252, 252, 252);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    :deep(.primary-logo) {
        position: absolute;
        right: 60px;
        top: 0;

        width: 92px;

        fill: rgb(0, 0, 0);

        transform: scale(6);
        opacity: 0.12;
    }

    :deep(.splide) {
        width: 100%;
    }
}

.main {
    overflow: auto;

    height: 84%;

    background-color: rgb(240, 240, 240);
}

.category {
    margin: 0;
    padding: 0;

    &__title {
        margin: 36px 30px 14px 30px;

        font-size: 22px;
        font-weight: 600;
        color: rgb(11, 11, 11);
    }

    :deep(.restaurant-product) {
        &--selected {
            transform: none;
        }
        * {
            will-change: auto;
        }
    }

    :deep(.restaurant-product),
    :deep(.restaurant-fixed-menu),
    :deep(.restaurant-custom-product) {
        width: 100%;

        margin: 0;

        &__box {
            border-radius: 0;
            box-shadow: none;
            transition: none;
        }

        border-top: 1px solid rgba(0, 0, 0, 0.08);
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }
    :deep(.restaurant-product + .restaurant-product),
    :deep(.restaurant-fixed-menu + .restaurant-fixed-menu),
    :deep(.restaurant-custom-product + .restaurant-custom-product) {
        border-top: none;
    }
}

.slide-category {
    cursor: pointer;

    margin: 20px;
    padding: 12px 20px;

    background-color: $primary-color;
    border-radius: 1000px;

    font-size: 16px;
    font-weight: 600;
    color: rgb(255, 255, 255);

    &--selected {
        background-color: $primary-brand-color;
    }
}

.latest-order {
    position: fixed;
    left: 0;
    top: 16vh;
    top: calc(var(--vh, 1vh) * 16);

    padding: 9px 2px;

    background-color: $primary-color;

    z-index: 11;

    &__text {
        font-size: 14px;
        font-weight: 800;
        text-transform: uppercase;
        color: rgb(255, 255, 255);
    }

    &__link {
        font-size: 14px;
        font-weight: 800;
        text-transform: uppercase;
        text-decoration: underline;
        color: $primary-brand-color;
        cursor: pointer;
    }

    & + .category {
        margin-top: 26px;
    }
}
</style>
