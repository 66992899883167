<template>
    <div class="flex flex--100 customer-promo-codes-tab">
        <div class="flex flex--100 box">
            <div class="flex flex--100 flex--x-align-center">
                <div class="flex flex--y-align-center new-button" @click="showGeneratePromoCodeWindow">
                    <span class="new-button__text">{{$t(`management.customerPromoCodesTab.new`).toLocaleUpperCase()}}</span>
                    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="flex new-button__icon">
                        <g stroke="none" stroke-width="1" fill-rule="evenodd">
                            <rect x="0" y="44" width="100" height="11" rx="5.5"/>
                            <rect x="44" y="0" width="11" height="100" rx="5.5"/>
                        </g>
                    </svg>
                </div>
            </div>
            <div class="flex flex--100">
                <div class="flex flex--100 search-bar">
                    <simple-textbox :placeholder="$t('productList.searchBarPlaceholder')" v-model:model-value="searchBarValue"/>
                </div>
            </div>
           <div class="promo-code-grid">
                <div class="grid-header">
                <div>{{ $t("management.customerPromoCodesTab.code")}}</div>
                <div>{{ $t("management.customerPromoCodesTab.description")}}</div>
                <div>{{ $t("management.customerPromoCodesTab.discount")}}</div>
                <div>{{ $t("management.customerPromoCodesTab.created")}}</div>
                <div>{{ $t("management.customerPromoCodesTab.expiry")}}</div>
                <div>{{ $t("management.customerPromoCodesTab.lastUpdate")}}</div>
                <div>{{ $t("management.customerPromoCodesTab.status")}}</div>
                </div>
                <div
                    class="grid-row"
                    v-for="promoCode in promoCodes"
                    :key="promoCode.id"
                    @click="onSelectProduct(promoCode)"
                >
                <div>{{ promoCode.code }}</div>
                <div>{{ promoCode.description }}</div>
                <div>{{ promoCode.discountPercentage }}</div>
                <div>{{ (new Date(promoCode.creationTimestamp)).toLocaleString() }}</div>
                <div>{{ promoCode.expiryDate ? (new Date(promoCode.expiryDate)).toLocaleString() : "/"}}</div>
                <div>{{ (new Date(promoCode.lastUpdateTimestamp)).toLocaleString() }}</div>
                <div>{{ promoCode.isActive ? 'Active' : 'Inactive' }}</div>
                </div>
            </div>
        </div>
        <generate-promo-code-modal-box ref="generatePromoCodeWindow" :closeOnPromoCodeAdd="true" @promo-code-add="onPromoCodeAdd"/>
    </div>
</template>

<script>
import {server} from "@/server";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
// import SimpleButton from "@/components/inputs/SimpleButton";
// import Fuse from "fuse.js";
import {RestaurantProcessor} from "@/utilities";
import {user} from "@/user";
import { notificationCenter } from "@/components/utilities/NotificationCenter";
import GeneratePromoCodeModalBox from "@/components/windows/GeneratePromoCodeModalBox";

export default {
    name: "CustomerPromoCodesTab",
    components: {
        SimpleTextbox,
        GeneratePromoCodeModalBox,
        // SimpleButton,
    },
    props: {
        loadOnRestaurantIdChange: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            restaurantId: undefined,
            restaurantPresentation: null,
            promoCodes: [],
            searchBarValue: "",
            selectedProduct: null,
            selectedProductAvailableVolume: "",
            isWaitingServerResponseForUpdate: false,
        }
    },
    computed: {
        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },
        // <restaurant>

        // <fuse>
        // fuse () {
        //     return new Fuse(this.restaurantProcessor.baseProducts.filter((product) => product.categories.length > 0), {
        //         includeScore: true,
        //         keys: [ "localizations.name", ],
        //     });
        // },
        // </fuse>

        // filteredProducts () {
        //     let filteredProducts = [];
        //     if (this.searchBarValue.length > 0) {
        //         filteredProducts = this.fuse.search(this.searchBarValue).map((entity) => entity.item);
        //         return filteredProducts;
        //     }
        //     try {
        //         filteredProducts = this.restaurantProcessor.products.filter((product) => product.categories.length > 0);
        //         return filteredProducts;
        //     }
        //     catch {
        //         return filteredProducts;
        //     }
        // },
    },
    methods: {
        clear () {
            this.selectedProductAvailableVolume = "";
            this.selectedProduct = null;
        },
        async load (restaurantId) {
            this.promoCodes = await user.getPromoCodes();
            this.restaurantPresentation = await server.getRestaurantPresentation(restaurantId);
        },
        getProductLocalization (product, languageIso) {
            return product.localizations.find((localization) => localization.languageIso === languageIso);
        },
        getLocalizedName (product) {
            let locale = this.getProductLocalization(product, this.$i18n.locale);
            if (locale) {
                return locale.name;
            }
            return this.getProductLocalization(product, "it")?.name ?? "";
        },
        onSelectProduct (product) {
            this.selectedProduct = product;
            this.selectedProductAvailableVolume = product.availableVolume;
        },
        onInputAvailableVolume (value) {
            this.selectedProductAvailableVolume = value;
        },
        async updateProductAvailableVolume () {
            if (this.isWaitingServerResponseForUpdate) {
                return;
            }

            this.isWaitingServerResponseForUpdate = true;
            try {
                const updatedProduct = await user.updateProductAvailableVolume({
                    id: this.selectedProduct.id,
                    availableVolume: this.selectedProductAvailableVolume,
                });

                if (updatedProduct) {
                    this.selectedProduct.availableVolume = updatedProduct.availableVolume;
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.productStockChangedSuccess`), });
                }
                else{
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }
            this.clear();
            this.isWaitingServerResponseForUpdate = false;
        },

        onPromoCodeAdd (promoCode) {
            this.promoCodes.push(promoCode);
        },

        productAvailableVolumeText (product) {
            let text = "non definita";

            if (product.availableVolume === 0) {
                text = this.$t(`management.restaurantProductsStockManagementTab.unavailable`);
            }
            else if (product.availableVolume > 0) {
                text = `x${product.availableVolume}`;
            }

            return text;
        },

        showGeneratePromoCodeWindow () {
            this.$refs.generatePromoCodeWindow.window.show();
        },
    }

}
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";

.customer-promo-codes-tab {

}

.restaurant-product-box {
    width: 100%;

    padding: 8px 12px;

    cursor: pointer;

    background-color: rgb(240, 240, 240);
    border-bottom: 1px solid rgb(220, 220, 220);

    &:hover {
        background-color: rgb(226, 226, 226);
    }
}

.restaurant-product-box-header {
    &__name {
        font-size: 16px;
        font-weight: 500;
    }
}

.no-products {
    font-size: 20px;
    font-weight: 500;
    color: rgb(80, 80, 80);
}

.product-available-volume-editor {
    &__label {
        font-size: 14px;
        font-weight: 600;

        color: rgb(126, 126, 126);
    }

    &__text {
        font-size: 16px;
        font-weight: 500;

        color: rgb(0, 0, 0);
    }

    :deep(.simple-textbox__text-input) {
        font-size: 16px;
        padding: 3px 13px 3px 13px;
        border-radius: 5px;
        text-align: end;
    }

    .simple-button {
        margin: 30px 0 5px 0;
        padding: 3px 13px 3px 13px;
    }
}

.search-bar {
    position: relative;

    margin: 0 0 50px 0;

    :deep(.simple-textbox__text-input) {
        margin: 0;
        padding: 10px 20px;

        border: 2px solid $primary-brand-color;
        border-radius: 1000px;
        background-color: rgb(252, 252, 252);

        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.02rem;
        color: rgb(0, 0, 0);

        &:focus {
            background-color: rgba($primary-brand-color, 0.06);
        }
    }
}

.new-button {
    cursor: pointer;

    margin: 0 0 50px 0;
    padding: 10px 20px;

    background-color: rgb(77, 77, 77);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.022), 0 0 6px 0 rgba(0, 0, 0, 0.034);

    border-radius: 6px;

    &__text {
        font-size: 14px;
        font-weight: 500;
        color: rgb(255, 255, 255);
    }

    &__icon {
        width: 13px;
        margin-left: 5px;

        fill: rgb(255, 255, 255);
    }

    @media (max-width: 448px) {
        margin-bottom: 20px!important;
    }
}

.promo-code-grid {
    min-width: 1000px;
    width: 100%; 
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    // gap: 0.5rem;
}

.grid-header {
    display: contents;
    background-color: #f8f8f8;
    div {
        padding-left: 5px;
        font-size: 18px;
        font-weight: bold;
        color: rgb(0,0,0);
    }
}

.grid-row {
    display: contents;
    cursor: pointer;
}

.grid-row div {
    font-size: 18px;
    padding: 0.5rem;
    border-bottom: 1px solid #ddd;
}

.grid-row:hover div {
    background-color: #f1f1f1;
}
</style>
