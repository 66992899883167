<template>
    <div class="flex flex--100 update-variation-modal-box">
        <modal-box ref="window">
            <form class="flex flex--100 update-variation-form">
                <div class="flex flex--100" v-for="requiredLocalization in requiredLocalizations" :key="requiredLocalization.iso">
                    <h2>{{ $t(requiredLocalization.name) }}</h2>
                    <div class="flex flex--100">
                        <simple-textbox :placeholder="$t('management.generic.name')"  v-model:model-value="localizations[requiredLocalization.iso].name" ref="enNameTextbox"/>
                    </div>
                    <hr class="separator"/>
                </div>
                <div class="flex flex--100">
                    <multi-selectbox 
                        class="multi-select-box"
                        :options="displayedVariations" 
                        :multiple="true" 
                        :can-search-options="true"
                        :loading="isWaitingServerResponse"
                        :selectPlaceholder="$t(`management.generic.variations`)"
                        label="text"
                        track-by="value"
                        v-model:modelValue="selectedVariations"
                    />
                </div>
                <hr class="separator"/>
                <div class="flex flex--100 update-variation">
                    <simple-button :text="$t('generic.update')" @click="updateVariation" :is-disabled="!dataChanged" :is-loading="isWaitingServerResponse"/>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import { user } from "@/user";
import { RestaurantProcessor } from '@/utilities';
import { notificationCenter } from "@/components/utilities/NotificationCenter";
import MultiSelectbox from "@/components/inputs/MultiSelectbox.vue";
// import {server} from "@/server";
// import { user } from "@/user";

/*
const ProductAddErrorType = {
    MISSING_INDEX: {
        id: 1,
        text: "Index is required.",
    },
    NEGATIVE_INDEX: {
        id: 2,
        text: "Index cannot be negative.",
    },
};
*/

export default {
    name: "UpdateVariationSetModalBox",
    props: {
        restaurantPresentation: {
            type: Object,
        },
        restaurantId: {
            type: String,
        },
        closeOnVariationUpdate: {
            type: Boolean,
            default: true,
        },
    },
    emits: [ "variation-set-update" ],
    components: {
        SimpleButton,
        SimpleTextbox,
        ModalBox,
        MultiSelectbox,
    },
    data () {
        return {
            variationSet: {},
            localizations: {},
            selectedVariations: [],
            saved: {},
            isWaitingServerResponse: false,
        };
    },
    methods: {
        async load (variationSet) {
            this.variationSet = variationSet;
            this.selectedVariations = variationSet.set;
            this.saved.selectedVariations = JSON.parse(JSON.stringify(this.selectedVariations));
            this.localizations = this.generateLocalizations();
            this.saved.localizations = JSON.parse(JSON.stringify(this.localizations));
        },

        validateUpdateVariationForm () {/*
            const nameTextbox = this.$refs.nameTextbox;

            nameTextbox.validate(ProductAddErrorType.MISSING_NAME, this.name.length > 0);

            return nameTextbox.errors.length === 0;*/return true;
        },

        clearVariationForm () {
            this.price = "";
            this.localizations = this.generateLocalizations();
        },

        async updateVariation () {
            if (!this.validateUpdateVariationForm() || this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const variationSet = await user.updateVariationSet({
                    id: this.variationSet.id,
                    set: JSON.stringify(this.selectedVariations),
                    restaurantId: this.restaurantId,
                    localizations: Object.keys(this.localizations).map((languageIso) => {
                        return {
                            languageIso,
                            ...this.localizations[languageIso],
                        };
                    }),
                });

                if (variationSet) {
                    try {
                        this.$emit("variation-set-update", {
                            ...variationSet,
                            set: JSON.parse(variationSet.set),
                        });
                    }
                    catch (e) {
                        console.log(e);
                     }

                    if (this.closeOnVariationUpdate) {
                        this.window.hide();
                    }

                    this.clearVariationForm();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.variationUpdatesSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },

        generateLocalizations () {
            const localizations = {};
            if (this.restaurantLocalizations) {
                this.restaurantLocalizations.forEach(iso => {
                    if (this.variationSet && this.variationSet.localizations) {
                        const existingLocalization = this.variationSet.localizations.find(l => l.languageIso === iso);
                        localizations[iso] = {
                            name: existingLocalization ? existingLocalization.name : '',
                        };
                    } else {
                        localizations[iso] = {
                            name: '',
                        };
                    }
                });
            }
            return localizations;
        },

        getLocalization (variation, languageIso) {
            return variation.localizations.find((localization) => localization.languageIso === languageIso);
        },

        getVariationText (variation) {
            const price = variation.price;
            let text = this.getLocalization(variation, this.$i18n.locale)?.name ?? "";

            if (price !== 0) {
                if (price > 0) {
                    text += ` +${this.normalizePriceToDisplay(price)} €`;
                }
                else {
                    text += ` ${this.normalizePriceToDisplay(price)} €`;
                }
            }

            return text;
        },
    },
    computed: {
        window () {
            return this.$refs.window;
        },
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, })
        },
        restaurantLocalizations () {
            return this.restaurantProcessor.getUserDisplayLocalizations();
        },
        requiredLocalizations() {
            if (!this.restaurantLocalizations) {
                return [];
            }

            return this.restaurantLocalizations.map(iso => ({
                name: `languages.${iso}`,
                iso: iso
            }));
        },
        variations () {
            return this.restaurantProcessor.variations;
        },
        displayedVariations () {
            return this.restaurantProcessor.variations.map((v) => ({
                text: this.getVariationText(v),
                value: v.id,
            }));
        },
        dataChanged () {
            for (const languageIso of Object.keys(this.localizations)) {
                if (this.localizations[languageIso].name !== this.saved.localizations[languageIso].name) {
                    return true;
                }
            }
            return JSON.stringify(this.selectedVariations) !== JSON.stringify(this.saved.selectedVariations) && !this.selectedVariations.length <= 0;
        }
    },

    async mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>
<style lang="scss" scoped>
.update-variation-modal-box {}

.update-variation-form {
    padding: 50px;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}

.update-variation-modal-box {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }

    .simple-button {
        margin: 0 50px 0 0;
    }
}

.multi-select-box {
    width: 80%;
}
</style>
