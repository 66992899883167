<template>
    <div class="flex restaurant-allergen" :class="getModifiers()" @click="onClick">
        <span class="flex restaurant-allergen__name">{{ allergenText }}</span>
        <update-allergen-modal-box
            v-if="showEditor"
            :allergen="allergen"
            :restaurant-id="restaurantId"
            :restaurant-presentation="restaurantPresentation"
            ref="updateAllergenModalBox"
            @allergen-update="onAllergenUpdate"
            @allergen-delete="onAllergenDelete"
        />
    </div>
</template>

<script>
import UpdateAllergenModalBox from "@/components/windows/UpdateAllergenModalBox";
export default {
    name: "RestaurantAllergen",
    components: {
        UpdateAllergenModalBox,
    },
    props: {
        allergen: {
            type: Object,
        },
        restaurantId: {
            type: String,
        },
        restaurantPresentation: {
            type: Object,
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        showEditor: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        "update:selected",
        "allergen-update",
        "allergen-delete",
    ],
    methods: {
        getModifiers () {
            return {
                "restaurant-allergen--selectable": this.selectable,
                "restaurant-allergen--selected": this.selected,
            };
        },

        onClick () {
            if (this.selectable) {
                if (this.showEditor) {
                    this.showUpdateAllergenModalBox();
                }
                else {
                    this.$emit("update:selected", !this.selected);
                }
            }
        },

        getLocalization (languageIso) {
            return this.allergen.localizations.find((localization) => localization.languageIso === languageIso);
        },

        onAllergenUpdate (allergen) {
            this.$emit("allergen-update", allergen);
        },

        onAllergenDelete (allergenId) {
            this.$emit("allergen-delete", allergenId);
        },

        showUpdateAllergenModalBox () {
            this.$refs.updateAllergenModalBox.load();
            this.$refs.updateAllergenModalBox.window.show();
        },
    },
    computed: {
        allergenText ()  {
            return this.getLocalization(this.$i18n.locale)?.name ?? "";
        }
    },
};
</script>
<style lang="scss" scoped>
@import "~@/css/globals.scss";
.restaurant-allergen {
    @extend .--unselectable;
    padding: 12px 16px;
    background-color: rgb(77, 77, 77);
    border-radius: 1000px;
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
    &__name {
        font-size: 16px;
        font-weight: 500;
        color: rgb(255, 255, 255);
    }
    &--selectable {
        cursor: pointer;
    }
    &--selected {
        outline-color: $primary-brand-color;
    }
}
</style>
