<template>
    <div class="flex flex--100 fixed-menu-selector">
        <div class="flex flex--100 header">
            <div class="flex flex--100 flex--x-align-center navigation">
                <div
                    class="flex flex--x-align-center flex--y-align-center navigation__button"
                    v-for="(choice, i) in customProduct.choices"
                    :key="i"
                    :class="getNavigationButtonModifiers(i)"
                    @click="setChoiceIndex(i)"
                >
                    {{ i + 1 }}
                </div>
            </div>
        </div>
        <div class="flex flex--100 main">
            <div class="flex flex--100 choice" v-for="(choice, i) in customProduct.choices" :key="i" :class="getChoiceModifiers(i)">
                <div class="flex flex--100 slider" v-if="choiceIndex === i">
                    <splide :options="splideOptions" :extensions="splideExtensions">
                        <splide-slide v-for="product in getChoiceProducts(choice)" :key="product.id">
                            <restaurant-product
                                :layout="productLayout"
                                :product="product"
                                :max-selectable-volume="1"
                                :value="modelValue.selectedProducts[i][product.id]"
                                @update-volume="(productValue) => onChoiceRestaurantProductInput(i, product.id, productValue)"
                                :restaurant-presentation="restaurantPresentation"
                            />
                        </splide-slide>
                    </splide>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RestaurantProduct from "@/components/RestaurantProduct";
import { Grid } from "@splidejs/splide-extension-grid";
import { Splide, SplideSlide, } from "@splidejs/vue-splide";
import { cloneDeep, set, tap, } from "lodash";
import { isKiosk, isMobile, } from "@/main";

export default {
    name: "CustomProductSelector",
    components: {
        RestaurantProduct,
        Splide,
        SplideSlide,
    },
    props: {
        customProduct: {
            type: Object,
        },
        modelValue: {
            type: Object,
        },
        restaurantPresentation: {
            type: Object,
        }
    },
    emits: [ "update:modelValue", "choice", ],
    computed: {
        splideOptions () {
            return {
                type: "slide",
                width: "100%",
                arrows: false,
                pagination: false,
                drag: true,
                trimSpace: "move",
                padding: "28px",
                grid: { dimensions: this.splideDimensions, },
            };
        },

        splideExtensions () {
            return {
                Grid,
            };
        },

        splideDimensions () {
            if (isMobile) {
                return [ [ 2, 1, ], [ 2, 1, ], ];
            }

            return [ [ 2, 3, ], [ 2, 3, ], ];
        },

        productLayout () {
            return isKiosk ? "vertical" : "horizontal";
        },

        choiceIndex () {
            return this.modelValue.choiceIndex ?? 0;
        },
    },
    methods: {
        update (key, value) {
            this.$emit("update:modelValue", tap(cloneDeep(this.modelValue), input => set(input, key, value)));
        },

        getChoiceProducts (choice) {
            return choice.products.map((product) => product.product);
        },

        getNavigationButtonModifiers (i) {
            return {
                "navigation__button--active": this.choiceIndex === i,
            };
        },

        getChoiceModifiers (i) {
            return {
                "choice--active": this.choiceIndex === i,
            };
        },

        setChoiceIndex (i) {
            /*
            if (i - 1 >= 0 && this.choiceIsMissing(i - 1)) {
                return;
            }
            */

            this.update("choiceIndex", i);
        },

        getProductLocalization (product, languageIso) {
            return product.localizations.find((localization) => localization.languageIso === languageIso);
        },

        choiceIsMissing (i) {
            return !this.modelValue.selectedProducts[i] || Object.keys(this.modelValue.selectedProducts[i]).length === 0;
        },

        getProductLocalizedName (product) {
            let locale = this.getProductLocalization(product, this.$i18n.locale);
            if (locale) {
                return locale.name;
            }
            return this.getProductLocalization(product, "it")?.name ?? "";
        },

        onChoiceRestaurantProductInput (choiceIndex, productId, value) {
            const product = this.customProduct.choices[choiceIndex].products.find((product) => product.product.id === productId).product;
            value.price = product.price;

            let selectedProducts = {};

            if (this.customProduct.choices[choiceIndex].multipleSelectable) {
                selectedProducts = this.modelValue.selectedProducts[choiceIndex];
            }

            if (!selectedProducts[productId] && value.selectedVolume > 0) {
                selectedProducts[productId] = value;
            }
            else {
                delete selectedProducts[productId];
            }

            this.update(`selectedProducts.${choiceIndex}`, selectedProducts);

            if (this.choiceIsMissing(choiceIndex)) {

                this.$emit("choice", {
                    choiceIndex,
                    value: {
                        id: productId,
                        ...value,
                    },
                });
            }
            else {
                // this.$emit("choice-change", {
                //     choiceIndex,
                //     value: {
                //         id: productId,
                //         ...value,
                //     },
                // }, cloneDeep(this.value.selectedProducts[choiceIndex]));
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.header {}

.main {}

.navigation {
    &__button {
        @extend .--unselectable;

        cursor: pointer;

        width: 64px;
        height: 64px;
        margin: 0;
        padding: 0;

        background-color: rgb(42, 40, 42);
        border-radius: 50%;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.0032), 0 0 10px 0 rgba(0, 0, 0, 0.0064);

        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.04rem;
        color: rgb(255, 255, 255);

        &--active {
            background-color: $primary-brand-color;
        }

        & + & {
            margin-left: 32px;
        }
    }
}

.slider {
    margin: 0;
    padding: 0;

    z-index: 2;
}

.splide {
    width: 100%;
    margin: 0;
    padding: 0;

    :deep(.splide__arrows) {
        display: none;
    }

    :deep(.splide__track) {
        overflow: initial;
    }

    :deep(.restaurant-product) {
        margin: 0 8px 30px 8px;
    }
}

</style>
