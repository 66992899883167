<template>
    <div class="flex flex--100 allergens">
        <div class="flex flex--100 box">
            <div class="flex flex--100 flex--x-align-center">
                <div class="flex flex--y-align-center add-allergen-button" @click="showAddAllergenModalBox">
                    <span class="add-allergen-button__text">{{$t(`management.restaurantAllergenManagementTab.newAllergen`).toLocaleUpperCase()}}</span>
                    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="flex add-allergen-button__icon">
                        <g stroke="none" stroke-width="1" fill-rule="evenodd">
                            <rect x="0" y="44" width="100" height="11" rx="5.5"/>
                            <rect x="44" y="0" width="11" height="100" rx="5.5"/>
                        </g>
                    </svg>
                </div>
                <div class="flex flex--100 action-box">
                    <div class="flex flex--20 search-bar">
                        <simple-textbox :placeholder="$t('productList.searchBarPlaceholder')" v-model:model-value="searchBarValue"/>
                    </div>
                    <div class="flex flex--80 flex--x-align-end">
                        <simple-button class="action-button" :text="$t(`management.generic.import`).toLocaleUpperCase()" @click="openNativeFilePicker" v-show="!exportMode" :is-loading="isWaitingFileLoad"/>
                        <input @change="onFileChange" ref="fileInput" type="file" accept="application/JSON" style="display:none">
                        <simple-button class="action-button" :class="selectAllButtonModifiers" :text="$t(`management.generic.selectAll`).toLocaleUpperCase()" @click="toggleSelectAllToExport" v-show="exportMode"/>
                        <simple-button class="action-button" :text="$t(`management.generic.export`).toLocaleUpperCase()" @click="toggleExportMode(true)"/>
                        <simple-button class="action-button" :text="$t(`management.generic.cancel`).toLocaleUpperCase()" @click="toggleExportMode(false)" v-show="exportMode"/>
                    </div>
                </div>
            </div>
            <div class="flex flex--100 flex--x-align-center allergen-list">
                <span class="no-allergens-text" v-if="allergens.length === 0">{{$t(`management.restaurantAllergenManagementTab.noAllergens`)}}</span>
                <div class="restaurant-allergen-box" v-for="allergen in filteredAllergens" :key="allergen.id">
                    <restaurant-allergen
                        :allergen="allergen"
                        :restaurant-id="restaurantId"
                        :restaurant-presentation="restaurantPresentation"
                        :selectable="true"
                        :show-editor="true"
                        @allergen-update="onAllergenUpdate"
                        @allergen-delete="onAllergenDelete"
                    >
                    </restaurant-allergen>
                    <div class="toggle-button" @click="toggleAllergensStatus(allergen)">
                        <svg v-if="exportMode && !allergen.export" class="toggle-button__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                        <svg v-if="exportMode && allergen.export" class="toggle-button__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                    </div>
                </div>
            </div>
        </div>
        <add-allergen-modal-box :restaurant-presentation="restaurantPresentation" :restaurant-id="restaurantId" ref="addAllergenModalBox" @allergen-add="onAllergenAdd"/>
    </div>
</template>

<script>
import {server} from "@/server";
import AddAllergenModalBox from "@/components/windows/AddAllergenModalBox";
import RestaurantAllergen from "@/components/RestaurantAllergen";
import {notificationCenter} from "@/components/utilities/NotificationCenter";
import {user} from "@/user";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import Fuse from "fuse.js";

export default {
    name: "RestaurantAllergenManagementTab",
    components: {
        RestaurantAllergen,
        AddAllergenModalBox,
        SimpleButton,
        SimpleTextbox,
    },
    props: {
        loadOnRestaurantIdChange: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            restaurantPresentation: {},
            allergens: [],
            restaurantId: undefined,
            selectedAllergen: undefined,
            searchBarValue: "",
            exportMode: false,
            isWaitingFileLoad: false,
            selectAllOn: false,
            selectedExportAllergens: [],
        };
    },
    computed: {
        // <fuse>
        fuse () {
            return new Fuse(this.allergens, {
                includeScore: true,
                keys: [ "localizations.name", ],
            });
        },
        // </fuse>
        filteredAllergens () {
            if (this.searchBarValue.length > 0) {
                return this.fuse.search(this.searchBarValue).map((entity) => entity.item);
            }

            return this.allergens;
        },
        selectAllButtonModifiers () {
            return {
                "action-button__selected": this.selectAllOn,
            }
        },
    },
    methods: {
        async load (restaurantId) {
            this.restaurantPresentation = await server.getRestaurantPresentation(restaurantId);
            this.allergens = this.restaurantPresentation.allergens;
            this.restaurantId = restaurantId;
        },

        onAllergenAdd (allergen) {
            this.allergens.push(allergen);
        },

        showAddAllergenModalBox () {
            this.$refs.addAllergenModalBox.window.show();
        },

        onAllergenUpdate ({oldId, allergen}) {
            const index = this.findAllergenIndexById(oldId);
            if (index !== -1) {
                this.allergens.splice(index, 1);
            }
            this.allergens.push(allergen);
        },

        onAllergenDelete (allergenId) {
            const index = this.findAllergenIndexById(allergenId);
            if (index !== -1) {
                this.allergens.splice(index, 1);
            }
        },

        findAllergenIndexById (id) {
            for (let i = 0; i < this.allergens.length; ++i) {
                const allergen = this.allergens[i];

                if (allergen.id === id) {
                    return i;
                }
            }

            return -1;
        },
        openNativeFilePicker () {
            this.$refs.fileInput.click();
        },

        async onFileChange (event) {
            if (this.isWaitingFileLoad) {
                return;
            }

            this.isWaitingFileLoad = true;

            try {
                const file = event.target.files[0];
                const fileReader = new FileReader();

                fileReader.readAsText(file);
                fileReader.addEventListener("error", () => {
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),})
                });
                fileReader.addEventListener("load", async (event) => {
                    const data = event.target.result;

                    const response = await user.bulkCreateAllergens({
                        allergens: JSON.parse(data),
                        restaurantId: this.restaurantId,
                    });

                    if (response.status === "ok") {
                        this.allergens = await server.getRestaurantAllergens(this.restaurantId);
                    }
                    this.isWaitingFileLoad = false;
                });
            }
            catch (error) {
                console.log(error);
                this.isWaitingFileLoad = false;
            }
        },

        toggleSelectAllToExport () {
            if (this.selectedExportAllergens.length === this.allergens.length) {
                this.selectAllOn = false;
                this.allergens.forEach((c) => {
                    c.export = false;
                })
                this.selectedExportAllergens = [];
            }
            else {
                this.selectAllOn = true;
                this.allergens.forEach((v) => {
                    v.export = true;
                    this.selectedExportAllergens = [ ...this.allergens, ];
                })
            }
            this.allergens.sort();
        },

        toggleExportMode (value) {
            if (value && !this.exportMode) {
                this.exportMode = value; // First click to choose variation to export
            }
            else if (value && this.exportMode) { // Second click, confirm export
                const exportData = this.selectedExportAllergens.map((allergen) => {
                    return {
                        localizations: allergen.localizations.map((localization) => {
                            return {
                                name: localization.name,
                                languageIso: localization.languageIso,
                            };
                        }),
                    }
                });
                if (exportData.length > 0) {
                    // <Create JSON file>
                    const a = document.createElement("a");
                    const file = new Blob([JSON.stringify(exportData)], { type: "text/plain" });
                    a.href = URL.createObjectURL(file);
                    a.download = `allergens_${Date.now()}.json`;
                    a.click();
                    // </Create JSON file>
                    this.selectedExportAllergens.forEach((c) => c.export = false);
                    this.selectedExportAllergens = [];
                    this.exportMode = false;
                    this.allergens.sort();
                }
            }
            else {
                this.exportMode = value; // Cancel export
            }
        },

        async toggleAllergensStatus (allergen) {
            if (!this.exportMode) {
                await this.toggleArchive(allergen);
            }
            else {
                const index = this.findAllergenIndexById(this.selectedExportAllergens, allergen.id);
                if (index !== -1) {
                    allergen.export = false;
                    this.selectedExportAllergens.splice(index, 1);
                }
                else {
                    allergen.export = true;
                    this.selectedExportAllergens.push(allergen);
                }
                this.allergens.sort(); // Force rerender
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";
.add-allergen-button {
    cursor: pointer;
    margin: 0 0 50px 25px;
    padding: 10px 20px;
    background-color: rgb(77, 77, 77);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.022), 0 0 6px 0 rgba(0, 0, 0, 0.034);
    border-radius: 6px;
    &__text {
        font-size: 14px;
        font-weight: 500;
        color: rgb(255, 255, 255);
    }
    &__icon {
        width: 13px;
        margin-left: 5px;
        fill: rgb(255, 255, 255);
    }
}
.modify-allergen-button {
    cursor: pointer;
    margin: 0 0 50px 25px;
    padding: 10px 20px;
    background-color: rgb(77, 77, 77);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.022), 0 0 6px 0 rgba(0, 0, 0, 0.034);
    border-radius: 6px;
    &__text {
        font-size: 14px;
        font-weight: 500;
        color: rgb(255, 255, 255);
    }
    &__icon {
        width: 14px;
        margin-left: 5px;
        fill: rgb(255, 255, 255);
    }
}
.no-allergens-text {
    font-size: 20px;
    font-weight: 500;
    color: rgb(80, 80, 80);
}
.allergen-list {
    margin: 0;

    background-color: rgb(255, 255, 255);
    box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);
}

.action-box {
    margin-bottom: 50px;

    .action-button {
        border-radius: 5px;
        padding: 10px;
        min-width: 100px;
        margin-left: 15px;

        &__selected {
            :deep(.simple-button__text) {
                color: $primary-brand-color;
            }
        }
    }
}

.search-bar {
    position: relative;

    margin: 0;

    :deep(.simple-textbox__text-input) {
        margin: 0;
        padding: 10px 20px;

        border: 2px solid $primary-brand-color;
        border-radius: 1000px;
        background-color: rgb(252, 252, 252);

        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.02rem;
        color: rgb(0, 0, 0);

        &:focus {
            background-color: rgba($primary-brand-color, 0.06);
        }
    }
}

.restaurant-allergen-box {
    position: relative;

    width: 100%;

    cursor: pointer;

    :deep(.restaurant-allergen) {
        background-color: rgb(240, 240, 240);
        border-radius: 0;

        &__name {
            padding: 3px;
            font-size: 16px;
            font-weight: 500;
            color: rgb(0, 0, 0);
        }

        &:hover {
            background-color: rgb(226, 226, 226);
        }
    }

    .toggle-button {
        position: absolute;
        right: 0;
        top: 25%;
        bottom: 25%;
        margin-top: auto;
        margin-bottom: auto;
        min-width: 40px;

        &:hover {
            .toggle-button__icon {
                transform: scale(1.3);
            }
        }
    }
}
</style>
