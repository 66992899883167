<template>
    <div class="flex flex--100 user-tab" v-if="restaurant">
        <div class="flex flex--100 box">
            <div class="flex flex--100 separator separator--no-margin-top">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.generic.restaurant`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100 flex--direction-column">
                <div class="flex flex--60 flex--y-align-center item">
                    <div class="flex flex--40">
                        <span class="item__key">{{$t(`management.generic.name`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--60 flex--x-align-end">
                        <simple-textbox v-model:model-value="restaurant.name" :is-disabled="true" class="item__value"/>
                    </div>
                </div>
                <div class="flex flex--60 flex--y-align-center item">
                    <div class="flex flex--40">
                        <span class="item__key">{{$t(`management.generic.address`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--60 flex--x-align-end">
                        <simple-textbox v-model:model-value="restaurant.address" :is-disabled="true" class="item__value"/>
                    </div>
                </div>
                <div class="flex flex--60 flex--y-align-center item">
                    <div class="flex flex--40">
                        <span class="item__key">{{$t(`management.generic.country`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--60 flex--x-align-end">
                        <simple-textbox v-model:model-value="restaurant.countryIso" :is-disabled="true" class="item__value"/>
                    </div>
                </div>
            </div>
            <div class="flex flex--100 separator">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.generic.actions`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100 flex--direction-column">
                <div class="flex flex--40 flex--y-align-center item">
                    <div class="flex flex--50">
                        <span class="item__key">{{$t(`management.generic.open`).toLocaleUpperCase()}} / {{$t(`management.generic.close`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <simple-checkbox @update:modelValue="onRestaurantOpenClose" :disabled="restaurantOpenCloseLoading" appearance="slider" text="" :model-value="Boolean(restaurant.isOpen)"/>
                    </div>
                </div>
                <div class="flex flex--40 flex--y-align-center item">
                    <div class="flex flex--100">
                        <span class="item__key">{{$t(`management.generic.serviceTime`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--100 day-box">
                        <simple-button v-for="day of serviceTime" :key="day.name" class="day-button" :text="getDayText(day)" @click="openUpdateServiceTimeModal(day)"/>
                    </div>
                </div>
                <div class="flex flex--40 flex--y-align-center item" v-if="userId === 1">
                    <div class="flex flex--100">
                        <span class="item__key">{{$t(`management.generic.userDisplayLocalizations`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--100 day-box">
                        <multi-selectbox 
                        class="multi-select-box"
                        :options="languages" 
                        :multiple="true" 
                        :loading="isWaitingServerResponse"
                        :selectPlaceholder="$t(`generic.languages`)"
                        label="text"
                        track-by="value"
                        v-model:modelValue="selectedLanguages"
                        @update:modelValue="saveSelectedLanguages"
                        />
                    </div>
                </div>
            </div>
            <div class="flex flex--100 separator">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.generic.configuration`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100 flex--direction-column">
                <div class="flex flex--40 flex--y-align-center item">
                    <div class="flex flex--50">
                        <span class="item__key">{{$t(`management.restaurantManagementTab.websiteOrder`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <simple-checkbox :disabled="websiteOrderEnabledLoading" @update:modelValue="onWebsiteOrderEnabledChanged" appearance="slider" text="" :model-value="Boolean(restaurant.configuration.websiteOrderEnabled)"/>
                    </div>
                </div>
                <div class="flex flex--40 flex--y-align-center item">
                    <div class="flex flex--50">
                        <span class="item__key">{{$t(`management.restaurantManagementTab.kioskEnabled`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <simple-checkbox :disabled="kioskEnabledLoading" @update:modelValue="onKioskEnabledChanged" appearance="slider" text="" :model-value="Boolean(restaurant.configuration.kioskEnabled)"/>
                    </div>
                </div>
                <div class="flex flex--40 flex--y-align-center item">
                    <div class="flex flex--50">
                        <span class="item__key">{{$t(`management.restaurantManagementTab.paymentAfterService`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <simple-checkbox :disabled="paymentAfterServiceLoading"  @update:modelValue="onPaymentAfterServiceChanged" appearance="slider" text="" :model-value="Boolean(restaurant.configuration.paymentAfterService)"/>
                    </div>
                </div>
                <div class="flex flex--40 flex--y-align-center item">
                    <div class="flex flex--50">
                        <span class="item__key">{{$t(`management.restaurantManagementTab.skipDeliveryState`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <simple-checkbox :disabled="skipDeliveryStateLoading"  @update:modelValue="onSkipDeliveryStateChanged" appearance="slider" text="" :model-value="Boolean(restaurant.configuration.skipDeliveryState)"/>
                    </div>
                </div>
                <div class="flex flex--40 flex--y-align-center item">
                    <div class="flex flex--50">
                        <span class="item__key">{{$t(`management.restaurantManagementTab.skipPreparationState`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <simple-checkbox :disabled="skipPreparationStateLoading"  @update:modelValue="onSkipPreparationStateChanged" appearance="slider" text="" :model-value="Boolean(restaurant.configuration.skipPreparationState)"/>
                    </div>
                </div>
                <div class="flex flex--60 flex--y-align-center item">
                    <div class="flex flex--40">
                        <span class="item__key">{{$t(`management.restaurantManagementTab.maxDeliveryDistance`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--30">
                        <simple-textbox ref="maxDeliveryDistance" placeholder="UM: METER (> 0)" v-model:model-value="restaurant.configuration.maxDeliveryDistance" class="item__value"/>
                    </div>
                    <div class="flex flex--30 flex--x-align-end">
                        <simple-button @click="onMaxDeliveryDistanceConfirm" :is-loading="isWaitingServerResponse" :text="$t(`management.restaurantManagementTab.saveDistance`).toLocaleUpperCase()" />
                    </div>
                </div>
                <div class="flex flex--60 flex--y-align-center item">
                    <div class="flex flex--40">
                        <span class="item__key">{{$t(`management.restaurantManagementTab.cover`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--30">
                        <simple-textbox ref="cover" placeholder="EUR €" v-model:model-value="restaurant.configuration.cover" class="item__value"/>
                    </div>
                    <div class="flex flex--30 flex--x-align-end">
                        <simple-button @click="onCoverConfirm" :is-loading="isWaitingServerResponse" :text="$t(`management.restaurantManagementTab.saveCover`).toLocaleUpperCase()" />
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex--100 box">
            <div class="flex flex--100 separator separator--no-margin-top">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.restaurantManagementTab.pointOfWork`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100">
                <a :href="posLocation" target="_blank" class="flex flex--y-align-center point-of-work">POS</a>
                <a :href="pocLocation" target="_blank" class="flex flex--y-align-center point-of-work">POC</a>
                <a :href="podLocation" target="_blank" class="flex flex--y-align-center point-of-work">POD</a>
            </div>
            <div class="flex flex--100 endpoint-box">
                <simple-button @click="openAddEndpointModalBox" :is-loading="isWaitingServerResponse" :text="$t('management.restaurantManagementTab.createEndpoint')" />
                <div class="flex flex--100 endpoint-list">
                    <div @click="openUpdateEndpointModalBox(endpoint)" class="endpoint" v-for="endpoint in endpoints" :key="endpoint.id">
                        <span class="endpoint__text">{{  endpoint.name }}<span :class="getEndpointOnlineModifiers(endpoint.isOnline)"> ●</span></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex--100 box" v-if="userId === 1">
            <div class="flex flex--100 separator separator--no-margin-top">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.restaurantManagementTab.stripe`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100">
                <div class="flex flex--100 stripe-textbox">
                    <simple-textbox v-model="restaurant.configuration.stripeSecretKey" placeholder="STRIPE SECRET KEY" class="item__value"/>
                </div>
                <div class="flex flex--100 stripe-textbox">
                    <simple-textbox v-model="restaurant.configuration.stripePublishableKey" placeholder="STRIPE PUBLISHER KEY" class="item__value"/>
                </div>
                <div class="flex flex--100">
                    <simple-textbox v-model="restaurant.configuration.stripeWebhook" placeholder="STRIPE WEBHOOK KEY" class="item__value"/>
                </div>
                <simple-button
                    class="custom-css-button"
                    :text="$t(`management.generic.update`).toLocaleUpperCase()"
                    :is-loading="isWaitingServerResponse"
                    @click="onStripeTokenConfirm"
                />
            </div>
        </div>
        <div class="flex flex--100 box">
            <div class="flex flex--100 separator separator--no-margin-top">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.restaurantManagementTab.customCSS`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100">
                <prism-editor class="custom-css-textbox" v-model:model-value="restaurant.configuration.customStyle" :highlight="highlightCss"></prism-editor>
                <simple-button
                    class="custom-css-button"
                    :text="$t(`management.generic.update`).toLocaleUpperCase()"
                    :is-loading="isWaitingServerResponse"
                    @click="updateConfigurationCustomStyle"
                />
            </div>
        </div>
        <add-endpoint-modal-box :restaurant-id="restaurantId" ref="addEndpointModalBox" @endpoint-add="onEndpointAdd"/>
        <update-endpoint-modal-box ref="updateEndpointModalBox" @endpoint-update="onEndpointUpdate" @endpoint-delete="onEndpointDelete"/>
        <update-service-time-modal-box ref="updateServiceTimeModalBox" :closeOnServiceTimeUpdate="true" :restaurant="restaurant" :service-time="serviceTime" @time-update="onTimeUpdate"/>
    </div>
</template>

<script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";

import SimpleCheckbox from "@/components/inputs/SimpleCheckbox";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import SimpleButton from "@/components/inputs/SimpleButton";
import { user } from "@/user";
import { server } from "@/server";
import {notificationCenter} from "@/components/utilities/NotificationCenter";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-css";
import "prismjs/themes/prism-tomorrow.css";
import AddEndpointModalBox from "@/components/windows/AddEndpointModalBox";
import UpdateEndpointModalBox from "@/components/windows/UpdateEndpointModalBox";
import UpdateServiceTimeModalBox from "@/components/windows/UpdateServiceTimeModalBox";
import MultiSelectbox from "@/components/inputs/MultiSelectbox.vue";

export default {
    name: "RestaurantManagementTab",
    components: {
        SimpleCheckbox,
        SimpleTextbox,
        SimpleButton,
        PrismEditor,
        AddEndpointModalBox,
        UpdateEndpointModalBox,
        UpdateServiceTimeModalBox,
        MultiSelectbox,
    },
    data () {
        return {
            restaurantId: null,
            restaurant: null,
            endpoints: [],
            isWaitingServerResponse: false,
            websiteOrderEnabledLoading: false,
            paymentAfterServiceLoading: false,
            skipDeliveryStateLoading: false,
            skipPreparationStateLoading: false,
            kioskEnabledLoading: false,
            restaurantOpenCloseLoading: false,
            serviceTime: [
                {
                    name: "Monday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
                {
                    name: "Tuesday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
                {
                    name: "Wednesday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
                {
                    name: "Thursday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
                {
                    name: "Friday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
                {
                    name: "Saturday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
                {
                    name: "Sunday",
                    times: [{
                        range: [{ hours: 10, minutes: 0, seconds: 0 }, 
                            {hours: 22, minutes: 0, seconds: 0}, 
                        ]
                    }]
                },
            ],
            languages: [
                { text: this.$t("languages.en"), value: "en" },
                { text: this.$t("languages.es"), value: "es" },
                // { text: this.$t("languages.fr"), value: "fr" },
                // { text: this.$t("languages.de"), value: "de" },
                { text: this.$t("languages.zh"), value: "zh" },
                // { text: this.$t("languages.zh_hk"), value: "zh_hk" },
                // { text: this.$t("languages.ja"), value: "ja" },
                // { text: this.$t("languages.ko"), value: "ko" },
                { text: this.$t("languages.it"), value: "it" },
                // { text: this.$t("languages.pt"), value: "pt" },
                // { text: this.$t("languages.ru"), value: "ru" },
                // { text: this.$t("languages.hi"), value: "hi" },
                // { text: this.$t("languages.ar"), value: "ar" },
                // { text: this.$t("languages.nl"), value: "nl" },
                // { text: this.$t("languages.el"), value: "el" },
                // { text: this.$t("languages.sv"), value: "sv" },
                // { text: this.$t("languages.no"), value: "no" },
                // { text: this.$t("languages.da"), value: "da" },
                // { text: this.$t("languages.fi"), value: "fi" },
                // { text: this.$t("languages.tr"), value: "tr" },
            ],
            selectedLanguages: [],
        };
    },
    computed: {
        posLocation () {
            return `/restaurant/${this.restaurantId}/pos`;
        },

        pocLocation () {
            return `/restaurant/${this.restaurantId}/poc`;
        },

        podLocation () {
            return `/restaurant/${this.restaurantId}/pod`;
        },
        userId () {
            return user.descriptor.id;
        }
    },
    methods: {
        getEndpointOnlineModifiers (isOnline) {
            return {
                "endpoint--online": isOnline,
                "endpoint--offline": !isOnline,
            };
        },
        async onRestaurantOpenClose (value) {
            if (this.restaurantOpenCloseLoading) {
                return;
            }

            this.restaurantOpenCloseLoading = true;

            try {
                if (value) {
                    const restaurant = await user.openRestaurant({ id: this.restaurantId});
                    if (restaurant) {
                        this.restaurant.isOpen = !!restaurant.isOpen;
                        notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.restaurantOpenSuccess`), });
                    }
                    else {
                        notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                    }
                }
                else {
                    const restaurant = await user.closeRestaurant({ id: this.restaurantId});
                    if (restaurant) {
                        this.restaurant.isOpen = !!restaurant.isOpen;
                        notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.restaurantCloseSuccess`), });
                    }
                    else {
                        notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                    }
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }
            this.restaurantOpenCloseLoading = false;
        },

        highlightCss (code) {
            return highlight(code, languages.css, "css");
        },

        async load (restaurantId) {
            this.restaurantId = restaurantId;
            this.restaurant = await server.getRestaurant(restaurantId);
            this.endpoints = await server.getRestaurantEndpoints(restaurantId);

            const configuration = await user.setConfiguration({ id: this.restaurantId, });
            const stripe = await server.getRestaurantStripeKeys(this.restaurantId);

            this.restaurant.configuration = {
                websiteOrderEnabled: !!configuration.websiteOrderEnabled,
                paymentAfterService: !!configuration.paymentAfterService,
                skipDeliveryState: !!configuration.skipDeliveryState,
                skipPreparationState: !!configuration.skipPreparationState,
                kioskEnabled: !!configuration.kioskEnabled,
                maxDeliveryDistance: configuration.maxDeliveryDistance.toString(),
                customStyle: configuration.customStyle,
                stripeSecretKey: stripe.stripeSecretKey ?? "",
                stripePublishableKey: stripe.stripePublishableKey ?? "",
                stripeWebhook: stripe.stripeWebhook ?? "",
                cover: configuration.cover?.toString() ?? "",
                userDisplayLocalizations: JSON.parse(configuration.userDisplayLocalizations) ?? "",
            };
            if (this.restaurant.serviceTime) {
                  this.serviceTime = JSON.parse(this.restaurant.serviceTime);
            }
            if (configuration.userDisplayLocalizations) {
                this.selectedLanguages = JSON.parse(configuration.userDisplayLocalizations) ?? [];
            }   
        },

        async onWebsiteOrderEnabledChanged (value) {
            if (this.websiteOrderEnabledLoading) {
                return;
            }

            this.websiteOrderEnabledLoading = true;

            try {
              const configuration = await user.setConfiguration({id: this.restaurantId, websiteOrderEnabled: value});
              if (value) {
                  if (configuration) {
                    this.restaurant.configuration.websiteOrderEnabled = !!configuration.websiteOrderEnabled;
                    notificationCenter?.sendSuccessNotification({text: this.$t(`notification.websiteOrderEnabledSuccess`),});
                  } else {
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),});
                  }
              }
              else {
                  if (configuration) {
                    this.restaurant.configuration.websiteOrderEnabled = !!configuration.websiteOrderEnabled;
                    notificationCenter?.sendSuccessNotification({text: this.$t(`notification.websiteOrderDisabledSuccess`),});
                  } else {
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),});
                  }
              }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }
            this.websiteOrderEnabledLoading = false;
        },

        async onPaymentAfterServiceChanged (value) {
            if (this.paymentAfterServiceLoading) {
                return;
            }

            this.paymentAfterServiceLoading = true;

            try {
              const configuration = await user.setConfiguration({id: this.restaurantId, paymentAfterService: value});
              if (value) {
                  if (configuration) {
                    this.restaurant.configuration.paymentAfterService = !!configuration.paymentAfterService;
                    notificationCenter?.sendSuccessNotification({text: this.$t(`notification.paymentAfterServiceEnabledSuccess`),});
                  } else {
                    this.restaurant.configuration.paymentAfterService = !value;
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),});
                  }
              }
              else {
                  if (configuration) {
                    this.restaurant.configuration.paymentAfterService = !!configuration.paymentAfterService;
                    notificationCenter?.sendSuccessNotification({text: this.$t(`notification.paymentAfterServiceDisabledSuccess`),});
                  } else {
                    this.restaurant.configuration.paymentAfterService = !value;
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),});
                  }
              }
            }
            catch (error) {
                this.restaurant.configuration.paymentAfterService = !value;
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.paymentAfterServiceLoading = false;
        },

        async onKioskEnabledChanged (value) {
            if (this.kioskEnabledLoading) {
                return;
            }

            this.kioskEnabledLoading = true;

            try {
              const configuration = await user.setConfiguration({id: this.restaurantId, kioskEnabled: value});
              if (value) {
                  if (configuration) {
                    this.restaurant.configuration.kioskEnabled = !!configuration.kioskEnabled;
                    notificationCenter?.sendSuccessNotification({text: this.$t(`notification.kioskEnabledSuccess`),});
                  } else {
                    this.restaurant.configuration.kioskEnabled = !value;
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),});
                  }
              }
              else {
                  if (configuration) {
                    this.restaurant.configuration.kioskEnabled = !!configuration.kioskEnabled;
                    notificationCenter?.sendSuccessNotification({text: this.$t(`notification.kioskDisabledSuccess`),});
                  } else {
                    this.restaurant.configuration.kioskEnabled = !value;
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),});
                  }
              }
            }
            catch (error) {
                this.restaurant.configuration.kioskEnabled = !value;
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.kioskEnabledLoading = false;
        },

        async onMaxDeliveryDistanceConfirm () {
            const value = this.restaurant.configuration.maxDeliveryDistance;
            if (this.isWaitingServerResponse && value !== "" && Number.parseInt(value) > 0) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const configuration = await user.setConfiguration({ id: this.restaurantId, maxDeliveryDistance: value});

                if (configuration) {
                    this.restaurant.configuration.maxDeliveryDistance = configuration.maxDeliveryDistance.toString();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.maxDeliveryDistanceChangeSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }

            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },

        async onCoverConfirm () {
            const value = this.restaurant.configuration.cover;
            if (this.isWaitingServerResponse && value !== "" && Number.parseFloat(value) > 0) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const configuration = await user.setConfiguration({ id: this.restaurantId, cover: value});

                if (configuration) {
                    this.restaurant.configuration.cover = configuration.cover.toString();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.coverChangeSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }

            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },

        async onStripeTokenConfirm () {
            if (this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const configuration = await user.setConfiguration({
                    id: this.restaurantId,
                    stripePublishableKey: this.restaurant.configuration.stripePublishableKey,
                    stripeWebhook: this.restaurant.configuration.stripeWebhook,
                    stripeSecretKey: this.restaurant.configuration.stripeSecretKey,
                });

                if (configuration) {
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.stripeConfigurationChangeSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }

            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },

        async onSkipDeliveryStateChanged (value) {
            if (this.skipDeliveryStateLoading) {
                return;
            }

            this.skipDeliveryStateLoading = true;
            try {
              const configuration = await user.setConfiguration({id: this.restaurantId, skipDeliveryState: value});
              if (value) {
                  if (configuration) {
                    this.restaurant.configuration.skipDeliveryState = !!configuration.skipDeliveryState;
                    notificationCenter?.sendSuccessNotification({text: this.$t(`notification.skipDeliveryStateEnabledSuccess`),});
                  } else {
                    this.restaurant.configuration.skipDeliveryState = !value;
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),});
                  }
              }
              else {
                  if (configuration) {
                    this.restaurant.configuration.skipDeliveryState = !!configuration.skipDeliveryState;
                    notificationCenter?.sendSuccessNotification({text: this.$t(`notification.skipDeliveryStateDisabledSuccess`),});
                  } else {
                    this.restaurant.configuration.skipDeliveryState = !value;
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),});
                  }
              }
            }
            catch (error) {
                this.restaurant.configuration.skipDeliveryState = !value;
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.skipDeliveryStateLoading = false;
        },

        async onSkipPreparationStateChanged (value) {
            if (this.skipPreparationStateLoading) {
                return;
            }

            this.skipPreparationStateLoading = true;
            try {
              const configuration = await user.setConfiguration({id: this.restaurantId, skipPreparationState: value});
              if (value) {
                  if (configuration) {
                    this.restaurant.configuration.skipPreparationState = !!configuration.skipPreparationState;
                    notificationCenter?.sendSuccessNotification({text: this.$t(`notification.skipPreparationStateEnabledSuccess`),});
                  } else {
                    this.restaurant.configuration.skipPreparationState = !value;
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),});
                  }
              }
              else {
                  if (configuration) {
                    this.restaurant.configuration.skipPreparationState = !!configuration.skipPreparationState;
                    notificationCenter?.sendSuccessNotification({text: this.$t(`notification.skipPreparationStateDisabledSuccess`),});
                  } else {
                    this.restaurant.configuration.skipPreparationState = !value;
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),});
                  }
              }
            }
            catch (error) {
                this.restaurant.configuration.skipPreparationState = !value;
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.skipPreparationStateLoading = false;
        },

        async updateConfigurationCustomStyle () {
            if (this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const configuration = await user.setConfiguration({ id: this.restaurantId, customStyle: this.restaurant.configuration.customStyle, });

                if (configuration) {
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.customStyleChangeSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }

            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },

        openAddEndpointModalBox () {
            this.$refs.addEndpointModalBox.window.show();
        },

        openUpdateEndpointModalBox (endpoint) {
            this.$refs.updateEndpointModalBox.window.show();
            this.$refs.updateEndpointModalBox.load(endpoint);
        },

        onEndpointAdd (endpoint) {
            this.endpoints.push(endpoint);
        },

        onEndpointUpdate (endpoint) {
            const toUpdate = this.endpoints.find((e) => e.id === endpoint.id);
            toUpdate.name = endpoint.name;
            toUpdate.configuration = endpoint.configuration;
            toUpdate.mode = endpoint.mode;
        },

        onEndpointDelete (id) {
            const index = this.endpoints.findIndex((e) => e.id === id);
            this.endpoints.splice(index, 1);
        },

        openUpdateServiceTimeModal (day) {
            this.$refs.updateServiceTimeModalBox.window.show();
            this.$refs.updateServiceTimeModalBox.load(day);
        },

        getDayText (day) {
            let output = this.$t(`day.${day.name}`).toUpperCase();

            if (day.times.length === 0) {
                output += ` ${this.$t("generic.closed")}`.toUpperCase();
            }
            day.times.forEach((t) => {
                if (t.range?.length === 2) {
                     output += ` ${t.range[0].hours.toString().padStart(2, '0')}:${t.range[0].minutes.toString().padStart(2, '0')}-${t.range[1].hours.toString().padStart(2, '0')}:${t.range[1].minutes.toString().padStart(2, '0')}`;
                }
            })
            return output;
        },
        
        async saveSelectedLanguages(value) {
            if (this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const userDisplayLocalizations = await user.updateUserDisplayLocalizations({
                    restaurantId: this.restaurantId,
                    userDisplayLocalizations: JSON.stringify(value)
                });

                if (userDisplayLocalizations) {
                    this.selectedLanguages = JSON.parse(userDisplayLocalizations);
                    notificationCenter?.sendSuccessNotification({ 
                        text: this.$t(`notification.languagesUpdateSuccess`) 
                    });
                } else {
                    notificationCenter?.sendFailureNotification({ 
                        text: this.$t(`notification.serverError`) 
                    });
                }
            } catch (error) {
                notificationCenter?.sendFailureNotification({ 
                    text: this.$t(`notification.networkError`) 
                });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";

.point-of-work {
    padding: 12px 20px;

    background-color: rgba(64, 102, 255, 0.1);
    border-radius: 6px;

    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color: rgb(64, 102, 255);

    & + & {
        margin-left: 24px;
    }

    &:hover {
        background-color: rgba(64, 102, 255, 0.18);
    }
}

.custom-css-button {
    margin: 20px 0;
}

.custom-css-textbox {
    width: 100%;

    padding: 20px;

    background-color: rgb(240, 240, 240);
    border-radius: 6px;

    :deep(*) {
        font-family: "Fira code", "Fira Mono", "Consolas", "Menlo", "Courier", "monospace";
        font-size: 14px;
        line-height: 1.5;
    }
}

.simple-textbox {
    width: 90%;
}

.stripe-textbox {
    margin-bottom: 15px;
}

.endpoint-box {
    margin: 18px 0 18px 0;
}

.endpoint-list {
    padding: 20px 0 0 0;
    .endpoint:first-child {
        margin-right: 20px;
    }
    .endpoint + .endpoint {
        margin-right: 20px;
    }
}

.endpoint {
    $background-color: $primary-color;
    $text-color: rgb(255, 255, 255);

    cursor: pointer;
    position: relative;
    padding: 14px;
    background-color: $background-color;
    border-radius: 12px;

    &--online {
        color: green;
    }

    &--offline {
        color: red;
    }

    &__text {
        font-size: 15px;
        font-weight: 600;
        text-decoration: none;
        color: $text-color;
    }
}

.day-box {
    gap: 10px;
    margin-top: 30px;
}

.day-button {
    min-width: none;
    padding: 5px 10px;
    max-width: 220px!important;
    border-radius: 0;
}

.multi-select-box {
    width: 300px;
}
</style>
