<template>
    <div class="flex flex--100 restaurant-product-stock-management-tab">
        <div class="flex flex--100 box">
            <div class="flex flex--100">
                <div class="flex flex--100 search-bar">
                    <simple-textbox :placeholder="$t('productList.searchBarPlaceholder')" v-model:model-value="searchBarValue"/>
                </div>
            </div>
            <div ref="productList" class="flex flex--x-align-center product-list">
                <span class="no-products" v-if="products.length === 0">{{ $t('management.restaurantProductManagementTab.noProducts' )}}</span>
                <div class="flex">
                    <div class="flex flex--100 restaurant-product-box" v-for="product in filteredProducts" :key="product.id" @click="onSelectProduct(product)">
                        <div class="flex flex--100 product-available-volume-editor">
                            <div class="flex flex--50">
                                <span class="flex flex--100 restaurant-product-box-header__name">{{ getLocalizedName(product) }}</span>
                            </div>
                            <div class="flex flex--50">
                                <span class="flex flex--30 product-available-volume-editor__label">{{$t(`management.restaurantProductsStockManagementTab.availableVolume`).toLocaleUpperCase()}}</span>
                                <div class="flex flex--70 flex--x-align-end">
                                    <simple-textbox
                                        v-if="selectedProduct === product"
                                        v-model:model-value="selectedProductAvailableVolume"
                                        :placeholder="$t('management.generic.unit')"
                                    />
                                    <span v-else class="product-available-volume-editor__text">{{ productAvailableVolumeText(product) }}</span>
                                </div>
                            </div>
                            <simple-button
                                v-if="selectedProduct === product"
                                class="flex flex--100 flex--y-align-center"
                                :text="$t(`management.generic.update`).toLocaleUpperCase()"
                                :is-loading="isWaitingServerResponseForUpdate"
                                :is-disabled="product.availableVolume === Number.parseInt(selectedProductAvailableVolume)"
                                @click="updateProductAvailableVolume"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {server} from "@/server";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import SimpleButton from "@/components/inputs/SimpleButton";
import Fuse from "fuse.js";
import {RestaurantProcessor} from "@/utilities";
import {user} from "@/user";
import {notificationCenter} from "@/components/utilities/NotificationCenter";

export default {
    name: "RestaurantProductStockManagementTab",
    components: {
        SimpleTextbox,
        SimpleButton,
    },
    props: {
        loadOnRestaurantIdChange: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            restaurantId: undefined,
            restaurantPresentation: null,
            products: [],
            categories: [],
            allergens: [],
            variations: [],
            searchBarValue: "",
            selectedProduct: null,
            selectedProductAvailableVolume: "",
            isWaitingServerResponseForUpdate: false,
        }
    },
    computed: {
        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },
        // <restaurant>

        // <fuse>
        fuse () {
            return new Fuse(this.restaurantProcessor.baseProducts.filter((product) => product.categories.length > 0), {
                includeScore: true,
                keys: [ "localizations.name", ],
            });
        },
        // </fuse>

        filteredProducts () {
            let filteredProducts = [];
            if (this.searchBarValue.length > 0) {
                filteredProducts = this.fuse.search(this.searchBarValue).map((entity) => entity.item);
                return filteredProducts;
            }
            try {
                filteredProducts = this.restaurantProcessor.products.filter((product) => product.categories.length > 0);
                return filteredProducts;
            }
            catch {
                return filteredProducts;
            }
        },
    },
    methods: {
        clear () {
            this.selectedProductAvailableVolume = "";
            this.selectedProduct = null;
        },
        async load (restaurantId) {
            this.restaurantPresentation = await server.getRestaurantPresentation(restaurantId);
            this.restaurantId = restaurantId;
            this.products = this.restaurantPresentation.products;
            this.categories = this.restaurantPresentation.categories;
            this.allergens = this.restaurantPresentation.allergens;
            this.variations = this.restaurantPresentation.variations;
        },
       getProductLocalization (product, languageIso) {
            return product.localizations.find((localization) => localization.languageIso === languageIso);
        },
        getLocalizedName (product) {
            let locale = this.getProductLocalization(product, this.$i18n.locale);
            if (locale) {
                return locale.name;
            }
            return this.getProductLocalization(product, "it")?.name ?? "";
        },
        onSelectProduct (product) {
            this.selectedProduct = product;
            this.selectedProductAvailableVolume = product.availableVolume;
        },
        onInputAvailableVolume (value) {
            this.selectedProductAvailableVolume = value;
        },
        async updateProductAvailableVolume () {
            if (this.isWaitingServerResponseForUpdate) {
                return;
            }

            this.isWaitingServerResponseForUpdate = true;
            try {
                const updatedProduct = await user.updateProductAvailableVolume({
                    id: this.selectedProduct.id,
                    availableVolume: this.selectedProductAvailableVolume,
                });

                if (updatedProduct) {
                    this.selectedProduct.availableVolume = updatedProduct.availableVolume;
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.productStockChangedSuccess`), });
                }
                else{
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }
            this.clear();
            this.isWaitingServerResponseForUpdate = false;
        },

        productAvailableVolumeText (product) {
            let text = "non definita";

            if (product.availableVolume === 0) {
                text = this.$t(`management.restaurantProductsStockManagementTab.unavailable`);
            }
            else if (product.availableVolume > 0) {
                text = `x${product.availableVolume}`;
            }

            return text;
        }
    }

}
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";

.restaurant-product-box {
    width: 100%;

    padding: 8px 12px;

    cursor: pointer;

    background-color: rgb(240, 240, 240);
    border-bottom: 1px solid rgb(220, 220, 220);

    &:hover {
        background-color: rgb(226, 226, 226);
    }
}

.restaurant-product-box-header {
    &__name {
        font-size: 16px;
        font-weight: 500;
    }
}

.product-list {
    .restaurant-product-box:first-child {
        border-radius: 6px 6px 0 0;
    }
    .restaurant-product-box:last-child {
        border: none;
        border-radius: 0 0 6px 6px;
    }
}

.no-products {
    font-size: 20px;
    font-weight: 500;
    color: rgb(80, 80, 80);
}

.product-available-volume-editor {
    &__label {
        font-size: 14px;
        font-weight: 600;

        color: rgb(126, 126, 126);
    }

    &__text {
        font-size: 16px;
        font-weight: 500;

        color: rgb(0, 0, 0);
    }

    :deep(.simple-textbox__text-input) {
        font-size: 16px;
        padding: 3px 13px 3px 13px;
        border-radius: 5px;
        text-align: end;
    }

    .simple-button {
        margin: 30px 0 5px 0;
        padding: 3px 13px 3px 13px;
    }
}

.search-bar {
    position: relative;

    margin: 0 0 50px 0;

    :deep(.simple-textbox__text-input) {
        margin: 0;
        padding: 10px 20px;

        border: 2px solid $primary-brand-color;
        border-radius: 1000px;
        background-color: rgb(252, 252, 252);

        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.02rem;
        color: rgb(0, 0, 0);

        &:focus {
            background-color: rgba($primary-brand-color, 0.06);
        }
    }
}

</style>
