<template>
    <div class="flex flex--100 restaurant-category-management-tab">
        <div class="flex flex--100 box">
            <div class="flex flex--100 flex--x-align-center">
                <div class="flex flex--x-align-center flex--y-align-center add-category-button" @click="showAddCategoryModalBox">
                    <span class="add-category-button__text">{{$t(`management.restaurantCategoryManagementTab.newCategory`).toLocaleUpperCase()}}</span>
                    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="flex add-category-button__icon">
                        <g stroke="none" stroke-width="1" fill-rule="evenodd">
                            <rect x="0" y="44" width="100" height="11" rx="5.5"/>
                            <rect x="44" y="0" width="11" height="100" rx="5.5"/>
                        </g>
                    </svg>
                </div>
                <div class="flex flex--y-align-center modify-active-category-button" @click="modifyActiveCategory">
                    <span class="modify-active-category-button__text">{{$t(`management.restaurantCategoryManagementTab.modifyActiveCategory`).toLocaleUpperCase()}}</span>
                    <svg class="flex modify-active-category-button__icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" version="1.1" id="Layer_1" viewBox="0 0 218.208 218.208" xml:space="preserve">
                        <g>
                            <g>
                                <g>
                                    <path d="M214.31,27.277H3.897C1.743,27.277,0,29.019,0,31.173v27.276v128.586c0,2.154,1.743,3.897,3.897,3.897h27.276h97.414     h27.276h58.448c2.154,0,3.897-1.743,3.897-3.897V58.449V31.173C218.207,29.019,216.464,27.277,214.31,27.277z M27.276,183.139     H7.793V62.345h19.483V183.139z M124.69,183.139H35.069V62.345h89.621V183.139z M151.966,183.139h-19.483V62.345h19.483V183.139z      M210.414,183.139h-50.655V62.345h50.655V183.139z M210.414,54.553h-54.552h-27.276H31.172H7.793V35.069h202.621V54.553z"/>
                                    <path d="M46.759,116.897H113c2.154,0,3.897-1.743,3.897-3.897V74.035c0-2.153-1.743-3.896-3.897-3.896H46.759     c-2.154,0-3.897,1.743-3.897,3.897v38.965C42.862,115.154,44.605,116.897,46.759,116.897z M50.655,77.931h58.448v31.172H50.655     V77.931z"/>
                                    <path d="M46.759,179.243H113c2.154,0,3.897-1.743,3.897-3.897v-38.966c0-2.154-1.743-3.897-3.897-3.897H46.759     c-2.154,0-3.897,1.743-3.897,3.897v38.966C42.862,177.501,44.605,179.243,46.759,179.243z M50.655,140.277h58.448v31.172H50.655     V140.277z"/>
                                    <rect x="46.759" y="120.793" width="66.241" height="7.793"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <div class="flex flex--100 action-box">
                    <div class="flex flex--20 search-bar">
                        <simple-textbox :placeholder="$t('productList.searchBarPlaceholder')" v-model:model-value="searchBarValue"/>
                    </div>
                    <div class="flex flex--80 flex--x-align-end">
                        <simple-button class="action-button" :text="$t(`management.generic.import`).toLocaleUpperCase()" @click="openNativeFilePicker" v-show="!exportMode" :is-loading="isWaitingFileLoad"/>
                        <input @change="onFileChange" ref="fileInput" type="file" accept="application/JSON" style="display:none">
                        <simple-button class="action-button" :class="selectAllButtonModifiers" :text="$t(`management.generic.selectAll`).toLocaleUpperCase()" @click="toggleSelectAllToExport" v-show="exportMode"/>
                        <simple-button class="action-button" :text="$t(`management.generic.export`).toLocaleUpperCase()" @click="toggleExportMode(true)"/>
                        <simple-button class="action-button" :text="$t(`management.generic.cancel`).toLocaleUpperCase()" @click="toggleExportMode(false)" v-show="exportMode"/>
                    </div>
                </div>
            </div>
            <div class="flex flex--100 flex--x-align-center category-list">
                <span class="no-categories-text" v-if="categories.length === 0">{{$t(`management.restaurantCategoryManagementTab.noCategories`)}}</span>
                <div class="restaurant-category-box" v-for="category in filteredCategories" :key="category.id">
                    <restaurant-category
                        :category="category"
                        :restaurant-presentation="restaurantPresentation"
                        :show-editor="true"
                        :selectable="true"
                        @category-update="onCategoryUpdate"
                    />
                    <div class="toggle-button" @click="toggleCategoriesStatus(category)">
                        <svg v-if="exportMode && !category.export" class="toggle-button__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                        <svg v-if="exportMode && category.export" class="toggle-button__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                    </div>
                </div>
            </div>
        </div>
        <add-category-modal-box :restaurant-presentation="restaurantPresentation" :restaurant-id="restaurantId" ref="addCategoryModalBox" @category-add="onCategoryAdd"/>
        <modify-active-category-modal-box :restaurant-id="restaurantId" :categories="categories" ref="modifyActiveCategoryModalBox" @category-add="onCategoryAdd"/>
    </div>
</template>

<script>
import RestaurantCategory from "@/components/RestaurantCategory";
import AddCategoryModalBox from "@/components/windows/AddCategoryModalBox";
import ModifyActiveCategoryModalBox from "@/components/windows/ModifyActiveCategoryModalBox";
import { server } from "@/server";
import {notificationCenter} from "@/components/utilities/NotificationCenter";
import {user} from "@/user";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import Fuse from "fuse.js";

export default {
    name: "RestaurantCategoryManagementTab",
    components: {
        AddCategoryModalBox,
        RestaurantCategory,
        ModifyActiveCategoryModalBox,
        SimpleButton,
        SimpleTextbox,
    },
    props: {
        loadOnRestaurantIdChange: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            restaurantId: undefined,
            restaurantPresentation: {},
            categories: [],
            activeCategories: [],
            searchBarValue: "",
            exportMode: false,
            isWaitingFileLoad: false,
            selectAllOn: false,
            selectedExportCategories: [],
        };
    },
    computed: {
        // <fuse>
        fuse () {
            return new Fuse(this.categories, {
                includeScore: true,
                keys: [ "localizations.name", ],
            });
        },
        // </fuse>
        filteredCategories () {
            if (this.searchBarValue.length > 0) {
                return this.fuse.search(this.searchBarValue).map((entity) => entity.item);
            }

            return this.categories;
        },
        selectAllButtonModifiers () {
            return {
                "action-button__selected": this.selectAllOn,
            }
        },
    },
    methods: {
        async load (restaurantId) {
            this.restaurantPresentation = await server.getRestaurantPresentation(restaurantId);
            this.categories = this.restaurantPresentation.categories;
            this.restaurantId = restaurantId;

            this.clear();
        },

        clear () {
            this.selectAllOn = false;
            this.selectedExportCategories = [];
            this.isWaitingFileLoad = false;
            this.isWaitingServerResponse = false;
            this.exportMode = false;
            this.searchBarValue = "";
        },

        showAddCategoryModalBox () {
            this.$refs.addCategoryModalBox.window.show();
        },

        showModifyActiveCategoryModalBox () {
            this.$refs.modifyActiveCategoryModalBox.window.show();
        },

        async modifyActiveCategory () {
            await this.$refs.modifyActiveCategoryModalBox.load();
            this.showModifyActiveCategoryModalBox();
        },

        onCategoryAdd (category) {
            this.categories.push(category);
        },

        onCategoryUpdate (category) {
            const index = this.findCategoryIndexById(category.id);
            if (index !== -1) {
                this.categories.splice(index, 1);
            }
            this.categories.push(category);
        },

        findCategoryIndexById (id) {
            for (let i = 0; i < this.categories.length; ++i) {
                const category = this.categories[i];

                if (category.id === id) {
                    return i;
                }
            }

            return -1;
        },

        openNativeFilePicker () {
            this.$refs.fileInput.click();
        },

        async onFileChange (event) {
            if (this.isWaitingFileLoad) {
                return;
            }

            this.isWaitingFileLoad = true;

            try {
                const file = event.target.files[0];
                const fileReader = new FileReader();

                fileReader.readAsText(file);
                fileReader.addEventListener("error", () => {
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),})
                });
                fileReader.addEventListener("load", async (event) => {
                    const data = event.target.result;

                    const response = await user.bulkCreateCategories({
                        categories: JSON.parse(data),
                        restaurantId: this.restaurantId,
                    });

                    if (response.status === "ok") {
                        this.categories = await server.getRestaurantCategories(this.restaurantId);
                    }
                    this.isWaitingFileLoad = false;
                });
            }
            catch (error) {
                console.log(error);
                this.isWaitingFileLoad = false;
            }
        },

        toggleSelectAllToExport () {
            if (this.selectedExportCategories.length === this.categories.length) {
                this.selectAllOn = false;
                this.categories.forEach((c) => {
                    c.export = false;
                })
                this.selectedExportCategories = [];
            }
            else {
                this.selectAllOn = true;
                this.categories.forEach((v) => {
                    v.export = true;
                    this.selectedExportCategories = [ ...this.categories, ];
                })
            }
            this.categories.sort();
        },

        toggleExportMode (value) {
            if (value && !this.exportMode) {
                this.exportMode = value; // First click to choose variation to export
            }
            else if (value && this.exportMode) { // Second click, confirm export
                const exportData = this.selectedExportCategories.map((category) => {
                    return {
                        localizations: category.localizations.map((localization) => {
                            return {
                                name: localization.name,
                                languageIso: localization.languageIso,
                            };
                        }),
                    }
                });
                if (exportData.length > 0) {
                    // <Create JSON file>
                    const a = document.createElement("a");
                    const file = new Blob([JSON.stringify(exportData)], { type: "text/plain" });
                    a.href = URL.createObjectURL(file);
                    a.download = `categories_${Date.now()}.json`;
                    a.click();
                    // </Create JSON file>
                    this.selectedExportVariations.forEach((c) => c.export = false);
                    this.selectedExportVariations = [];
                    this.exportMode = false;
                    this.categories.sort();
                }
            }
            else {
                this.exportMode = value; // Cancel export
            }
        },

        async toggleCategoriesStatus (category) {
            if (!this.exportMode) {
                await this.toggleArchive(category);
            }
            else {
                const index = this.findCategoryIndexById(this.selectedExportCategories, category.id);
                if (index !== -1) {
                    category.export = false;
                    this.selectedExportCategories.splice(index, 1);
                }
                else {
                    category.export = true;
                    this.selectedExportCategories.push(category);
                }
                this.categories.sort(); // Force rerender
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";

.add-category-button {
    cursor: pointer;

    margin: 0 0 50px 0;
    padding: 10px 20px;

    background-color: rgb(77, 77, 77);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.022), 0 0 6px 0 rgba(0, 0, 0, 0.034);
    border-radius: 6px 0 0 6px;
    border-right: 1px dashed rgb(255,255,255);

    &__text {
        font-size: 14px;
        font-weight: 500;
        color: rgb(255, 255, 255);
    }

    &__icon {
        width: 13px;
        margin-left: 5px;

        fill: rgb(255, 255, 255);
    }
}

.modify-active-category-button {
        cursor: pointer;

    margin: 0 0 50px 0;
    padding: 10px 20px;

    background-color: rgb(77, 77, 77);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.022), 0 0 6px 0 rgba(0, 0, 0, 0.034);
    border-radius: 0 6px 6px 0;

    &__text {
        font-size: 14px;
        font-weight: 500;
        color: rgb(255, 255, 255);
    }

    &__icon {
        width: 13px;
        margin-left: 5px;

        fill: rgb(255, 255, 255);
    }
}
.no-categories-text {
    font-size: 20px;
    font-weight: 500;
    color: rgb(80, 80, 80);
}

.category-list {
    margin: 0;

    background-color: rgb(255, 255, 255);
    box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);
}

.action-box {
    margin-bottom: 50px;

    .action-button {
        border-radius: 5px;
        padding: 10px;
        min-width: 100px;
        margin-left: 15px;

        &__selected {
            :deep(.simple-button__text) {
                color: $primary-brand-color;
            }
        }
    }
}

.search-bar {
    position: relative;

    margin: 0;

    :deep(.simple-textbox__text-input) {
        margin: 0;
        padding: 10px 20px;

        border: 2px solid $primary-brand-color;
        border-radius: 1000px;
        background-color: rgb(252, 252, 252);

        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.02rem;
        color: rgb(0, 0, 0);

        &:focus {
            background-color: rgba($primary-brand-color, 0.06);
        }
    }
}

.restaurant-category-box {
    position: relative;

    width: 100%;

    cursor: pointer;

    :deep(.restaurant-category) {
        background-color: rgb(240, 240, 240);
        border-radius: 0;

        &__name {
            padding: 3px;
            font-size: 16px;
            font-weight: 500;
            color: rgb(0, 0, 0);
        }

        &:hover {
            background-color: rgb(226, 226, 226);
        }
    }

    .toggle-button {
        position: absolute;
        right: 0;
        top: 25%;
        bottom: 25%;
        margin-top: auto;
        margin-bottom: auto;
        min-width: 40px;

        &:hover {
            .toggle-button__icon {
                transform: scale(1.3);
            }
        }
    }
}
</style>
