<template>
    <div class="flex flex--100 management-view">
        <div class="top-right-button" @click="toggleSidebar" v-show="isMobile">
            <div style="position: relative;">
                <div :class="{'nav-menu-arrow': true, 'arrow': show}">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
        <primary-user-navigation :show="show" @restaurant-change="onRestaurantChange" @navigation-change="onNavigationTabChange"/>
        <div class="flex main">
            <primary-user-navigation-tab ref="nav">
                <component :is="activeTabComponent" :ref="assignRef"/>
            </primary-user-navigation-tab>
        </div>
    </div>
</template>

<script>
import PrimaryUserNavigation from "@/components/navigation/PrimaryUserNavigation";
import PrimaryUserNavigationTab from "@/components/tabs/PrimaryUserNavigationTab";
import RestaurantAnalyticsTab from "@/components/tabs/RestaurantAnalyticsTab";
import RestaurantCategoryManagementTab from "@/components/tabs/RestaurantCategoryManagementTab";
import RestaurantManagementTab from "@/components/tabs/RestaurantManagementTab";
import RestaurantMediaTab from "@/components/tabs/RestaurantMediaTab";
import RestaurantPosterTab from "@/components/tabs/RestaurantPosterTab";
import RestaurantMenusTab from "@/components/tabs/RestaurantMenusTab";
import RestaurantOrderListTab from "@/components/tabs/RestaurantOrderListTab";
import RestaurantProductManagementTab from "@/components/tabs/RestaurantProductManagementTab";
import RestaurantAllergenManagementTab from "@/components/tabs/RestaurantAllergenManagementTab";
import RestaurantVariationsTab from "@/components/tabs/RestaurantVariationsTab";
import UserTab from "@/components/tabs/UserTab";
import RestaurantTableManagementTab from "@/components/tabs/RestaurantTableManagementTab";
import RestaurantProductStockManagementTab from "@/components/tabs/RestaurantProductStockManagementTab";
import RestaurantProductsAnalyticsTab from "@/components/tabs/RestaurantProductsAnalyticsTab";
import EventsManagementTab from "@/components/tabs/EventsManagementTab";
import ScenesManagementTab from "@/components/tabs/ScenesManagementTab";
import RestaurantFixedMenusTab from "@/components/tabs/RestaurantFixedMenusTab";
import CustomersManagementTab from "@/components/tabs/CustomersManagementTab";
import CustomerPromoCodesTab from "@/components/tabs/CustomerPromoCodesTab";
import { isMobile, } from '@/main';

export const NavigationTabComponents = {
    user: "UserTab",
    userOrders: "userOrders",
    restaurantManagement: "RestaurantManagementTab",
    restaurantMedia: "RestaurantMediaTab",
    restaurantPoster: "RestaurantPosterTab",
    restaurantCategoryManagement: "RestaurantCategoryManagementTab",
    restaurantOrderList: "RestaurantOrderListTab",
    restaurantAnalytics: "RestaurantAnalyticsTab",
    contentProducts: "RestaurantProductManagementTab",
    contentCategories: "RestaurantCategoryManagementTab",
    contentMenus: "RestaurantMenusTab",
    contentAllergens: "RestaurantAllergenManagementTab",
    contentVariations: "RestaurantVariationsTab",
    contentTables: "RestaurantTableManagementTab",
    restaurantProductStockManagement: "RestaurantProductStockManagementTab",
    restaurantProductsAnalytics: "RestaurantProductsAnalyticsTab",
    restaurantEventsManagement: "EventsManagementTab",
    restaurantScenesManagement: "ScenesManagementTab",
    restaurantFixedMenus: "RestaurantFixedMenusTab",
    customerPromoCodes: "CustomerPromoCodesTab",
    customersManagement: "CustomersManagementTab",
};

export default {
    name: "PrivateView",
    components: {
        EventsManagementTab,
        RestaurantProductStockManagementTab,
        RestaurantAnalyticsTab,
        RestaurantCategoryManagementTab,
        RestaurantMediaTab,
        RestaurantPosterTab,
        RestaurantOrderListTab,
        RestaurantMenusTab,
        RestaurantProductManagementTab,
        RestaurantManagementTab,
        PrimaryUserNavigationTab,
        UserTab,
        PrimaryUserNavigation,
        RestaurantAllergenManagementTab,
        RestaurantVariationsTab,
        RestaurantTableManagementTab,
        RestaurantProductsAnalyticsTab,
        ScenesManagementTab,
        RestaurantFixedMenusTab,
        CustomersManagementTab,
        CustomerPromoCodesTab,
    },
    data () {
        return {
            selectedRestaurantId: null,
            selectedNavigationTab: null,
            activeTabComponent: null,
            activeTabComponentRef: null,
            show: isMobile ? false : true,
            isMobile,
        }
    },
    methods: {
        assignRef ($el) {
            this.activeTabComponentRef = $el;
        },

        onRestaurantChange (value) {
            if (value) {
                this.selectedRestaurantId = value;
                window.sessionStorage.setItem("FortuneRMS::ManagementRestaurantId", value);
                this.activeTabComponent = NavigationTabComponents[this.selectedNavigationTab];
            }
        },

        onNavigationTabChange ({ restaurantId, value, }) {
            if (value) {
                this.selectedRestaurantId = restaurantId;
                this.selectedNavigationTab = value;
                this.activeTabComponent = NavigationTabComponents[this.selectedNavigationTab];
                window.sessionStorage.setItem("FortuneRMS::ManagementNavigationTab", value);
                if (isMobile) {
                    this.toggleSidebar();
                }
            }
        },

        toggleSidebar () {
            this.show = !this.show;
        },
    },
    watch: {
        activeTabComponentRef (actualValue) {
            if (actualValue) {
                const nav = this.$refs["nav"];
                if (this.activeTabComponent && this.activeTabComponentRef?.load) {
                    nav.load(() => actualValue.load(this.selectedRestaurantId));
                }
            }
        },

        selectedRestaurantId (actualValue, previousValue) {
            if (previousValue && actualValue) {
                const nav = this.$refs["nav"];

                if (this.activeTabComponent && this.activeTabComponentRef?.load) {
                    nav.load(() => this.activeTabComponentRef.load(this.selectedRestaurantId));
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.management-view {
    overflow: hidden;

    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    background-color: rgb(242, 242, 242);
    position: relative;

    .main {

    }

    .top-right-button {
        position: absolute;
        bottom: 16vh; // Adjust as needed
        bottom: calc(var(--vh, 1vh) * 16);
        right: 10px; // Adjust as needed
        background: rgb(255,255,255);
        border-radius: 8px;
        cursor: pointer;
        padding: 10px; // Optional: Add padding for a larger clickable area
        outline: none; // Remove outline on focus
        z-index: 1;

        .nav-menu-arrow {
            width: 50px;
            height: 50px;
            position: relative;
            cursor: pointer;
        }
        .nav-menu-arrow span {
            transform: rotate(0deg);
            transition: all 0.5s ease, top 0.5s ease;
            width: 50px;
            position: absolute;
            height: 10px;
            background-color: #000;
            border-radius: 5px;
            left: 0;
        }
        .nav-menu-arrow span:nth-child(1) {
            top: 0px;
        }
        .nav-menu-arrow span:nth-child(2) {
            top: 20px;
        }
        .nav-menu-arrow span:nth-child(3) {
            top: 40px;
        }
        .arrow span:nth-child(1) {
            transform: rotate(45deg);
            top: 10px;
            width: 30px;
            left: 20px;
        }
        .arrow span:nth-child(3) {
            transform: rotate(-45deg);
            top: 30px;
            width: 30px;
            left: 20px;
        }

    }
}
</style>
