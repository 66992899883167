<template>
    <div class="multi-selectbox" :class="getModifiers()">
        <multiselect
        v-model="selected"
        :options="options"
        :searchable="canSearchOptions"
        :placeholder="selectPlaceholder"
        :label="label"
        :track-by="trackBy"
        :loading="loading"
        select-label=""
        selected-label=""
        deselect-label=""
        :allow-empty="canSelectEmptyValue"
        :multiple="multiple">
            <template #noResult>
                {{ noResultsText }}
            </template>
            <template #noOptions>
                {{ noOptionsText }}
            </template>
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
    name: "MultiSelectbox",
    components: {
        Multiselect,
    },
    props: {
        selectPlaceholder: {
            type: String,
            default: "MultiSelectBox",
        },
        canSearchOptions: {
            type: Boolean,
            default: false,
        },
        searchPlaceholder: {
            type: String,
            default: "Type...",
        },
        noResultsText: {
            type: String,
            default: "No results found.",
        },
        noOptionsText: {
            type: String,
            default: "No options to choose from.",
        },
        options: {
            type: Array,
            default: () => [],
        },
        canSelectEmptyValue: {
            type: Boolean,
            default: true,
        },
        setOptionsLater: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: [Array, Object, String, Number],
            default: () => (this.multiple ? [] : null)
        },
        preSelect: {
            type: Boolean,
        },
        label: {
            type: String,
        },
        trackBy: {
            type: String,
        },
        multiple: {
            type: Boolean,
        },
        loading: {
            type: Boolean,
            default: false,
        }
    },
    emits: [ "options-load", "update:modelValue" ],
    data () {
        return {
            choicesInstance: null,
            savedOptions: [],
            internalSelected: this.multiple ? [] : null,
        };
    },
    computed: {
        selected: {
            get() {
                if (Array.isArray(this.modelValue) && this.modelValue.length > 0 && typeof this.modelValue[0] !== 'object') {
                    return this.modelValue.map(value => 
                        this.options.find(option => option[this.trackBy] === value)
                    ).filter(option => option !== undefined);
                }
                return this.modelValue !== undefined ? this.modelValue : this.internalSelected;
            },
            set(value) {
                if (value !== this.selected) {
                    this.internalSelected = value;
                    const emitValue = this.multiple 
                        ? value.map(item => typeof item === 'object' ? item[this.trackBy] : item)
                        : value?.[this.trackBy];
                    this.$emit('update:modelValue', emitValue);
                }
            }
        }
    },
    methods: {
        getModifiers () {
            return {
                "simple-selectbox--hidden-empty-option": !this.canSelectEmptyValue,
                "simple-selectbox--search-disabled": !this.canSearchOptions,
            };
        },
    },
    watch: {
        searchPlaceholder () {
            this.setOptions(this.setOptionsLater ? this.savedOptions : this.options);
        },

        noResultsText () {
            this.setOptions(this.setOptionsLater ? this.savedOptions : this.options);
        },

        noOptionsText () {
            this.setOptions(this.setOptionsLater ? this.savedOptions : this.options);
        },

        options: {
            deep: true,
            handler (value) {
                if (this.setOptions) {
                    this.setOptions(value);
                }
            },
        },
    },
    mounted () {
    }
}
</script>
<style lang="scss" scoped>
@import "~@/css/globals.scss";
</style>
