<template>
  <div class="restaurant-closed">
    <div v-if="restaurant" class="restaurant-info">
      <h1 class="restaurant-name">{{ restaurantName }}</h1>
      <p class="restaurant-address">{{ restaurantAddress }}</p>
    </div>
    <div class="logo-container" v-if="giaogiao.includes(Number.parseInt(restaurant.id))">
      <PrimaryLogo :restaurant-id="restaurantId"/>
    </div>
    <div class="message-container">
      <div>
        <h2>{{ $t('restaurantClosed.title') }}</h2>
        <p>{{ $t('restaurantClosed.description') }}</p>
      </div>
    </div>
    <div class="language-selector --unselectable">
      <button @click="changeLanguage('it')">Italiano</button>
      <button @click="changeLanguage('en')">English</button>
      <button @click="changeLanguage('zh')">中文</button>
    </div>
  </div>
</template>

<script>
import PrimaryLogo from '@/components/utilities/PrimaryLogo';
import { server } from '@/server';

export default {
  name: 'RestaurantClosedView',
  components: {
    PrimaryLogo,
  },
  data() {
    return {
      restaurant: null,
      giaogiao: [1,3,5,13,14],
    };
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const data = await server.getRestaurant(to.params.restaurantId);
      next(vm => {
        vm.restaurant = data;
      });
    } catch (error) {
      console.error('Errore nel caricamento dei dati del ristorante:', error);
      next();
    }
  },
  computed: {
    restaurantName() {
      return this.restaurant?.name || '';
    },
    restaurantAddress() {
      return this.restaurant?.address || '';
    },
    restaurantId() {
      return this.restaurant?.id || '';
    },
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
  },
};
</script>

<style scoped lang="scss">
$primary-color: rgb(70, 70, 70);
$primary-brand-color: rgb(241, 191, 65);
$primary-brand-color-hover: rgb(221, 171, 45);

.restaurant-closed {
  text-align: center;
  margin: 50px 20px;
}

.restaurant-info {
  margin-bottom: 30px;
}

.restaurant-name {
  font-size: 2.5em; 
  color: $primary-color;
  margin: 10px 0;
}

.restaurant-address {
  font-size: 1.5em; 
  color: $primary-color;
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.message-container {
  margin-bottom: 30px;
}

.message-container h2 {
  font-size: 2em;
  color: #ff0000;
  word-break: break-word;
}

.message-container p {
  font-size: 1.2em;
  color: #555;
  word-break: break-word;
}

.language-selector {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  button {
    margin: 5px;
    padding: 10px 20px;
    font-size: 1em;
    color: $primary-color;
    background-color: $primary-brand-color;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: $primary-brand-color-hover;
    }

    &:focus {
      outline: none;
    }
  }
}

.--unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (max-width: 448px) {
  .restaurant-closed {
    margin: 30px 10px;
  }

  .restaurant-name {
    font-size: 2em;
  }

  .restaurant-address {
    font-size: 1.2em;
  }

  .message-container h2 {
    font-size: 1.5em;
  }

  .message-container p {
    font-size: 1em;
  }

  .language-selector button {
    width: 100%;
    max-width: 300px;
  }
}
</style>
