<template>
    <div class="flex language-selector" @click="onClick">
        <div class="flex flex--100 language-selector__box">
            <div
                class="flex flex--y-align-center language"
                v-for="locale in languages"
                :class="getLanguageModifiers(locale)"
                :key="locale.languageIso"
                @click="changeLocale(locale.languageIso)"
            >
                <span class="flex language__name">{{ locale.name }}</span>
                <div class="flex language__icon" v-html="locale.icon"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LanguageSelector",
    emits: ["change", "click"],
    props: {
        availableLanguages: {
            type: Array,
        },
    },
    data () {
        return {
            locales: [{
                name: "Italiano",
                languageIso: "it",
                icon: `
                    <svg class="flag_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
                        <polygon fill="#FFFFFF" points="107 0 533 0 533 480 107 480"/>
                        <polygon fill="#009246" points="0 0 213.3 0 213.3 480 0 480"/>
                        <polygon fill="#CE2B37" points="426.7 0 640 0 640 480 426.7 480"/>
                    </svg>
                `,
            }, {
                name: "English",
                languageIso: "en",
                icon: `
                    <svg class="flag_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
                        <path fill="#012169" d="M0 0h640v480H0z"/>
                        <path fill="#FFF" d="M75 0l244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"/>
                        <path fill="#C8102E" d="M424 281l216 159v40L369 281h55zm-184 20l6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"/>
                        <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z"/>
                        <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"/>
                    </svg>
                `,
            }, {
                name: "Español",
                languageIso: "es",
                icon: `
                    <svg class="flag_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
                        <g fill-rule="evenodd">
                            <path fill="#AA151B" d="M0 0h640v480H0z"/>
                            <path fill="#F1BF00" d="M0 120h640v240H0z"/>
                        </g>
                    </svg>
                `,
            }, {
                name: "中文",
                languageIso: "zh",
                icon: `
                    <svg class="flag_svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="flag-icon-css-cn" viewBox="0 0 640 480">
                        <defs>
                          <path id="a" fill="#ffde00" d="M-.6.8L0-1 .6.8-1-.3h2z"/>
                        </defs>
                        <path fill="#de2910" d="M0 0h640v480H0z"/>
                        <use width="30" height="20" transform="matrix(71.9991 0 0 72 120 120)" xlink:href="#a"/>
                        <use width="30" height="20" transform="matrix(-12.33562 -20.5871 20.58684 -12.33577 240.3 48)" xlink:href="#a"/>
                        <use width="30" height="20" transform="matrix(-3.38573 -23.75998 23.75968 -3.38578 288 95.8)" xlink:href="#a"/>
                        <use width="30" height="20" transform="matrix(6.5991 -23.0749 23.0746 6.59919 288 168)" xlink:href="#a"/>
                        <use width="30" height="20" transform="matrix(14.9991 -18.73557 18.73533 14.99929 240 216)" xlink:href="#a"/>
                    </svg>
                `,
            }, {
                name: "Русский",
                languageIso: "ru",
                icon: `
                    <svg class="flag_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
                        <g fill-rule="evenodd" stroke-width="1pt">
                            <path fill="#fff" d="M0 0h640v160H0z"/>
                            <path fill="#0039a6" d="M0 160h640v160H0z"/>
                            <path fill="#d52b1e" d="M0 320h640v160H0z"/>
                        </g>
                    </svg>
                `,
            }, {
                name: "Deutsch",
                languageIso: "de",
                icon: `
                        <svg class="flag_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
                            <g fill-rule="evenodd" stroke-width="1pt">
                                <path fill="#000" d="M0 0h640v160H0z"/>
                                <path fill="#D00" d="M0 160h640v160H0z"/>
                                <path fill="#FFCE00" d="M0 320h640v160H0z"/>
                            </g>
                        </svg>
                    `,
            }
            ],
        };
    },
    computed: {
        languages () {
            if (!this.availableLanguages) {
                return this.locales.filter(locale => ["it", "en", "zh"].includes(locale.languageIso));
            }
            return this.locales
            .filter(locale => this.availableLanguages.includes(locale.languageIso))
            .sort((a, b) => this.availableLanguages.indexOf(a.languageIso) - this.availableLanguages.indexOf(b.languageIso));
        }
    },
    methods: {
        onClick (event) {
            this.$emit("click", event);
        },

        getLanguageModifiers (language) {
            return {
                "language--selected": this.$i18n.locale === language.languageIso,
            };
        },

        changeLocale (languageIso) {
            const previousLanguageIso = this.$i18n.locale;

            this.$i18n.locale = languageIso;

            this.$emit("change", languageIso, previousLanguageIso);
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@/css/globals.scss";

.language-selector {
    &__box {}
}

.language {
    cursor: pointer;

    margin: 0;
    padding: 12px 20px;

    background-color: rgb(42, 40, 42);
    border-radius: 1000px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.0032), 0 0 10px 0 rgba(0, 0, 0, 0.0064);

    &__name {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.04rem;
        color: rgb(255, 255, 255);
    }

    &__icon {
        margin-left: 8px;
    }

    & + & {
        margin-left: 12px;
    }

    &--selected {
        background-color: $primary-brand-color;
    }
}

::v-deep .flag_svg {
    width: 24px;

    border-radius: 3px;
}
</style>
