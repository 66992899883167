<template>
    <div class="flex flex--100 kiosk-order-view">
        <new-chinese-year-modal-box ref="chineseNewYearModalBox" :restaurant-presentation="restaurantPresentation"/>
        <template v-if="!showCategoriesGrid">
            <restaurant-product-list
                :restaurant-presentation="restaurantPresentation"
                v-model:selected-products="basket.selectedProducts"
                ref="productList"
                @increase-volume="onProductIncreaseVolume"
                :selectedScene="sceneId"
                :preselected-category="selectedCategoryId"
            />
            <customer-basket
                ref="customerBasket" 
                :restaurant-presentation="restaurantPresentation" 
                v-model:basket="basket"
                :scene="selectedSceneObj"
            />
        </template>
        <template v-else>
            <restaurant-categories-grid
                :restaurant-presentation="restaurantPresentation"
                ref="categoriesGrid"
                :selectedScene="sceneId"
                @select="onCategorySelect"
            />
        </template>
        <div class="flex flex--100 flex--x-align-center flex--y-align-center footer">
            <simple-button :text="$t('generic.cancel')" class="footer__cancel-button" @click="toKioskHomeView"/>
            <simple-button :text="$t('generic.continue')" class="footer__continue-button" :is-disabled="basketIsEmpty" @click="showCheckoutModalBox"/>
        </div>
        <modal-box
            class="checkout-modal-box"
            :show-close-button="false"
            :close-on-background-click="false"
            @before-hide="onBeforeCheckoutModalBoxHide"
            ref="checkoutModalBox"
        >
            <customer-order-checkout
                :restaurant-presentation="restaurantPresentation"
                :sceneId="sceneId"
                v-model:basket="basket"
                @cancel="hideCheckoutModalBox"
                @complete="onCheckoutComplete"
                @close="hideCheckoutModalBox"
            />
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import CustomerBasket from "@/components/CustomerBasket";
import SimpleButton from "@/components/inputs/SimpleButton";
import CustomerOrderCheckout from "@/components/CustomerOrderCheckout";
import NewChineseYearModalBox from "@/components/modals/special/NewChineseYearModalBox";
import RestaurantProductList from "@/components/RestaurantProductList";
import RestaurantCategoriesGrid from "@/components/RestaurantCategoriesGrid";
import { server } from "@/server";
import {
    InteractionListener,
    RestaurantProcessor,
    BasketProcessor,
} from "@/utilities";
import { cloneDeep } from "lodash";
// import { DiscountStrategies } from "@/products/DiscountStrategies";

const NO_INTERACTION_TIMEOUT = 50000;

const preloadedData = {};
let isInteractionListenerEnabled = false;
let lastInteractionTimestamp = Date.now();
let productListReference;
let sceneId;

let newYearReference;

export default {
    async beforeRouteEnter (to, from, next) {
        if (!preloadedData.restaurantPresentation) {
            preloadedData.restaurantPresentation = await server.getRestaurantPresentation(to.params.restaurantId);
        }
        if (!preloadedData.restaurantPresentation.restaurant.isOpen) {
            next(`/restaurant/${to.params.restaurantId}/closed`);

            return;
        }

        lastInteractionTimestamp = Date.now();
        isInteractionListenerEnabled = false; // Temporary disabled due to unexpected bug
        sceneId = !to.query.sceneId || to.query.sceneId === "undefined" || to.query.sceneId === "all" ? undefined : to.query.sceneId;
        try {
            productListReference?.selectFirstCategory();
            newYearReference?.show();
        }
        catch {
            // Silence is golden
        }

        next();
    },
    beforeRouteLeave (to, from, next) {
        this.clearOrder();

        isInteractionListenerEnabled = false;

        next();
    },

    name: "KioskOrderView",
    components: {
        NewChineseYearModalBox,
        RestaurantProductList,
        CustomerBasket,
        SimpleButton,
        ModalBox,
        CustomerOrderCheckout,
        RestaurantCategoriesGrid,
    },
    data () {
        return {
            basket: BasketProcessor.createEmpty(),
            returnHomeTimeoutId: undefined,
            interactionListenerIntervalId: undefined,
            sceneId,
            showCategoriesGrid: true,
            selectedCategoryId: -1,
        };
    },
    methods: {
        toKioskHomeView () {
            this.$router.push(`/restaurant/${this.restaurantId}/kiosk${sceneId ? `?sceneId=${sceneId}` : ''}`);
        },

        clearOrder () {
            this.basket = BasketProcessor.createEmpty();
        },

        onCheckoutComplete () {
            this.clearOrder();
            productListReference?.selectFirstCategory();

            this.returnHomeTimeoutId = setTimeout(() => {
                this.returnHomeTimeoutId = undefined;

                this.$refs.checkoutModalBox.hide();
                this.toKioskHomeView();
            }, 1000 * 16);
        },

        showCheckoutModalBox () {
            this.$refs.checkoutModalBox.show();
        },

        onBeforeCheckoutModalBoxHide () {
            if (this.returnHomeTimeoutId) {
                clearTimeout(this.returnHomeTimeoutId);

                this.returnHomeTimeoutId = undefined;

                this.toKioskHomeView();
            }
        },

        hideCheckoutModalBox () {
            this.$refs.checkoutModalBox.hide();
        },

        onProductIncreaseVolume (product) {
            if (product.variations.length > 0) {
                this.$refs.customerBasket.showProductVariationModalBox(product);
            }
        },

        onCategorySelect (categoryId) {
            this.selectedCategoryId = categoryId;
            this.showCategoriesGrid = false;
        },
    },
    computed: {
        restaurantPresentation () {
            // filter product 637 for 1 month
            const restaurantPresentation = {
                ...preloadedData.restaurantPresentation,
                products: preloadedData.restaurantPresentation.products.filter((p) => !(p.id == 2738 || p.id == 2865 || p.id == 2899)),
            }
            return restaurantPresentation;
        },

        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        restaurantId () {
            return this.restaurantProcessor.id;
        },
        // </restaurant>

        // <basket>
        basketProcessor () {
            return new BasketProcessor({
                restaurantProcessor: this.restaurantProcessor,
                basket: this.basket,
            })
        },

        basketSelectedProducts () {
            return this.basketProcessor.selectedProducts;
        },

        basketIsEmpty () {
            return this.basketProcessor.isEmpty;
        },
        // </basket>
        selectedSceneObj () {
            let result;
            if (this.sceneId && this.sceneId !== "all") {
                result = this.restaurantProcessor.getSceneById(Number(this.sceneId));
            }
            return result;
        },
    },
    watch: {
        basketSelectedProducts: {
            deep: true,
            handler (value) {
                // <products-variations>
                // Remove a variation if its product is no longer selected.
                for (const productId in this.basket.selectedProductsVariations) {
                    if (!value.find((product) => Number.parseInt(product.id) === Number.parseInt(productId))) {
                        delete this.basket.selectedProductsVariations[productId];
                    }
                    else {
                        const variationsCopy = cloneDeep(this.basket.selectedProductsVariations);
                        const productVariations = [];

                        for (let i = 0; i < this.basketProcessor.getProductSelectedVolume(productId); ++i) {
                            productVariations[i] = variationsCopy[productId][i] ?? [];
                        }

                        variationsCopy[productId] = productVariations;
                        this.basket.selectedProductsVariations = variationsCopy;
                    }
                }
                // </products-variations>
                // <one-plus-one>
                // this.basket.deductions = [];
                // for (const productId in this.basket.selectedProducts) {
                //     const product = this.restaurantProcessor.getProductById(Number.parseInt(productId));
                //     if (product && product.discountStrategies.includes(DiscountStrategies.onePlusOne.id) && (!product.discountExpirationTimestamp || product.discountExpirationTimestamp > Date.now())) {
                //         const volume = Math.floor(this.basket.selectedProducts[productId].selectedVolume / 2);
                //         this.basket.deductions = this.basket.deductions.filter((a) => a.description !== this.restaurantProcessor.getProductLocalizedName(product.id));
                //         if (volume > 0) {
                //             this.basket.deductions.push({
                //                 description: this.restaurantProcessor.getProductLocalizedName(product.id),
                //                 value: -volume * product.price,
                //             });
                //         }
                //     }
                // }
                // </one-plus-one>
            },
        },
    },
    created () {
        const customStyleElement = this.restaurantProcessor.getCustomStyleElement();

        console.log(customStyleElement);

        window.document.head.appendChild(customStyleElement);
    },
    mounted () {
        if (this.restaurantProcessor.restaurant.ITSplashAd) {
            newYearReference = this.$refs.chineseNewYearModalBox;

            this.$refs.chineseNewYearModalBox.show();
        }

        // <interaction-listener>
        lastInteractionTimestamp = Date.now();

        InteractionListener.listen(() => {
            lastInteractionTimestamp = Date.now();
        });

        this.interactionListenerIntervalId = setInterval(() => {
            if (!isInteractionListenerEnabled) {
                return;
            }

            if (Math.abs(Date.now() - lastInteractionTimestamp) >= NO_INTERACTION_TIMEOUT) {
                this.hideCheckoutModalBox();
                this.toKioskHomeView();
            }
        }, 1000);
        // </interaction-listener>

        productListReference = this.$refs.productList;
        this.showCategoriesGrid = this.restaurantId == 1;
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
@import "~@/css/flex.scss";

.kiosk-order-view {
    overflow: hidden;

    min-height: 100vh;
    height: 100vh;

    background-color: rgb(243, 243, 243);

    :deep(> .restaurant-product-list) {
        @extend .flex--y-align-center;

        overflow: hidden;

        background-color: inherit;

        height: 62vh;
    }
    :deep(> .restaurant-product-list .slider) {
        height: 85%;
    }

    :deep(> .restaurant-categories-grid) {
        @extend .flex--y-align-center;

        overflow: hidden;

        background-color: inherit;

        height: 92vh;
    }

    :deep(> .customer-basket) {
        overflow: hidden;

        width: 100%;
        height: 30vh; // Usare percentuali e non VH per sistemare anche basket overflow.
        margin: 0;
        padding: 0;

        background-color: rgb(255, 255, 255);
        border-radius: 22px 22px 0 0;
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.03);

        z-index: 3;
    }
    :deep(> .customer-basket .empty-basket-text) {
        margin: 0;
        padding: 0;
    }
    :deep(> .customer-basket .selected-product-list) {
        overflow: auto;

        height: 26vh;
        padding: 25px 25px 0 25px;
    }
    :deep(> .customer-basket .summary) {
        @extend .flex--y-align-center;

        overflow: hidden;

        height: 4vh;
        margin: 0;
        padding: 0;
    }
    :deep(> .customer-basket .subtotal) {
        margin: 0;
        padding: 0 40px;
    }
    :deep(> .customer-basket .total) {
        display: none;
    }
}

.footer {
    overflow: hidden;

    height: 8vh;

    background-color: rgb(42, 40, 42);

    z-index: 5;

    &__cancel-button {
        min-width: 120px;
        margin-right: 50px;

        background-color: transparent;
        border: 2px solid rgb(220, 220, 220);
        border-radius: 1000px;

        :deep(.simple-button__text) {
            color: rgb(230, 230, 230);
        }
    }

    &__continue-button {
        margin-left: 50px;

        background-color: $primary-brand-color;
        border-radius: 1000px;
        border: 2px solid $primary-brand-color;
    }
}

.checkout-modal-box {
    :deep(.modal-box__slot) {
        width: 100%;
        height: 90vh;
        margin: 10vh 0 0 0;
        margin: calc(var(--vh, 1vh) * 10) 0 0 0;
        padding: 0;

        border-radius: 22px 22px 0 0;
    }

    :deep(.customer-order-checkout) {
        height: inherit;
    }
}

.restaurant-product-list {
    :deep(.restaurant-product) {
        &__card {
            padding: 12px;
        }
        &__name {
            font-size: 14px;
        }
    }
    :deep(.restaurant-fixed-menu .preview) {
        &__image {
            width: 24%;
        }
        &__image:nth-child(2) {
            width: 31%;
        }
    }
}
</style>
