<template>
    <div class="flex flex--100 user-tab">
        <div class="flex flex--100 box">
            <div class="flex flex--100 separator separator--no-margin-top">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.restaurantSceneManagementTab.title`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100 flex--direction-column">
                <div class="flex flex--100 flex--x-align-center">
                    <simple-button class="action-button" :text="$t(`management.restaurantSceneManagementTab.newScene`).toLocaleUpperCase()" @click="create()"/>
                </div>
            </div>
        </div>
        <div class="flex flex--100 box">
            <div class="flex flex--100 separator separator--no-margin-top">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.restaurantSceneManagementTab.list`).toLocaleUpperCase()}}</span>
            </div>

             <div class="flex flex--100 flex--x-align-center scene-list">
                <span class="no-allergens-text" v-if="scenes.length === 0">{{$t(`management.restaurantSceneManagementTab.noScenes`)}}</span>
                <div class="flex flex--100 flex--y-align-center restaurant-scene-box" v-for="scene, index in scenes" :key="scene.id">
                    <restaurant-scene
                        class="flex flex--30"
                        :scene="scene"
                        :restaurant-id="restaurantId"
                        :selectable="false"
                    >
                    </restaurant-scene>
                    <div class="flex flex--70 flex--x-align-end flex--y-align-center">
                        <simple-button class="restaurant-scene-box_button" :text="$t(`management.restaurantSceneManagementTab.modify`).toLocaleUpperCase()" @click="openModifyModal(index)"/>
                        <simple-button class="restaurant-scene-box_button" :text="$t(`management.restaurantSceneManagementTab.category`).toLocaleUpperCase()" @click="openCategoryModal(index)"/>
                        <simple-button class="restaurant-scene-box_button" :text="$t(`management.restaurantSceneManagementTab.product`).toLocaleUpperCase()" @click="openProductModal(index)"/>
                        <simple-button class="restaurant-scene-box_button" :text="$t(`management.restaurantSceneManagementTab.fixedMenu`).toLocaleUpperCase()" @click="openFixedMenuModal(index)"/>
                        <simple-button class="restaurant-scene-box_button" :text="$t(`management.restaurantSceneManagementTab.customProduct`).toLocaleUpperCase()" @click="openCustomProductModal(index)"/>
                        <simple-button class="restaurant-scene-box_button" :text="$t(`management.restaurantSceneManagementTab.table`).toLocaleUpperCase()" @click="openTableModal(index)"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <add-scene-modal-box :restaurant-presentation="restaurantPresentation" :restaurant-id="restaurantId" ref="addSceneModalBox" @scene-add="onSceneAdd"/>
    <update-scene-modal-box :restaurant-presentation="restaurantPresentation" ref="sceneModifyModalBox" :restaurant-id="restaurantId" v-model:scene="scenes[selected]"/>
    <scene-category-modal-box :categories="restaurantProcessor.categories" :restaurant-id="restaurantId" v-model:scene="scenes[selected]" ref="sceneCategoryModalBox"/>
    <scene-product-modal-box :products="restaurantProcessor?.baseProducts" :restaurant-id="restaurantId" v-model:scene="scenes[selected]" ref="sceneProductModalBox"/>
    <scene-fixed-menu-modal-box :fixedMenus="restaurantProcessor?.fixedMenus" :restaurant-id="restaurantId" v-model:scene="scenes[selected]" ref="sceneFixedMenuModalBox"/>
    <scene-custom-product-modal-box :customProducts="restaurantProcessor?.customProducts" :restaurant-id="restaurantId" v-model:scene="scenes[selected]" ref="sceneCustomProductModalBox"/>
    <scene-table-modal-box :tables="restaurantProcessor?.tables" :restaurant-id="restaurantId" v-model:scene="scenes[selected]" ref="sceneTableModalBox"/>
</template>

<script>
import {server} from "@/server";
import AddSceneModalBox from "@/components/windows/AddSceneModalBox";
import UpdateSceneModalBox from "@/components/windows/UpdateSceneModalBox";
import SceneCategoryModalBox from "@/components/windows/SceneCategoryModalBox";
import SceneProductModalBox from "@/components/windows/SceneProductModalBox";
import SceneFixedMenuModalBox from "@/components/windows/SceneFixedMenuModalBox";
import SceneCustomProductModalBox from "@/components/windows/SceneCustomProductModalBox";
import SceneTableModalBox from "@/components/windows/SceneTableModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import { RestaurantProcessor } from "@/utilities";
import RestaurantScene from "@/components/RestaurantScene";
// import SimpleTextbox from "@/components/inputs/SimpleTextbox";

export default {
    name: "scenesManagementTab",
    components: {
        SimpleButton,
        AddSceneModalBox,
        RestaurantScene,
        SceneCategoryModalBox,
        SceneProductModalBox,
        SceneFixedMenuModalBox,
        SceneCustomProductModalBox,
        SceneTableModalBox,
        UpdateSceneModalBox,
        // SimpleTextbox,
    },
    props: {
        loadOnRestaurantIdChange: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            restaurantPresentation: {},
            scenes: [],
            restaurantId: undefined,
            isWaitingServerResponse: false,
            selected: undefined,
        };
    },
    computed: {
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },
    },
    methods: {
        async load (restaurantId) {
            this.restaurantPresentation = await server.getRestaurantPresentation(restaurantId);
            this.scenes = this.restaurantPresentation.scenes;
            this.restaurantId = restaurantId;
            // Clear
            this.clear();
        },
        clear () {
            this.isWaitingServerResponse = false;
            this.scenes.forEach((scene) => {
                scene.selected = false;
            })
        },
        openCategoryModal (index) {
            this.selected = index;
            this.$refs.sceneCategoryModalBox.load(this.scenes[index]);
            this.$refs.sceneCategoryModalBox.window.show();
        },  
        openProductModal (index) {
            this.selected = index;
            this.$refs.sceneProductModalBox.load(this.scenes[index]);
            this.$refs.sceneProductModalBox.window.show();
        }, 
        openFixedMenuModal (index) {
            this.selected = index;
            this.$refs.sceneFixedMenuModalBox.load(this.scenes[index]);
            this.$refs.sceneFixedMenuModalBox.window.show();
        }, 
        openCustomProductModal (index) {
            this.selected = index;
            this.$refs.sceneCustomProductModalBox.load(this.scenes[index]);
            this.$refs.sceneCustomProductModalBox.window.show();
        }, 
        openTableModal (index) {
            this.selected = index;
            this.$refs.sceneTableModalBox.load(this.scenes[index]);
            this.$refs.sceneTableModalBox.window.show();
        }, 
        openModifyModal (index) {
            this.selected = index;
            this.$refs.sceneModifyModalBox.load(this.scenes[index]);
            this.$refs.sceneModifyModalBox.window.show();
        }, 
        onSceneAdd (scene) {
            this.scenes.push(scene);
        },
        create () {
            this.$refs.addSceneModalBox.window.show();
        },
    }
};
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";

.simple-textbox {
    width: 90%;
}

.date-picker-box {
    margin-top: 28px;
    padding: 0;
    gap: 20px;
}

.restaurant-scene-box {
    position: relative;

    width: 100%;

    cursor: pointer;
    background-color: rgb(240, 240, 240);
    border-radius: 0;
    border: 1px solid rgb(226, 226, 226);

    :deep(.restaurant-scene) {
        height: 100%;
        background-color: transparent;
        &__name {
            padding: 3px;
            font-size: 16px;
            font-weight: 500;
            color: rgb(0, 0, 0);
        }
    }

    &_button {
        border-radius: 5px;
        padding: 10px;
        min-width: 100px;
        margin-left: 15px;

        &__selected {
            :deep(.simple-button__text) {
                color: $primary-brand-color;
            }
        }
    }
}
</style>
