<template>
    <div class="flex flex--100 cashier-sale-point-view">
        <div class="top-right-div">
            <language-selector :available-languages="displayLanguages" />
            <div class="flex flex--100 flex--x-align-center">
                <simple-selectbox v-show="false" class="scene-select-box" :options="sceneSelectboxOptions"
                    :can-select-empty-value="false" :can-search-options="false"
                    :select-placeholder="$t('generic.chooseScene')" @options-load="onSceneSelectboxOptionsLoad"
                    ref="sceneSelectbox" v-model:model-value="selectedScene" />
            </div>
        </div>
        <div class="top-div" v-if="globalDiscount.length > 0">
            <span @click="removeGlobalDiscount">{{ $t(`cashierPos.removeGlobalDiscount`) }} -{{ this.globalDiscount
                }}%</span>
        </div>
        <div class="flex flex--100 main">
            <template v-if="restaurantId === 15 || restaurantId === 9 || restaurantId === 1 || restaurantId === 13 || restaurantId === 14">
                <restaurant-product-list-vertical ref="restaurantProductList"
                    :restaurant-presentation="restaurantPresentation" :show-search-bar="true" :cashier-layout="true"
                    layout="horizontal" v-model:selected-products="basket.selectedProducts"
                    :selected-scene="selectedScene" @updateVolume="updateSingleProductDiscount" />
            </template>
            <template v-else>
                <restaurant-product-list ref="restaurantProductList" :restaurant-presentation="restaurantPresentation"
                    :show-search-bar="true" :cashier-layout="true" layout="horizontal"
                    v-model:selected-products="basket.selectedProducts" :selected-scene="selectedScene"
                    @updateVolume="updateSingleProductDiscount" />
            </template>

            <customer-basket :restaurant-presentation="restaurantPresentation" :scene="selected"
                :cover-price="coverPrice" :show-cashier-controls="true" v-model:basket="basket" ref="customerBasket"
                @updateVolume="updateSingleProductDiscount" />
            <div class="flex flex--100 flex--y-align-center action-box">
                <div class="member-only" v-if="restaurantId === 1 || restaurantId === 15 || restaurantId === 9">
                    <primary-logo :restaurant-id="restaurantId" @click="showMemberOnlyModal" class="member-only__logo" />
                </div>
                <div class="flex flex--50 flex--x-align-center left-action-box">
                    <div class="flex flex--x-align-center flex--y-align-center cash-register-button">
                        <simple-button :text="$t('cashierPos.tables')" @click="showCashRegisterModalBox" />
                        <svg class="flex cash-register-button__icon" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512">
                            <path
                                d="M490 367h-17V150c0-9-7-16-17-16h-83v-34h83c10 0 17-7 17-17V17c0-10-7-17-17-17H256c-9 0-17 7-17 17v66c0 10 8 17 17 17h83v34H56c-10 0-17 7-17 16v217H22c-9 0-16 8-16 17v111c0 10 7 17 16 17h468c9 0 16-7 16-17V384c0-9-7-17-16-17zM273 67V33h167v34H273zM72 167h368v200H72V167zm401 312H39v-78h434v78z" />
                            <path d="M189 200h-67a17 17 0 000 34h67a17 17 0 000-34z" />
                            <circle cx="256" cy="250.4" r="16.7" />
                            <circle cx="322.8" cy="250.4" r="16.7" />
                            <circle cx="389.6" cy="250.4" r="16.7" />
                            <circle cx="256" cy="317.2" r="16.7" />
                            <circle cx="322.8" cy="317.2" r="16.7" />
                            <circle cx="389.6" cy="317.2" r="16.7" />
                            <circle cx="256" cy="439.7" r="16.7" />
                        </svg>
                    </div>
                    <simple-button :text="$t('cashierPos.ordersToPay')"
                        :is-loading="isWaitingServerResponseForOrdersToPay" @click="showOrdersToPay" />
                    <simple-button v-if="!skipPreparationState" :text="$t('cashierPos.ordersInPreparation')"
                        :is-loading="isWaitingServerResponseForOrdersInPreparation" @click="showOrdersInPreparation" />
                    <simple-button v-if="!skipDeliveryState && !skipPreparationState"
                        :text="$t('cashierPos.ordersInDelivery')"
                        :is-loading="isWaitingServerResponseForOrdersInDelivery" @click="showOrdersInDelivery" />
                    <simple-button :text="$t('cashierPos.completedOrders')"
                        :is-loading="isWaitingServerResponseForCompletedOrders" @click="showCompletedOrders" />
                    <simple-button v-if="restaurantHasTables" :text="$t('cashierPos.tables')"
                        :is-loading="isWaitingServerResponseForTables" @click="showTables" />
                </div>
                <div class="flex flex--50 flex--x-align-center">
                    <simple-button :text="clearButtonText"
                        :is-disabled="basketIsEmpty && !selectedTableOrderToAddProduct && !selectedTableForCreateOrder && globalDiscount === '' && !selectedOrderToPay"
                        @click="clearOrder" />
                    <simple-button v-if="selectedTableOrderToAddProduct" :text="addProductButtonText"
                        :is-disabled="basketIsEmpty" @click="addProduct" :is-loading="isWaitingServerResponseAddProduct" />
                    <simple-button data-cy="continueButton" v-else :text="continueButtonText" :is-disabled="basketIsEmpty" :is-loading="isWaitingServerResponseForOrderCreation"
                        @click="continueOrder" />
                </div>
            </div>
        </div>
        <modal-box class="cashier-modal-box order-list-modal-box" ref="orderToPayListModalBox"
            :show-close-button="false" :show-back-button="true">
            <div class="flex flex--100 flex--x-align-center flex--y-align-center order-list-title">
                <h2 class="order-list-title__text">{{ $t("cashierPos.ordersToPay") }}</h2>
            </div>
            <div class="flex flex--100 order-list order-list--to-pay">
                <div class="flex flex--100 flex--x-align-center flex--y-align-center" v-for="order in ordersToPay"
                    :key="order.id">
                    <restaurant-order :restaurant-presentation="restaurantPresentation" :order="order"
                        :show-cashier-controls="true" />
                    <div class="flex flex--direction-column order-select-box">
                        <template v-if="order.glovo">
                            <simple-button class="order-select-button" :text="$t('cashierPos.rider')"
                            @click="selectOrderToPay(order)" />
                        </template>
                        <template v-else>
                            <simple-button class="order-select-button" :text="$t('cashierPos.select')"
                                @click="selectOrderToPay(order)" />
                            <simple-button class="order-replace-button" :text="$t('cashierPos.replace')"
                                @click="replaceOrderToPay(order)" />
                        </template>
                    </div>
                </div>
            </div>
        </modal-box>
        <modal-box class="cashier-modal-box cash-register-modal-box" ref="cashRegisterModalBox"
            :show-close-button="false" :show-back-button="true">
            <div class="flex flex--100 flex--x-align-center">
                <simple-button text="Open cash box" @click="openCashRegister" />
                <simple-button text="Close fiscal day" @click="closeFiscalDay" />
                <simple-button :text="$t(`host.exit`)" @click="exit" />
            </div>
        </modal-box>
        <modal-box class="cashier-modal-box order-list-modal-box" ref="orderInPreparationListModalBox"
            :show-close-button="false" :show-back-button="true">
            <div class="flex flex--100 flex--x-align-center flex--y-align-center order-list-title">
                <h2 class="order-list-title__text">{{ $t("cashierPos.ordersInPreparation") }}</h2>
            </div>
            <div class="flex flex--100 flex--x-align-center order-list">
                <div class="flex flex--100 flex--x-align-center flex--y-align-center"
                    v-for="order in ordersInPreparation" :key="order.id">
                    <restaurant-order :restaurant-presentation="restaurantPresentation" :order="order"
                        :show-cashier-controls="true" @cancel="() => ordersInPreparation = ordersInPreparation.filter((o) => o.id !== order.id)"/>
                    <simple-button v-if="paymentAfterService" class="order-select-button"
                        :is-loading="isWaitingServerResponseForFromInPreparationToToPay" :text="$t('cashierPos.toPay')"
                        @click="selectOrderFromInPreparationToToPay(order)" />
                </div>
            </div>
        </modal-box>
        <modal-box class="cashier-modal-box order-list-modal-box" ref="orderInDeliveryListModalBox"
            :show-close-button="false" :show-back-button="true">
            <div class="flex flex--100 flex--x-align-center flex--y-align-center order-list-title">
                <h2 class="order-list-title__text">{{ $t("cashierPos.ordersInDelivery") }}</h2>
            </div>
            <div class="flex flex--100 flex--x-align-center order-list">
                <div class="flex flex--100 flex--x-align-center flex--y-align-center" v-for="order in ordersInDelivery"
                    :key="order.id">
                    <restaurant-order :restaurant-presentation="restaurantPresentation" :order="order"
                        :show-cashier-controls="true" @cancel="() => ordersInDelivery = ordersInDelivery.filter((o) => o.id !== order.id)"/>
                    <simple-button v-if="paymentAfterService" class="order-select-button"
                        :is-loading="isWaitingServerResponseForFromInDeliveryToToPay" :text="$t('cashierPos.toPay')"
                        @click="selectOrderFromInDeliveryToToPay(order)" />
                </div>
            </div>
        </modal-box>
        <modal-box class="cashier-modal-box order-list-modal-box" ref="completedOrderListModalBox"
            :show-close-button="false" :show-back-button="true">
            <div class="flex flex--100 flex--x-align-center flex--y-align-center order-list-title">
                <h2 class="order-list-title__text">{{ $t("cashierPos.completedOrders") }}</h2>
            </div>
            <div class="flex flex--100 flex--x-align-center order-list">
                <restaurant-order v-for="order in completedOrders" :key="order.id"
                    :restaurant-presentation="restaurantPresentation" :order="order" :show-cashier-controls="true" @cancel="() => completedOrders = completedOrders.filter((o) => o.id !== order.id)"
                    />
            </div>
        </modal-box>
        <modal-box class="cashier-modal-box member-only-modal-box" ref="memberOnlyModalBox" :show-close-button="false"
            :show-back-button="true" :manual-close="true" @before-hide="goBackToFeatures">
            <!-- Features List -->
            <div class="flex flex--100 flex--x-align-center flex--y-align-center order-list-title">
                <h2 class="order-list-title__text">{{ $t("cashierPos.memberOnly") }}</h2>
            </div>
            <div class="flex flex--100 flex--wrap member-features" v-if="featureSelected === -1">
                <div class="feature-box" @click="selectFeature(0)">
                    <svg class="feature-icon" width="64" height="64" viewBox="0 0 64 64"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M54 10L10 54L32 54L54 32V10Z" fill="#FF9800" />
                        <circle cx="44" cy="20" r="6" fill="#FFFFFF" />
                        <text x="20" y="44" font-size="18" fill="#FFFFFF" transform="rotate(-45 20 44)">%</text>
                    </svg>
                    <span class="feature-label">{{ $t("memberOnly.promoCode") }}</span>
                </div>
                <div class="feature-box" @click="selectFeature(1)">
                    <svg class="feature-icon" width="64" height="64" viewBox="0 0 64 64"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle cx="32" cy="32" r="30" stroke="#4CAF50" stroke-width="4" fill="none" />
                        <polyline points="20,34 28,42 44,26" fill="none" stroke="#4CAF50" stroke-width="4"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span class="feature-label">{{ $t("memberOnly.checkInfo") }}</span>
                </div>
                <div class="feature-box" @click="selectFeature(2)">
                    <svg class="feature-icon" width="64" height="64" viewBox="0 0 64 64"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M32 4V12C43.0457 12 52 20.9543 52 32C52 43.0457 43.0457 52 32 52C20.9543 52 12 43.0457 12 32H4C4 47.464 16.536 60 32 60C47.464 60 60 47.464 60 32C60 16.536 47.464 4 32 4Z"
                            fill="#2196F3" />
                        <polygon points="12,32 20,24 20,40" fill="#2196F3" />
                    </svg>
                    <span class="feature-label">{{ $t("memberOnly.updateInfo") }}</span>
                </div>
                <div class="feature-box" @click="selectFeature(3)">
                    <svg class="feature-icon" width="64" height="64" viewBox="0 0 64 64"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle cx="32" cy="32" r="28" fill="#F44336" />
                        <line x1="20" y1="44" x2="44" y2="20" stroke="white" stroke-width="4" stroke-linecap="round" />
                        <circle cx="24" cy="24" r="4" fill="white" />
                        <circle cx="40" cy="40" r="4" fill="white" />
                    </svg>
                    <span class="feature-label">{{ $t("memberOnly.discount") }}</span>
                </div>
                <div class="feature-box" @click="selectFeature(4)">
                    <svg class="feature-icon" width="64" height="64"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                    <line x1="1" y1="10" x2="23" y2="10"></line>
                    <line x1="7" y1="16" x2="7" y2="16"></line>
                    <line x1="15" y1="16" x2="15" y2="16"></line>
                    </svg>

                    <span class="feature-label">{{ $t("memberOnly.stripeWebhook") }}</span>
                </div>
                <div class="feature-box" @click="selectFeature(5)">
                    <svg class="feature-icon" width="64" height="64" viewBox="0 0 64 64"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M32 4V12C43.0457 12 52 20.9543 52 32C52 43.0457 43.0457 52 32 52C20.9543 52 12 43.0457 12 32H4C4 47.464 16.536 60 32 60C47.464 60 60 47.464 60 32C60 16.536 47.464 4 32 4Z"
                            fill="#2196F3" />
                        <polygon points="12,32 20,24 20,40" fill="#2196F3" />
                    </svg>
                    <span class="feature-label">{{ $t("memberOnly.unavailable") }}</span>
                </div>
            </div>

            <!-- Feature Content -->
            <div class="flex flex--100 flex--x-align-center flex--y-align-center" v-else>
                <!-- Promo Code Feature -->
                <div class="flex flex--100 flex--x-align-center flex--y-align-center promo-code-feature"
                    v-if="featureSelected === 0">
                    <div class="flex flex--100 flex--x-align-center promo-code-input-container">
                        <simple-textbox :placeholder="$t('memberOnly.promoCode').toUpperCase()"
                            v-model:model-value="promoCode" />
                        <simple-button class="promo-code-button" :text="$t('memberOnly.check')"
                            @click="checkPromoCode" />
                        <simple-button class="promo-code-button" :text="$t('memberOnly.use')" @click="usePromoCode" />
                    </div>
                    <div class="flex flex--100 flex--x-align-center promo-code-response">{{ promoCodeReply }}</div>

                </div>

                <!-- Check Info Feature -->
                <div v-else-if="featureSelected === 1">
                    <!-- Check info content -->
                </div>

                <!-- Update Info Feature -->
                <div v-else-if="featureSelected === 2">
                    <!-- Update info content -->
                </div>
                <!-- Discount Feature -->
                <div v-else-if="featureSelected === 3">
                    <!-- Discount content -->
                    <div class="flex promo-code-input-container">
                        <simple-textbox :placeholder="$t('memberOnly.percentage').toUpperCase()"
                            v-model:model-value="globalDiscount" />
                        <simple-button class="promo-code-button" :text="$t('memberOnly.applyDiscount')"  :is-loading="isWaitingServerResponseForPromoCode"
                            @click="applyGlobalDiscountSafe" />
                    </div>
                </div>
                <!-- Update Info Feature -->
                <div v-else-if="featureSelected === 4" class="flex flex--100 webhook-box">
                    <div class="table-container">

                        <div class="table-row table-header-single">
                            <span class="table-title">
                                {{ $t("memberOnly.stripeWebhook") }} 
                            </span>
                        </div>
                        <!-- Table Header -->
                        <div class="table-row table-header">
                        <div class="table-cell">{{ $t("webhookCol.id") }}</div>
                        <div class="table-cell">{{ $t("webhookCol.orderId") }}</div>
                        <div class="table-cell">{{ $t("webhookCol.paymentMethod") }}</div>
                        <!-- <div class="table-cell">{{ $t("webhookCol.amount") }}</div> -->
                        <div class="table-cell">{{ $t("webhookCol.status") }}</div>
                        <div class="table-cell">{{ $t("webhookCol.timestamp") }}</div>
                        </div>

                        <!-- Table Rows -->
                        <div v-for="(item, index) in webhook" :key="index" class="table-row" :class="{ 'table-row-alt': index % 2 === 0 }">
                        <div class="table-cell">{{ item.id }}</div>
                        <div class="table-cell">{{ item.orderId }}</div>
                        <div class="table-cell">{{ $t(`stripePaymentMethod.${item.paymentMethod}`) }}</div>
                         <!-- <div class="table-cell">{{ normalizePriceToDisplay(item.amount / 100) }}</div> -->
                        <div class="table-cell" :class="getModifiers(item.status)">{{ $t(`stripeStatus.${item.status}`) }}</div>
                        <div class="table-cell">{{ (new Date(item.timestamp * 1000)).toLocaleString() }}</div>
                    </div>
                </div>
                    <!-- Stripe Webhook -->
                </div>
                <!-- Update Info Feature -->
                <div v-else-if="featureSelected === 5">
                    <!-- Update info content -->
                </div>
            </div>
        </modal-box>
        <modal-box class="cashier-modal-box" ref="orderConfirmationModalBox" @hide="onConfirmationModalBoxHide"
            :show-close-button="false" :show-back-button="true">
            <div class="flex flex--100 full-height">
                <div class="flex order-confirmation-box">
                    <div class="flex flex--100 basket-box">
                        <customer-basket :restaurant-presentation="restaurantPresentation" :show-cashier-controls="true"
                            v-model:basket="basket" />
                    </div>
                    <div class="flex flex--100 flex--x-align-center flex--y-align-center take-away-box">
                        <div class="flex flex--y-align-center take-away">
                            <span class="take-away__text">{{ $t("generic.takeAway").toUpperCase() }}</span>
                            <simple-checkbox appearance="slider" v-model:model-value="basket.isTakeAway" />
                        </div>
                        <div class="flex flex--y-align-center cover-number" v-if="coverPrice > 0">
                            <simple-textbox :placeholder="$t('generic.cover').toUpperCase()"
                                v-model:model-value="basket.cover" @input="onCoverInput" />
                        </div>
                        <!-- <div class="flex flex--y-align-center one-plus-one-button" v-if="includeOnePlusOne">
                            <simple-button :text="$t('discountStrategy.onePlusOne').toUpperCase()" @click="applyOnePlusOne"/>
                        </div> -->
                    </div>
                    <div
                        class="flex flex--100 flex--x-align-center flex--y-align-center action-box payment-method-selection-box">
                        <simple-button data-cy="cashButton" class="payment-method-button" :text="$t('generic.cash')"
                            :class="getPaymentMethodButtonModifiers(OrderPaymentMethod.CASH)"
                            @click="selectCashPaymentMethod" />
                        <simple-button data-cy="cardButton" class="payment-method-button" :text="$t('generic.card')"
                            :class="getPaymentMethodButtonModifiers(OrderPaymentMethod.CARD)"
                            @click="selectCardPaymentMethod" />
                        <simple-button data-cy="thirdPartyButton" class="payment-method-button" :text="$t('generic.thirdParty')"
                            :class="getPaymentMethodButtonModifiers(OrderPaymentMethod.THIRD_PARTY)"
                            @click="selectThirdPartyPaymentMethod" />
                        <simple-button data-cy="prebillingButton" class="payment-method-button" :text="$t('generic.prebilling')"
                            :class="getPaymentMethodButtonModifiers(OrderPaymentMethod.PREBILLING)"
                            @click="selectPrebillingPaymentMethod" />
                        <simple-button data-cy="mixedButton" class="payment-method-button" :text="$t('generic.mixed')"
                            :class="getPaymentMethodButtonModifiers(OrderPaymentMethod.MIXED)"
                            @click="selectMixedPaymentMethod" />
                    </div>
                </div>
                <div class="flex order-payment-box">
                    <div class="flex flex--100 flex--x-align-center flex--y-align-center title"
                        v-show="orderPaymentMethod === null">
                        <h2 class="title__text">{{ $t("generic.selectPaymentMethod") }}</h2>
                    </div>
                    <div class="flex flex--100 cash-payment-box"
                        v-show="orderPaymentMethod === OrderPaymentMethod.CASH">
                        <cashier-payment-calculator v-model:model-value="cashAmountPaid" />
                        <div class="flex flex--100 flex--x-align-center">
                            <p class="order-confirm-notice-text">
                                {{ $t("cashierPos.confirmAfterPayment") }}
                            </p>
                            <simple-button data-cy="cashConfirmButton" :text="confirmButtonText" :is-disabled="basketIsEmpty"
                                :is-loading="isWaitingServerResponseForOrderConfirmation" @click="confirmOrder()" />
                        </div>
                    </div>
                    <div class="flex flex--100 flex--x-align-center flex--y-align-center card-payment-box"
                        v-show="orderPaymentMethod === OrderPaymentMethod.CARD">
                        <div class="flex flex--100 flex--x-align-center">
                            <p class="order-confirm-notice-text">
                                {{ $t("cashierPos.confirmAfterPayment") }}
                            </p>
                            <simple-button data-cy="cardConfirmButton" :text="confirmButtonText" :is-disabled="basketIsEmpty"
                                :is-loading="isWaitingServerResponseForOrderConfirmation" @click="confirmOrder()" />
                        </div>
                    </div>
                    <div class="flex flex--100 third-party-payment-box"
                        v-show="orderPaymentMethod === OrderPaymentMethod.THIRD_PARTY">
                        <div class="flex flex--100 flex--x-align-center">
                            <simple-selectbox class="third-party-select-box"
                                :pre-select="true"
                                :select-placeholder="$t('management.generic.origin')"
                                :options="thirdPartyPaymentOptions" :can-search-options="false"
                                :can-select-empty-value="false" v-model:model-value="selectedThirdPartyOrigin" />
                        </div>
                        <div class="flex flex--100 flex--x-align-center third-party-payment-textbox">
                            <simple-textbox class="flex flex--80" ref="thirdPartyTotalPriceTextbox"
                                v-model:model-value="thirdPartyOriginTotal"
                                :placeholder="$t('generic.thirdPartyTotal')"></simple-textbox>
                            <p class="order-confirm-notice-text">
                                {{ $t("cashierPos.confirmAfterPayment") }}
                            </p>
                        </div>
                        <div class="flex flex--100 flex--x-align-center third-party-payment-box">
                            <template v-if="selectedThirdPartyOrigin != ThirdPartyOrigin.WECHAT">
                                <simple-button class="third-party-payment-button" :text="$t('generic.platform')"
                                    :is-disabled="thirdPartyButtonIsDisabled"
                                    :is-loading="isWaitingServerResponseForOrderConfirmation"
                                    @click="validateThirdPartyOriginTotal(thirdPartyPaymentMethodMap2.get(Number.parseInt(selectedThirdPartyOrigin)).platform)" />
                                <simple-button class="third-party-payment-button" :text="$t('generic.pickupCash')"
                                    :is-disabled="thirdPartyButtonIsDisabled"
                                    :is-loading="isWaitingServerResponseForOrderConfirmation"
                                    @click="validateThirdPartyOriginTotal(thirdPartyPaymentMethodMap2.get(Number.parseInt(selectedThirdPartyOrigin)).cash)" />
                                <simple-button class="third-party-payment-button" :text="$t('generic.pickupCard')"
                                    :is-disabled="thirdPartyButtonIsDisabled"
                                    :is-loading="isWaitingServerResponseForOrderConfirmation"
                                    @click="validateThirdPartyOriginTotal(thirdPartyPaymentMethodMap2.get(Number.parseInt(selectedThirdPartyOrigin)).card)" />
                            </template>
                            <template v-else>
                                <simple-button class="third-party-payment-button" :text="$t('generic.platform')"
                                    :is-disabled="thirdPartyButtonIsDisabled"
                                    :is-loading="isWaitingServerResponseForOrderConfirmation"
                                    @click="validateThirdPartyOriginTotal(thirdPartyPaymentMethodMap.get(Number.parseInt(selectedThirdPartyOrigin)))" />
                            </template>
                        </div>
                    </div>
                    <div class="flex flex--100 flex--x-align-center flex--y-align-center card-payment-box"
                        v-show="orderPaymentMethod === OrderPaymentMethod.PREBILLING">
                        <div class="flex flex--100 flex--x-align-center">
                            <p class="order-confirm-notice-text">
                                {{ $t("cashierPos.confirmWithoutPayment") }}
                            </p>
                            <simple-button :text="confirmButtonText" :is-disabled="basketIsEmpty"
                                :is-loading="isWaitingServerResponseForOrderConfirmation" @click="confirmOrder()" />
                        </div>
                    </div>
                    <div class="flex flex--100 flex--x-align-center flex--y-align-center card-payment-box"
                        v-show="orderPaymentMethod === OrderPaymentMethod.MIXED">
                        <div class="flex flex--100 flex--x-align-center">
                            <p class="order-confirm-notice-text">
                                {{ $t("cashierPos.confirmAfterPayment") }}
                            </p>
                            <simple-textbox class="flex flex--80 amount-input" ref="mixedCashTextbox"
                                v-model:model-value="cashAmountPaid"
                                @input="autoCalculateRest('CASH')"
                                :placeholder="$t('generic.cash')">
                            </simple-textbox>
                            <simple-textbox class="flex flex--80 amount-input" ref="mixedCardTextbox"
                                v-model:model-value="cardAmountPaid"
                                @input="autoCalculateRest('CARD')"
                                :placeholder="$t('generic.card')">
                            </simple-textbox>
                            <simple-button :text="confirmButtonText" :is-disabled="basketIsEmpty"
                                :is-loading="isWaitingServerResponseForOrderConfirmation" @click="confirmOrder()" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex--100 flex--y-align-center order-completed-box" v-if="confirmedOrder"
                @click="hideConfirmationModalBox">
                <div class="flex flex--100 flex--x-align-center">
                    <div class="flex order-completed-icon-box">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xml:space="preserve"
                            class="flex order-completed-icon-box__icon">
                            <path
                                d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7C514.5,101.703,514.499,85.494,504.502,75.496z" />
                        </svg>
                    </div>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <span class="order-completed-box__daily-id-text">{{ $t("generic.order") }} #{{
                        confirmedOrder.dailyId
                        }}</span>
                </div>
                <div class="flex flex--100 flex--x-align-center" v-if="customerHasRest">
                    <span class="order-completed-box__customer-rest-text">{{ $t("generic.rest") }} € {{
                        normalizePriceToDisplay(customerRest) }}</span>
                </div>
            </div>
        </modal-box>
        <scene-map-table-management ref="sceneMapTableManagement" :restaurant-presentation="restaurantPresentation"
            :scene="selected" :scenes="scenes" :tables="tables" @to-pay="tableOrderToPay" @table-update="updateTables"
            @table-add-product="tableAddProduct" @table-select="updateBasketTable" />
    </div>
</template>

<script>
import CashierPaymentCalculator from "@/components/CashierPaymentCalculator";
import ModalBox from "@/components/containers/ModalBox";
import CustomerBasket from "@/components/CustomerBasket";
import LanguageSelector from "@/components/inputs/LanguageSelector";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleCheckbox from "@/components/inputs/SimpleCheckbox";
import SimpleSelectbox from "@/components/inputs/SimpleSelectbox";
import RestaurantOrder from "@/components/RestaurantOrder";
import RestaurantProductList from "@/components/RestaurantProductList";
import RestaurantProductListVertical from "@/components/RestaurantProductListVertical";
import {
    OrderStatus,
    OrderDeliveryLocation,
    OrderOrigin,
    OrderPaymentMethod,
    ThirdPartyOrigin,
    OrderFiscalReceiptType,
} from "@/orders/RestaurantOrder";
import { server, WS_API_URI, } from "@/server";
import { cloneDeep, } from "lodash";
import { RestaurantProcessor, BasketProcessor, WSKeepAlive, } from "@/utilities";
import { isDesktop, i18n, } from "@/main";
import SceneMapTableManagement from "@/components/SceneMapTableManagement";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import { DiscountStrategies } from "@/products/DiscountStrategies";
import PrimaryLogo from "@/components/utilities/PrimaryLogo";

const preloadedData = {};
let presentation = {};
const orderInPreparationAudio = new Audio(require("@/audio/order-in-preparation.mp3"));
let primaryWs;
let selectedScene;

function createPrimaryWs () {
    primaryWs?.close();

    try {
        primaryWs = new WSKeepAlive(WS_API_URI);
    }
    catch {
        // Silence is golden.
    }
}

function getMidnightDate () {
    const date = new Date();

    date.setHours(0, 0, 0, 0);

    return date;
}

function getTodayEndDate () {
    const date = new Date();

    date.setHours(23, 59, 59, 999);

    return date;
}

export default {
    async beforeRouteEnter (to, from, next) {
        selectedScene = !to.query.sceneId || to.query.sceneId === "undefined" || to.query.sceneId === "all" ? undefined : to.query.sceneId;
        preloadedData.restaurantPresentation = await server.getRestaurantPresentation(to.params.restaurantId);
        presentation = cloneDeep(preloadedData.restaurantPresentation);
        if (!preloadedData.restaurantPresentation.restaurant.isOpen) {
            next(`/restaurant/${to.params.restaurantId}/closed`);

            return;
        }

        next();
    },

    name: "CashierSalePointView",
    components: {
        SimpleTextbox,
        SceneMapTableManagement,
        LanguageSelector,
        SimpleCheckbox,
        SimpleSelectbox,
        CashierPaymentCalculator,
        RestaurantOrder,
        ModalBox,
        SimpleButton,
        CustomerBasket,
        RestaurantProductList,
        PrimaryLogo,
        RestaurantProductListVertical,
    },
    data () {
        return {
            OrderPaymentMethod,
            basket: BasketProcessor.createEmpty(),

            ordersToPay: [],
            ordersInPreparation: [],
            ordersInDelivery: [],
            completedOrders: [],
            tables: [],
            selectedThirdPartyOrigin: null,
            thirdPartyOriginTotal: "",

            isWaitingServerResponseForOrderCreation: false,
            isWaitingServerResponseToToPay: false,
            isWaitingServerResponseForTables: false,
            isWaitingServerResponseForFromInPreparationToToPay: false,
            isWaitingServerResponseForFromInDeliveryToToPay: false,
            isWaitingServerResponseForOrdersToPay: false,
            isWaitingServerResponseForOrdersInPreparation: false,
            isWaitingServerResponseForOrdersInDelivery: false,
            isWaitingServerResponseForCompletedOrders: false,
            isWaitingServerResponseForOrderConfirmation: false,
            isWaitingServerResponseForPromoCode: false,
            isWaitingServerResponseAddProduct: false,

            selectedOrderToPay: null,
            selectedOrderToReplace: null,

            selectedTableForCreateOrder: null,

            selectedTableOrderToAddProduct: null,

            ordersHandledByCashier: {},

            orderOrigin: null,
            orderPaymentMethod: null,

            cashAmountPaid: null,

            confirmedOrder: null,
            customerRest: null,

            cardAmountPaid: null,

            thirdPartyPaymentOptions: [{
                text: "Deliveroo",
                value: ThirdPartyOrigin.DELIVEROO,
            }, {
                text: "GuaGua",
                value: ThirdPartyOrigin.GUAGUA,
            }, {
                text: "Glovo",
                value: ThirdPartyOrigin.GLOVO,
            }, {
                text: "Uber Eats",
                value: ThirdPartyOrigin.UBEREATS,
            }, {
                text: "Phone Call",
                value: ThirdPartyOrigin.PHONECALL,
            }, {
                text: "WeChat",
                value: ThirdPartyOrigin.WECHAT,
            }, {
                text: "Just Eat",
                value: ThirdPartyOrigin.JUSTEAT,
            }, {
                text: "Other",
                value: ThirdPartyOrigin.OTHER,
            }],

            thirdPartyPaymentMethodMap: new Map([
                [ThirdPartyOrigin.JUSTEAT, OrderPaymentMethod.JUSTEAT],
                [ThirdPartyOrigin.GLOVO, OrderPaymentMethod.GLOVO],
                [ThirdPartyOrigin.DELIVEROO, OrderPaymentMethod.DELIVEROO],
                [ThirdPartyOrigin.PHONECALL, OrderPaymentMethod.PHONECALL],
                [ThirdPartyOrigin.WECHAT, OrderPaymentMethod.PERSONAL_WECHAT_PAY],
                [ThirdPartyOrigin.GUAGUA, OrderPaymentMethod.GUAGUA],
                [ThirdPartyOrigin.OTHER, OrderPaymentMethod.OTHER],
                [ThirdPartyOrigin.UBEREATS, OrderPaymentMethod.UBEREATS]
            ]),

            thirdPartyPaymentMethodMap2: new Map([
                [ThirdPartyOrigin.JUSTEAT, {
                    platform: OrderPaymentMethod.JUSTEAT,
                    card: OrderPaymentMethod.JUSTEAT_CARD,
                    cash: OrderPaymentMethod.JUSTEAT_CASH,
                }],
                [ThirdPartyOrigin.GLOVO, {
                    platform: OrderPaymentMethod.GLOVO,
                    card: OrderPaymentMethod.GLOVO_CARD,
                    cash: OrderPaymentMethod.GLOVO_CASH,
                }],
                [ThirdPartyOrigin.DELIVEROO, {
                    platform: OrderPaymentMethod.DELIVEROO,
                    card: OrderPaymentMethod.DELIVEROO_CARD,
                    cash: OrderPaymentMethod.DELIVEROO_CASH,
                }],
                [ThirdPartyOrigin.GUAGUA, {
                    platform: OrderPaymentMethod.GUAGUA,
                    card: OrderPaymentMethod.GUAGUA_CARD,
                    cash: OrderPaymentMethod.GUAGUA_CASH,
                }],
                [ThirdPartyOrigin.OTHER, {
                    platform: OrderPaymentMethod.OTHER,
                    card: OrderPaymentMethod.OTHER_CARD,
                    cash: OrderPaymentMethod.OTHER_CASH,
                }],
                [ThirdPartyOrigin.UBEREATS, {
                    platform: OrderPaymentMethod.UBEREATS,
                    card: OrderPaymentMethod.UBEREATS_CARD,
                    cash: OrderPaymentMethod.UBEREATS_CASH,
                }],
                [ThirdPartyOrigin.PHONECALL, {
                    platform: OrderPaymentMethod.PHONECALL,
                    card: OrderPaymentMethod.PHONECALL_CARD,
                    cash: OrderPaymentMethod.PHONECALL_CASH,
                }],
            ]),

            selectedScene,
            featureSelected: -1,
            promoCodeReply: "",
            promoCode: "",
            ThirdPartyOrigin,
            globalDiscount: "",
            reload: true,
            webhook: "",
        };
    },
    methods: {
        getModifiers(status) {
            const statusColors = {
                requires_action: "text-yellow",
                succeeded: "text-green",
                failed: "text-red",
                canceled: "text-gray",
            };
            return statusColors[status] || "text-gray";
        },
    
        async clearOrder () {
            this.selectedThirdPartyOrigin = null;
            this.thirdPartyOriginTotal = "";
            this.selectedTableForCreateOrder = null;
            this.selectedOrderToPay = null;
            this.selectedOrderToReplace = null;
            this.selectedTableOrderToAddProduct = null;
            this.promoCode = "";
            this.cardAmountPaid = "";
            this.cashAmountPaid = "";
            this.basket = BasketProcessor.createEmpty();
            await this.removeGlobalDiscount();
        },

        hideConfirmationModalBox () {
            this.$refs.orderConfirmationModalBox.hide();
        },

        onConfirmationModalBoxHide () {
            this.orderPaymentMethod = null;
            this.cashAmountPaid = null;
            this.customerRest = null;
            this.confirmedOrder = null;
        },

        async selectOrderFromInPreparationToToPay (order) {
            if (this.isWaitingServerResponseForFromInPreparationToToPay) {
                return;
            }

            this.isWaitingServerResponseForFromInPreparationToToPay = true;

            const updatedOrder = await server.updateOrderToToPay({
                id: order.id,
            });

            this.isWaitingServerResponseForFromInPreparationToToPay = false;


            this.selectOrderToPay(updatedOrder);
        },

        async orderFromInPreparationToToPay (order) {
            const orderProducts = order.products;

            for (const orderProduct of orderProducts) {
                if (orderProduct.requestedMenus) {
                    for (const orderFixedMenu of orderProduct.requestedMenus) {
                        for (const orderFixedMenuProduct of orderFixedMenu.products) {
                            await server.updateOrderProduct({
                                orderId: order.id,
                                productId: orderFixedMenuProduct.id,
                                completedVolume: orderFixedMenuProduct.requestedVolume,
                                orderFixedMenuId: orderFixedMenu.id,
                            });

                            await server.updateOrderProductDeliveredVolume({
                                orderId: order.id,
                                productId: orderFixedMenuProduct.id,
                                deliveredVolume: orderFixedMenuProduct.requestedVolume,
                                orderFixedMenuId: orderFixedMenu.id,
                            });
                        }
                    }
                }
                else {
                    await server.updateOrderProduct({
                        orderId: order.id,
                        productId: orderProduct.id,
                        completedVolume: orderProduct.requestedVolume,
                    });

                    await server.updateOrderProductDeliveredVolume({
                        orderId: order.id,
                        productId: orderProduct.id,
                        deliveredVolume: orderProduct.requestedVolume,
                    });
                }
            }

            if (!this.skipDeliveryState) {
                await server.updateOrder({
                    id: order.id,
                    status: OrderStatus.IN_DELIVERY,
                });
            }

            return server.updateOrder({
                id: order.id,
                status: OrderStatus.TO_PAY,
            });
        },

        async selectOrderFromInDeliveryToToPay (order) {

            if (this.isWaitingServerResponseForFromInDeliveryToToPay) {
                return;
            }

            this.isWaitingServerResponseForFromInDeliveryToToPay = true;

            const updatedOrder = await server.updateOrderToToPay({
                id: order.id,
            });

            this.isWaitingServerResponseForFromInDeliveryToToPay = false;

            this.selectOrderToPay(updatedOrder);
        },

        async orderFromInDeliveryToToPay (order) {
            const orderProducts = order.products;

            for (const orderProduct of orderProducts) {
                if (orderProduct.requestedMenus) {
                    for (const orderFixedMenu of orderProduct.requestedMenus) {
                        for (const orderFixedMenuProduct of orderFixedMenu.products) {
                            await server.updateOrderProductDeliveredVolume({
                                orderId: order.id,
                                productId: orderFixedMenuProduct.id,
                                deliveredVolume: orderFixedMenuProduct.requestedVolume,
                                orderFixedMenuId: orderFixedMenu.id,
                            });
                        }
                    }
                }
                else {
                    await server.updateOrderProductDeliveredVolume({
                        orderId: order.id,
                        productId: orderProduct.id,
                        deliveredVolume: orderProduct.requestedVolume,
                    });
                }
            }

            return server.updateOrder({
                id: order.id,
                status: OrderStatus.TO_PAY,
            });
        },

        replaceOrderToPay (order) {
            this.clearOrder();
            this.selectedOrderToPay = order;
            this.selectedOrderToReplace = order;

            this.$refs.orderToPayListModalBox.hide();
            this.$refs.orderInPreparationListModalBox.hide();
            this.$refs.orderInDeliveryListModalBox.hide();
        },

        selectOrderToPay (order) {
            this.clearOrder();

            order.products.forEach((product) => {
                const productId = product.id;
                const productVariations = product.variations;
                const productMessage = product.message;
                const productSelectionDescriptor = {
                    selectedVolume: product.requestedVolume,
                };

                if (this.restaurantProcessor.productIsFixedMenu(productId)) {
                    productSelectionDescriptor.selectedProducts = product.requestedMenus.map((menu) => menu.products.map((menuProduct) => {
                        return {
                            [menuProduct.id]: { selectedVolume: 1, },
                        };
                    }));
                }

                if (this.restaurantProcessor.productIsCustomProduct(productId)) {
                    productSelectionDescriptor.selectedProducts = product.requestedMenus.map((menu) => menu.products.map((menuProduct) => {
                        return {
                            [menuProduct.id]: { selectedVolume: 1, price: this.restaurantProcessor.getProductById(menuProduct.id).price, },
                        };
                    }));
                }

                this.basket.selectedProducts[productId] = productSelectionDescriptor;

                if (productVariations && productVariations.length > 0) {
                    const normalizedProductVariations = {};

                    for (let i = 0; i < product.requestedVolume; ++i) {
                        normalizedProductVariations[i] = productVariations.filter(
                            (productVariation) => productVariation.volumeIndex === i
                        ).map(
                            (productVariation) => productVariation.id
                        );
                    }

                    this.basket.selectedProductsVariations[productId] = normalizedProductVariations;
                }

                if (productMessage) {
                    this.basket.selectedProductsMessages[productId] = productMessage;
                }
            });

            this.basket.isTakeAway = order.deliveryLocation === OrderDeliveryLocation.TAKE_AWAY;
            this.selectedOrderToPay = order;

            if (order.requestedPersons !== null && order.requestedPersons !== undefined && Number(order.requestedPersons) > 0) {
                this.basket.cover = String(order.requestedPersons);
                let additions = this.basketProcessor.appliedAdditions;
                if (this.basket.cover.length === 0) {
                    additions = additions.filter((a) => a.description !== "Coperto");
                }
                if (this.basket.cover.length > 0) {
                    const coverAddition = additions.find((a) => a.description === "Coperto");
                    if (coverAddition) {
                        coverAddition.value = order.requestedPersons * this.coverPrice;
                    }
                    else {
                        additions.push({
                            description: "Coperto",
                            value: order.requestedPersons * this.coverPrice,
                        });
                    }
                }
            }

            // if (order.deductions.length > 0) { // If add other promotion type change this!!!
            //     this.applyOnePlusOne();
            // }

            this.$refs.orderToPayListModalBox.hide();
            this.$refs.orderInPreparationListModalBox.hide();
            this.$refs.orderInDeliveryListModalBox.hide();

            if (order.glovo) {
                this.selectedThirdPartyOrigin = String(ThirdPartyOrigin.GLOVO);
                this.thirdPartyOriginTotal = order.totalPrice.toFixed(2);
                this.showOrderConfirmationModalBox();
                this.selectThirdPartyPaymentMethod();
            }
        },

        getPaymentMethodButtonModifiers (method) {
            return {
                "payment-method-button--selected": this.orderPaymentMethod === method,
            };
        },

        async updateOrdersToPay () {
            if (this.isWaitingServerResponseForOrdersToPay) {
                return;
            }

            this.isWaitingServerResponseForOrdersToPay = true;
            this.ordersToPay = await server.getRestaurantOrdersSceneId(this.restaurantId, OrderStatus.TO_PAY, this.selectedScene);
            this.isWaitingServerResponseForOrdersToPay = false;
        },

        async updateOrdersInPreparation () {
            if (this.isWaitingServerResponseForOrdersInPreparation) {
                return;
            }

            this.isWaitingServerResponseForOrdersInPreparation = true;
            this.ordersInPreparation = await server.getRestaurantOrdersSceneId(this.restaurantId, OrderStatus.IN_PREPARATION, this.selectedScene);
            this.isWaitingServerResponseForOrdersInPreparation = false;
        },

        async updateOrdersInDelivery () {
            if (this.isWaitingServerResponseForOrdersInDelivery) {
                return;
            }

            this.isWaitingServerResponseForOrdersInDelivery = true;
            this.ordersInDelivery = await server.getRestaurantOrdersSceneId(this.restaurantId, OrderStatus.IN_DELIVERY, this.selectedScene);
            this.isWaitingServerResponseForOrdersInDelivery = false;
        },

        async updateCompletedOrders () {
            if (this.isWaitingServerResponseForCompletedOrders) {
                return;
            }

            this.isWaitingServerResponseForCompletedOrders = true;
            this.completedOrders = await server.getRestaurantOrdersSceneId(this.restaurantId, OrderStatus.CLOSED, this.selectedScene);
            this.isWaitingServerResponseForCompletedOrders = false;
        },

        async updateTables () {
            if (this.isWaitingServerResponseForTables) {
                return;
            }

            this.isWaitingServerResponseForTables = true;
            this.tables = await server.getRestaurantTables({ restaurantId: this.restaurantId, });
            this.isWaitingServerResponseForTables = false;
        },

        async showOrdersToPay () {
            await this.updateOrdersToPay();
            this.ordersToPay.sort((a, b) => b.creationTimestamp - a.creationTimestamp);
            this.$refs.orderToPayListModalBox.show();
        },

        async showOrdersInPreparation () {
            await this.updateOrdersInPreparation();
            this.ordersInPreparation.sort((a, b) => b.creationTimestamp - a.creationTimestamp);
            this.$refs.orderInPreparationListModalBox.show();
        },

        async showOrdersInDelivery () {
            await this.updateOrdersInDelivery();
            this.ordersInDelivery.sort((a, b) => b.creationTimestamp - a.creationTimestamp);
            this.$refs.orderInDeliveryListModalBox.show();
        },

        async showCompletedOrders () {
            await this.updateCompletedOrders();
            this.completedOrders.sort((a, b) => b.creationTimestamp - a.creationTimestamp);
            this.$refs.completedOrderListModalBox.show();
        },

        async showTables () {
            await this.updateTables();
            this.$refs.sceneMapTableManagement.load();
            this.$refs.sceneMapTableManagement.show();
        },

        showMemberOnlyModal () {
            this.$refs.memberOnlyModalBox.show();
        },

        showCashRegisterModalBox () {
            this.$refs.cashRegisterModalBox.show();
        },

        playNewOrderNotificationSound () {
            orderInPreparationAudio.play();
        },

        showOrderConfirmationModalBox () {
            this.$refs.orderConfirmationModalBox.show();
        },

        selectCashPaymentMethod () {
            this.orderPaymentMethod = OrderPaymentMethod.CASH;
        },

        selectCardPaymentMethod () {
            this.orderPaymentMethod = OrderPaymentMethod.CARD;
        },

        selectThirdPartyPaymentMethod () {
            this.orderPaymentMethod = OrderPaymentMethod.THIRD_PARTY;
        },

        selectPrebillingPaymentMethod () {
            this.orderPaymentMethod = OrderPaymentMethod.PREBILLING;
        },

        selectMixedPaymentMethod () {
            this.orderPaymentMethod = OrderPaymentMethod.MIXED;
        },

        async createOrderToPay ({ products, fixedMenus, customProducts, }) {
            const origin = this.orderPaymentMethod === OrderPaymentMethod.THIRD_PARTY ? OrderOrigin.THIRD_PARTY : OrderOrigin.CASHIER;
            const deliveryLocation = this.basket.isTakeAway ? OrderDeliveryLocation.TAKE_AWAY : OrderDeliveryLocation.RESTAURANT;
            const tables = [];
            if (this.selectedTableForCreateOrder) {
                tables.push(this.selectedTableForCreateOrder.id);
            }
            return server.sendOrder({
                sceneId: this.selectedScene,
                origin,
                deliveryLocation,
                products,
                fixedMenus,
                customProducts,
                tables,
                thirdPartyOrigin: this.selectedThirdPartyOrigin,
                totalPrice: this.thirdPartyOriginTotal,
                discounts: this.basket.discounts,
                additions: this.basket.additions,
                deductions: this.basket.deductions,
                productsVariations: this.basket.selectedProductsVariations,
                productsMessages: this.basket.selectedProductsMessages,
                globalDiscount: this.globalDiscount,
                promoCode: this.promoCode,
            });
        },

        async createOrderPaymentAfterService () {
            if (this.isWaitingServerResponseForOrderCreation) {
                return;
            }

            this.isWaitingServerResponseForOrderCreation = true;
            const products = [];
            const fixedMenus = [];
            const customProducts = [];

            this.basketSelectedProducts.forEach((product) => {
                for (let i = 0; i < this.basketProcessor.getProductSelectedVolume(product.id); ++i) {
                    products.push(product.id);
                }
            });

            this.basketSelectedFixedMenus.forEach((fixedMenu) => {
                this.basket.selectedProducts[fixedMenu.id].selectedProducts.forEach((menu) => {
                    fixedMenus.push([
                        fixedMenu.id, ...menu.map((product) => Number.parseInt(Object.keys(product)[0])),
                    ]);
                });
            });

            this.basketSelectedCustomProducts.forEach((customProduct) => {
                this.basket.selectedProducts[customProduct.id].selectedProducts.forEach((products) => {
                    let productIds = [];
                    const currentCustomProduct = [customProduct.id,];

                    productIds = products.map((product) => Object.keys(product));
                    productIds = productIds.map((ids) => {
                        let intIds = [];
                        ids.forEach((id) => {
                            intIds = [...intIds, Number.parseInt(id)];
                        })
                        return intIds;
                    });

                    productIds.forEach((ids) => {
                        ids.forEach((id) => {
                            currentCustomProduct.push(id);
                        })
                    });
                    customProducts.push(currentCustomProduct);
                });
            });

            await this.createOrderToPay({ products, fixedMenus, customProducts, });

            this.isWaitingServerResponseForOrderCreation = false;
            this.clearOrder();
        },

        async confirmOrder (thirdPartyPaymentMethod = null) {
            if (this.orderPaymentMethod === OrderPaymentMethod.CASH) {
                if (this.cashAmountPaid < this.basketTotal) {
                    return;
                }
            }

            if (this.orderPaymentMethod === OrderPaymentMethod.MIXED) {
                if (Number.parseFloat(this.cashAmountPaid) + Number.parseFloat(this.cardAmountPaid) < this.basketTotal) {
                    return;
                }
            }

            if (this.isWaitingServerResponseForOrderConfirmation) {
                return;
            }

            this.isWaitingServerResponseForOrderConfirmation = true;

            const products = [];
            const fixedMenus = [];
            const customProducts = [];
            const deliveryLocation = this.basket.isTakeAway ? OrderDeliveryLocation.TAKE_AWAY : OrderDeliveryLocation.RESTAURANT;

            this.basketSelectedProducts.forEach((product) => {
                for (let i = 0; i < this.basketProcessor.getProductSelectedVolume(product.id); ++i) {
                    products.push(product.id);
                }
            });

            this.basketSelectedFixedMenus.forEach((fixedMenu) => {
                this.basket.selectedProducts[fixedMenu.id].selectedProducts.forEach((menu) => {
                    fixedMenus.push([
                        fixedMenu.id, ...menu.map((product) => Number.parseInt(Object.keys(product)[0])),
                    ]);
                });
            });

            this.basketSelectedCustomProducts.forEach((customProduct) => {
                this.basket.selectedProducts[customProduct.id].selectedProducts.forEach((products) => {
                    let productIds = [];
                    const currentCustomProduct = [customProduct.id,];

                    productIds = products.map((product) => Object.keys(product));
                    productIds = productIds.map((ids) => {
                        let intIds = [];
                        ids.forEach((id) => {
                            intIds = [...intIds, Number.parseInt(id)];
                        })
                        return intIds;
                    });

                    productIds.forEach((ids) => {
                        ids.forEach((id) => {
                            currentCustomProduct.push(id);
                        })
                    });
                    customProducts.push(currentCustomProduct);
                });
            });

            let order = this.selectedOrderToPay;

            if (!order) {
                order = await this.createOrderToPay({ products, fixedMenus, customProducts, });
            }

            this.ordersHandledByCashier[order.id] = true;

            let nextStatus = OrderStatus.IN_PREPARATION;

            if (this.selectedOrderToPay && this.paymentAfterService || this.skipPreparationState || order.glovo) {
                nextStatus = OrderStatus.CLOSED;
            }
            if (!this.selectedOrderToPay && this.skipPreparationState && this.paymentAfterService) {
                nextStatus = OrderStatus.TO_PAY;
            }

            let paymentMethod = this.orderPaymentMethod;
            let amount = this.basketTotal;

            if (thirdPartyPaymentMethod) {
                paymentMethod = thirdPartyPaymentMethod;
                amount = this.thirdPartyOriginTotal;
            }

            let paymentDetail = [
                {
                    paymentMethod,
                    amount,
                }
            ];

            if (paymentMethod === OrderPaymentMethod.MIXED) {
                paymentDetail = [
                    {
                        paymentMethod: OrderPaymentMethod.CASH,
                        amount: this.cashAmountPaid,
                    },
                    {
                        paymentMethod: OrderPaymentMethod.CARD,
                        amount: this.cardAmountPaid,
                    }
                ];
            }

            const updateDescriptor = {
                id: order.id,
                paidThroughCashier: 1,
                status: nextStatus,
                paymentMethod,
                paymentDetail,
            };

            if (this.selectedOrderToPay) {
                updateDescriptor.products = products;
                updateDescriptor.fixedMenus = fixedMenus;
                updateDescriptor.customProducts = customProducts;
                updateDescriptor.discounts = this.basket.discounts;
                updateDescriptor.additions = this.basket.additions;
                updateDescriptor.deductions = this.basket.deductions;
                updateDescriptor.deliveryLocation = deliveryLocation;
                updateDescriptor.productsVariations = this.basket.selectedProductsVariations;
                updateDescriptor.productsMessages = this.basket.selectedProductsMessages;
                updateDescriptor.globalDiscount = this.globalDiscount;
            }
            if (this.selectedOrderToReplace) {
                // Table modification goes here
                updateDescriptor.origin = this.orderPaymentMethod === OrderPaymentMethod.THIRD_PARTY ? OrderOrigin.THIRD_PARTY : OrderOrigin.CASHIER;
                updateDescriptor.thirdPartyOrigin = this.selectedThirdPartyOrigin;
                updateDescriptor.totalPrice = this.thirdPartyOriginTotal;
            }

            order = await server.updateOrder(updateDescriptor);
            order.customProducts?.forEach((customProduct) => {
                const product = order.products.find((productToFind) => productToFind.id === customProduct.customProductId);
                if (product) {
                    let totalPrice = 0;
                    product.requestedMenus.forEach((menu) => {
                        menu.products.forEach((menuProduct) => {
                            totalPrice += this.restaurantProcessor.getProductById(menuProduct.id).price;
                        });
                    });
                    product.unitPrice = totalPrice;
                }
            });

            // Add cash amount
            order.cashAmountPaid = this.cashAmountPaid;
            // Add third party payment method
            order.thirdPartyPaymentMethod = thirdPartyPaymentMethod;

            if (this.orderPaymentMethod === OrderPaymentMethod.CASH ||
                this.orderPaymentMethod === OrderPaymentMethod.GLOVO_CASH ||
                this.orderPaymentMethod === OrderPaymentMethod.DELIVEROO_CASH ||
                this.orderPaymentMethod === OrderPaymentMethod.JUSTEAT_CASH ||
                this.orderPaymentMethod === OrderPaymentMethod.UBEREATS_CASH ||
                this.orderPaymentMethod === OrderPaymentMethod.PHONECALL_CASH ||
                this.orderPaymentMethod === OrderPaymentMethod.GUAGUA_CASH ||
                this.orderPaymentMethod === OrderPaymentMethod.OTHER_CASH) {
                this.customerRest = this.cashAmountPaid - this.basketTotal;
            }

            this.confirmedOrder = order;
            this.isWaitingServerResponseForOrderConfirmation = false;

            this.clearOrder();
            this.printReceipt(order);
        },

        printReceipt (order, onlyReminder = false) {
            // <receipt>
            if (isDesktop && order) {
                try {
                    const { ipcRenderer, } = window.require("electron");

                    /**
                     * Link price and localizations to order products to keep compatibility with FortuneRMS Desktop (avoids breaking printers).
                     */
                    order.products.forEach((orderProduct) => {
                        const product = this.restaurantProcessor.getProductById(orderProduct.id);

                        orderProduct.price = product.price !== 0 ? product.price : orderProduct.unitPrice;
                        orderProduct.localizations = product.localizations;
                        orderProduct.variationsDetails = this.restaurantProcessor.getProductVariations(orderProduct.id);
                        orderProduct.valueAddedTax = product.valueAddedTax;
                        orderProduct.category = product.categories[0].id;
                        if (!product.discountExpirationTimestamp || product.discountExpirationTimestamp > Date.now()) {
                            orderProduct.discountStrategies = product.discountStrategies;
                        }
                        this.bindFixedMenuProductLocalizations(order); // Link fixed menu products localizations for receipt
                        this.bindCustomProductProductLocalizations(order); // Link custom dish products localizations for receipt
                    });
                    console.log(order);
                    if (onlyReminder) {
                        ipcRenderer.send("printOrder", JSON.stringify(order));
                    }
                    else {
                        ipcRenderer.send("confirmOrder", JSON.stringify(order));
                    }
                }
                catch {
                    // Silence is golden.
                }
            }
            // </receipt>
        },

        bindFixedMenuProductLocalizations (order) {
            order.products.forEach((product) => {
                if (this.restaurantProcessor.productIsFixedMenu(product.id)) {
                    for (const menu of product.requestedMenus) {
                        menu.products.forEach((fixedMenuProduct) => {
                            fixedMenuProduct.localizations = this.restaurantProcessor.getProductById(fixedMenuProduct.id).localizations;
                        });
                    }
                }
            });
        },

        bindCustomProductProductLocalizations (order) {
            order.products.forEach((product) => {
                if (this.restaurantProcessor.productIsCustomProduct(product.id)) {
                    for (const menu of product.requestedMenus) {
                        menu.products.forEach((customProductProduct) => {
                            customProductProduct.localizations = this.restaurantProcessor.getProductById(customProductProduct.id).localizations;
                        });
                    }
                }
            });
        },

        openCashRegister () {
            try {
                const { ipcRenderer, } = window.require("electron");

                ipcRenderer.send("openCashRegister");
            }
            catch {
                // Silence is golden.
            }

            this.$refs.cashRegisterModalBox.hide();
        },

        closeFiscalDay () {
            try {
                const { ipcRenderer, } = window.require("electron");

                ipcRenderer.send("closeFiscalDay");
            }
            catch {
                // Silence is golden.
            }

            this.$refs.cashRegisterModalBox.hide();
        },

        exit () {
            try {
                const { ipcRenderer, } = window.require("electron");

                ipcRenderer.send("exit");
            }
            catch {
                // Silence is golden.
            }

            this.$refs.cashRegisterModalBox.hide();
        },

        async tableOrderToPay (orderId) {
            if (this.isWaitingServerResponseToToPay) {
                return;
            }

            this.isWaitingServerResponseToToPay = true;

            const updatedOrder = await server.updateOrderToToPay({
                id: orderId,
            });

            this.selectOrderToPay(updatedOrder);

            this.isWaitingServerResponseToToPay = false;
            this.$refs.sceneMapTableManagement.hide();
        },

        async tableAddProduct (tableId, orderId) {
            this.selectedTableOrderToAddProduct = await server.getOrder({ id: orderId });
            this.$refs.sceneMapTableManagement.hide();
        },

        async addProduct () {
            if (this.isWaitingServerResponseAddProduct) {
                return;
            }

            this.isWaitingServerResponseAddProduct = true;

            const products = [];
            const fixedMenus = [];

            this.basketSelectedProducts.forEach((product) => {
                for (let i = 0; i < this.basketProcessor.getProductSelectedVolume(product.id); ++i) {
                    products.push(product.id);
                }
            });

            this.basketSelectedFixedMenus.forEach((fixedMenu) => {
                this.basket.selectedProducts[fixedMenu.id].selectedProducts.forEach((menu) => {
                    fixedMenus.push([
                        fixedMenu.id, ...menu.map((product) => Number.parseInt(Object.keys(product)[0])),
                    ]);
                });
            });

            const order = await server.addOrderProduct({
                id: this.selectedTableOrderToAddProduct.id,
                fixedMenus,
                products,
            });

            if (order) {
                this.playNewOrderNotificationSound();
                this.clearOrder();
            }
            this.isWaitingServerResponseAddProduct = false;
        },

        async updateBasketTable (table) {
            this.selectedTableForCreateOrder = table;
            this.$refs.sceneMapTableManagement.hide();
        },

        async continueOrder () {
            // if (this.paymentAfterService && this.selectedTableForCreateOrder) {
            if (this.paymentAfterService && !this.selectedOrderToPay) {
                await this.createOrderPaymentAfterService();
            }
            else {
                this.showOrderConfirmationModalBox();
            }
        },

        async validateThirdPartyOriginTotal (paymentMethod) {
            const regex = /^(?:\d*\.\d{1,2}|\d+)$/;
            const thirdPartyTotalPriceTextbox = this.$refs.thirdPartyTotalPriceTextbox;

            thirdPartyTotalPriceTextbox.clearErrors();

            thirdPartyTotalPriceTextbox.validate({
                id: 1,
                text: "Invalid number format",
            }, regex.test(this.thirdPartyOriginTotal));

            if (thirdPartyTotalPriceTextbox.isValid) {
                await this.confirmOrder(paymentMethod);
            }
        },

        onSceneSelectboxOptionsLoad () {
            const firstOption = this.sceneSelectboxOptions[0];
            const selectedScene = this.selectedScene || firstOption?.value;
            if (firstOption) {
                this.$refs.sceneSelectbox.selectOptionByValue(String(selectedScene));
            }
            else {
                this.selectedScene = String(selectedScene);
            }
        },

        onCoverInput (value) {
            this.$refs.customerBasket.updateAddition(value);
        },

        selectFeature (featureIndex) {
            this.featureSelected = featureIndex;
        },

        goBackToFeatures () {
            if (this.featureSelected !== -1) {
                this.featureSelected = -1; // Return to features list
            } else {
                // Close the modal or perform another action
                this.$refs.memberOnlyModalBox.toggle();
            }
        },

        async checkPromoCode () {
            try {
                const response = await server.checkPromoCode({ code: this.promoCode, });
                this.promoCodeReply = this.formatPromoCodeResponse(response);
            }
            catch (e) {
                console.log(e);
            }
        },

        async usePromoCode () {
            if (this.isWaitingServerResponseForPromoCode) {
                return;
            }
            this.isWaitingServerResponseForPromoCode = true;
            try {
                const response = await server.usePromoCode({ code: this.promoCode, });
                if (response) {
                    this.promoCodeReply = "OK";
                    if (response.discountPercentage) {
                        this.globalDiscount = response.discountPercentage;
                        this.applyGlobalDiscount();
                    }
                }
                else {
                    this.promoCodeReply = this.$t("memberOnly.invalid");
                }
            }
            catch (e) {
                console.log(e);
            }
            this.isWaitingServerResponseForPromoCode = false;
        },

        formatPromoCodeResponse (response) {
            if (!response || !response.isActive) {
                return this.$t("memberOnly.invalid");
            }
            // Parse the expiry date from the response
            const expiryDate = response.expiryDate ? new Date(response.expiryDate) : null;
            const currentDate = new Date();

            // Check if the promo code has expired
            if (expiryDate && (currentDate > expiryDate)) {
                return this.$t('memberOnly.expired');
            }

            const options = { day: 'numeric', month: 'long', year: 'numeric' };
            const formattedExpiryDate = expiryDate ? expiryDate.toLocaleDateString('it-IT', options) : this.$t("memberOnly.unlimited");

            let message = `**${this.$t("memberOnly.promoCode")}:** ${response.code}\n`;
            message += `**${this.$t("memberOnly.description")}:** ${response.description}\n`;
            if (response.discountPercentage !== null) {
                message += `**${this.$t("memberOnly.discount")}:** ${response.discountPercentage}%\n`;
            } else {
                message += `**${this.$t("memberOnly.discount")}:** ${this.$t("memberOnly.notApplicable")}\n`;
            }
            message += `**${this.$t("memberOnly.validUntil")}:** ${formattedExpiryDate}\n`;

            return message;
        },

        async updateSingleProductDiscount ({ id, volume }) {
            if (this.globalDiscount.length > 0) {
                let ds = null;
                const discountPercentage = Number.parseFloat(this.globalDiscount);
                if (this.restaurantProcessor.productIsFixedMenu(id)) {
                    const fixedMenu = this.restaurantProcessor.getFixedMenuById(id);
                    const originalFixedMenu = presentation.fixedMenus.find((p) => p.id === id);
                    const estimateDiscount = -DiscountStrategies.percentage.applyDiscount(
                        Math.max(volume, 1),
                        fixedMenu.price,
                        discountPercentage
                    );
                    const normalDiscount = this.getDiscountedProductPrice(originalFixedMenu, volume);
                    if (estimateDiscount < normalDiscount) {
                        if (fixedMenu.discountStrategies.length > 0) {
                            fixedMenu.discountStrategies[0] = `%${this.globalDiscount}`;
                        }
                        else {
                            fixedMenu.discountStrategies = [`%${this.globalDiscount}`];
                        }
                        fixedMenu.discountExpirationTimestamp = null;
                    }
                    else {
                        fixedMenu.discountStrategies = cloneDeep(originalFixedMenu.discountStrategies);
                        fixedMenu.discountExpirationTimestamp = originalFixedMenu.discountExpirationTimestamp;
                    }
                    ds = cloneDeep(fixedMenu.discountStrategies);
                }
                else {
                    const product = this.restaurantProcessor.getProductById(id);
                    const originalProduct = presentation.products.find((p) => p.id === id);
                    const estimateDiscount = -DiscountStrategies.percentage.applyDiscount(
                        Math.max(volume, 1),
                        product.price,
                        discountPercentage
                    );
                    const normalDiscount = this.getDiscountedProductPrice(originalProduct, volume);
                    if (estimateDiscount < normalDiscount) {
                        if (product.discountStrategies.length > 0) {
                            product.discountStrategies[0] = `%${this.globalDiscount}`;
                        }
                        else {
                            product.discountStrategies = [`%${this.globalDiscount}`];
                        }
                        product.discountExpirationTimestamp = null;
                    }
                    else {
                        product.discountStrategies = cloneDeep(originalProduct.discountStrategies);
                        product.discountExpirationTimestamp = originalProduct.discountExpirationTimestamp;
                    }
                    ds = cloneDeep(product.discountStrategies);
                }
                this.basketProcessor.unselectProducts();
                await this.$nextTick();
                this.basketProcessor.reselectProducts();
                await this.$refs.restaurantProductList.forceUpdateById(id, ds);
            }
        },

        async applyGlobalDiscountSafe () {
            await this.resetGlobalDiscount();
            await this.applyGlobalDiscount();
        },

        async applyGlobalDiscount () {
            const products = this.restaurantPresentation.products;
            const fixedMenus = this.restaurantPresentation.fixedMenus;
            const discountPercentage = Number.parseFloat(this.globalDiscount);
            products.forEach((p) => {
                const volume = this.basketProcessor.getProductSelectedVolume(p.id) || 1;
                const estimateDiscount = -DiscountStrategies.percentage.applyDiscount(
                    volume,
                    p.price,
                    discountPercentage
                );
                const normalDiscount = this.getDiscountedProductPrice(p, volume);
                if (estimateDiscount < normalDiscount) {
                    if (p.discountStrategies.length > 0) {
                        p.discountStrategies[0] = `%${this.globalDiscount}`;
                    }
                    else {
                        p.discountStrategies = [`%${this.globalDiscount}`];
                    }
                    p.discountExpirationTimestamp = null;
                }
            });
            fixedMenus.forEach((p) => {
                const volume = this.basketProcessor.getProductSelectedVolume(p.id) || 1;
                const estimateDiscount = -DiscountStrategies.percentage.applyDiscount(
                    volume,
                    p.price,
                    discountPercentage
                );
                const normalDiscount = this.getDiscountedProductPrice(p, volume);
                if (estimateDiscount < normalDiscount) {
                    if (p.discountStrategies.length > 0) {
                        p.discountStrategies[0] = `%${this.globalDiscount}`;
                    }
                    else {
                        p.discountStrategies = [`%${this.globalDiscount}`];
                    }
                    p.discountExpirationTimestamp = null;
                }
            });
            this.basketProcessor.unselectProducts();
            this.$refs.memberOnlyModalBox.hide();
            this.$refs.memberOnlyModalBox.hide();
            await this.$nextTick();
            this.basketProcessor.reselectProducts();
            await this.$refs.restaurantProductList.forceUpdate();
        },

        async removeGlobalDiscount () {
            if (this.globalDiscount.length > 0) {
                this.globalDiscount = "";
                await this.$nextTick();
                await this.resetGlobalDiscount();
            }
        },

        async resetGlobalDiscount () {
            const products = this.restaurantPresentation.products;
            const fixedMenus = this.restaurantPresentation.fixedMenus;

            products.forEach((p) => {
                const original = presentation.products.find((pp) => pp.id === p.id);
                p.discountStrategies = cloneDeep(original.discountStrategies);
                p.discountExpirationTimestamp = original.discountExpirationTimestamp;
            });
            fixedMenus.forEach((p) => {
                const original = presentation.fixedMenus.find((pf) => pf.id === p.id);
                p.discountStrategies = cloneDeep(original.discountStrategies);
                p.discountExpirationTimestamp = original.discountExpirationTimestamp;
            });
            this.basketProcessor.unselectProducts();
            await this.$nextTick();
            this.basketProcessor.reselectProducts();
            await this.$refs.restaurantProductList.forceUpdate();
        },

        getDiscountedProductPrice (product, volume) {
            if (!product.discountStrategies || product.discountExpirationTimestamp && (Number.parseInt(product.discountExpirationTimestamp) < Date.now())) {
                return 0;
            }
            let totalPrice = 0;

            if (product.discountStrategies.includes(DiscountStrategies.onePlusOne.id)) {
                totalPrice -= DiscountStrategies.onePlusOne.applyDiscount(
                    volume,
                    product.price,
                );
            }

            const fixedDiscount = product.discountStrategies.find((s) =>
                String(s).startsWith("-")
            );
            const fixed = Boolean(fixedDiscount);
            if (fixed) {
                const fixedValue = fixedDiscount.substring(1);
                totalPrice -= DiscountStrategies.fixed.applyDiscount(
                    volume,
                    product.price,
                    fixedValue
                );
            }

            const percentageDiscount = product.discountStrategies.find((s) =>
                String(s).startsWith("%")
            );
            const percentage = Boolean(percentageDiscount);
            if (percentage) {
                const percentageValue = percentageDiscount.substring(1);
                totalPrice -= DiscountStrategies.percentage.applyDiscount(
                    volume,
                    product.price,
                    percentageValue
                );
            }

            return totalPrice;
        },
        
        async printWithDelay (onlineOrder, delay = 1000) {
            for (const order of onlineOrder) {
                this.playNewOrderNotificationSound();
                this.printReceipt(order, false);
                await new Promise(resolve => setTimeout(resolve, delay));
            }
        },
        
        shouldPrintReceipt (order) {
            // Filtra gli scontrini in ordine cronologico
            const sortedReceipts = [...order.fiscalReceipts].sort(
                (f1, f2) => f2.creationTimestamp - f1.creationTimestamp
            );

            // Verifica se esistono errori o annullamenti
            for (const receipt of sortedReceipts) {
                if (receipt.type === OrderFiscalReceiptType.ERROR) {
                    // Se c'è un errore, bisogna riprovare a stampare
                    return true;
                }
                if (receipt.type === OrderFiscalReceiptType.VOID) {
                    // Se c'è un annullamento, verifica se c'è un RECEIPT successivo da stampare
                    const subsequentReceipt = sortedReceipts.find(
                        (r) =>
                            r.type === OrderFiscalReceiptType.RECEIPT &&
                            r.creationTimestamp > receipt.creationTimestamp
                    );
                    if (!subsequentReceipt) {
                        // Non c'è un RECEIPT valido successivo, bisogna riprovare a stampare
                        return true;
                    }
                }
            }

            // Se non ci sono ERROR o VOID che necessitano azione, non serve stampare
            return false;
        },

        async loadWebhookData () {
            try {
                const result = await server.getStripeWebhook(this.restaurantId);
                const parsed = JSON.parse(result);
                this.webhook = parsed.sort((r1, r2) => r2.timestamp - r1.timestamp);
            }
            catch (e) {
                console.log(e);
            }
        },

        autoCalculateRest (input) {
            try {
                const cashDiff = this.basketTotal - Number.parseFloat(this.cardAmountPaid);
                const cardDiff = this.basketTotal - Number.parseFloat(this.cashAmountPaid);
                
                if (input === "CARD" && Number.parseFloat(this.cardAmountPaid) > 0) {
                    this.cashAmountPaid = cashDiff >= 0 ? String(cashDiff.toFixed(2)) : "ERROR";
                    
                }
                if (input === "CASH" && Number.parseFloat(this.cashAmountPaid) > 0) {
                    this.cardAmountPaid = cardDiff >= 0 ? String(cardDiff.toFixed(2)) : "ERROR";
                }
            }
            catch (e) {
                console.log(e);
            }
        }
    },
    computed: {
        restaurantPresentation () {
            return preloadedData.restaurantPresentation;
        },
        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        restaurantId () {
            return this.restaurantProcessor.id;
        },

        configuration () {
            return this.restaurantProcessor.restaurantConfiguration;
        },

        displayLanguages () {
            return this.restaurantProcessor.getUserDisplayLocalizations();
        },

        restaurantHasTables () {
            return this.restaurantProcessor.tables.length > 0;
        },

        skipDeliveryState () {
            if (this.selected) {
                return this.selected.skipDeliveryState;
            }
            return this.restaurantProcessor.restaurantConfiguration.skipDeliveryState;
        },

        skipPreparationState () {
            if (this.selected) {
                return this.selected.skipPreparationState;
            }
            return this.restaurantProcessor.restaurantConfiguration.skipPreparationState;
        },

        scenes () {
            return this.restaurantProcessor.scenes ?? [];
        },

        coverPrice () {
            let coverPrice = 0;
            if (this.selectedOrderToPay && this.selectedOrderToPay.sceneId) {
                const orderScene = this.scenes.find((scene) => String(scene.id) === String(this.selectedOrderToPay.sceneId));
                if (orderScene) {
                    coverPrice = Number.parseFloat(orderScene.cover);
                }
            }
            else if (this.selected) {
                if (this.selected.cover) {
                    coverPrice = Number.parseFloat(this.selected.cover);
                }
            }
            else if (this.configuration && this.configuration.cover) {
                coverPrice = Number.parseFloat(this.configuration.cover);
            }
            return coverPrice;
        },

        // </restaurant>

        // <basket>
        basketProcessor () {
            return new BasketProcessor({
                basket: this.basket,
                restaurantProcessor: this.restaurantProcessor,
            });
        },

        basketSelectedProducts () {
            return this.basketProcessor.selectedProducts;
        },

        basketSelectedFixedMenus () {
            return this.basketProcessor.selectedFixedMenus;
        },

        basketSelectedCustomProducts () {
            return this.basketProcessor.selectedCustomProducts;
        },

        basketTotalSelectedVolume () {
            return this.basketProcessor.totalSelectedVolume;
        },

        basketSubtotal () {
            return this.basketProcessor.subtotal;
        },

        basketTotal () {
            return this.basketProcessor.total;
        },

        basketIsEmpty () {
            return this.basketProcessor.isEmpty;
        },
        // </basket>

        isDesktop () {
            return isDesktop;
        },

        customerHasRest () {
            return Number.isFinite(this.customerRest) && this.customerRest > 0;
        },

        clearButtonText () {
            let text = this.$t("generic.clear");

            if (this.selectedOrderToPay) {
                text += ` #${this.selectedOrderToPay.dailyId}`;
            }
            else if (this.selectedTableOrderToAddProduct) {
                text += ` #${this.selectedTableOrderToAddProduct.dailyId}`;
            }
            else if (this.selectedTableForCreateOrder) {
                text += ` / ${this.selectedTableForCreateOrder.name}`;
            }

            return text;
        },

        continueButtonText () {
            let text = this.$t("generic.continue");

            if (this.selectedOrderToPay) {
                text += ` #${this.selectedOrderToPay.dailyId}`;
            }
            else if (this.selectedTableForCreateOrder) {
                text += ` / ${this.selectedTableForCreateOrder.name}`;
            }

            return text;
        },

        confirmButtonText () {
            let text = this.$t("generic.confirm");

            if (this.selectedOrderToPay) {
                text += ` #${this.selectedOrderToPay.dailyId}`;
            }
            else if (this.selectedTableForCreateOrder) {
                text += ` / ${this.selectedTableForCreateOrder.name}`;
            }

            return text;
        },

        addProductButtonText () {
            let text = this.$t("cashierPos.addProduct");

            if (this.selectedTableOrderToAddProduct) {
                text += ` #${this.selectedTableOrderToAddProduct.dailyId}`;
            }

            return text;
        },

        paymentAfterService () {
            if (this.selected) {
                return this.selected.paymentAfterService;
            }
            return this.restaurantProcessor.restaurantConfiguration.paymentAfterService;
        },

        thirdPartyButtonIsDisabled () {
            return this.basketIsEmpty || !this.selectedThirdPartyOrigin || !this.thirdPartyOriginTotal;
        },

        sceneSelectboxOptions () {
            let options = [{
                text: this.$t("generic.allScene").toLocaleUpperCase(),
                value: "all",
            }];

            for (const scene of this.scenes) {
                options = [...options, {
                    text: scene.localizations?.find((localization) => localization.languageIso === i18n.global.locale).name.toLocaleUpperCase(),
                    value: scene.id,
                },];
            }
            return options;
        },

        selected () {
            return this.scenes.find((scene) => String(scene.id) === String(this.selectedScene));
        },

        // includeOnePlusOne () {
        //     for (const productId in this.basket.selectedProducts) {
        //         const product = this.restaurantProcessor.getProductById(Number.parseInt(productId));
        //         if (product && product.discountStrategies.includes(DiscountStrategies.onePlusOne.id)) {
        //             return true;
        //         }
        //     }

        //     return false;
        // }
    },
    watch: {
        basketSelectedProducts: {
            deep: true,
            handler (value) {
                if (value.length === 0) {
                    this.basket.discounts = [];
                    this.basket.additions = [];
                    this.basket.deductions = [];

                    if (!this.confirmedOrder && this.$refs.orderConfirmationModalBox.isActive) {
                        this.$refs.orderConfirmationModalBox.hide();
                    }
                }

                // <products-messages>
                // Remove a message if its product is no longer selected.
                for (const productId in this.basket.selectedProductsMessages) {
                    if (!value.some((product) => product.id === Number.parseInt(productId))) {
                        delete this.basket.selectedProductsMessages[productId];
                    }
                }
                // </products-messages>

                // <products-variations>
                // Remove a variation if its product is no longer selected.
                for (const productId in this.basket.selectedProductsVariations) {
                    if (!value.find((product) => Number.parseInt(product.id) === Number.parseInt(productId))) {
                        delete this.basket.selectedProductsVariations[productId];
                    }
                    else {
                        const variationsCopy = cloneDeep(this.basket.selectedProductsVariations);
                        const productVariations = [];

                        for (let i = 0; i < this.basketProcessor.getProductSelectedVolume(productId); ++i) {
                            productVariations[i] = variationsCopy[productId][i] ?? [];
                        }

                        variationsCopy[productId] = productVariations;
                        this.basket.selectedProductsVariations = variationsCopy;
                    }
                }
                // </products-variations>
            },
        },
        featureSelected (value) {
             if (value === 4) {
                this.loadWebhookData();
            }
        },
    },
    async created () {
        let token;
        try {
            if (isDesktop) {
                const { ipcRenderer, } = window.require("electron");
                const userData = JSON.parse(await ipcRenderer.invoke("host:getUserData"));
                if (userData) {
                    token = userData.token;
                }
                if (preloadedData.restaurantPresentation.restaurant.id === 15 || preloadedData.restaurantPresentation.restaurant.id === 9) {
                    const midnight = getMidnightDate();
                    const todayEnd = getTodayEndDate();
                    const orders = await server.getRestaurantOrdersByTimestamp({
                        id: preloadedData.restaurantPresentation.restaurant.id,
                        fromTimestamp: midnight.getTime(),
                        toTimestamp: todayEnd.getTime(),
                        sceneId: selectedScene,
                    });
                    const onlineOrders = orders.filter((order) => order.fiscalReceipts.length === 0 && (order.paymentMethod === OrderPaymentMethod.STRIPE_CARD || order.paymentMethod === OrderPaymentMethod.WECHAT_PAY));
                    this.printWithDelay(onlineOrders, 10000);
                }
            }
        } catch (e) {
            console.log(e);
        }

        try {
            createPrimaryWs();

            primaryWs.addEventListener("open", () => {
                primaryWs.send(JSON.stringify({
                    restaurantId: this.restaurantId,
                    sceneId: selectedScene,
                    token: token,
                    service: {
                        name: "POS",
                    }
                }));
            });

            primaryWs.addEventListener("message", (event) => {
                let message;

                try {
                    message = JSON.parse(event.data);
                }
                catch {
                    return;
                }

                if (message.type === "RestaurantAuthentication" && message.result === "OK") {
                    console.log("connected to server");
                }

                if (message.type === "OrderInPreparationNotification") {
                    const order = message.result.order;
                    const firstProduct = this.restaurantProcessor.getProductById(order.products[0].id);

                    if (!firstProduct || firstProduct.restaurantId !== this.restaurantId) {
                        return;
                    }

                    if (!this.ordersHandledByCashier[order.id]) {
                        this.playNewOrderNotificationSound();
                        if (this.restaurantId === 6) {
                            this.printReceipt(order, true);
                        }
                        else {
                            this.printReceipt(order, false); // TODO: Set to false to enable stripe online payment receipt
                        }
                    }
                }
                // TODO: For skip preparation order there should catch the signal to print order but I dont have scene here
                // if (message.type === "OrderClosedNotification") {
                //     const order = message.result.order;
                //     const firstProduct = this.restaurantProcessor.getProductById(order.products[0].id);

                //     if (!firstProduct || firstProduct.restaurantId !== this.restaurantId) {
                //         return;
                //     }

                //     if (!this.ordersHandledByCashier[order.id]) {
                //         this.playNewOrderNotificationSound();
                //         this.printReceipt(order, true); 
                //     }
                // }
            });
            primaryWs.keepAlive();
        }
        catch (error) {
            console.log(error);
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
@import "~@/css/flex.scss";

.cashier-sale-point-view {
    overflow: hidden;

    min-height: 100vh;
    height: 100vh;
}

.main {
    height: 100%;

    background-color: rgb(245, 245, 245);
    align-content: space-between;

    :deep(.restaurant-product-list) {
        @extend .flex--y-align-center;

        overflow: hidden;

        height: 66%;
    }

    :deep(.restaurant-product-list-vertical) {
        // @extend .flex--y-align-center;

        overflow: hidden;

        height: 66%;
    }

    :deep(.restaurant-product-list .slider) {
        height: 70%;
    }

    :deep(> .customer-basket) {
        overflow: hidden;

        width: 100%;
        height: 24%;
        margin: 0;
        padding: 25px 0 0 0;

        background-color: rgb(255, 255, 255);
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.03);

        z-index: 3;
    }

    :deep(> .customer-basket .empty-basket-text) {
        margin: 0;
        padding: 0;
    }

    :deep(> .customer-basket .basket) {
        height: 100%;
    }

    :deep(> .customer-basket .selected-product-list) {
        overflow: auto;

        height: 80%;
        padding: 0 25px 25px 25px;
    }

    :deep(> .customer-basket .summary) {
        @extend .flex--y-align-center;

        overflow: hidden;

        height: 20%;
        margin: 0;
        padding: 0;

        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    :deep(> .customer-basket .subtotal) {
        margin: 0;
        padding: 0 40px;
    }

    :deep(> .customer-basket .discount) {
        display: none;
    }

    :deep(> .customer-basket .total) {
        display: none;
    }
}

.action-box {
    position: relative;

    height: 10%;

    background-color: rgb(250, 250, 250);
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    z-index: 5;

    :deep(.simple-button + .simple-button) {
        margin-left: 16px;
    }
}

.left-action-box {
    :deep(.simple-button) {
        min-width: 120px;

        margin: 0;

        border-radius: 0;
        border-left: 1px solid rgb(54, 54, 54);
    }

    :deep(.simple-button + .simple-button) {
        margin: 0;
    }

    :deep(.cash-register-button .simple-button) {
        border: none;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }

    :deep(.simple-button:last-child) {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }
}

.payment-method-selection-box {
    :deep(.simple-button) {
        min-width: 120px;

        margin: 0;

        border-radius: 0;
        border-left: 1px solid rgb(54, 54, 54);
    }

    :deep(.simple-button + .simple-button) {
        margin: 0;
    }

    :deep(.simple-button:first-child) {
        border: none;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }

    :deep(.simple-button:last-child) {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }
}

.action-button {
    position: relative;
}

.remote-notifications-length-text {
    @extend .--unselectable;

    position: absolute;
    right: -10px;
    top: -10px;

    width: 24px;
    height: 24px;

    background-color: rgb(227, 65, 43);
    border-radius: 50%;

    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: rgb(255, 255, 255);
}

.cashier-modal-box {
    :deep(.close-button) {
        right: 22px;
        top: calc(15vh - 22px);

        transform: translateY(-100%);

        z-index: 999;
    }

    :deep(.modal-box__slot) {
        overflow: hidden;

        position: relative;

        width: 100%;
        height: 90vh;
        margin: 10vh 0 0 0;
        padding: 0;

        border-radius: 0;
    }
}

.order-list-modal-box {
    :deep(.restaurant-order) {
        width: 40%;
        margin: 25px;
    }
}

.order-list-title {
    overflow: hidden;

    height: 15%;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &__text {
        margin: 50px 0;

        font-size: 32px;
        font-weight: 600;
    }
}

.order-list {
    overflow: auto;

    height: 85%;

    background-color: rgb(240, 240, 240);

    &--to-pay {
        :deep(.restaurant-order .name) {
            display: none;
        }
    }
}

.order-confirmation-box {
    width: 65%;
    height: 100%;

    border-right: 1px solid rgba(0, 0, 0, 0.1);

    .basket-box {
        height: 65%;
    }

    .basket-box:deep(> .customer-basket) {
        overflow: hidden;

        width: 100%;
        height: 100%;
        margin: 0;
        padding: 25px 0 0 0;

        background-color: transparent;
    }

    .basket-box:deep(> .customer-basket .empty-basket-text) {
        margin: 0;
        padding: 0;
    }

    .basket-box:deep(> .customer-basket .basket) {
        height: 100%;
    }

    .basket-box:deep(> .customer-basket .selected-product-list) {
        overflow: auto;

        height: 70%;
        padding: 0 25px 25px 25px;
    }

    .basket-box:deep(> .customer-basket .summary) {
        @extend .flex--y-align-center;

        overflow: hidden;

        height: 30%;
        margin: 0;
        padding: 0;

        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .basket-box:deep(> .customer-basket .subtotal) {
        margin: 0;
        padding: 0 40px;
    }

    .basket-box:deep(> .customer-basket .discount) {
        margin: 15px 0 0 0;
        padding: 0 40px;
    }

    .basket-box:deep(> .customer-basket .total) {
        margin: 15px 0 0 0;
        padding: 0 40px;
    }

    .take-away-box {
        height: 10%;

        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .action-box {
        height: 25%;

        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.take-away {
    margin: 0;
    padding: 0;

    &__text {
        margin: 0 12px 0 0;
        padding: 0;

        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.06rem;
        color: rgb(66, 66, 66);
    }
}

.order-payment-box {
    overflow: auto;

    width: 35%;
    height: 100%;

    background-color: rgb(250, 250, 250);

    .title {
        height: inherit;

        &__text {
            @extend .--unselectable;

            font-weight: 600;
            font-size: 20px;
            color: rgb(160, 160, 160);
        }
    }
}

.cash-payment-box {
    height: inherit;

    :deep(.cashier-payment-calculator) {
        margin: 50px 0;
    }
}

.card-payment-box {
    height: inherit;
}

.third-party-payment-textbox {

    :deep(.simple-textbox) {
        width: 80%;
    }

    margin-top: 25px;
}

.full-height {
    height: 100%;
}

.third-party-select-box {
    margin-top: 50px;
    width: 80%;
}

.order-confirm-notice-text {
    width: 100%;
    margin: 20px;

    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: rgb(232, 142, 27);
}

.payment-method-button {
    &--selected:deep(.simple-button__text) {
        color: $primary-brand-color;
    }
}

.order-completed-box {
    position: absolute;
    left: 0;
    top: 0;

    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);

    z-index: 50;

    &__daily-id-text {
        position: relative;

        margin-top: 20px;

        font-size: 48px;
        font-weight: 600;
        letter-spacing: 0.06rem;
        color: rgb(33, 33, 33);

        &::after {
            content: "";

            position: absolute;
            left: 0;
            bottom: -5px;
            width: 100%;
            height: 5px;

            background-color: $primary-brand-color;
        }
    }

    &__customer-rest-text {
        margin-top: 20px;
        padding: 6px 25px;

        background-color: $primary-brand-color;
        border-radius: 1000px;

        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.06rem;
        color: rgb(33, 33, 33);
    }

    .order-completed-icon-box {
        margin: 0;
        padding: 20px;

        border: 4px solid rgb(20, 153, 98);
        border-radius: 50%;

        &__icon {
            width: 48px;
            height: 48px;

            fill: rgb(20, 153, 98);
        }
    }
}

.restaurant-product-list {
    &::before {
        min-height: 180px;
        height: 30%;
    }

    :deep(.restaurant-product) {
        max-height: 208px;
    }

    :deep(.restaurant-product .preview-horizontal) {
        &__image {
            width: 72px;
            height: 72px;
        }
    }

    :deep(.restaurant-fixed-menu .preview) {
        &__image {
            width: 100%;
            max-width: 88px;
        }

        &__image:nth-child(2) {
            width: 100%;
            max-width: 142px;
        }
    }
}

.cash-register-button {
    position: relative;

    :deep(.simple-button) {
        min-width: 78px;

        &__text {
            opacity: 0;
        }
    }

    &__icon {
        pointer-events: none;

        position: absolute;

        width: 23px;

        fill: rgb(255, 255, 255);
    }
}

.cash-register-modal-box {
    .simple-button {
        margin: 40px;
    }
}

.top-right-div {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;

    .language-selector {
        :deep(.language) {
            background-color: transparent;
            border-radius: 0;
        }

        :deep(.language.language--selected) {
            background-color: transparent;
        }

        :deep(.language .language__name) {
            display: none;
        }

        :deep(.language .language__icon) {
            margin: 0;

            opacity: 0.5;
        }

        :deep(.language.language--selected .language__icon) {
            opacity: 1;
        }

        :deep(.language + .language) {
            margin: 0;
        }
    }
}

.top-div {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 10px 20px;

    span {
        font-size: 25px;
        font-weight: 500;
        color: red;
    }
}


.scene-select-box {
    min-width: 174px;

    :deep(.choices__item) {
        color: rgb(254, 254, 254);
        font-weight: 600;
        font-size: 15px;
    }

    :deep(.choices__inner) {
        border: none;
        background-color: transparent;
    }

    :deep(.choices__list) {
        border: none;
        border-radius: 0;
    }

    :deep(.choices__item--choice) {
        border: none;
        background-color: rgb(77, 77, 77);
    }

    :deep(.choices__item--choice) {
        font-size: 1rem;
        padding-right: 0;
    }
}

.scene-box {
    margin: 0;
    padding: 0;
}

.cover-number {
    margin-left: 20px;
    width: 130px;
}

.one-plus-one-button {
    :deep(.simple-button) {
        min-width: 78px;
        margin-left: 20px;
    }
}

.third-party-payment-box {
    height: auto;

    :deep(.third-party-payment-button) {
        min-width: 120px;

        margin: 0;

        border-radius: 0;
        border: none;
        border-left: 1px solid rgb(54, 54, 54);
    }

    :deep(.third-party-payment-button + .third-party-payment-button) {
        margin: 0;
    }

    :deep(.third-party-payment-button:first-child) {
        border-top-left-radius: 12px !important;
        border-bottom-left-radius: 12px !important;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    :deep(.third-party-payment-button:last-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }
}

.member-only {
    position: absolute;
    left: 10px;
    bottom: 10px;
    width: 60px;
    height: 60px;
    background-color: $primary-brand-color;
    border-radius: 50%;
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    }

    &__logo {
        width: 42px;
        height: 42px;
        filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
    }
}

.member-features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    overflow: auto;

    height: 85%;

    background-color: rgb(240, 240, 240);
}

.feature-box {
    background-color: $primary-brand-color;
    border-radius: 12px;
    width: 30%;
    /* Adjust as needed */
    margin: 10px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.feature-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.feature-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
}

.feature-label {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .feature-box {
        width: 45%;
    }
}

@media (max-width: 480px) {
    .feature-box {
        width: 100%;
    }
}

.promo-code-feature {
    padding: 20px;
    box-sizing: border-box;
}

.promo-code-input-container {
    margin: 20px 0;
}

/* Style for the textbox */
.promo-code-input-container .simple-textbox {
    margin-right: 10px;
    font-size: 18px;
}

/* Style for the buttons */
.promo-code-button {
    padding: 15px 20px;
    font-size: 16px;
    margin: 0px 10px;
}

/* Remove margin from the last button */
.promo-code-button:last-child {
    margin-right: 0;
}

/* Style for the response message */
.promo-code-response {
    white-space: pre-wrap;
    /* Preserva gli a capo e gli spazi */
    font-size: 26px;
    color: #333;
    text-align: left;
    width: 80%;
    max-width: 800px;
}

.order-select-box {
    gap: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .promo-code-input-container {
        flex-direction: column;
        align-items: stretch;
    }

    .promo-code-input-container simple-textbox,
    .promo-code-button {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .promo-code-button:last-child {
        margin-bottom: 0;
    }
}

.webhook-box {
    display: flex;
    justify-content: center;
    justify-items: center;  
    height: 70vh;
    width: 100%;
    overflow: auto;
}

.table-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.table-header {
  background-color: #f9f9f9;
  font-weight: bold;
  display: flex;
  border-bottom: 1px solid #ddd;
  text-transform: uppercase;
  font-size: 1.25rem;
}

.table-header-single {
  background-color: #f3f4f6;
  font-weight: bold;
  text-align: center;
  font-size: 1.25rem;
  padding: 16px;
  border-bottom: 2px solid #ddd;
}

.table-title {
  font-weight: bold;
  text-align: center;
  font-size: 1.25rem;
}

.table-row {
  display: flex;
  border-bottom: 1px solid #f4f4f4;
  transition: background-color 0.3s;
}

.table-row:last-child {
  border-bottom: none;
}

.table-row:hover {
  background-color: #f4f8fb;
}

.table-row-alt {
  background-color: #fafafa;
}

.table-cell {
  flex: 1;
  padding: 12px 16px;
  text-align: left;
  font-size: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-cell:nth-child(2) {
  font-weight: bold;
}

.table-cell:last-child {
  text-align: right;
}

.get-modifiers {
  font-weight: bold;
}

.text-green {
  color: #10b981; /* Green */
}

.text-yellow {
  color: #f59e0b; /* Yellow */
}

.text-red {
  color: #ef4444; /* Red */
}

.text-gray {
  color: #6b7280; /* Gray */
}

.amount-input {
    margin-bottom: 20px;
}
</style>
