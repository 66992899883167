<template>
    <div class="flex flex--100 update-category-modal-box">
        <modal-box ref="window">
            <form class="flex flex--100 update-category-form">
                <div class="flex flex--100" v-for="requiredLocalization in requiredLocalizations" :key="requiredLocalization.iso">
                    <h2>{{ $t(requiredLocalization.name) }}</h2>
                    <div class="flex flex--100">
                        <simple-textbox :placeholder="$t('management.generic.name')"  v-model:model-value="localizations[requiredLocalization.iso].name" ref="enNameTextbox"/>
                    </div>
                    <hr class="separator"/>
                </div>
                <div class="flex flex--100 update-category">
                    <simple-button :text="$t('generic.update')" @click="updateCategory" :is-disabled="!dataChanged" :is-loading="isWaitingServerResponse"/>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import { RestaurantProcessor } from '@/utilities';
import {user} from "@/user";
import {notificationCenter} from "@/components/utilities/NotificationCenter";

/*
const ProductAddErrorType = {
    MISSING_INDEX: {
        id: 1,
        text: "Index is required.",
    },
    NEGATIVE_INDEX: {
        id: 2,
        text: "Index cannot be negative.",
    },
};
*/

export default {
    name: "UpdateCategoryModalBox",
    props: {
        category: {
            type: Object,
        },
        restaurantPresentation: {
            type: Object,
        },
        closeOnCategoryUpdate: {
            type: Boolean,
            default: true,
        },
    },
    emits: [ "category-update" ],
    components: {
        SimpleButton,
        SimpleTextbox,
        ModalBox,
    },
    data () {
        return {
            localizations: {},
            price: "",
            saved: {},
            isWaitingServerResponse: false,
        };
    },
    methods: {
        async load () {
            this.localizations = this.generateLocalizations();
            this.saved.localizations = JSON.parse(JSON.stringify(this.localizations));
        },

        validateUpdateCategoryForm () {/*
            const nameTextbox = this.$refs.nameTextbox;

            nameTextbox.validate(ProductAddErrorType.MISSING_NAME, this.name.length > 0);

            return nameTextbox.errors.length === 0;*/return true;
        },

        clearCategoryForm () {
            this.localizations = this.generateLocalizations();
        },

        async updateCategory () {
            if (!this.validateUpdateCategoryForm() || this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const category = await user.updateCategory({
                    id: this.category.id,
                    localizations: Object.keys(this.localizations).map((languageIso) => {
                        return {
                            languageIso,
                            ...this.localizations[languageIso],
                        };
                    }),
                });

                if (category) {
                    this.$emit("category-update", category);

                    if (this.closeOnCategoryUpdate) {
                        this.window.hide();
                    }

                    this.clearCategoryForm();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.categoryUpdatedSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },
        generateLocalizations () {
            const localizations = {};
            if (this.restaurantLocalizations) {
                this.restaurantLocalizations.forEach(iso => {
                    if (this.category && this.category.localizations) {
                        const existingLocalization = this.category.localizations.find(l => l.languageIso === iso);
                        localizations[iso] = {
                            name: existingLocalization ? existingLocalization.name : '',
                        };
                    } else {
                        localizations[iso] = {
                            name: '',
                        };
                    }
                });
            }
            return localizations;
        },
    },
    computed: {
        window () {
            return this.$refs.window;
        },
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, })
        },
        restaurantLocalizations () {
            return this.restaurantProcessor.getUserDisplayLocalizations();
        },
        requiredLocalizations() {
            if (!this.restaurantLocalizations) {
                return [];
            }

            return this.restaurantLocalizations.map(iso => ({
                name: `languages.${iso}`,
                iso: iso
            }));
        },
        dataChanged () {
            if (this.localizations.en.name && this.localizations.it.name && this.localizations.zh.name) {
                for (const languageIso of Object.keys(this.localizations)) {
                    if (this.localizations[languageIso].name !== this.saved.localizations[languageIso].name) {
                        return true;
                    }
                }
            }
            return false;
        }
    },

    async mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>
<style lang="scss" scoped>
.update-category-modal-box {}

.update-category-form {
    padding: 50px;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}

.update-category-modal-box {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }

    .simple-button {
        margin: 0 50px 0 0;
    }
}
</style>
