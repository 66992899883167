<template>
    <div class="flex flex--100 add-category-modal-box">
        <modal-box ref="window">
            <form class="flex flex--100 add-category-form">
                <div class="flex flex--100" v-for="requiredLocalization in requiredLocalizations" :key="requiredLocalization.iso">
                    <div class="flex flex--100">
                        <h2 class="lang-title">{{ $t(requiredLocalization.name) }}</h2>
                    </div>
                    <div class="flex flex--100">
                        <simple-textbox :placeholder="$t('management.generic.name')" v-model:model-value="localizations[requiredLocalization.iso].name" ref="enNameTextbox"/>
                    </div>
                    <hr class="separator"/>
                </div>
                <div class="flex flex--100">
                    <simple-button :text="$t('generic.add')" @click="addCategory" :is-loading="isWaitingServerResponse"/>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import { RestaurantProcessor } from "@/utilities";
import { notificationCenter } from "@/components/utilities/NotificationCenter";
import { user } from "@/user";

/*
const ProductAddErrorType = {
    MISSING_NAME: {
        id: 1,
        text: "Name is required.",
    },
};
*/

export default {
    name: "AddCategoryModalBox",
    props: {
        restaurantId: {
            type: String,
        },
        restaurantPresentation: {
            type: Object,
        },
        closeOnCategoryAdd: {
            type: Boolean,
            default: true,
        },
    },
    emits: [ "category-add" ],
    components: {
        SimpleButton,
        SimpleTextbox,
        ModalBox,
    },
    data () {
        return {
            localizations: {},
            isWaitingServerResponse: false,
        };
    },
    methods: {
        validateAddCategoryForm () {/*
            const nameTextbox = this.$refs.nameTextbox;

            nameTextbox.validate(ProductAddErrorType.MISSING_NAME, this.name.length > 0);

            return nameTextbox.errors.length === 0;*/return true;
        },

        clearCategoryForm () {
            this.name = "";
            this.localizations = this.generateLocalizations();
        },

        async addCategory () {
            if (!this.validateAddCategoryForm() || this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const category = await user.addCategory({
                    restaurantId: this.restaurantId,
                    localizations: Object.keys(this.localizations).map((languageIso) => {
                        return {
                            languageIso,
                            ...this.localizations[languageIso],
                        };
                    }),
                });

                if (category) {
                    this.$emit("category-add", category);

                    if (this.closeOnCategoryAdd) {
                        this.$refs.window.hide();
                    }

                    this.clearCategoryForm();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.categoryAddedSuccess`), });
                }
                else{
                  notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },
        generateLocalizations() {
            const localizations = {};
            if (this.restaurantLocalizations) {
                this.restaurantLocalizations.forEach(iso => {
                    localizations[iso] = {
                        name: '',
                    };
                });
            }
            return localizations;
        },
    },
    computed: {
        window () {
            return this.$refs.window;
        },
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },
        restaurantLocalizations () {
            return this.restaurantProcessor.getUserDisplayLocalizations();
        },
        requiredLocalizations() {
            if (!this.restaurantLocalizations) {
                return [];
            }

            return this.restaurantLocalizations.map(iso => ({
                name: `languages.${iso}`,
                iso: iso
            }));
        },
    },
     watch: {
        restaurantLocalizations: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.localizations = this.generateLocalizations();
                }
            }
        }
    },
    async mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>
.add-category-window {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }
}

.add-category-form {
    padding: 50px;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}
</style>

<style lang="scss">
.add-category-window {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }

    .add-category-form .simple-textbox {
        margin: 17px 0;
        width: 40%;
    }
}
.lang-title {
    font-size: 18px;
    font-weight: 500;
    margin: 0px 10px 15px 0px;
}
</style>
