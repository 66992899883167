export const es = {
  brandName: "giaogiao",
  generic: {
    totalItem: "Resultados",
    totalPage: "Páginas",
    add: "Añadir",
    cash: "Efectivo",
    cashier: "Caja",
    order: "Pedido",
    card: "Tarjeta",
    thirdParty: "Terceros",
    cancel: "Anular",
    clear: "Cancelar",
    continue: "Continuar",
    confirm: "Confirmar",
    selectPaymentMethod: "Seleccionar método de pago",
    products: "Productos",
    rest: "Cambio",
    date: "Fecha",
    notApplicable: "N/D",
    takeAway: "Para llevar",
    exit: "Salir",
    back: "Volver",
    close: "Cerrar",
    table: "Mesa",
    times: "Horario",
    today: "Hoy",
    print: "Imprimir",
    paymentThroughCashier: "PRESIONA AQUÍ PARA PAGAR EN LA CAJA",
    paymentThroughWeChatPay: "PRESIONA AQUÍ PARA PAGAR CON WECHAT PAY",
    invalidDate: "Fecha inválida",
    hasMissingChoices: "Elige un plato más | Elige {choices} platos más",
    press: "Presiona",
    toShowAllergenTab: "para ver los alérgenos",
    popular: "Popular",
    update: "Modificar",
    unavailable: "No disponible",
    chooseScene: "Seleccionar zona",
    allScene: "Todas las zonas",
    save: "Guardar",
    cover: "N. Cubierto",
    paid: "Pagado",
    openFileBrowser: "Abrir explorador de archivos",
    showCategories: "Mostrar por categorías",
    showThirdParty: "Mostrar terceros",
    backToTop: "Volver arriba",
    all: "Todo",
    free: "¡Toca para recibir 1 gratis!",
    thirdPartyTotal: "Total de terceros",
    username: "Usuario",
    password: "Contraseña",
    access: "Entrar",
    gotIt: "Entendido",
    default:
      "Predeterminado si no hay versión en otros idiomas, solo incluir esta",
    waitOther:
      "Por favor, espera a que los otros platos estén listos en otro lugar.",
    showHideOrders: "Mostrar pedidos ocultos",
    prebilling: "Precuenta",
    delete: "Eliminar",
    modifyOrder: "Modificar [trabajo en curso]",
    reprintFiscalReceipt: "Reimprimir recibo fiscal",
    printIntern: "Imprimir recibo no fiscal",
    expirationTime: "Fecha de vencimiento",
    timeRemaining: "Tiempo restante: ",
    days: "días",
    hours: "horas",
    minutes: "minutos",
    seconds: "segundos",
    newArrival: "Novedad",
    selectTag: "Seleccionar etiqueta",
    platform: "Pagado en la plataforma",
    pickupCash: "Efectivo",
    pickupCard: "Tarjeta",
    void: "Anular documento",
    closed: "Cerrado",
    reservation: "Reserva: ",
    status: "Estado",
    fixStuck: "Resolver pedidos bloqueados",
    clickToContinue: "Toca en cualquier parte para continuar",
    cny: "Oferta del mes",
    languages: "Idiomas",
    mixed: "Pago Mixto",
    whereToEat: "¿Para aquí o para llevar?",
  },
  notification: {
    networkError: "Error de red, verifica tu conexión a Internet.",
    serverError: "Error interno del servidor.",
    productAddedSuccess: "Producto añadido con éxito.",
    productRemoveSuccess: "Producto eliminado con éxito.",
    productRemoveFail:
      "La eliminación del producto ha fallado porque ya ha sido pedido una vez.",
    productArchivingSuccess: "Producto archivado con éxito.",
    productUnarchivingSuccess: "Producto desarchivado con éxito.",
    productUpdatedSuccess: "Producto actualizado con éxito.",
    productStockChangedSuccess: "Cantidad de producto actualizada con éxito.",
    restaurantOpenSuccess: "Restaurante abierto con éxito.",
    restaurantCloseSuccess: "Restaurante cerrado con éxito.",
    websiteOrderEnabledSuccess: "Pedido desde la web habilitado.",
    websiteOrderDisabledSuccess: "Pedido desde la web deshabilitado.",
    paymentAfterServiceEnabledSuccess: "Pago después del servicio habilitado.",
    paymentAfterServiceDisabledSuccess:
      "Pago después del servicio deshabilitado.",
    kioskEnabledSuccess: "Kiosco habilitado.",
    kioskDisabledSuccess: "Kiosco deshabilitado.",
    maxDeliveryDistanceChangeSuccess:
      "Distancia máxima de entrega actualizada con éxito.",
    stripeConfigurationChangeSuccess:
      "Configuración de Stripe actualizada con éxito.",
    coverChangeSuccess: "Cubierto actualizado con éxito.",
    skipDeliveryStateEnabledSuccess: "Omitir fase de entrega habilitado.",
    skipDeliveryStateDisabledSuccess: "Omitir fase de entrega deshabilitado.",
    skipPreparationStateEnabledSuccess:
      "Omitir fase de preparación habilitado.",
    skipPreparationStateDisabledSuccess:
      "Omitir fase de preparación deshabilitado.",
    customStyleChangeSuccess:
      "Estilo personalizado cambiado con éxito, idiota.", // (manteniendo el tono original)
    passwordChangeSuccess: "Contraseña cambiada con éxito.",
    passwordChangeFail: "Error al cambiar la contraseña.",
    allergenAddedSuccess: "Alérgeno añadido con éxito.",
    allergenUpdatedSuccess: "Alérgeno actualizado con éxito.",
    allergenDeletedSuccess: "Alérgeno eliminado con éxito.",
    categoryAddedSuccess: "Categoría añadida con éxito.",
    categoryUpdatedSuccess: "Categoría actualizada con éxito.",
    tableAddedSuccess: "Mesa añadida con éxito.",
    tableUpdatedSuccess: "Mesa modificada con éxito.",
    tableRemovedSuccess: "Mesa eliminada con éxito.",
    variationAddedSuccess: "Variación añadida con éxito.",
    variationUpdatedSuccess: "Variación actualizada con éxito.",
    variationArchivedSuccess: "Variación archivada con éxito.",
    variationUnarchivedSuccess: "Variación desarchivada con éxito.",
    skipErrorOrders:
      "Los pedidos de terceros no se han cargado correctamente. Para más información sobre los pedidos, consulta la consola.",
    serviceTimeUpdatedSuccess: "Horarios de apertura actualizados con éxito.",
    languagesUpdateSuccess: "Idiomas actualizados con éxito.",
  },
  error: {
    wrongPassword: "La contraseña es incorrecta.",
    fileSizeLimitExceeded:
      "Límite de tamaño de archivo excedido, máximo { size } kb.",
  },
  checkout: {
    orderReceiveNowWait:
      "Recibirás tu pedido pronto, contáctanos si tienes alguna duda.",
    weAreWaitingForYou: "¡Te esperamos!",
    whereToPay: "¿Dónde quieres pagar?",
    takeAway: "Para llevar",
    preOrder: "Reservar",
    completePaymentToCashier: "Dirígete a la caja para completar el pago.",
    message: "Notas para el pedido",
    checkoutDeliveryErrorType: {
      MISSING_STREET: "Se requiere la dirección.",
      MISSING_BUILDING_CODE: "Se requiere el número del edificio.",
      MISSING_POSTAL_CODE: "Se requiere el código postal.",
      INCORRECT_POSTAL_CODE: "El código postal debe ser un número.",
      MISSING_FULL_NAME: "Se requiere nombre y apellido.",
      MISSING_MOBILE_PHONE: "Se requiere un número de teléfono.",
      MISSING_PERSONS: "Se requiere el número de personas.",
      UNSUPPORTED_ZONE:
        "La zona ingresada no está disponible para la entrega en este momento.",
      MESSAGE_LENGTH_OVERFLOW: "Máximo 512 caracteres.",
    },
  },
  customerBasket: {
    subtotal: "Subtotal",
    discount: "Descuento",
    total: "Total",
    emptyBasket: "Tu carrito está vacío.",
  },
  productList: {
    searchBarPlaceholder: "Buscar",
    productHasNoAllergens: "Este producto no tiene alérgenos.",
  },
  cashierPos: {
    activeOrders: "Órdenes activas",
    tables: "Mesas",
    orderId: "Número de orden",
    bindOrder: "Asociar",
    freeTable: "Liberar",
    addProduct: "Añadir",
    select: "Elegir",
    replace: "Reemplazar",
    toPay: "Pagar",
    rider: "Pagar Rider",
    ordersToPay: "Por pagar",
    ordersInPreparation: "En preparación",
    ordersInDelivery: "En entrega",
    completedOrders: "Completadas",
    confirmAfterPayment:
      "¡Atención! Al presionar este botón, se emitirá el recibo fiscal. Presiona solo después de la confirmación del pago.",
    maps: "Ver mapas",
    confirmWithoutPayment:
      "¡Atención! Al presionar este botón NO se emitirá el recibo fiscal. Presiona solo si el cliente ha solicitado una factura.",
    memberOnly: "Giaogiao Club",
    removeGlobalDiscount: "Cancelar descuento",
  },
  kitchen: {
    noOrdersToPrepare: "No hay pedidos para preparar.",
    completedOrders: "Órdenes completadas",
    stock: "Inventario",
  },
  kiosk: {
    touchToOrder: "Toca para ordenar",
  },
  order: {
    verifyDeliveryAddress: "Verifica la entrega en tu dirección",
    suggestedSpecialRequests: [
      "Menos picante",
      "Más picante",
      "Sin cilantro",
      "Sin carne",
      "Sin verduras",
      "Menos sal",
      "Más sal",
      "Menos azúcar",
      "Más azúcar",
      "Frío",
      "Templado",
      "Caliente",
      "Con hielo",
      "Sin hielo",
      "Con tapioca",
      "Sin tapioca",
      "Menos vinagre",
      "Más vinagre",
    ],
    paymentDate: "Fecha de pago",
    deliveryDate: "Fecha de entrega",
    origin: "Punto de pedido",
    originMap: {
      0: "Web",
      1: "Kiosco",
      2: "Cajero",
      3: "Código QR en mesa",
      4: "Terceros",
    },
    statusMap: {
      0: "Por pagar",
      1: "En preparación",
      2: "Entregado",
      3: "En entrega",
      4: "Cerrado",
      5: "Cancelado",
    },
    paymentMethod: "Método de pago",
    paymentMethodMap: {
      0: "Efectivo",
      1: "Tarjeta",
      2: "Terceros",
      3: "Stripe pagado con WeChat Pay",
      4: "Plataforma Deliveroo",
      5: "Plataforma Gua Gua",
      6: "Plataforma Glovo",
      7: "Plataforma Uber Eats",
      8: "Llamada",
      9: "Plataforma Just Eat",
      10: "Otras plataformas",
      11: "Desconocido",
      12: "WeChat Personal",
      13: "Deliveroo pagado en efectivo",
      14: "Deliveroo pagado con tarjeta",
      15: "Gua Gua pagado en efectivo",
      16: "Gua Gua pagado con tarjeta",
      17: "Glovo pagado en efectivo",
      18: "Glovo pagado con tarjeta",
      19: "Uber Eats pagado en efectivo",
      20: "Uber Eats pagado con tarjeta",
      21: "Llamada pagado en efectivo",
      22: "Llamada pagado con tarjeta",
      23: "Just Eat pagado en efectivo",
      24: "Just Eat pagado con tarjeta",
      25: "Otras plataformas pagado en efectivo",
      26: "Otras plataformas pagado con tarjeta",
      27: "Stripe pagado con tarjeta",
      28: "Pago Mixto",
    },
    deliveryLocation: "Punto de entrega",
    deliveryLocationMap: {
      0: "Restaurante",
      1: "Para llevar",
      2: "Entrega a domicilio",
    },
    order: "Pedido",
    discounts: "Descuentos",
    products: "Productos",
    restaurant: "Restaurante",
    total: "Precio total",
    uniqueCode: "Código único",
    noOrdersToDeliver: "No hay pedidos para entregar",
    lastOrder: "Tu último número de pedido es",
    stateAlert: "Recibirás tu pedido en breve.",
    orderAgain: "Pedir de nuevo",
    promoCode: "Código promocional",
    receiver: {
      address: "Dirección",
      name: "Nombre",
      mobilePhone: "Teléfono",
      requestedTime: "Hora de la reserva",
      requestedPerson: "Núm. de personas",
    },
    table: "Mesa",
    tableAbb: "Mes.",
    additions: "Adiciones",
    deductions: "Deducciones",
  },
  delivery: {
    checkAddress: "Verifica si entregamos en tu dirección",
    checkLater: "Verificar más tarde",
    check: "Verificar",
    addressTooFar:
      "Lo sentimos, pero la entrega no está disponible en la dirección indicada.",
    addressReachable: "¡Hacemos entregas en tu zona! Puedes ordenar ahora.",
    checkDifferentAddress: "Verificar otra dirección",
    deliveryToAddress: "Entrega a domicilio",
    pickupInRestaurant: "Recoger en restaurante",
    address: "Dirección",
    streetNumber: "Número",
    postalCode: "Código postal",
    fullName: "Nombre y Apellido",
    phoneNumber: "Número de teléfono",
    notes: "Notas",
    timePlaceholder: "¿A qué hora llegarás?",
    numberOfPeople: "Número de personas",
    endOfDay: "Lo sentimos, pero hoy estamos cerrados. Elige otra fecha.",
    pickDate: "Elige una fecha",
  },
  pickUp: {
    title: "Ordena, Recoge o Quédate con Nosotros",
    openMap: ">> Cómo Llegar <<",
    address: "Dirección",
    serviceTime: "Horario de apertura",
    contact: "Contacto",
    services: "Nuestros servicios",
    pickUp: "Recoger",
    dineIn: "Comer en el local",
    restaurantInfo: "Información del restaurante",
    delivery:
      "Para la entrega a domicilio, contáctanos o utiliza una de las siguientes plataformas.",
    weAreHere: "Estamos aquí",
  },
  payment: {
    payNow: "Pagar ahora",
    paidConfirmation: "He pagado",
    notPaid: "Aún no has pagado",
    failed: "Pago fallido, por favor intenta de nuevo.",
  },
  management: {
    generic: {
      daily: "Diario",
      weekly: "Semanal",
      monthly: "Mensual",
      restaurant: "Restaurante",
      name: "Nombre",
      address: "Dirección",
      country: "País",
      vat: "IVA",
      actions: "Acciones",
      open: "Abrir",
      close: "Cerrar",
      configuration: "Configuración",
      update: "Actualizar",
      missingValue: "El valor es obligatorio.",
      invalidValue: "El valor no es válido.",
      origin: "Origen",
      amount: "Cantidad",
      total: "Total",
      unitPrice: "Precio unitario",
      dailyStatistics: "Estadísticas diarias",
      revenue: "Ingresos",
      orderOrigin: ["Sitio Web", "Kiosco", "Caja", "Código QR", "Terceros"],
      export: "Exportar",
      cancel: "Cancelar",
      import: "Importar",
      selectAll: "Seleccionar todo",
      eventManagement: "Gestión de eventos",
      date: "Fecha",
      save: "Guardar",
      description: "Descripción",
      categories: "Categorías",
      price: "Precio",
      previewImages: "Imágenes",
      allergens: "Alérgenos",
      variations: "Variaciones",
      variationSets: "Variaciones Set",
      selectCategory: "Seleccionar categoría | Seleccionar categorías",
      selectAllergen: "Seleccionar alérgeno | Seleccionar alérgenos",
      selectVariation: "Seleccionar variación | Seleccionar variaciones",
      unit: "Unidad",
      index: "Índice",
      active: "Activo",
      deactivated: "Desactivado",
      preview: "Vista previa",
      deleteAds: "Eliminar anuncios",
      mode: "Modo",
      productTag: "Etiquetas de producto",
      discount: "Descuentos",
      serviceTime: "Horarios de apertura",
      serviceTimeNote:
        "Para añadir franjas horarias separadas, presiona 'Añadir'. Para cerrar el día seleccionado, elimina todas las franjas horarias.",
      userDisplayLocalizations: "Idiomas de los servicios del sistema",
    },
    primaryUserNavigation: {
      user: "Usuario",
      management: "Gestión",
      analytics: "Análisis",
      productsAnalytics: "Análisis de productos",
      orders: "Pedidos",
      stock: "Inventario",
      content: "Contenido",
      products: "Productos",
      media: "Medios",
      poster: "Anuncio de apertura",
      categories: "Categorías",
      allergens: "Alérgenos",
      variations: "Variaciones",
      tables: "Mesas",
      events: "Eventos",
      scenes: "Zonas",
      fixedMenus: "Menús fijos",
      customerRelationship: "Relación con clientes",
      customers: "Clientes",
      promoCodes: "Códigos promocionales",
    },
    userTab: {
      username: "Usuario",
      password: "Contraseña",
      fullName: "Nombre completo",
      role: "Rol",
      changePassword: "Cambiar contraseña",
      oldPassword: "Contraseña antigua",
      newPassword: "Nueva contraseña",
      logout: "Cerrar sesión",
      secure: "Seguridad",
    },
    analyticsTab: {
      closedOrders: "Órdenes cerradas",
      unclosedOrders: "Órdenes en curso",
      revenue: "Ingresos",
      totRevenue: "Ingresos totales",
      revenueByDay: "Ingresos por día",
      averageOrders: "Órdenes promedio",
      averageDailyOrders: "Órdenes promedio diarias",
      averageOrderTotal: "Total promedio por orden",
      averageDailyProfit: "Ganancia diaria promedio",
      averageDailyRevenue: "Ingresos diarios promedio",
      ordersByDay: "Órdenes por día",
      ordersByOrigin: "Órdenes por origen",
      ordersByPaymentMethod: "Órdenes por método de pago",
      profitByPaymentMethod: "Ganancia por método de pago (Obsoleto)",
      profitByPaymentMethodStripe: "Ganancia Stripe",
      stripeRevenue: "Ingresos Stripe",
      inRestaurant: "En restaurante",
      online: "En línea",
      cash: "Efectivo",
      card: "Tarjeta",
      stripe: "STRIPE",
      thirdParty: "Plataformas de terceros",
      originChartOptions: JSON.stringify({
        labels: [
          "Sitio Web",
          "Kiosco",
          "Caja",
          "Deliveroo",
          "Guagua",
          "Glovo",
          "Uber Eats",
          "Llamada",
          "WeChat",
          "Just Eat",
          "Otro",
        ],
      }),
      paymentMethodChartOptions: JSON.stringify({
        labels: [
          "Efectivo",
          "Tarjeta",
          "Plataforma Deliveroo",
          "Plataforma Gua Gua",
          "Plataforma Glovo",
          "Plataforma Uber Eats",
          "Llamada",
          "Stripe pagado con WeChat Pay",
          "Plataforma Just Eat",
          "Otras plataformas",
          "Desconocido",
          "Mixto",
          // "WeChat Personal",
          // "Deliveroo pagado en efectivo",
          // "Deliveroo pagado con tarjeta",
          // "Gua Gua pagado en efectivo",
          // "Gua Gua pagado con tarjeta",
          // "Glovo pagado en efectivo",
          // "Glovo pagado con tarjeta",
          // "Uber Eats pagado en efectivo",
          // "Uber Eats pagado con tarjeta",
          // "Llamada pagado en efectivo",
          // "Llamada pagado con tarjeta",
          // "Just Eat pagado en efectivo",
          // "Just Eat pagado con tarjeta",
          // "Otras plataformas pagado en efectivo",
          // "Otras plataformas pagado con tarjeta",
          // "Stripe pagado con tarjeta",
        ],
      }),
      stripeChartOptions: JSON.stringify({
        labels: ["Stripe pagado con WeChat Pay", "Stripe pagado con tarjeta"],
      }),
      inRestaurantCashPaymentMethodChartCategories: [
        "Efectivo",
        "Deliveroo pagado en efectivo",
        "Gua Gua pagado en efectivo",
        "Glovo pagado en efectivo",
        "Uber Eats pagado en efectivo",
        "Just Eat pagado en efectivo",
        "Llamada pagado en efectivo",
        "Otras plataformas pagado en efectivo",
      ],
      inRestaurantCardPaymentMethodChartCategories: [
        "Tarjeta",
        "Deliveroo pagado con tarjeta",
        "Gua Gua pagado con tarjeta",
        "Glovo pagado con tarjeta",
        "Uber Eats pagado con tarjeta",
        "Just Eat pagado con tarjeta",
        "Llamada pagado con tarjeta",
        "Otras plataformas pagado con tarjeta",
      ],
      onlinePaymentMethodChartCategories: [
        "Stripe pagado con tarjeta",
        "Stripe pagado con WeChat Pay",
        "Plataforma Deliveroo",
        "Plataforma Gua Gua",
        "Plataforma Glovo",
        "Plataforma Uber Eats",
        "Plataforma Just Eat",
        "Llamada",
        "Otras plataformas",
        "Desconocido/Factura",
        "WeChat Personal",
      ],
      thirdPartyChartCategories: [
        "Deliveroo",
        "Gua Gua",
        "Glovo",
        "Uber Eats",
        "Just Eat",
        "Otras plataformas",
      ],
      excelRevenueColumns: [
        "Sistema Gua Gua",
        "Pago contra entrega Gua Gua",
        "Sistema Glovo",
        "Glovo en efectivo en tienda",
        "Glovo con tarjeta en tienda",
        "Sistema de entrega",
        "Efectivo en restaurante",
        "POS en restaurante",
        "Recogida en web - Transferencia bancaria",
        "Kiosco - Pago con WeChat",
        "Pedido móvil con WeChat - RMB",
        "Pedido con WeChat - Convertido a euros",
        "Total recaudado en euros",
        "Ingresos totales del día",
        "Costo de envío",
        "Tarifa de entrega",
        "Otros gastos",
        "Bonificaciones",
      ],
      avgOrdersPerHourPerDay: "Órdenes promedio por hora al día",
      orders: "Órdenes",
      restaurantManagementTab: {
        websiteOrder: "Pedido desde la web",
        kioskEnabled: "Kiosco habilitado",
        paymentAfterService: "Pago después del servicio",
        skipDeliveryState: "Omitir fase de entrega",
        skipPreparationState: "Omitir fase de preparación",
        maxDeliveryDistance: "Distancia máxima de entrega",
        pointOfWork: "Punto de trabajo",
        customCSS: "CSS personalizado",
        saveDistance: "Guardar distancia",
        cover: "Cubierto",
        saveCover: "Guardar cubierto",
        createEndpoint: "Crear Endpoint",
      },
      restaurantVariationsTab: {
        newVariation: "Nueva variación",
        newVariationSet: "Nueva variación set",
        noVariations: "Este restaurante no tiene variaciones.",
        active: "Variaciones activas",
        archived: "Variaciones archivadas",
        setOn: "Mostrar variación set",
        setOff: "Esconder variación set",
      },
      restaurantTableManagementTab: {
        newTable: "Nueva mesa",
        noTables: "Este restaurante no tiene mesas.",
        update: "Modificar",
        remove: "Eliminar",
        seats: "Asientos (número de personas)",
        name: "Código",
      },
      restaurantAllergenManagementTab: {
        newAllergen: "Nuevo alérgeno",
        noAllergens: "Este restaurante no tiene alérgenos.",
      },
      restaurantCategoryManagementTab: {
        newCategory: "Nueva categoría",
        noCategories: "Este restaurante no tiene categorías.",
        modifyActiveCategory: "Gestionar orden de categorías.",
      },
      restaurantProductManagementTab: {
        newProduct: "Nuevo producto",
        noProducts: "Este restaurante no tiene productos.",
        active: "Productos activos",
        archived: "Productos archivados",
        visible: "Visible",
        indexRule:
          "Cuanto mayor sea el número, mayor será la prioridad. El orden predeterminado está al final de la categoría.",
        index: "Índice",
        visibility: "VISIBILIDAD",
        excludeFromTopSeller: "Excluir de los más vendidos",
        modify: "MODIFICAR",
        archive: "ARCHIVAR",
        unarchive: "RESTAURAR",
        discount: "DESCUENTO",
        layout: "Diseño",
        layoutTips:
          "Instrucciones: Selecciona el plato desde la barra de vista previa, usa los valores en la parte superior derecha para ver los movimientos de entrada. Nota: los platos pueden estar en múltiples categorías con valores independientes, y la opción 'Excluir de los más vendidos' excluye todas las categorías y la etiqueta 'Popular'.",
      },
    },
    restaurantFixedMenuManagementTab: {
      newFixedMenu: "Nuevo menú fijo",
      noFixedMenus: "Este restaurante no tiene menús fijos.",
      selectFixedMenu: "Seleccionar productos",
      createChoice: "Nueva página",
      removeChoice: "Eliminar",
      selectFixedMenuProducts: "Productos del menú fijo",
      note: "Haz clic en 'Nueva Página' para agregar más opciones de platos al menú. Haz clic en 'Eliminar' y luego en el número de la página para eliminarla. Haz clic nuevamente en 'Eliminar' para salir del modo de eliminación. Haz clic en el nombre del plato para añadirlo a la página del menú. Haz clic nuevamente en el nombre del plato para eliminarlo.",
    },
    restaurantProductsStockManagementTab: {
      availableVolume: "Cantidad",
      unavailable: "No disponible",
    },
    restaurantOrderListTab: {
      noOrders: "Este restaurante no tiene pedidos.",
    },
    restaurantProductsAnalyticsTab: {
      noOrders: "Este restaurante no tiene pedidos.",
    },
    restaurantSceneManagementTab: {
      title: "Gestión de zonas",
      list: "Lista",
      newScene: "Nueva zona",
      name: "Nombre",
      configuration: "Configuración",
      category: "Categoría",
      product: "Producto",
      fixedMenu: "Menú fijo",
      customProduct: "Producto personalizado",
      table: "Mesa",
      tableMap: "Mapa de mesas",
      noMaps: "Esta zona no tiene mapa.",
      active: "Activo",
      disable: "Desactivar",
      noScenes: "Este restaurante no tiene zonas.",
      noProducts: "Este restaurante no tiene productos.",
      noCustomProducts: "Este restaurante no tiene productos personalizados.",
      noFixedMenus: "Este restaurante no tiene menús fijos.",
      modify: "Modificar",
    },
    customerPromoCodesTab: {
      new: "Nuevo código promocional",
      code: "Código",
      description: "Descripción",
      discount: "Descuento",
      created: "Creado el",
      expiry: "Expira el",
      lastUpdate: "Última actualización",
      status: "Estado",
    },
  },
  homepage: {
    home: "Inicio",
    ourIdea: "Nuestra idea",
    loyalty: "Hazte miembro de fidelidad",
    orderNow: "Ordenar ahora",
    logIn: "Iniciar sesión",
    findOutMore: "Descubre más",
    slogan: "Nuestros productos están hechos para ti",
    firstSectionTitle: "Estilo moderno. Sabor tradicional.",
    firstSectionParagraph_1:
      "El predecesor de Giaogiao en Via dei Servi fue el restaurante chino más antiguo de Florencia y de toda la Toscana, fundado en 1961 con el nombre de '荷香园' y 'Fior di Loto'.",
    firstSectionParagraph_2:
      "Hoy, 60 años después, Giaogiao, un nuevo concepto de comida china, sigue las recetas tradicionales y mejora los procesos de producción y entrega utilizando tecnología avanzada para revitalizar la gastronomía china.",
    firstSectionParagraph_3:
      "Nuestra visión: Llevar la nueva y deliciosa comida china (inteligente) al mundo.",
    firstSectionParagraph_4:
      "Nuestra misión: Redefinir la comida china en el extranjero, potenciarla con tecnología y hacer que la gastronomía china sea moderna y accesible.",
    firstSectionParagraph_5: "Lema: ¡Cada ciudad cultural tiene Giaogiao!",
    secondSectionTitle: "¿Por qué se llama Giaogiao?",
    secondSectionParagraph_1:
      "¡Queremos ser líderes en la comida china en el extranjero!",
    secondSectionParagraph_2:
      "El promotor más joven de la cultura gastronómica china.",
    secondSectionParagraph_3:
      "Giaogiao es la abreviatura de las siguientes cuatro palabras en inglés:",
    secondSectionParagraph_4: "Good (Bueno)",
    secondSectionParagraph_5: "Innovative (Innovador)",
    secondSectionParagraph_6: "Authentic (Auténtico)",
    secondSectionParagraph_7: "Original (Original)",
    serviceTimesTitle: "Horarios de servicio",
    serviceTimes: "Via dei Servi 11:00 - 21:30 LUN-DOM",
    serviceTimes2:
      "Via Nicola Tagliaferri 11:00 - 16:00, 18:00 - 23:00 MAR-DOM (Lunes cerrado)",
    homeDeliveryTitle: "Entrega a domicilio",
    homeDelivery: "Hasta 5 km, ordena ahora para verificar disponibilidad",
    deliveryChargesTitle: "Costos de entrega",
    deliveryCharges: "1€/km",
    contact: "Contacto",
    addressesTitle: "Ven a visitarnos",
    history: "Historia",
    present: "Presente",
    future: "Futuro",
    vision: "Visión",
    restaurantSectionTitle: "+3 RESTAURANTES",
    restaurantSectionParagraph: "y muchos más en camino...",
    orderSectionTitle: "+20k PEDIDOS",
    orderSectionParagraph:
      "realizados a través de nuestro sistema automático",
    preorder: "Preordenar",
  },
  restaurantList: {
    chooseRestaurant: "Elige un restaurante",
  },
  loyalty: {
    title:
      "¡Regístrate ahora en el Giaogiao Club y recibe un regalo de bienvenida en tu próxima visita!",
    firstName: "Nombre (requerido)",
    lastName: "Apellido (requerido)",
    phone: "Número de teléfono móvil (requerido)",
    email: "Correo electrónico (requerido)",
    birthday: "Fecha de nacimiento (requerido)",
    subscribe: "Suscribirse",
    read: "He leído la",
    privacy: "política de privacidad",
    authorize: ", autorizo el tratamiento de mis datos personales.",
    street: "Dirección",
    city: "Ciudad",
    province: "Provincia",
    postalCode: "Código postal",
    optional: "Opcional",
    newsletter:
      "Acepto recibir boletines y comunicaciones promocionales por correo electrónico y SMS. (Opcional)",
    thanks:
      "¡Gracias por unirte a nuestro club! Estamos encantados de tenerte con nosotros y esperamos ofrecerte ofertas exclusivas, novedades y promociones. ¡Nos vemos pronto!",
    MISSING_STREET: "La dirección es obligatoria.",
    MISSING_CITY: "La ciudad es obligatoria.",
    MISSING_POSTAL_CODE: "El código postal es obligatorio.",
    INCORRECT_POSTAL_CODE: "El código postal debe ser un número.",
    MISSING_FIRST_NAME: "El nombre es obligatorio.",
    MISSING_LAST_NAME: "El apellido es obligatorio.",
    MISSING_PHONE: "El número de teléfono es obligatorio.",
    MISSING_PROVINCE: "La provincia es obligatoria.",
    MISSING_EMAIL: "El correo electrónico es obligatorio.",
    MISSING_BIRTHDAY: "La fecha de nacimiento es obligatoria.",
    consentEmail:
      "Quiero recibir ofertas y actualizaciones por correo electrónico.",
    consentSMS: "Quiero recibir ofertas y actualizaciones por SMS.",
    consentPhoneCall:
      "Quiero ser contactado para ofertas y actualizaciones a través de llamadas telefónicas.",
  },
  categoryTimes: {
    0: "Inmediato",
    10: "10 Minutos",
    20: "20 Minutos",
    30: "30 Minutos",
  },
  discountStrategy: {
    onePlusOne: "Oferta 1 + 1",
    percentage: "Porcentaje",
    fixed: "Fijo",
  },
  lang: {
    it: "Italiano",
    zh: "Chino",
    en: "Inglés",
  },
  endpoint: {
    cashier: "Caja",
    kiosk: "Kiosco",
    kitchen: "Cocina",
    waiter: "Camarero",
  },
  host: {
    logout: "Cerrar sesión",
    access: "Acceso",
    development: "Modo Desarrollo",
    fullScreen: "Pantalla Completa",
    clearCache: "Borrar Caché",
    exit: "Salir",
    checkUpdate: "Buscar Actualizaciones",
    start: "Iniciar",
    openLogs: "Abrir Registros",
    getConfiguration: "Cargar configuración",
    selectDirectory: "Seleccionar Directorio Axon Printer",
    voidOrder: "Documento de Anulación",
  },
  productTag: {
    new: "Novedad(1)",
    discount: "Descuento(2)",
  },
  memberOnly: {
    promoCode: "Código Promocional",
    checkInfo: "Verificar Datos del Cliente",
    updateInfo: "Actualizar Datos del Cliente",
    check: "Verificar",
    use: "Aplicar (No revocable)",
    invalid: "El código promocional ya no está activo.",
    expired: "El código promocional ha expirado.",
    description: "Descripción",
    discount: "Descuento",
    notApplicable: "No aplicable",
    validUntil: "Válido hasta",
    percentage: "Porcentaje",
    applyDiscount: "Aplicar descuento",
    unavailable: "No disponible",
    unlimited: "Ilimitado",
    info: "Información",
    stripeWebhook: "Webhook de Stripe",
  },
  restaurantClosed: {
    title: "El restaurante está actualmente cerrado",
    description:
      "Disculpa las molestias. Vuelve a visitarnos durante el horario de apertura.",
  },
  day: {
    Monday: "Lunes",
    Tuesday: "Martes",
    Wednesday: "Miércoles",
    Thursday: "Jueves",
    Friday: "Viernes",
    Saturday: "Sábado",
    Sunday: "Domingo",
  },
  stripeStatus: {
    requires_action: "Esperando confirmación",
    succeeded: "Pago exitoso",
    failed: "Pago fallido",
    canceled: "Cancelado",
  },
  stripePaymentMethod: {
    wechat_pay: "WeChat Pay",
    card: "Tarjeta",
  },
  webhookCol: {
    amount: "Importe",
    orderId: "ID de Pedido",
    id: "ID",
    timestamp: "Fecha y Hora",
    status: "Estado",
    paymentMethod: "Método de pago",
  },
  languages: {
    en: "Inglés",
    es: "Español",
    fr: "Francés",
    de: "Alemán",
    zh: "Chino (Simplificado)",
    zh_hk: "Chino (Tradicional)",
    ja: "Japonés",
    ko: "Coreano",
    it: "Italiano",
    pt: "Portugués",
    ru: "Ruso",
    hi: "Hindi",
    ar: "Árabe",
    nl: "Holandés",
    el: "Griego",
    sv: "Sueco",
    no: "Noruego",
    da: "Danés",
    fi: "Finlandés",
    tr: "Turco",
  },
};