<template>
    <div class="flex flex--100 mobile-order-view" :class="getModifiers()">
        <div class="member-only" :class="getMemberOnlyModifiers()" v-if="restaurantId === 1 || restaurantId === 15 || restaurantId === 9">
            <primary-logo :restaurant-id="restaurantId" @click="toggleMenu" class="member-only__logo" />
            <nav v-if="isActive" class="vertical-menu" :class="getMenuModifiers()">
            <ul>
                <li>
                    <a @click="showPickUpModalBox()">{{ $t("memberOnly.info") }}</a>
                </li>
                <li>
                    <a @click="showMemberOnlyModalBox()">{{  $t("memberOnly.promoCode") }}</a>
                </li>
            </ul>
            </nav>
        </div>
        <simple-language-selector :available-languages="displayLanguages"/>
        <mobile-restaurant-product-list
            :restaurant-presentation="restaurantPresentation"
            :table-number="tableNumber"
            :inside-restaurant="insideRestaurant"
            v-model:selected-products="basket.selectedProducts"
            ref="restaurantProductList"
            @updateVolume="updateSingleProductDiscount" 
        />
        <div class="flex flex--100 flex--x-align-center flex--y-align-center footer" :class="getFooterModifiers()" @click="showCheckoutModalBox">
            <div class="flex flex--80 flex--y-align-center basket-summary">
                <div class="flex flex--30">
                    <span class="basket-summary__products-length-text">{{ basketTotalSelectedVolume }}</span>
                </div>
                <div class="flex flex--40 flex--x-align-center">
                    <span class="basket-summary__continue-text">{{ $t("generic.continue") }}</span>
                </div>
                <div class="flex flex--30 flex--x-align-end">
                    <span class="basket-summary__total-price-text">€ {{ normalizePriceToDisplay(basketSubtotal) }}</span>
                </div>
            </div>
        </div>
        <modal-box class="checkout-modal-box" ref="checkoutModalBox" :show-close-button="false" :close-on-background-click="false">
            <customer-order-checkout
                :restaurant-presentation="restaurantPresentation"
                :skip-delivery-state="skipDeliveryState"
                :is-reservation="isReservation"
                :inside-restaurant="insideRestaurant"
                :table-number="tableNumber"
                :sceneId="sceneId"
                v-model:basket="basket"
                :promo-code="promoCode"
                :global-discount="globalDiscount"
                :preset-order-delivery-location="orderDeliveryLocation"
                @cancel="hideCheckoutModalBox"
                @complete="onCheckoutComplete"
                @close="hideCheckoutModalBox"
            />
        </modal-box>
        <modal-box class="member-only-modal-box" ref="memberOnlyModalBox" :show-close-button="false" :close-on-background-click="false" :show-back-button="true">
            <div class="modal-content">
                 <div class="modal-header">
                    <p class="member-only-container--title">{{ $t("memberOnly.promoCode") }}</p>
                </div>
                <div class="flex flex--100 flex--x-align-center modal-body ">
                    <!-- Promo Code Feature -->
                    <div class="flex flex--100 flex--x-align-center flex--y-align-center member-only-container--box"
                        v-if="featureSelected === 0">
                        <div class="flex flex--100 flex--x-align-center">
                            <simple-textbox class="member-only-container--input" :placeholder="$t('memberOnly.promoCode').toUpperCase()"
                                v-model:model-value="promoCode" />
                        </div>
                        <div class="flex flex--100 flex--x-align-center member-only-container--paragraph">{{ promoCodeReply }}</div>
                    </div>

                    <!-- Check Info Feature -->
                    <div v-else-if="featureSelected === 1">
                        <!-- Check info content -->
                    </div>

                    <!-- Update Info Feature -->
                    <div v-else-if="featureSelected === 2">
                        <!-- Update info content -->
                    </div>
                    <!-- Discount Feature -->
                    <div v-else-if="featureSelected === 3">
                    </div>
                    <!-- Update Info Feature -->
                    <div v-else-if="featureSelected === 4">
                        <!-- Update info content -->
                    </div>
                    <!-- Update Info Feature -->
                    <div v-else-if="featureSelected === 5">
                        <!-- Update info content -->
                    </div>
                </div>
                <div class="flex flex--100 flex--x-align-center flex--y-align-center modal-footer">
                    <simple-button class="member-only-container--button" :text="$t('memberOnly.applyDiscount')" @click="usePromoCode" />
                </div>
            </div>
        </modal-box>
        <pick-up-modal-box :restaurant-presentation="restaurantPresentation" ref="pickUpModalBox" @delivery="updateDeliveryMode"/>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import CustomerOrderCheckout from "@/components/CustomerOrderCheckout";
import MobileRestaurantProductList from "@/components/MobileRestaurantProductList";
import { server, } from "@/server";
import {
    MobileViewportSimulator,
    RestaurantProcessor,
    BasketProcessor,
} from "@/utilities";
import { cloneDeep } from "lodash";
import { i18n } from "@/main";
import SimpleLanguageSelector from "@/components/inputs/SimpleLanguageSelector";
import PickUpModalBox from "@/components/modals/PickUpModalBox";
import PrimaryLogo from "@/components/utilities/PrimaryLogo";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import { DiscountStrategies } from "@/products/DiscountStrategies";

const preloadedData = {};
let presentation = {};

let isReservation = false;
let insideRestaurant = false;
let tableNumber = null;
let sceneId = null;

export default {
    async beforeRouteEnter (to, from, next) {
        isReservation = to.query.isReservation === "true";

        if (to.query.language) {
            i18n.locale = to.query.language;
        }

        // <redirect-qr> Used to redirect old QR codes to mobile home view
        if (to.query.fromHome !== "true") {
            next(`/restaurant/${to.params.restaurantId}/mobile?insideRestaurant=${to.query.insideRestaurant === "true"}`);

            return;
        }
        // </redirect-qr>

        if (to.query.insideRestaurant) {
            insideRestaurant = to.query.insideRestaurant === "true";
        }

        if (to.query.tableNumber) {
            tableNumber = to.query.tableNumber;
        }

        if (to.query.sceneId) {
            sceneId = to.query.sceneId === "undefined" ? undefined : to.query.sceneId;
        }

        preloadedData.restaurantPresentation = await server.getRestaurantPresentation(to.params.restaurantId);
        presentation = cloneDeep(preloadedData.restaurantPresentation);

        if (!preloadedData.restaurantPresentation.restaurant.isOpen) {
            next(`/restaurant/${to.params.restaurantId}/closed`);

            return;
        }
        
        next();
    },
    name: "MobileOrderView",
    components: {
        MobileRestaurantProductList,
        ModalBox,
        CustomerOrderCheckout,
        SimpleLanguageSelector,
        PickUpModalBox,
        PrimaryLogo,
        SimpleButton,
        SimpleTextbox,
    },
    data () {
        return {
            basket: BasketProcessor.createEmpty(),
            isReservation,
            insideRestaurant,
            tableNumber,
            sceneId,
            isActive: false,
            featureSelected: 0,
            promoCode: "",
            globalDiscount: "",
            promoCodeReply: "",
            orderDeliveryLocation: null,
        };
    },
    computed: {
        restaurantPresentation () {
            // filter product 637 for 1 month
            const restaurantPresentation = {
                ...preloadedData.restaurantPresentation,
                products: preloadedData.restaurantPresentation.products.filter((p) => !(p.id == 2738 || p.id == 2865 || p.id == 2899)),
            }
            return restaurantPresentation;
        },

        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        restaurantId () {
            return this.restaurantProcessor.restaurant.id;
        },

        displayLanguages () {
            return this.restaurantProcessor.getUserDisplayLocalizations();
        },
        // </restaurant>

        // <basket>
        basketProcessor () {
            return new BasketProcessor({
                basket: this.basket,
                restaurantProcessor: this.restaurantProcessor,
            });
        },

        basketSelectedProducts () {
            return this.basketProcessor.selectedProducts;
        },

        basketTotalSelectedVolume () {
            return this.basketProcessor.totalSelectedVolume;
        },

        basketSubtotal () {
            return this.basketProcessor.subtotal;
        },

        basketIsEmpty () {
            return this.basketProcessor.isEmpty;
        },
        // </basket>

        skipDeliveryState () {
            return this.insideRestaurant;
        },
    },
    methods: {
        getModifiers () {
            return {
                "mobile-order-view--basket-not-empty": !this.basketIsEmpty,
            };
        },

        getFooterModifiers () {
            return {
                "footer--active": !this.basketIsEmpty,
            };
        },

        getMemberOnlyModifiers () {
            return {
                "member-only--active": !this.basketIsEmpty,
            };
        },

        getMenuModifiers () {
            return {
                "vertical-menu--active": !this.basketIsEmpty,
            };
        },

        onCheckoutComplete (confirmedOrder) {
            this.clearBasket();
            const orders = JSON.parse(window.localStorage.getItem("FortuneRMS::LatestOrders")) ?? [];
            window.localStorage.setItem("FortuneRMS::LatestOrders", JSON.stringify([ confirmedOrder, ...orders, ]));
            this.$refs.restaurantProductList.updateLatestOrder();
            this.$refs.restaurantProductList.selectFirstCategory();
            this.promoCode = "";
            this.promoCodeReply = "";
            this.globalDiscount = "";
            this.resetGlobalDiscount();
        },

        clearBasket () {
            this.basket = BasketProcessor.createEmpty();
        },

        showCheckoutModalBox () {
            this.$refs.checkoutModalBox.show();
        },

        hideCheckoutModalBox () {
            this.$refs.checkoutModalBox.hide();
        },

        showPickUpModalBox () {
            this.$refs.pickUpModalBox.show();
            this.isActive = false;
        },

        updateDeliveryMode (delivery) {
            this.orderDeliveryLocation = delivery;
        },

        toggleMenu () {
            this.isActive = !this.isActive;
        },

        showMemberOnlyModalBox () {
            this.$refs.memberOnlyModalBox.show();
            this.isActive = false;
        },

        async usePromoCode () {
           if (this.isWaitingServerResponseForPromoCode) {
                return;
            }
            this.isWaitingServerResponseForPromoCode = true;
            try {
                const response = await server.checkPromoCode({ code: this.promoCode, });
                const expiryDate = (response && response.expiryDate) ? new Date(response.expiryDate) : null;
                const currentDate = new Date();
                const hasExpiration = Boolean(response?.expiryDate);
                if (response && response.isActive && (!hasExpiration || currentDate < expiryDate)) {
                    this.promoCodeReply = "OK";
                    if (response.discountPercentage) {
                        this.globalDiscount = response.discountPercentage;
                        this.applyGlobalDiscount();
                    }
                }
                else {
                    this.promoCodeReply = this.$t("memberOnly.invalid");
                }
            }
            catch (e) {
                console.log(e);
            }
            this.isWaitingServerResponseForPromoCode = false;
        },

         formatPromoCodeResponse (response) {
            if (!response || !response.isActive) {
                return this.$t("memberOnly.invalid");
            }
            // Parse the expiry date from the response
            const expiryDate = response.expiryDate ? new Date(response.expiryDate) : null;
            const currentDate = new Date();

            // Check if the promo code has expired
            if (expiryDate && (currentDate > expiryDate)) {
                return this.$t('memberOnly.expired');
            }

            const options = { day: 'numeric', month: 'long', year: 'numeric' };
            const formattedExpiryDate = expiryDate ? expiryDate.toLocaleDateString('it-IT', options) : this.$t("memberOnly.unlimited");

            let message = `**${this.$t("memberOnly.promoCode")}:** ${response.code}\n`;
            message += `**${this.$t("memberOnly.description")}:** ${response.description}\n`;
            if (response.discountPercentage !== null) {
                message += `**${this.$t("memberOnly.discount")}:** ${response.discountPercentage}%\n`;
            } else {
                message += `**${this.$t("memberOnly.discount")}:** ${this.$t("memberOnly.notApplicable")}\n`;
            }
            message += `**${this.$t("memberOnly.validUntil")}:** ${formattedExpiryDate}\n`;

            return message;
        },

        async updateSingleProductDiscount ({ id, volume }) {
            if (this.globalDiscount.length > 0) {
                let ds = null;
                const discountPercentage = Number.parseFloat(this.globalDiscount);
                if (this.restaurantProcessor.productIsFixedMenu(id)) {
                    const fixedMenu = this.restaurantProcessor.getFixedMenuById(id);
                    const originalFixedMenu = presentation.fixedMenus.find((p) => p.id === id);
                    const estimateDiscount = -DiscountStrategies.percentage.applyDiscount(
                        Math.max(volume, 1),
                        fixedMenu.price,
                        discountPercentage
                    );
                    const normalDiscount = this.getDiscountedProductPrice(originalFixedMenu, volume);
                    if (estimateDiscount < normalDiscount) {
                        if (fixedMenu.discountStrategies.length > 0) {
                            fixedMenu.discountStrategies[0] = `%${this.globalDiscount}`;
                        }
                        else {
                            fixedMenu.discountStrategies = [`%${this.globalDiscount}`];
                        }
                        fixedMenu.discountExpirationTimestamp = null;
                    }
                    else {
                        fixedMenu.discountStrategies = cloneDeep(originalFixedMenu.discountStrategies);
                        fixedMenu.discountExpirationTimestamp = originalFixedMenu.discountExpirationTimestamp;
                    }
                    ds = cloneDeep(fixedMenu.discountStrategies);
                }
                else {
                    const product = this.restaurantProcessor.getProductById(id);
                    const originalProduct = presentation.products.find((p) => p.id === id);
                    const estimateDiscount = -DiscountStrategies.percentage.applyDiscount(
                        Math.max(volume, 1),
                        product.price,
                        discountPercentage
                    );
                    const normalDiscount = this.getDiscountedProductPrice(originalProduct, volume);
                    if (estimateDiscount < normalDiscount) {
                        if (product.discountStrategies.length > 0) {
                            product.discountStrategies[0] = `%${this.globalDiscount}`;
                        }
                        else {
                            product.discountStrategies = [`%${this.globalDiscount}`];
                        }
                        product.discountExpirationTimestamp = null;
                    }
                    else {
                        product.discountStrategies = cloneDeep(originalProduct.discountStrategies);
                        product.discountExpirationTimestamp = originalProduct.discountExpirationTimestamp;
                    }
                    ds = cloneDeep(product.discountStrategies);
                }
                this.basketProcessor.unselectProducts();
                await this.$nextTick();
                this.basketProcessor.reselectProducts();
                await this.$refs.restaurantProductList.forceUpdateById(id, ds);
            }
        },

        async applyGlobalDiscountSafe () {
            await this.resetGlobalDiscount();
            await this.applyGlobalDiscount();
        },

        async applyGlobalDiscount () {
            const products = this.restaurantPresentation.products;
            const fixedMenus = this.restaurantPresentation.fixedMenus;
            const discountPercentage = Number.parseFloat(this.globalDiscount);
            products.forEach((p) => {
                const volume = this.basketProcessor.getProductSelectedVolume(p.id) || 1;
                const estimateDiscount = -DiscountStrategies.percentage.applyDiscount(
                    volume,
                    p.price,
                    discountPercentage
                );
                const normalDiscount = this.getDiscountedProductPrice(p, volume);
                if (estimateDiscount < normalDiscount) {
                    if (p.discountStrategies.length > 0) {
                        p.discountStrategies[0] = `%${this.globalDiscount}`;
                    }
                    else {
                        p.discountStrategies = [`%${this.globalDiscount}`];
                    }
                    p.discountExpirationTimestamp = null;
                }
            });
            fixedMenus.forEach((p) => {
                const volume = this.basketProcessor.getProductSelectedVolume(p.id) || 1;
                const estimateDiscount = -DiscountStrategies.percentage.applyDiscount(
                    volume,
                    p.price,
                    discountPercentage
                );
                const normalDiscount = this.getDiscountedProductPrice(p, volume);
                if (estimateDiscount < normalDiscount) {
                    if (p.discountStrategies.length > 0) {
                        p.discountStrategies[0] = `%${this.globalDiscount}`;
                    }
                    else {
                        p.discountStrategies = [`%${this.globalDiscount}`];
                    }
                    p.discountExpirationTimestamp = null;
                }
            });
            this.basketProcessor.unselectProducts();
            this.$refs.memberOnlyModalBox.hide();
            this.$refs.memberOnlyModalBox.hide();
            await this.$nextTick();
            this.basketProcessor.reselectProducts();
            await this.$refs.restaurantProductList.forceUpdate();
        },

        async removeGlobalDiscount () {
            if (this.globalDiscount.length > 0) {
                this.globalDiscount = "";
                await this.$nextTick();
                await this.resetGlobalDiscount();
            }
        },

        async resetGlobalDiscount () {
            const products = this.restaurantPresentation.products;
            const fixedMenus = this.restaurantPresentation.fixedMenus;

            products.forEach((p) => {
                const original = presentation.products.find((pp) => pp.id === p.id);
                p.discountStrategies = cloneDeep(original.discountStrategies);
                p.discountExpirationTimestamp = original.discountExpirationTimestamp;
            });
            fixedMenus.forEach((p) => {
                const original = presentation.fixedMenus.find((pf) => pf.id === p.id);
                p.discountStrategies = cloneDeep(original.discountStrategies);
                p.discountExpirationTimestamp = original.discountExpirationTimestamp;
            });
            this.basketProcessor.unselectProducts();
            await this.$nextTick();
            this.basketProcessor.reselectProducts();
            await this.$refs.restaurantProductList.forceUpdate();
        },

        getDiscountedProductPrice (product, volume) {
            if (!product.discountStrategies || product.discountExpirationTimestamp && (Number.parseInt(product.discountExpirationTimestamp) < Date.now())) {
                return 0;
            }
            let totalPrice = 0;

            if (product.discountStrategies.includes(DiscountStrategies.onePlusOne.id)) {
                totalPrice -= DiscountStrategies.onePlusOne.applyDiscount(
                    volume,
                    product.price,
                );
            }

            const fixedDiscount = product.discountStrategies.find((s) =>
                String(s).startsWith("-")
            );
            const fixed = Boolean(fixedDiscount);
            if (fixed) {
                const fixedValue = fixedDiscount.substring(1);
                totalPrice -= DiscountStrategies.fixed.applyDiscount(
                    volume,
                    product.price,
                    fixedValue
                );
            }

            const percentageDiscount = product.discountStrategies.find((s) =>
                String(s).startsWith("%")
            );
            const percentage = Boolean(percentageDiscount);
            if (percentage) {
                const percentageValue = percentageDiscount.substring(1);
                totalPrice -= DiscountStrategies.percentage.applyDiscount(
                    volume,
                    product.price,
                    percentageValue
                );
            }

            return totalPrice;
        },
    },
    watch: {
        basketSelectedProducts: {
            deep: true,
            handler (value) {
                // <products-variations>
                // Remove a variation if its product is no longer selected.
                for (const productId in this.basket.selectedProductsVariations) {
                    if (!value.find((product) => Number.parseInt(product.id) === Number.parseInt(productId))) {
                        delete this.basket.selectedProductsVariations[productId];
                    }
                    else {
                        const variationsCopy = cloneDeep(this.basket.selectedProductsVariations);
                        const productVariations = [];

                        for (let i = 0; i < this.basketProcessor.getProductSelectedVolume(productId); ++i) {
                            productVariations[i] = variationsCopy[productId][i] ?? [];
                        }

                        variationsCopy[productId] = productVariations;
                        this.basket.selectedProductsVariations = variationsCopy;
                    }
                }
                // </products-variations>

                // <one-plus-one>
                // this.basket.deductions = [];
                // for (const productId in this.basket.selectedProducts) {
                //     const product = this.restaurantProcessor.getProductById(Number.parseInt(productId));
                //     if (product && product.discountStrategies.includes(DiscountStrategies.onePlusOne.id) && (!product.discountExpirationTimestamp || product.discountExpirationTimestamp > Date.now())) {
                //         const volume = Math.floor(this.basket.selectedProducts[productId].selectedVolume / 2);
                //         this.basket.deductions = this.basket.deductions.filter((a) => a.description !== this.restaurantProcessor.getProductLocalizedName(product.id));
                //         if (volume > 0) {
                //             this.basket.deductions.push({
                //                 description: this.restaurantProcessor.getProductLocalizedName(product.id),
                //                 value: -volume * product.price,
                //             });
                //         }
                //     }
                // }
                // </one-plus-one>
            },
        },
    },
    mounted () {
        MobileViewportSimulator.activate();
        MobileViewportSimulator.update();
        if (!this.insideRestaurant) {
            this.showPickUpModalBox();
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
@import "~@/css/flex.scss";

.mobile-order-view {
    overflow: hidden;

    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    background-color: rgb(243, 243, 243);

    :deep(.modal-box) {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
    }
    :deep(.modal-box__slot) {
        width: 100%;
        height: 90vh;
        height: calc(var(--vh, 1vh) * 90);
        margin: 10vh 0 0 0;
        margin: calc(var(--vh, 1vh) * 10) 0 0 0;
        padding: 0;

        border-radius: 0;
    }

    :deep(> .mobile-restaurant-product-list) {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
    }

    :deep(> .mobile-restaurant-product-list) {
        .main {
            padding-bottom: 20px;
        }

        .restaurant-product .restaurant-product__box,
        .restaurant-fixed-menu .restaurant-fixed-menu__box {
            border: none;
        }

        .restaurant-product--selected .restaurant-product__name,
        .restaurant-product--selected .restaurant-product__price,
        .restaurant-fixed-menu--selected .restaurant-fixed-menu__name,
        .restaurant-fixed-menu--selected .restaurant-fixed-menu__price {
            font-weight: 800;
            color: $primary-brand-color;
        }

        .restaurant-product .selection-controls,
        .restaurant-fixed-menu .selection-controls {
            justify-content: center; // @extend not working here

            left: 50%;
            top: initial;
            bottom: 0;

            width: 169px;

            border-radius: 28px 28px 0 0;

            transform: translateX(-50%);

            &__minus {
                background-color: transparent;
            }

            &__plus {
                background-color: transparent;
            }
        }

        .restaurant-product .preview-horizontal {
            &__image {
                position: absolute;
                right: 0;
                top: 0;

                width: 88px;

                border-radius: 0 0 0 10px;
            }
        }
        .restaurant-product .allergen-list-box {
            right: 20px;
        }
    }

    &--basket-not-empty :deep(> .mobile-restaurant-product-list) {
        .main {
            padding-bottom: 18vh;
            padding-bottom: calc(var(--vh, 1vh) * 18);
        }
    }
}

.footer {
    pointer-events: none;

    height: 15vh;
    height: calc(var(--vh, 1vh) * 15);

    position: absolute;
    bottom: 0;

    background-color: $primary-color;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    opacity: 0;
    z-index: 100;

    transition: opacity 0.3s ease;

    &--active {
        pointer-events: initial;

        opacity: 1;
    }
}

.checkout-modal-box {
    :deep(.customer-order-checkout) {
        height: inherit;
        overflow: auto;

        .customer-basket {
            height: 80%;
        }

        .control-box {
            height: 20%;

            &__cancel-button {
                display: none;
            }

            &__confirm-button {
                margin: 10px 0 0 0;
            }
            &__confirm-button .simple-button__text {
                font-size: 16px;
                font-weight: 800;
            }
        }

        .take-away {
            justify-content: center;

            width: 100%;

            margin: 0 0 16px 0;
        }

        .payment-method-list {
            flex-direction: column;
            flex-wrap: nowrap;
            min-height: 600px;
            row-gap: 20px;

            &__title {
                margin: 28px 24px 36px 24px;

                font-size: 2rem;
                text-align: center;
            }
        }

        .payment-method {
            width: 80vw;
            min-height: 145px;

            padding: 18px 30px;

            &__icon {
                width: 46px;
                height: 46px;

                margin-bottom: 18px;
            }

            &__name {
                font-size: 1.5rem;
                text-align: center;
            }

            &--secondary {
                margin-top: 0;
            }

            .wechat-pay-icon {
                height: 46px;
                margin-bottom: 18px;
            }

            .wechat-payment-text {
                margin-top: 0;
                text-align: center;
            }

            .arrow-icon {
                display: none;
            }
        }

        .payment-method + .payment-method {
            margin: 22px 0 0 0;
        }
    }

    :deep(.basket) {
        .summary .total {
            .total__text {
                width: 50%;
            }

            .total__price {
                text-align: right;
            }
        }
    }

}

.basket-summary {
    cursor: pointer;

    padding: 8px 20px;

    background-color: $primary-brand-color;
    border-radius: 6px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);

    &__products-length-text {
        @extend .--unselectable;

        min-width: 42px;

        padding: 8px;

        background-color: rgba(100, 100, 100, 0.16);
        border-radius: 6px;

        font-size: 18px;
        font-weight: 800;
        text-align: center;
        color: rgb(255, 255, 255);
    }

    &__continue-text {
        font-size: 18px;
        font-weight: 800;
        text-align: center;
        color: rgb(255, 255, 255);
    }

    &__total-price-text {
        font-size: 18px;
        font-weight: 800;
        text-align: center;
        color: rgb(255, 255, 255);
    }
}

.customer-order-checkout {
    :deep(.customer-address-box),
    :deep(.customer-id-box),
    :deep(.order-message-box),
    :deep(.requested-delivery-timestamp-box) {
        width: 100%;
        padding: 25px 0 !important;
    }

    :deep(.street-textbox) {
        width: 100%!important;
    }

    $max-input-width: 400px;
    $two-input-space: 16px;

    :deep(.building-code-textbox) {
        width: calc(50% - #{$two-input-space / 2})!important;
        max-width: calc(#{$max-input-width} / 2 - #{$two-input-space / 2})!important;

        margin: 16px $two-input-space 0 0;
    }

    :deep(.postal-code-textbox) {
        width: calc(50% - #{$two-input-space / 2})!important;
        max-width: calc(#{$max-input-width} / 2 - #{$two-input-space / 2})!important;

        margin: 16px 0 0 0;
    }

    :deep(.full-name-textbox) {
        width: 100%;
    }

    :deep(.mobile-textbox) {
        width: 100%;
    }

    :deep(.persons-textbox) {
        width: 100%;
    }

    :deep(.delivery-location-button) {
        margin: 4px;

        border: none !important;
        border-radius: 12px !important;
    }

    :deep(.order-message-textbox) {
        width: 100%;
    }

    :deep(.times-selectbox) {
        width: 100%;
    }
}

:deep(.order-completed-box) {
    &__cashier-payment-notice-text {
        max-width: 80vw;
        margin-top: 20px;
        padding: 6px 25px;

        background-color: $primary-brand-color;
        border-radius: 1000px;

        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: 0.06rem;
        text-transform: uppercase;
        text-align: center;
        color: rgb(33, 33, 33);
    }
}

// .language-selector {
//     position: absolute;
//     left: 0;
//     top: 0;

//     background-color: transparent;
//     border-bottom-right-radius: 12px;

//     z-index: 10;

//     :deep(.language) {
//         background-color: transparent;
//         border-radius: 0;
//         padding: 6px 10px;
//     }

//     :deep(.language.language--selected) {
//         background-color: transparent;
//     }

//     :deep(.language .language__name) {
//         display: none;
//     }

//     :deep(.language .language__icon) {
//         margin: 0;

//         opacity: 0.5;
//     }

//     :deep(.language.language--selected .language__icon) {
//         opacity: 1;
//     }

//     :deep(.language+.language) {
//         margin: 0;
//     }
// }

:deep(.language-selector) {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    &__selected {
        background-color: transparent;
    }

    &__dropdown {
        left: 0;
        min-width: 120px;
    }
}

.member-only {
  position: absolute;
  z-index: 4;
  left: 10px;
  bottom: 10px;
  width: 60px;
  height: 60px;
  background-color: $primary-brand-color;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease, bottom 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }

  &__logo {
    width: 42px;
    height: 42px;
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
  }

  &--active {
    bottom: calc(var(--vh, 1vh) * 16);
  }
}

.vertical-menu {
  position: absolute;
  left: 10px;
  bottom: 80px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 3;
  padding: 10px;
  width: auto;
  min-width: 100px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 10px;

      a {
        text-decoration: none;
        color: $primary-brand-color;
        font-size: 1.2rem;
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.member-only-modal-box {
    .modal-content {
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: #fff;
        // border-radius: 8px;
        // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        max-width: 400px;
        margin: 0 auto;
        width: 100%;
    }

     .modal-body {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .modal-footer {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-top: 20px;
    }

    .member-only-container {
        &--title {
            text-align: center;
            font-size: 1.6rem;
            font-weight: 700;
            margin-bottom: 10px;
            color: #444;
        }

        &--description {
            font-size: 1rem;
            font-weight: 400;
            margin: 5px 0;
            color: #555;
        }

        &--paragraph {
            font-size: 1.2rem;
            font-weight: 700;
            margin-bottom: 10px;
            color: #444;
        }

        &--input {
            width: 90%;
            margin: 10px 0;
        }

        &--info {
            text-align: center;
            font-size: 1.8rem;
            font-weight: 500;
            margin-bottom: 10px;
            color: #444;
        }
    }

    @media (max-width: 768px) {
         :deep(.back-button) {
            top: 55%;
        }

         :deep(.modal-box__slot) {
            overflow: hidden;

            position: relative;

            width: 100%;
            height: 40vh;
            height: calc(var(--vh, 1vh)* 40);
            margin: 60vh 0 0 0;
            margin: calc(var(--vh, 1vh)* 60) 0 0 0;
            padding: 0;

            min-height: 30vh;

            border-radius: 12px 12px 0 0;
        }

        .modal-header {
            height: 8%!important;
        }

        .modal-body {
            height: 72%!important;
        }

        .modal-footer{
            height: 20%!important;
            min-height: 90px;
        }

        .modal-content {
            height: 100%;
        }

        .modal-header h2 {
            font-size: 1.5rem;
        }

        .restaurant-info h3 {
            font-size: 1.3rem;
        }

        .simple-button {
            margin: 0 10px;
            width: 90%;
            min-width: 130px;
        }
    }
}

</style>
