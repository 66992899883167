<template>
    <div class="flex flex--100 add-allergen-modal-box">
        <modal-box ref="window">
            <form class="flex flex--100 add-allergen-form">
                <div class="flex flex--100" v-for="requiredLocalization in requiredLocalizations" :key="requiredLocalization.iso">
                    <div class="flex flex--100">
                        <h2 class="lang-title">{{ $t(requiredLocalization.name) }}</h2>
                    </div>
                    <div class="flex flex--100">
                        <simple-textbox :placeholder="$t('management.generic.name')"  v-model:model-value="localizations[requiredLocalization.iso].name" ref="enNameTextbox"/>
                    </div>
                    <hr class="separator"/>
                </div>
                <div class="flex flex--100">
                    <simple-button :text="$t('generic.add')" @click="addAllergen" :is-loading="isWaitingServerResponse"/>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import { RestaurantProcessor } from "@/utilities";
import { notificationCenter } from "@/components/utilities/NotificationCenter";
import { user } from "@/user";

export default {
    name: "AddAllergenModalBox",
    props: {
        restaurantId: {
            type: String,
        },
        restaurantPresentation: {
            type: Object,
        },
        closeOnAllergenAdd: {
            type: Boolean,
            default: true,
        },
    },
    emits: [ "allergen-add" ],
    components: {
        SimpleButton,
        SimpleTextbox,
        ModalBox,
    },
    data () {
        return {
            localizations: {},
            isWaitingServerResponse: false,
        };
    },
    methods: {
        validateAllergenCategoryForm () {/*
             const nameTextbox = this.$refs.nameTextbox;
             nameTextbox.validate(ProductAddErrorType.MISSING_NAME, this.name.length > 0);
             return nameTextbox.errors.length === 0;*/return true;
        },
        clearAllergenForm () {
            this.localizations = this.generateLocalizations();
        },
        async addAllergen () {
            if (!this.validateAllergenCategoryForm() || this.isWaitingServerResponse) {
                return;
            }
            this.isWaitingServerResponse = true;
            try {
                const allergen = await user.addAllergen({
                    restaurantId: this.restaurantId,
                    localizations: Object.keys(this.localizations).map((languageIso) => {
                        return {
                            languageIso,
                            ...this.localizations[languageIso],
                        };
                    }),
                });
                if (allergen) {
                    this.$emit("allergen-add", allergen);
                    if (this.closeOnAllergenAdd) {
                        this.$refs.window.hide();
                    }
                    this.clearAllergenForm();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.allergenAddedSuccess`), });
                }
                else{
                  notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }
            this.isWaitingServerResponse = false;
        },
        generateLocalizations() {
            const localizations = {};
            if (this.restaurantLocalizations) {
                this.restaurantLocalizations.forEach(iso => {
                    localizations[iso] = {
                        name: '',
                    };
                });
            }
            return localizations;
        },
    },
    computed: {
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },
        restaurantLocalizations () {
            return this.restaurantProcessor.getUserDisplayLocalizations();
        },
        requiredLocalizations() {
            if (!this.restaurantLocalizations) {
                return [];
            }

            return this.restaurantLocalizations.map(iso => ({
                name: `languages.${iso}`,
                iso: iso
            }));
        },
        window () {
            return this.$refs.window;
        },
    },
    watch: {
        restaurantLocalizations: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.localizations = this.generateLocalizations();
                }
            }
        }
    },
    async mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>
<style lang="scss" scoped>
.add-allergen-form {
    padding: 50px;
}
.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}
.add-allergen-modal-box {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }
}
.lang-title {
    font-size: 18px;
    font-weight: 500;
    margin: 0px 10px 15px 0px;
}
</style>
