<template>
    <div class="flex flex--100 add-scene-modal-box">
        <modal-box ref="window">
            <form class="flex flex--100 add-scene-form">
                <div class="flex flex--100 separator separator--no-margin-top">
                    <hr class="flex flex--100 separator__bar"/>
                    <span class="separator__text">
                        {{$t(`management.restaurantSceneManagementTab.name`).toLocaleUpperCase()}}
                    </span>
                </div>
                <div class="flex flex--100 item" v-for="requiredLocalization in requiredLocalizations" :key="requiredLocalization.iso">
                    <div class="flex flex--100">
                        <simple-textbox :placeholder="$t(requiredLocalization.name)" v-model:model-value="localizations[requiredLocalization.iso].name" ref="enNameTextbox"/>
                    </div>
                </div>
                <div class="flex flex--100 separator separator--no-margin-top">
                    <hr class="flex flex--100 separator__bar"/>
                    <span class="separator__text">
                        {{$t(`management.restaurantSceneManagementTab.configuration`).toLocaleUpperCase()}}
                    </span>
                </div>
                <div class="flex flex--100 flex--y-align-center item">
                    <div class="flex flex--50 flex--y-align-center">
                        <div class="flex flex--50">
                            <span class="item__key">{{$t(`management.restaurantManagementTab.paymentAfterService`).toLocaleUpperCase()}}</span>
                        </div>
                        <div class="flex flex--50">
                            <simple-checkbox @update:modelValue="paymentAfterService" appearance="slider" text="" :model-value="paymentAfterService"/>
                        </div>
                    </div>
                </div>
                <div class="flex flex--100 flex--y-align-center item">
                    <div class="flex flex--50 flex--y-align-center">
                        <div class="flex flex--50">
                            <span class="item__key">{{$t(`management.restaurantManagementTab.skipDeliveryState`).toLocaleUpperCase()}}</span>
                        </div>
                        <div class="flex flex--50">
                            <simple-checkbox @update:modelValue="skipDeliveryState" appearance="slider" text="" :model-value="skipDeliveryState"/>
                        </div>
                    </div>
                </div>
                <div class="flex flex--100 flex--y-align-center item">
                    <div class="flex flex--50 flex--y-align-center">
                        <div class="flex flex--50">
                            <span class="item__key">{{$t(`management.restaurantManagementTab.skipPreparationState`).toLocaleUpperCase()}}</span>
                        </div>
                        <div class="flex flex--50">
                            <simple-checkbox @update:modelValue="skipPreparationState" appearance="slider" text="" :model-value="skipPreparationState"/>
                        </div>
                    </div>
                </div>
                <div class="flex flex--100 flex--y-align-center item">
                    <div class="flex flex--50 flex--y-align-center">
                        <div class="flex flex--50">
                            <span class="item__key">{{$t(`management.restaurantManagementTab.kioskEnabled`).toLocaleUpperCase()}}</span>
                        </div>
                        <div class="flex flex--50">
                            <simple-checkbox @update:modelValue="kioskEnabled" appearance="slider" text="" :model-value="kioskEnabled"/>
                        </div>
                    </div>
                </div>
                <div class="flex flex--100 flex--y-align-center item">
                    <div class="flex flex--50 flex--y-align-center">
                        <div class="flex flex--50">
                            <span class="item__key">{{$t(`management.restaurantManagementTab.cover`).toLocaleUpperCase()}}</span>
                        </div>
                        <div class="flex flex--50">
                            <simple-textbox ref="cover" placeholder="EUR €" v-model:model-value="cover" class="item__value"/>
                        </div>
                    </div>
                </div>
                <hr class="separator">
                <div class="flex flex--100">
                    <simple-button :text="$t('generic.add')" @click="addScene" :is-loading="isWaitingServerResponse"/>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import SimpleCheckbox from "@/components/inputs/SimpleCheckbox";
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import { RestaurantProcessor } from "@/utilities";
import { notificationCenter } from "@/components/utilities/NotificationCenter";
import { user } from "@/user";

export default {
    name: "AddSceneModalBox",
    props: {
        restaurantId: {
            type: String,
        },
        restaurantPresentation: {
            type: Object,
        },
        closeOnSceneAdd: {
            type: Boolean,
            default: true,
        },
    },
    emits: [ "scene-add" ],
    components: {
        SimpleButton,
        SimpleTextbox,
        SimpleCheckbox,
        ModalBox,
    },
    data () {
        return {
            localizations: {},
            paymentAfterService: false,
            skipDeliveryState: false,
            skipPreparationState: false,
            kioskEnabled: false,
            cover: "0",
            isWaitingServerResponse: false,
        };
    },
    methods: {
        validateSceneCategoryForm () {/*
            const nameTextbox = this.$refs.nameTextbox;

            nameTextbox.validate(ProductAddErrorType.MISSING_NAME, this.name.length > 0);

            return nameTextbox.errors.length === 0;*/return true;
        },

        clearSceneForm () {
            this.localizations = this.generateLocalizations();
            this.paymentAfterService = false;
            this.skipDeliveryState = false;
            this.skipPreparationState = false;
            this.kioskEnabled = false;
            this.cover = "0";
            this.isWaitingServerResponse = false;
        },

        async addScene () {
            if (!this.validateSceneCategoryForm() || this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const scene = await user.addScene({
                    paymentAfterService: this.paymentAfterService,
                    skipDeliveryState: this.skipDeliveryState,
                    skipPreparationState: this.skipPreparationState,
                    kioskEnabled: this.kioskEnabled,
                    restaurantId: this.restaurantId,
                    cover: this.cover,
                    localizations: Object.keys(this.localizations).map((languageIso) => {
                        return {
                            languageIso,
                            ...this.localizations[languageIso],
                        };
                    }),
                });

                if (scene) {
                    this.$emit("scene-add", scene);

                    if (this.closeOnSceneAdd) {
                        this.$refs.window.hide();
                    }

                    this.clearSceneForm();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.sceneAddedSuccess`), });
                }
                else{
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },
        generateLocalizations() {
            const localizations = {};
            if (this.restaurantLocalizations) {
                this.restaurantLocalizations.forEach(iso => {
                    localizations[iso] = {
                        name: '',
                    };
                });
            }
            return localizations;
        },
    },
    computed: {
        window () {
            return this.$refs.window;
        },
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },
        restaurantLocalizations () {
            return this.restaurantProcessor.getUserDisplayLocalizations();
        },
        requiredLocalizations() {
            if (!this.restaurantLocalizations) {
                return [];
            }

            return this.restaurantLocalizations.map(iso => ({
                name: `languages.${iso}`,
                iso: iso
            }));
        }
    },
    watch: {
        restaurantLocalizations: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.localizations = this.generateLocalizations();
                }
            }
        }
    },
    async mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>

.add-scene-form {
    padding: 50px;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}

.add-scene-modal-box {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }
}

.item {
    margin: 10px 0;
}
</style>
