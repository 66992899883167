<template>
    <div class="flex flex--100 restaurant-products-analytics-tab">
        <div class="flex flex--100 box">
            <div class="flex flex--100 flex--x-align-center common-timeframes-box">
                <simple-button
                    :text="$t(`management.generic.daily`)"
                    :is-loading="isWaitingServerResponseForOrders"
                    :is-disabled="isWaitingServerResponseForOrders"
                    @click="getDailyOrders()"
                />
                <simple-button
                    :text="$t(`management.generic.weekly`)"
                    :is-loading="isWaitingServerResponseForOrders"
                    :is-disabled="isWaitingServerResponseForOrders"
                    @click="getWeeklyOrders()"
                />
                <simple-button
                    :text="$t(`management.generic.monthly`)"
                    :is-loading="isWaitingServerResponseForOrders"
                    :is-disabled="isWaitingServerResponseForOrders"
                    @click="getMonthlyOrders()"
                />
            </div>
            <div class="flex flex--100 flex--x-align-center date-picker-box">
               <v3-date-picker
                    ref="DatePicker"
                    class="date-picker-input"
                    input-format="dd/MM/yyyy"
                    :locale="calendarLanguage"
                    v-model:model-value="range"
                    :range="true"
                />
                <simple-selectbox
                    class="scene-select-box"
                    :options="sceneSelectboxOptions"
                    :can-select-empty-value="false"
                    :can-search-options="false"
                    :select-placeholder="$t('generic.chooseScene')"
                    @options-load="onSceneSelectboxOptionsLoad"
                    ref="sceneSelectbox"
                    v-model:model-value="selectedScene"
                />
                <simple-button class="action-button" :text="$t(`productList.searchBarPlaceholder`).toLocaleUpperCase()" @click="updateOrders" :is-loading="isWaitingServerResponseForOrders"/>
            </div>
        </div>
        <div class="flex flex--100 box">
            <div class="flex flex--100 flex--x-align-center product-list">
                <span class="no-orders-text" v-if="completedOrders.length === 0">{{$t(`management.restaurantProductsAnalyticsTab.noOrders`)}}</span>
                <div class="restaurant-product-analytics-box" v-for="product in orderedNormalizedProductsByDailyCompletedVolume" :key="product.id">
                    <restaurant-product-analytics
                        :product="product"
                        :daily-revenue="product.dailyRevenue"
                        :daily-discounted="product.dailyDiscounted"
                        :origins="product.origins"
                        :daily-completed-volume="product.dailyCompletedVolume"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {server} from "@/server";
import RestaurantProductAnalytics from "@/components/RestaurantProductAnalytics";
import {OrderStatus} from "@/orders/RestaurantOrder";
import SimpleButton from "@/components/inputs/SimpleButton";
import {enUS, zhCN, it} from "date-fns/locale";
import V3DatePicker from "@/components/utilities/V3DatePicker";
import SimpleSelectbox from "@/components/inputs/SimpleSelectbox";
import { i18n, } from "@/main";
import { getUserSelectedFilters, setUserSelectedFilters } from "@/utilities";
import { DiscountStrategies } from "@/products/DiscountStrategies";

const DAY = 86400000;

function getMidnightDate () {
    const date = new Date();

    date.setHours(0, 0, 0, 0);

    return date;
}

export default {
    name: "RestaurantProductsAnalyticsTab",
    components: {
        RestaurantProductAnalytics,
        SimpleButton,
        V3DatePicker,
        SimpleSelectbox,
    },
    props: {
        loadOnRestaurantIdChange: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            restaurantId: undefined,
            products: [],
            categories: [],
            allergens: [],
            variations: [],
            orders: [],
            restaurantPresentation: undefined,
            isWaitingServerResponseForOrders: false,
            calendarLanguageEN: enUS,
            calendarLanguageZH: zhCN,
            calendarLanguageIT: it,
            fromDate: getMidnightDate(),
            toDate: new Date(getMidnightDate().getTime() + DAY),
            range: [getMidnightDate(), new Date(getMidnightDate().getTime() + DAY)],

            selectedScene: undefined,
            scenes: [],
        }
    },
    computed: {
        normalizedProducts () {
            const normalizedProducts = [];
            const dailyProducts = this.completedOrders.flatMap((order) => order.products);
            for (const dailyProduct of dailyProducts) {
                if (!normalizedProducts.find((normalizedProduct) => normalizedProduct.id === dailyProduct.id)) {
                    const product = this.products.find((product) => product.id === dailyProduct.id);
                    if (product) {
                        product.origins = this.getProductOriginsByProductId(product.id);
                        product.orders = this.getOrderProductsByProductId(product.id);
                        product.price = product.orders.reduce((acc, product) => {
                            acc[product.unitPrice] = (acc[product.unitPrice] || 0) + product.requestedVolume;
                            return acc;
                        }, {});
                        product.dailyRevenue = JSON.parse(product.orders.reduce((p1, p2) =>
                            (p2.unitPrice === 0 ? 1 : p2.requestedVolume)
                            * (p2.unitPrice === 0 ? this.getCustomProductPrice(p2) : p2.unitPrice)
                            + p1 + this.getDiscountedProductPrice(p2), 0).toFixed(2));
                        product.dailyDiscounted = Math.abs(JSON.parse(product.orders.reduce((p1, p2) =>
                            p1 + this.getDiscountedProductPrice(p2), 0).toFixed(2)));
                        product.dailyCompletedVolume = product.orders.reduce((p1, p2) => p2.requestedVolume + p1, 0);
                        product.isCustomProduct = product.orders.every((p) => p.unitPrice === 0),
                        normalizedProducts.push(product);
                    }
                }
            }
            return normalizedProducts;
        },

        completedOrders () {
            return this.orders.filter((order) => order.status === OrderStatus.CLOSED);
        },

        orderedNormalizedProductsByDailyCompletedVolume () {
            const ordered =  [...this.normalizedProducts].sort((product1, product2) => product2.dailyCompletedVolume - product1.dailyCompletedVolume);
            return ordered;
        },

        calendarToDisabledDates () {
            return {
                predicate: (date) => date < this.fromDate,
            }
        },

        calendarLanguage () {
            return this[`calendarLanguage${this.$i18n.locale.toLocaleUpperCase()}`];
        },

        sceneSelectboxOptions () {
            let options = [{
                text: this.$t("generic.allScene").toLocaleUpperCase(),
                value: "all",
            }];

            for (const scene of this.scenes) {
                options = [ ...options, {
                    text: scene.localizations?.find((localization) => localization.languageIso === i18n.global.locale).name.toLocaleUpperCase(),
                    value: scene.id,
                }, ];
            }

            return options;
        },

        selected () {
            return this.scenes.find((scene) => String(scene.id) === String(this.selectedScene));
        }
    },
    methods: {
        async load (restaurantId) {
            this.restaurantId = restaurantId;
            this.restaurantPresentation = await server.getRestaurantPresentation(restaurantId, true);
            this.orders = await server.getRestaurantOrdersByTimestamp({
                id: this.restaurantId,
                fromTimestamp: Date.parse(this.fromDate.toString()),
                toTimestamp: Date.parse(this.toDate.toString()),
                sceneId: this.selectedScene,
            });
            this.products = this.restaurantPresentation.products;
            this.categories = this.restaurantPresentation.categories;
            this.allergens = this.restaurantPresentation.allergens;
            this.variations = this.restaurantPresentation.variations;
            this.scenes = this.restaurantPresentation.scenes;
        },

        getOrderProductsByProductId (productId) {
            return this.completedOrders.flatMap((order) => order.products).filter((product) => product.id === productId);
        },

        getOrdersByProductId (productId) {
            return this.completedOrders.filter((order) => order.products.find((product) => product.id === productId));
        },

        getProductOriginsByProductId (productId) {
            const origins = {};
            const orders = this.getOrdersByProductId(productId);
            orders.forEach((order) => {
                const product = order.products.find((product) => product.id === productId);
                if (!origins[order.origin]) {
                    origins[order.origin] = product.requestedVolume;
                }
                else {
                    origins[order.origin] += product.requestedVolume;
                }
            });
            return origins;
        },

        async getDailyOrders () {
            this.fromDate = getMidnightDate();
            this.toDate = new Date(this.fromDate.getTime() + DAY - 1);
            this.range = [this.fromDate, this.toDate];
            this.$refs.DatePicker.set(this.range);

            await this.updateOrders();
        },

        async getWeeklyOrders () {
            const date = new Date();

            date.setHours(0, 0, 0, 0);

            this.fromDate = new Date(date.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)));
            this.toDate = new Date (new Date(date.setDate(date.getDate() - date.getDay() + 7)).getTime() + DAY - 1);
            this.range = [this.fromDate, this.toDate];
            this.$refs.DatePicker.set(this.range);

            await this.updateOrders();
        },

        async getMonthlyOrders () {
            const date = new Date();

            date.setHours(0, 0, 0, 0);

            this.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
            this.toDate = new Date((new Date(date.getFullYear(), date.getMonth() + 1, 0)).getTime() + DAY - 1);
            this.range = [this.fromDate, this.toDate];
            this.$refs.DatePicker.set(this.range);

            await this.updateOrders();
        },

        async updateOrders () {
            if (this.isWaitingServerResponseForOrders) {
                return false;
            }

            this.isWaitingServerResponseForOrders = true;

            this.orders = await server.getRestaurantOrdersByTimestamp({
                id: this.restaurantId,
                fromTimestamp: Date.parse(this.fromDate.toString()),
                toTimestamp: Date.parse(this.toDate.toString()),
                sceneId: this.selectedScene,
            });

            this.isWaitingServerResponseForOrders = false;
        },

        getCustomProductPrice (product) {
            let totalPrice = 0;

            product.requestedMenus.forEach((menu) => {
                menu.products.forEach((product) => {
                    totalPrice += this.products.find((productToFind) => productToFind.id === product.id).price;
                })
            });

            return totalPrice;
        },

        onSceneSelectboxOptionsLoad () {
            const firstOption = this.sceneSelectboxOptions[0];
            const selectedScene = this.selectedScene || firstOption?.value;
            if (firstOption) {
                this.$refs.sceneSelectbox.selectOptionByValue(String(selectedScene));
            }
            else {
                this.selectedScene = String(selectedScene);
            }
        },

         getDiscountedProductPrice (orderProduct) {
            if (!orderProduct.discountStrategies) {
                return 0;
            }
            const volume = Number.parseInt(orderProduct.requestedVolume);
            let totalPrice = 0;

            if (orderProduct.discountStrategies.includes(DiscountStrategies.onePlusOne.id)) {
                totalPrice -= DiscountStrategies.onePlusOne.applyDiscount(
                    volume,
                    orderProduct.unitPrice,
                );
            }
                
            const fixedDiscount = orderProduct.discountStrategies.find((s) =>
                String(s).startsWith("-")
            );
                const fixed = Boolean(fixedDiscount);
            if (fixed) {
                const fixedValue = fixedDiscount.substring(1);
                totalPrice -= DiscountStrategies.fixed.applyDiscount(
                    volume,
                    orderProduct.unitPrice,
                    fixedValue
                );
            }

            const percentageDiscount = orderProduct.discountStrategies.find((s) =>
            String(s).startsWith("%")
            );
            const percentage = Boolean(percentageDiscount);
            if (percentage) {
                const percentageValue = percentageDiscount.substring(1);
                totalPrice -= DiscountStrategies.percentage.applyDiscount(
                    volume,
                    orderProduct.unitPrice,
                    percentageValue
                );
            }

            return totalPrice;
        },
    },
    watch: {
        range (newValue) {
            this.fromDate = newValue[0];
            this.toDate = newValue[1];
            this.updateOrders();
        }
    },
    mounted () {
        const filters = getUserSelectedFilters(this.$options.name);
        if (filters) {
            this.fromDate = new Date(filters.fromDate);
            this.toDate = new Date(filters.toDate);
            this.selectedScene = filters.selectedScene;
        } else {
            this.fromDate = getMidnightDate();
            this.toDate = new Date(getMidnightDate().getTime() + DAY); 
        }
        this.range = [this.fromDate, this.toDate];
        this.$refs.DatePicker.set(this.range);
    },
    unmounted () {
        const filters = {};
        filters.fromDate = this.fromDate.getTime();
        filters.toDate = this.toDate.getTime();
        filters.selectedScene = this.selectedScene;
        setUserSelectedFilters(this.$options.name, filters);
    }
}
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";

.product-list {
    background-color: rgb(245, 245, 245);
    border-radius: 12px;
    box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);
}

.no-orders-text {
    font-size: 20px;
    font-weight: 500;
    color: rgb(80, 80, 80);
}

.common-timeframes-box {
    :deep(.simple-button) {
        margin: 0;

        border-radius: 0;
        border-left: 1px solid rgb(48, 48, 48);
    }
    :deep(.simple-button:first-child) {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        border-left: none;
    }
    :deep(.simple-button:last-child) {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }
}

.date-picker-box {
    margin-top: 28px;
    padding: 0;
    gap: 20px;
}

.restaurant-product-analytics-box {
    width: 40%;
    margin: 30px;

    @media (max-width: 448px) {
        margin: 10px;
        width: 100%!important;
    }
}

.title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.06rem;
    color: rgb(33, 33, 33);
}

.date-picker-input {
    width: 200px;
}

.scene-select-box {
    width: 300px;
}

.scene-box {
    margin-top: 28px;
    padding: 0;
}

</style>
