export const OrderStatus = Object.freeze({
  TO_PAY: 0,
  IN_PREPARATION: 1,
  IN_DELIVERY: 3,
  DELIVERED: 2,
  CLOSED: 4,
  CANCELLED: 5,
});

export const OrderOrigin = Object.freeze({
  WEBSITE: 0,
  KIOSK: 1,
  CASHIER: 2,
  TABLE_QR: 3,
  THIRD_PARTY: 4,
});

export const OrderPaymentMethod = Object.freeze({
  CASH: 0,
  CARD: 1,
  THIRD_PARTY: 2,
  WECHAT_PAY: 3,
  DELIVEROO: 4,
  GUAGUA: 5,
  GLOVO: 6,
  UBEREATS: 7,
  PHONECALL: 8,
  JUSTEAT: 9,
  OTHER: 10,
  PREBILLING: 11,
  PERSONAL_WECHAT_PAY: 12,
  DELIVEROO_CASH: 13,
  DELIVEROO_CARD: 14,
  GUAGUA_CASH: 15,
  GUAGUA_CARD: 16,
  GLOVO_CASH: 17,
  GLOVO_CARD: 18,
  UBEREATS_CASH: 19,
  UBEREATS_CARD: 20,
  PHONECALL_CASH: 21,
  PHONECALL_CARD: 22,
  JUSTEAT_CASH: 23,
  JUSTEAT_CARD: 24,
  OTHER_CASH: 25,
  OTHER_CARD: 26,
  STRIPE_CARD: 27,
  MIXED: 28,
});

export const OrderDeliveryLocation = Object.freeze({
  RESTAURANT: 0,
  TAKE_AWAY: 1,
  ADDRESS: 2,
});

export const ThirdPartyOrigin = Object.freeze({
  DELIVEROO: 0,
  GUAGUA: 1,
  GLOVO: 2,
  UBEREATS: 3,
  PHONECALL: 4,
  WECHAT: 5,
  JUSTEAT: 6,
  OTHER: 7
});

export const OrderFiscalReceiptType = Object.freeze({
  RECEIPT: 0,
  ERROR: 1,
  VOID: 2,
  REFUND: 3,
});
