<template>
  <div class="language-selector" ref="selector">
    <div class="language-selector__selected" @click="toggleDropdown">
      <div class="language__icon" v-html="selectedLocale.icon"></div>
    </div>
    <div class="language-selector__dropdown" v-if="isOpen">
      <div
        class="language"
        v-for="locale in languages"
        :key="locale.languageIso"
        @click="selectLanguage(locale.languageIso)"
      >
        <div class="language__icon" v-html="locale.icon"></div>
        <span class="language__name">{{ locale.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SimpleLanguageSelector",
  emits: ["change"],
  props: {
    availableLanguages: {
      type: Array,
    },
  },
  data() {
    return {
      isOpen: false,
      locales: [
        {
          name: "Italiano",
          languageIso: "it",
          icon: `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
              <polygon fill="#FFFFFF" points="107 0 533 0 533 480 107 480"/>
              <polygon fill="#009246" points="0 0 213.3 0 213.3 480 0 480"/>
              <polygon fill="#CE2B37" points="426.7 0 640 0 640 480 426.7 480"/>
            </svg>
          `,
        },
        {
          name: "English",
          languageIso: "en",
          icon: `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
              <path fill="#012169" d="M0 0h640v480H0z"/>
              <path fill="#FFF" d="M75 0l244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"/>
              <path fill="#C8102E" d="M424 281l216 159v40L369 281h55zm-184 20l6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"/>
              <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z"/>
              <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"/>
            </svg>
          `,
        },
        {
          name: "Español",
          languageIso: "es",
          icon: `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
              <g fill-rule="evenodd">
                <path fill="#AA151B" d="M0 0h640v480H0z"/>
                <path fill="#F1BF00" d="M0 120h640v240H0z"/>
              </g>
            </svg>
          `,
        },
        {
          name: "中文",
          languageIso: "zh",
          icon: `
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="flag-icon-css-cn" viewBox="0 0 640 480">
              <defs>
                <path id="a" fill="#ffde00" d="M-.6.8L0-1 .6.8-1-.3h2z"/>
              </defs>
              <path fill="#de2910" d="M0 0h640v480H0z"/>
              <use width="30" height="20" transform="matrix(71.9991 0 0 72 120 120)" xlink:href="#a"/>
              <use width="30" height="20" transform="matrix(-12.33562 -20.5871 20.58684 -12.33577 240.3 48)" xlink:href="#a"/>
              <use width="30" height="20" transform="matrix(-3.38573 -23.75998 23.75968 -3.38578 288 95.8)" xlink:href="#a"/>
              <use width="30" height="20" transform="matrix(6.5991 -23.0749 23.0746 6.59919 288 168)" xlink:href="#a"/>
              <use width="30" height="20" transform="matrix(14.9991 -18.73557 18.73533 14.99929 240 216)" xlink:href="#a"/>
            </svg>
          `,
        },
        {
          name: "Русский",
          languageIso: "ru",
          icon: `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
              <g fill-rule="evenodd" stroke-width="1pt">
                <path fill="#fff" d="M0 0h640v160H0z"/>
                <path fill="#0039a6" d="M0 160h640v160H0z"/>
                <path fill="#d52b1e" d="M0 320h640v160H0z"/>
              </g>
            </svg>
          `,
        },
        {
          name: "Deutsch",
          languageIso: "de",
          icon: `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
              <g fill-rule="evenodd" stroke-width="1pt">
                <path fill="#000" d="M0 0h640v160H0z"/>
                <path fill="#D00" d="M0 160h640v160H0z"/>
                <path fill="#FFCE00" d="M0 320h640v160H0z"/>
              </g>
            </svg>
          `,
        },
      ],
    };
  },
  computed: {
    // 如果没有传入 availableLanguages，则只显示部分语言
    languages() {
      if (!this.availableLanguages) {
        return this.locales.filter(locale =>
          ["it", "en", "zh"].includes(locale.languageIso)
        );
      }
      return this.locales
        .filter(locale => this.availableLanguages.includes(locale.languageIso))
        .sort(
          (a, b) =>
            this.availableLanguages.indexOf(a.languageIso) -
            this.availableLanguages.indexOf(b.languageIso)
        );
    },
    // 当前选中的语言（若没有找到，则默认取 languages 数组的第一项）
    selectedLocale() {
      return (
        this.locales.find(
          (locale) => locale.languageIso === this.$i18n.locale
        ) || this.languages[0]
      );
    },
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectLanguage(languageIso) {
      const previousLanguageIso = this.$i18n.locale;
      this.$i18n.locale = languageIso;
      this.$emit("change", languageIso, previousLanguageIso);
      this.isOpen = false;
    },
    // 点击组件外部时关闭下拉菜单
    handleClickOutside(event) {
      if (this.$refs.selector && !this.$refs.selector.contains(event.target)) {
        this.isOpen = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped lang="scss">
@import "~@/css/globals.scss";

.language-selector {
  position: relative;  // Makes sure that the absolute positioning is relative to this container
  display: inline-block;

  &__selected {
    cursor: pointer;
    padding: 8px;
    border-radius: 1000px;
    background-color: rgb(42, 40, 42);
    display: flex;
    align-items: center;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.0032),
                0 0 10px 0 rgba(0, 0, 0, 0.0064);

    .language__icon {
      width: 24px;
      border-radius: 3px;
      margin: 5px 5px 0 0;
    }
  }

  &__dropdown {
    position: absolute;  // Removed from the document flow
    top: calc(100% + 4px);
    right: 0;
    z-index: 1000;       // Ensures it's on top
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

    /* Prevent the dropdown from going off-screen on mobile */
    max-height: calc(100vh - 20px);
    overflow-y: auto;

    .language {
      padding: 8px 12px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #f7f7f7;
      }

      .language__icon {
        margin-right: 8px;
        width: 24px;
        border-radius: 3px;
      }

      .language__name {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.04rem;
        color: #333;
      }
    }
  }
}

</style>
