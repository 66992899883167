import ManagementView from "@/components/views/ManagementView";
import MobileHomeView from "@/components/views/mobile/MobileHomeView";
import PlaygroundView from "@/components/views/PlaygroundView";
import { createRouter, createWebHistory, } from "vue-router";
import CashierSalePointView from "@/components/views/CashierSalePointView";
import CookOrderPointView from "@/components/views/CookOrderPointView";
import HomeView from "@/components/views/HomeView";
import KioskHomeView from "@/components/views/kiosk/KioskHomeView";
import KioskOrderView from "@/components/views/kiosk/KioskOrderView";
import KioskVerticalOrderView from "@/components/views/kiosk/KioskVerticalOrderView";
import LoginView from "@/components/views/LoginView";
import MobileOrderView from "@/components/views/mobile/MobileOrderView";
import OrderStatusView from "@/components/views/OrderStatusView";
import WaiterDeliveryPointView from "@/components/views/WaiterDeliveryPointView";
import WebOrderView from "@/components/views/WebOrderView";
import { user } from "@/user";
import MobilePointOfSaleView from "@/components/views/mobile/MobilePointOfSaleView";
import MobileUserOrdersView from "@/components/views/mobile/MobileUserOrdersView";
import RestaurantListView from "@/components/views/RestaurantListView";
import UserOrderStatusView from "@/components/views/UserOrderStatusView";
import LoginHostView from "@/components/views/host/LoginHostView";
import LoyaltyView from "./components/views/LoyaltyView.vue";
import RestaurantClosedView from "./components/views/RestaurantClosedView.vue";

function createRoute (path, component, meta = {}) {
  return {
    path,
    component,
    name: component.name,
    meta,
  };
}

export const router = createRouter({
  history: createWebHistory("/"),
  routes: [
    createRoute("/", HomeView),
    createRoute("/restaurant/list", RestaurantListView),
    createRoute("/membership", LoyaltyView),
    createRoute("/login", LoginView, {
      requiresAnonymousUser: true,
    }),
    createRoute("/login-host", LoginHostView),
    createRoute("/management", ManagementView, {
      requiresLoggedInUser: true,
    }),
    createRoute("/restaurant/:restaurantId/pos", CashierSalePointView, {
      requiresLoggedInUser: true,
    }),
    createRoute("/restaurant/:restaurantId/poc", CookOrderPointView, {
      requiresLoggedInUser: true,
    }),
    createRoute("/restaurant/:restaurantId/pod", WaiterDeliveryPointView, {
      requiresLoggedInUser: true,
    }),
    createRoute("/restaurant/:restaurantId/pou", UserOrderStatusView),

    createRoute("/restaurant/:restaurantId/order", WebOrderView),
    createRoute("/restaurant/:restaurantId/closed", RestaurantClosedView),

    // <kiosk>
    createRoute("/restaurant/:restaurantId/kiosk", KioskHomeView),
    createRoute("/restaurant/:restaurantId/kiosk/order", KioskOrderView),
    createRoute("/restaurant/:restaurantId/kiosk-new/order", KioskVerticalOrderView),
    // </kiosk>

    // <mobile>
    createRoute("/restaurant/:restaurantId/mobile", MobileHomeView),
    createRoute("/restaurant/:restaurantId/mobile/order", MobileOrderView),
    createRoute("/restaurant/:restaurantId/mobile/pos", MobilePointOfSaleView),
    createRoute(
      "/restaurant/:restaurantId/mobile/orders",
      MobileUserOrdersView
    ),
    // </mobile>

    createRoute("/restaurant/:restaurantId/order/:id/status", OrderStatusView),
    createRoute("/playground", PlaygroundView),
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAnonymousUser && to.meta.requiresAuthenticatedUser) {
    throw new Error("A route can't require a user to be anonymous and logged in at the same time.");
  }
  else if (to.meta.requiresAnonymousUser) {
    if (user.isLoggedIn) {
      next({
        name: ManagementView.name,
      });
    }
    else {
      next();
    }
  }
  else if (to.meta.requiresLoggedInUser) {
    if (user.isLoggedIn) {
      next();
    }
    else {
      // Used to redirect user to requested route after successfull login.
      window.sessionStorage.setItem("FortuneRMS::LoginRedirect", to.fullPath);

      next({
        name: LoginView.name,
      });
    }
  }
  else {
    next();
  }
});
