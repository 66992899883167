<template>
    <div class="flex flex--100 restaurant-order-list-tab" v-if="hasLoadedData">
        <div class="flex flex--100 box">
            <div class="flex flex--100 flex--x-align-center common-timeframes-box">
                <simple-button
                    :text="$t(`management.generic.daily`)"
                    :is-loading="isWaitingServerResponseForOrders"
                    :is-disabled="isWaitingServerResponseForOrders"
                    @click="setDailyTimeframe"
                />
                <simple-button
                    :text="$t(`management.generic.weekly`)"
                    :is-loading="isWaitingServerResponseForOrders"
                    :is-disabled="isWaitingServerResponseForOrders"
                    @click="setWeeklyTimeframe"
                />
                <simple-button
                    :text="$t(`management.generic.monthly`)"
                    :is-loading="isWaitingServerResponseForOrders"
                    :is-disabled="isWaitingServerResponseForOrders"
                    @click="setMonthlyTimeframe"
                />
            </div>
            <div class="flex flex--100 flex--x-align-center date-picker-box">
                <v3-date-picker
                    ref="DatePicker"
                    class="date-picker-input"
                    input-format="dd/MM/yyyy"
                    :locale="calendarLanguage"
                    v-model:model-value="range"
                    :range="true"
                />
                <simple-selectbox
                    class="scene-select-box"
                    :options="sceneSelectboxOptions"
                    :can-select-empty-value="false"
                    :can-search-options="false"
                    :select-placeholder="$t('generic.chooseScene')"
                    @options-load="onSceneSelectboxOptionsLoad"
                    ref="sceneSelectbox"
                    v-model:model-value="selectedScene"
                />
                <simple-button class="action-button" :text="$t(`productList.searchBarPlaceholder`).toLocaleUpperCase()" @click="updateOrders" :is-loading="isWaitingServerResponseForOrders"/>
                <simple-button class="action-button" text="选择日期导出giaogiao佛罗伦萨店专用日表" @click="createOrderRevenueExcel"></simple-button>
            </div>
            <div class="flex flex--100 flex--x-align-center date-picker-box">
                <v3-date-picker
                    ref="MonthDatePicker"
                    class="date-picker-input"
                    input-format="MM/yyyy"
                    :locale="calendarLanguage"
                    v-model:model-value="reportMonthYear"
                    :monthPicker="true"
                />
                <simple-button class="action-button" text="选择月份导出giaogiao佛罗伦萨店专用月表" @click="createOrderMonthRevenueExcel"></simple-button>
            </div>
        </div>
        <div class="flex flex--100 box">
            <div class="flex flex--100 separator">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.analyticsTab.revenue`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <h2 class="flex flex--y-align-center total-gross-profit">
                    <span class="total-gross-profit__title">{{$t(`management.analyticsTab.totRevenue`).toLocaleUpperCase()}} /</span>
                    € {{ normalizePriceToDisplay(totalGrossProfit) }} 
                </h2>
                <span class="separator__vertical">|</span>
                <h2 class="flex flex--y-align-center total-gross-profit">
                    <span class="total-gross-profit__title">{{$t(`management.analyticsTab.closedOrders`).toLocaleUpperCase()}} /</span>
                    {{ closedOrders.length }}
                </h2>
                <span class="separator__vertical">|</span>
                <h2 class="flex flex--y-align-center total-gross-profit">
                    <span class="total-gross-profit__title">{{$t(`management.analyticsTab.averageOrderTotal`).toLocaleUpperCase()}} /</span>
                    € {{ normalizePriceToDisplay(Number.isFinite(averageOrderTotal) ?  averageOrderTotal : 0) }}
                </h2>
                <span class="separator__vertical">|</span>
                <h2 class="flex flex--y-align-center total-gross-profit">
                    <span class="total-gross-profit__title">{{$t(`management.analyticsTab.averageDailyOrders`).toLocaleUpperCase()}} /</span>
                    {{ (this.closedOrders.length / timeframeEffectiveDays).toFixed(0) }}
                </h2>
                <span class="separator__vertical">|</span>
                <h2 class="flex flex--y-align-center total-gross-profit">
                    <span class="total-gross-profit__title">{{$t(`management.analyticsTab.unclosedOrders`).toLocaleUpperCase()}} /</span>
                     {{ timeframeOrders.length - closedOrders.length }}
                </h2>
            </div>
        </div>
        <div class="flex flex--100 box">
            <div class="flex flex--100 separator separator--no-margin-top">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.analyticsTab.revenueByDay`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <apexchart width="1000" type="line" :options="profitByDayChartOptions" :series="profitByDaySeries"/>
            </div>
        </div>
         <div class="flex flex--100 box">
            <div class="flex flex--100 separator separator--no-margin-top">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.analyticsTab.avgOrdersPerHourPerDay`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <apexchart width="1000" :options="avgOrdersPerHourPerDayChartOptions" :series="avgOrdersPerHourPerDaySeries"/>
            </div>
        </div>
        <div class="flex flex--100 box">
            <div class="flex flex--100 separator separator--no-margin-top">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.analyticsTab.inRestaurant`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <h2 class="flex flex--y-align-center total-gross-profit">
                    <span class="total-gross-profit__title">{{$t(`management.analyticsTab.revenue`).toLocaleUpperCase()}} /</span>
                    € {{ normalizePriceToDisplay(totalInRestaurantGrossProfit) }}
                </h2>
                <span class="separator__vertical">|</span>
                <h2 class="flex flex--y-align-center total-gross-profit">
                    <span class="total-gross-profit__title">{{$t(`management.analyticsTab.closedOrders`).toLocaleUpperCase()}} /</span>
                    {{ inRestaurantClosedOrders.length }}
                </h2>
                <span class="separator__vertical">|</span>
                <h2 class="flex flex--y-align-center total-gross-profit">
                    <span class="total-gross-profit__title">{{$t(`management.analyticsTab.averageOrderTotal`).toLocaleUpperCase()}} /</span>
                    € {{ normalizePriceToDisplay(Number.isFinite(averageInRestaurantOrderTotal) ?  averageInRestaurantOrderTotal : 0) }}
                </h2>
                <span class="separator__vertical">|</span>
                <h2 class="flex flex--y-align-center total-gross-profit">
                    <span class="total-gross-profit__title">{{$t(`management.analyticsTab.averageDailyOrders`).toLocaleUpperCase()}} /</span>
                    {{ (inRestaurantClosedOrders.length  / timeframeEffectiveDays).toFixed(0) }}
                </h2>
                <span class="separator__vertical">|</span>
                <h2 class="flex flex--y-align-center total-gross-profit">
                    <span class="total-gross-profit__title">{{$t(`management.analyticsTab.mixedOrders`).toLocaleUpperCase()}} /</span>
                    {{ inRestaurantMixedClosedOrders.length }}
                </h2>
            </div>
            <div class="flex flex--100 separator"></div>
            <div class="flex flex--50 flex--x-align-center">
                <h2 class="flex flex--y-align-center total-gross-profit">
                    <span class="total-gross-profit__title">{{$t(`management.analyticsTab.cash`).toLocaleUpperCase()}} /</span>
                    € {{ normalizePriceToDisplay(totalInRestaurantCashGrossProfit) }}
                </h2>
                <span class="separator__vertical">|</span>
                <h2 class="flex flex--y-align-center total-gross-profit">
                    <span class="total-gross-profit__title">{{$t(`management.analyticsTab.closedOrders`).toLocaleUpperCase()}} /</span>
                    {{ inRestaurantCashClosedOrders.length }}
                </h2>
                <div class="flex flex--100 separator"></div>
                <apexchart width="600" :options="cashChartOptions" :series="cashChartSeries"/>
            </div>
            <div class="flex flex--50 flex--x-align-center">
                <h2 class="flex flex--y-align-center total-gross-profit">
                    <span class="total-gross-profit__title">{{$t(`management.analyticsTab.card`).toLocaleUpperCase()}} /</span>
                    € {{ normalizePriceToDisplay(totalInRestaurantCardGrossProfit) }}
                </h2>
                <span class="separator__vertical">|</span>
                <h2 class="flex flex--y-align-center total-gross-profit">
                    <span class="total-gross-profit__title">{{$t(`management.analyticsTab.closedOrders`).toLocaleUpperCase()}} /</span>
                    {{ inRestaurantCardClosedOrders.length }}
                </h2>
                <div class="flex flex--100 separator"></div>
                <apexchart width="600" :options="cardChartOptions" :series="cardChartSeries"/>
            </div>
            <div class="flex flex--100 separator">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.analyticsTab.online`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <h2 class="flex flex--y-align-center total-net-profit">
                    <span class="total-net-profit__title">{{$t(`management.analyticsTab.revenue`).toLocaleUpperCase()}} /</span>
                     € {{ normalizePriceToDisplay(totalOnlineGrossProfit) }}
                </h2>
                <span class="separator__vertical">|</span>
                <h2 class="flex flex--y-align-center total-gross-profit">
                    <span class="total-gross-profit__title">{{$t(`management.analyticsTab.closedOrders`).toLocaleUpperCase()}} /</span>
                    {{ onlineClosedOrders.length }}
                </h2>
                <span class="separator__vertical">|</span>
                <h2 class="flex flex--y-align-center total-gross-profit">
                    <span class="total-gross-profit__title">{{$t(`management.analyticsTab.averageOrderTotal`).toLocaleUpperCase()}} /</span>
                    € {{ normalizePriceToDisplay(Number.isFinite(averageOnlineOrderTotal) ?  averageOnlineOrderTotal : 0) }}
                </h2>
                <span class="separator__vertical">|</span>
                <h2 class="flex flex--y-align-center total-gross-profit">
                    <span class="total-gross-profit__title">{{$t(`management.analyticsTab.averageDailyOrders`).toLocaleUpperCase()}} /</span>
                    {{ (onlineClosedOrders.length / timeframeEffectiveDays).toFixed(0) }}
                </h2>
                <div class="flex flex--100 separator"></div>
                <apexchart width="1000" :options="onlineChartOptions" :series="onlineChartSeries"/>
            </div>
        </div>
         <div class="flex flex--100">
            <div class="flex flex--100 box">
                <div class="flex flex--100 separator separator--no-margin-top">
                    <hr class="flex flex--100 separator__bar"/>
                    <span class="separator__text">{{$t(`management.analyticsTab.stripe`).toLocaleUpperCase()}}</span>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <h2 class="flex flex--y-align-center total-gross-profit">
                        <span class="total-gross-profit__title">{{$t(`management.analyticsTab.revenue`).toLocaleUpperCase()}} /</span>
                        € {{ normalizePriceToDisplay(totalStripeGrossProfit) }}
                    </h2>
                    <span class="separator__vertical">|</span>
                    <h2 class="flex flex--y-align-center total-gross-profit">
                        <span class="total-gross-profit__title">{{$t(`management.analyticsTab.closedOrders`).toLocaleUpperCase()}} /</span>
                        {{ stripeClosedOrders.length }}
                    </h2>
                    <span class="separator__vertical">|</span>
                    <h2 class="flex flex--y-align-center total-gross-profit">
                        <span class="total-gross-profit__title">{{$t(`management.analyticsTab.averageOrderTotal`).toLocaleUpperCase()}} /</span>
                        € {{ normalizePriceToDisplay(Number.isFinite(averageStripeOrderTotal) ?  averageStripeOrderTotal : 0) }}
                    </h2>
                    <span class="separator__vertical">|</span>
                    <h2 class="flex flex--y-align-center total-gross-profit">
                        <span class="total-gross-profit__title">{{$t(`management.analyticsTab.averageDailyOrders`).toLocaleUpperCase()}} /</span>
                        {{ (stripeClosedOrders.length / timeframeEffectiveDays).toFixed(0) }}
                    </h2>
                    <div class="flex flex--100 separator"></div>
                    <apexchart width="600" type="pie" :options="JSON.parse($tm(`management.analyticsTab.stripeChartOptions`))" :series="profitStripeSeries"/>
                </div>
            </div>
        </div>
        <div class="flex flex--100">
            <div class="flex flex--100 box">
                <div class="flex flex--100 separator separator--no-margin-top">
                    <hr class="flex flex--100 separator__bar"/>
                    <span class="separator__text">{{$t(`management.analyticsTab.thirdParty`).toLocaleUpperCase()}}</span>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <h2 class="flex flex--y-align-center total-gross-profit">
                        <span class="total-gross-profit__title">{{$t(`management.analyticsTab.revenue`).toLocaleUpperCase()}} /</span>
                        € {{ normalizePriceToDisplay(totalThirdPartyGrossProfit) }}
                    </h2>
                    <span class="separator__vertical">|</span>
                    <h2 class="flex flex--y-align-center total-gross-profit">
                        <span class="total-gross-profit__title">{{$t(`management.analyticsTab.closedOrders`).toLocaleUpperCase()}} /</span>
                        {{ thirdPartyClosedOrders.length }}
                    </h2>
                    <span class="separator__vertical">|</span>
                    <h2 class="flex flex--y-align-center total-gross-profit">
                        <span class="total-gross-profit__title">{{$t(`management.analyticsTab.averageOrderTotal`).toLocaleUpperCase()}} /</span>
                        € {{ normalizePriceToDisplay(Number.isFinite(averageThirdPartyOrderTotal) ?  averageThirdPartyOrderTotal : 0) }}
                    </h2>
                    <span class="separator__vertical">|</span>
                    <h2 class="flex flex--y-align-center total-gross-profit">
                        <span class="total-gross-profit__title">{{$t(`management.analyticsTab.averageDailyOrders`).toLocaleUpperCase()}} /</span>
                        {{ (thirdPartyClosedOrders.length / timeframeEffectiveDays).toFixed(0) }}
                    </h2>
                    <div class="flex flex--100 separator"></div>
                    <apexchart width="1000" :options="thirdPartyChartOptions" :series="thirdPartyChartSeries"/>
                </div>
            </div>
        </div>
        <div class="flex flex--100">
            <div class="flex half-box">
                <div class="flex flex--100 separator separator--no-margin-top">
                    <hr class="flex flex--100 separator__bar"/>
                    <span class="separator__text">{{$t(`management.analyticsTab.ordersByOrigin`).toLocaleUpperCase()}}</span>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <apexchart width="500" type="pie" :options="JSON.parse($tm(`management.analyticsTab.originChartOptions`))" :series="originSeries"/>
                </div>
            </div>
            <div class="flex half-box">
                <div class="flex flex--100 separator separator--no-margin-top">
                    <hr class="flex flex--100 separator__bar"/>
                    <span class="separator__text">{{$t(`management.analyticsTab.ordersByPaymentMethod`).toLocaleUpperCase()}}</span>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <apexchart width="500" type="pie" :options="JSON.parse($tm(`management.analyticsTab.paymentMethodChartOptions`))" :series="paymentMethodSeries"/>
                </div>
            </div>
        </div>
        <div class="flex flex--100">
            <div class="flex half-box">
                <div class="flex flex--100 separator separator--no-margin-top">
                    <hr class="flex flex--100 separator__bar"/>
                    <span class="separator__text">{{$t(`management.analyticsTab.profitByPaymentMethod`).toLocaleUpperCase()}}</span>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <apexchart width="500" type="pie" :options="JSON.parse($tm(`management.analyticsTab.paymentMethodChartOptions`))" :series="profitByPaymentMethodSeries"/>
                </div>
            </div>
        </div>
        <div class="flex flex--100">
            
        </div>
    </div>
</template>

<script>
import SimpleButton from "@/components/inputs/SimpleButton";
import { server, } from "@/server";
import { OrderOrigin, OrderPaymentMethod, OrderStatus, ThirdPartyOrigin, OrderDeliveryLocation } from '@/orders/RestaurantOrder';
import {enUS, zhCN, it} from "date-fns/locale";
import {notificationCenter} from "@/components/utilities/NotificationCenter";
import V3DatePicker from "@/components/utilities/V3DatePicker";
import SimpleSelectbox from "@/components/inputs/SimpleSelectbox";
import { i18n, } from "@/main";
import { getUserSelectedFilters, setUserSelectedFilters } from "@/utilities";
import { DiscountStrategies } from "@/products/DiscountStrategies";
import { RestaurantProcessor, } from "@/utilities";
import ExcelJS from "exceljs";

const DAY = 86400000;

function getMidnightDate () {
    const date = new Date();

    date.setHours(0, 0, 0, 0);

    return date;
}

export default {
    name: "RestaurantAnalyticsTab",
    components: {
        SimpleButton,
        V3DatePicker,
        SimpleSelectbox,
    },
    data () {
        return {
            hasLoadedData: false,
            fromDate: getMidnightDate(),
            toDate: new Date(getMidnightDate().getTime() + DAY - 1),
            range: [getMidnightDate(), new Date(getMidnightDate().getTime() + DAY - 1)],
            nativeFromDate: undefined,
            nativeToDate: undefined,
            valueAddedTax: 10,
            restaurantId: -1,
            orders: [],
            products: [],
            fixedMenus: [],
            isWaitingServerResponseForOrders: false,

            calendarLanguageEN: enUS,
            calendarLanguageZH: zhCN,
            calendarLanguageIT: it,
            reportMonthYear: {
                month: (new Date()).getMonth(),
                year: (new Date()).getFullYear(),
            },

            selectedScene: undefined,
            scenes: [],
            inRestaurantPayment: [
                OrderPaymentMethod.CASH,
                OrderPaymentMethod.CARD,
                OrderPaymentMethod.MIXED,
                OrderPaymentMethod.GLOVO_CASH,
                OrderPaymentMethod.GLOVO_CARD,
                OrderPaymentMethod.DELIVEROO_CASH,
                OrderPaymentMethod.DELIVEROO_CARD,
                OrderPaymentMethod.GUAGUA_CASH,
                OrderPaymentMethod.GUAGUA_CARD,
                OrderPaymentMethod.UBEREATS_CASH,
                OrderPaymentMethod.UBEREATS_CARD,
                OrderPaymentMethod.PHONECALL_CASH,
                OrderPaymentMethod.PHONECALL_CARD,
                OrderPaymentMethod.JUSTEAT_CASH,
                OrderPaymentMethod.JUSTEAT_CARD,
                OrderPaymentMethod.OTHER_CASH,
                OrderPaymentMethod.OTHER_CARD,
            ],
            onlinePayment: [
                OrderPaymentMethod.WECHAT_PAY,
                OrderPaymentMethod.DELIVEROO,
                OrderPaymentMethod.GUAGUA,
                OrderPaymentMethod.GLOVO,
                OrderPaymentMethod.UBEREATS,
                OrderPaymentMethod.JUSTEAT,
                OrderPaymentMethod.PHONECALL,
                OrderPaymentMethod.OTHER,
                OrderPaymentMethod.PREBILLING,
                OrderPaymentMethod.PERSONAL_WECHAT_PAY,
                OrderPaymentMethod.STRIPE_CARD,
            ],
            inRestaurantCashPayment: [
                OrderPaymentMethod.CASH,
                OrderPaymentMethod.GLOVO_CASH,
                OrderPaymentMethod.DELIVEROO_CASH,
                OrderPaymentMethod.GUAGUA_CASH,
                OrderPaymentMethod.UBEREATS_CASH,
                OrderPaymentMethod.PHONECALL_CASH,
                OrderPaymentMethod.JUSTEAT_CASH,
                OrderPaymentMethod.OTHER_CASH,
            ],
            inRestaurantCardPayment: [
                OrderPaymentMethod.CARD,
                OrderPaymentMethod.GLOVO_CARD,
                OrderPaymentMethod.DELIVEROO_CARD,
                OrderPaymentMethod.GUAGUA_CARD,
                OrderPaymentMethod.UBEREATS_CARD,
                OrderPaymentMethod.PHONECALL_CARD,
                OrderPaymentMethod.JUSTEAT_CARD,
                OrderPaymentMethod.OTHER_CARD,
            ],
            stripePayment: [
                OrderPaymentMethod.WECHAT_PAY,
                OrderPaymentMethod.STRIPE_CARD,
            ],
            thirdPartyPayment: [
                OrderPaymentMethod.DELIVEROO,
                OrderPaymentMethod.GUAGUA,
                OrderPaymentMethod.GLOVO,
                OrderPaymentMethod.UBEREATS,
                OrderPaymentMethod.JUSTEAT,
                OrderPaymentMethod.PHONECALL,
                OrderPaymentMethod.OTHER,
                OrderPaymentMethod.GLOVO_CASH,
                OrderPaymentMethod.GLOVO_CARD,
                OrderPaymentMethod.DELIVEROO_CASH,
                OrderPaymentMethod.DELIVEROO_CARD,
                OrderPaymentMethod.GUAGUA_CASH,
                OrderPaymentMethod.GUAGUA_CARD,
                OrderPaymentMethod.UBEREATS_CASH,
                OrderPaymentMethod.UBEREATS_CARD,
                OrderPaymentMethod.PHONECALL_CASH,
                OrderPaymentMethod.PHONECALL_CARD,
                OrderPaymentMethod.JUSTEAT_CASH,
                OrderPaymentMethod.JUSTEAT_CARD,
                OrderPaymentMethod.OTHER_CASH,
                OrderPaymentMethod.OTHER_CARD,
            ],
            deliverooPayment: [
                OrderPaymentMethod.DELIVEROO,
                OrderPaymentMethod.DELIVEROO_CASH,
                OrderPaymentMethod.DELIVEROO_CARD,
            ],
            glovoPayment: [
                OrderPaymentMethod.GLOVO,
                OrderPaymentMethod.GLOVO_CASH,
                OrderPaymentMethod.GLOVO_CARD,
            ],
            guaguaPayment: [
                OrderPaymentMethod.GUAGUA,
                OrderPaymentMethod.GUAGUA_CASH,
                OrderPaymentMethod.GUAGUA_CARD,
            ],
            ubereatsPayment: [
                OrderPaymentMethod.UBEREATS,
                OrderPaymentMethod.UBEREATS_CASH,
                OrderPaymentMethod.UBEREATS_CARD,
            ],
            justeatPayment: [
                OrderPaymentMethod.JUSTEAT,
                OrderPaymentMethod.JUSTEAT_CASH,
                OrderPaymentMethod.JUSTEAT_CARD,
            ],
            otherPayment: [
                OrderPaymentMethod.OTHER,
                OrderPaymentMethod.OTHER_CASH,
                OrderPaymentMethod.OTHER_CARD,
            ],
            mixedPayment: [
                OrderPaymentMethod.MIXED,
            ],
        };
    },
    methods: {
        async createOrderMonthRevenueExcel () {
            await this.getMonthOrders();
            await this.createMonthlyReportExcel();
        },
        
        async getMonthOrders () {
            const month = this.reportMonthYear.month;
            const year = this.reportMonthYear.year;
            const date = new Date(year, month, 1);

            date.setHours(0, 0, 0, 0);

            this.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
            this.toDate = new Date((new Date(date.getFullYear(), date.getMonth() + 1, 0)).getTime() + DAY - 1);
            this.range = [this.fromDate, this.toDate];
            this.$refs.DatePicker.set(this.range);

            await this.updateOrders();
        },

        async createMonthlyReportExcel () {
            const workbook = new ExcelJS.Workbook();
            const daysInMonth = 31; // Adjust based on the actual month
            const dailySumRows = {}; // To track sum row numbers for each day
            let numRows;

            // Add Index Sheet with working hyperlinks
            const indexSheet = workbook.addWorksheet('目录');
            indexSheet.mergeCells('A1:B1');
            indexSheet.getCell('A1').value = '点击右边列表中的表名称可直接跳转';
            indexSheet.getCell('A1').font = { bold: true, size: 14 };
            indexSheet.addRow(['表格', '链接']);

            // Create daily sheets
            for (let day = 1; day <= daysInMonth; day++) {
                const getOrderDay = (orders, day) => {
                    return orders.filter((order) => {
                        const timestamp = order.creationTimestamp;
                        const date = new Date(timestamp);
                        return date.getDate() === day;
                    });
                }
                // Filter orders for the current day (example, adjust as needed)
                const filteredOrders = {
                    guaguaPlatformPaymentOrders: getOrderDay(this.guaguaPlatformPaymentOrders, day),
                    guaguaCashPaymentOrders: getOrderDay(this.guaguaCashPaymentOrders, day),
                    glovoPlatformPaymentOrders: getOrderDay(this.glovoPlatformPaymentOrders, day),
                    glovoCashPaymentOrders: getOrderDay(this.glovoCashPaymentOrders, day),
                    glovoCardPaymentOrders: getOrderDay(this.glovoCardPaymentOrders, day),
                    cashPaymentOrders: getOrderDay([ ...this.cashPaymentOrders, ...this.inRestaurantMixedClosedOrders], day),
                    cardPaymentOrders: getOrderDay([ ...this.cardPaymentOrders, ...this.inRestaurantMixedClosedOrders], day),
                    WebPickUpOrders: getOrderDay(this.WebPickUpOrders, day),
                    kioskWechatPayOrders: getOrderDay(this.kioskWechatPayOrders, day),
                };

                // Create daily worksheet
                const worksheet = workbook.addWorksheet(`${day}号营收`);

                // Generate columnData for the day (replace with filtered data)
                const columnData = {
                    NUM: [],
                    GUAGUA: filteredOrders.guaguaPlatformPaymentOrders.map(order => order.totalPrice || this.getOrderTotal(order)),
                    GUAGUA_CASH: filteredOrders.guaguaCashPaymentOrders.map(order => order.totalPrice || this.getOrderTotal(order)),
                    GLOVO: filteredOrders.glovoPlatformPaymentOrders.map(order => order.totalPrice || this.getOrderTotal(order)),
                    GLOVO_CASH: filteredOrders.glovoCashPaymentOrders.map(order => order.totalPrice || this.getOrderTotal(order)),
                    GLOVO_CARD: filteredOrders.glovoCardPaymentOrders.map(order => order.totalPrice || this.getOrderTotal(order)),
                    THIRD_PARTY: [],
                    CASH: filteredOrders.cashPaymentOrders.map(order => order.totalPrice || this.getOrderDetailTotal(order, OrderPaymentMethod.CASH)),
                    CARD: filteredOrders.cardPaymentOrders.map(order => order.totalPrice || this.getOrderDetailTotal(order, OrderPaymentMethod.CARD)),
                    WEB_PICKUP: filteredOrders.WebPickUpOrders.map(order => order.totalPrice || this.getOrderTotal(order)),
                    KIOSK_WECHATPAY: filteredOrders.kioskWechatPayOrders.map(order => order.totalPrice || this.getOrderTotal(order)),
                    PERSONAL_WECHAT_CNY: [],
                    CONVERTED_EUR: [],
                    TOTAL_CASH: [],
                    TODAY_INCOME: [],
                };

                // Add and style the main title
                worksheet.mergeCells('A1:S1');
                worksheet.getCell('A1').value = '每日订单营收统计';
                worksheet.getCell('A1').font = { bold: true, size: 12 };
                worksheet.getCell('A1').alignment = { horizontal: 'center', wrapText: true };

                // Retrieve headers and add them to the worksheet
                const headers = this.$tm('management.analyticsTab.excelRevenueColumns');
                const adjustedHeaders = ['', ...headers];
                worksheet.addRow(adjustedHeaders);

                // Style the header row
                worksheet.getRow(2).font = { bold: true, size: 10 };
                worksheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                worksheet.getRow(2).height = 31.25;

                // Find the index of "外卖系统" (Takeout System) in headers
                const takeoutSystemIndex = headers.indexOf("外卖系统") + 1; // Adjust for the NUM column

                // Determine the number of rows to populate
                numRows = Math.max(...Object.values(columnData).map(col => col.length));

                // Populate data row by row
                for (let i = 0; i < numRows; i++) {
                    const rowData = adjustedHeaders.map((header, colIndex) => {
                        const key = Object.keys(columnData)[colIndex]; // Get corresponding key

                        if (colIndex === 0) {
                            // Populate the NUM column with row numbers
                            return i + 1;
                        } else if (colIndex < takeoutSystemIndex) {
                            // Populate regular data columns
                            return columnData[key]?.[i] ?? null;
                        } else if (colIndex === takeoutSystemIndex) {
                            // Add formula to sum previous columns in "外卖系统"
                            const startColumn = 'B';
                            const endColumn = String.fromCharCode(65 + takeoutSystemIndex - 1);
                            return { formula: `SUM(${startColumn}${i + 3}:${endColumn}${i + 3})` };
                        } else if (header === "微信下单     折合欧元") {
                            // Convert PERSONAL_WECHAT_CNY to EUR
                            const personalWechatCol = String.fromCharCode(66 + headers.indexOf("手机微信下单       人民币"));
                            return { formula: `${personalWechatCol}${i + 3} / 8` };
                        } else if (header === "实收欧元") {
                            // Sum specific cash-related columns
                            const cashCols = ["呱呱到付", "Glove店内现金", "堂食现金", "微信下单     折合欧元"];
                            const cashLetters = cashCols.map(col => String.fromCharCode(66 + headers.indexOf(col)));
                            const sumFormula = cashLetters.map(letter => `${letter}${i + 3}`).join('+');
                            return { formula: sumFormula };
                        } else if (header === "今日总营业额") {
                            // Sum all income columns
                            const incomeCols = [
                                "呱呱系统",
                                "呱呱到付",
                                "Glove系统",
                                "Glove店内现金",
                                "Glove店内刷卡",
                                "堂食现金",
                                "堂食POS机",
                                "网站自提      网上银行",
                                "点餐机     微信支付",
                                "微信下单     折合欧元",
                            ];
                            const incomeLetters = incomeCols.map(col => String.fromCharCode(66 + headers.indexOf(col)));
                            const sumFormula = incomeLetters.map(letter => `${letter}${i + 3}`).join('+');
                            return { formula: sumFormula };
                        } else {
                            // Populate remaining columns
                            return columnData[key]?.[i] ?? null;
                        }
                    });

                    worksheet.addRow(rowData);
                }

                // Add a blank row for spacing
                worksheet.addRow([]);

                // Add a sum row at the end of each column (excluding NUM column)
                const sumRow = adjustedHeaders.map((header, colIndex) => {
                    if (colIndex === 0) {
                        return null; // Skip the NUM column
                    } else {
                        const columnLetter = String.fromCharCode(65 + colIndex);
                        return { formula: `SUM(${columnLetter}3:${columnLetter}${numRows + 2})` };
                    }
                });

                // Add total formula to the last cell of the sum row
                const totalCols = adjustedHeaders.length - 1;
                const totalFormula = `SUM(B${numRows + 3}:${String.fromCharCode(65 + totalCols - 1)}${numRows + 3})`;
                sumRow.push({ formula: totalFormula });

                // Add the sum row to the worksheet and style it
                worksheet.addRow(sumRow).font = { bold: true };

                // Add combined totals for "呱呱合计" and "Glove合计"
                const guaguaIndex = headers.indexOf("呱呱系统") + 1;
                const gloveIndex = headers.indexOf("Glove店内现金") + 1;
                const cardIndex = headers.indexOf("堂食现金") + 1;

                const combinedSummaryRow = Array(adjustedHeaders.length).fill(null);
                combinedSummaryRow[guaguaIndex] = "呱呱合计";
                combinedSummaryRow[guaguaIndex + 1] = { formula: `SUM(B3:B${numRows + 2}, C3:C${numRows + 2})` };
                combinedSummaryRow[gloveIndex] = "Glove合计";
                combinedSummaryRow[gloveIndex + 1] = { formula: `SUM(D3:D${numRows + 2}, E3:E${numRows + 2}, F3:F${numRows + 2})` };
                combinedSummaryRow[cardIndex] = "刷卡pos合计";
                combinedSummaryRow[cardIndex + 1] = { formula: `SUM(F3:F${numRows + 2}, I3:I${numRows + 2})` };
                worksheet.addRow(combinedSummaryRow);

                // Apply distinct background colors to the summary cells
                const lastRowNum = worksheet.lastRow.number;

                // "呱呱合计" cells styling
                worksheet.getCell(`${String.fromCharCode(65 + guaguaIndex)}${lastRowNum}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFD700' }, // Gold
                };
                worksheet.getCell(`${String.fromCharCode(65 + guaguaIndex + 1)}${lastRowNum}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFD700' }, // Gold
                };

                // "Glove合计" cells styling
                worksheet.getCell(`${String.fromCharCode(65 + gloveIndex)}${lastRowNum}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '87CEFA' }, // Light blue
                };
                worksheet.getCell(`${String.fromCharCode(65 + gloveIndex + 1)}${lastRowNum}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '87CEFA' }, // Light blue
                };

                // "刷卡合计" cells styling
                worksheet.getCell(`${String.fromCharCode(65 + cardIndex)}${lastRowNum}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'CCC0DA' }, // Light purple
                };
                worksheet.getCell(`${String.fromCharCode(65 + cardIndex + 1)}${lastRowNum}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'CCC0DA' }, // Light purple
                };

                // Apply background colors to specific columns
                const realEuroIndex = headers.indexOf("实收欧元") + 1;
                const todayIncomeIndex = headers.indexOf("今日总营业额") + 1;

                for (let rowIndex = 3; rowIndex <= numRows + 2; rowIndex++) {
                    // "外卖系统" column styling
                    worksheet.getCell(`${String.fromCharCode(65 + takeoutSystemIndex)}${rowIndex}`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFCC' }, // Light yellow
                    };
                    // "实收欧元" column styling
                    worksheet.getCell(`${String.fromCharCode(65 + realEuroIndex)}${rowIndex}`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'D1E7DD' }, // Light green
                    };
                    // "今日总营业额" column styling
                    worksheet.getCell(`${String.fromCharCode(65 + todayIncomeIndex)}${rowIndex}`).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFD6E7' }, // Light pink
                    };
                }

                // Apply background color to the header row
                worksheet.getRow(2).eachCell(cell => {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'C6E0B4' }, // Light green
                    };
                });

                // Set default column widths
                worksheet.columns.forEach(column => {
                    column.width = 12;
                });
                worksheet.getColumn(1).width = 2.71; // Width for NUM column

                // Hide gridlines to make outside cells appear blank
                worksheet.views = [{ showGridLines: false }];

                // Apply borders to all cells in the data area to make data readable
                const firstDataRow = 1;
                const lastDataRow = worksheet.lastRow.number;
                const firstDataCol = 1;
                const lastDataCol = worksheet.columnCount;

                for (let row = firstDataRow; row <= lastDataRow; row++) {
                    for (let col = firstDataCol; col <= lastDataCol; col++) {
                        const cell = worksheet.getCell(row, col);

                        if (col === lastDataCol) {
                            // Last column: white background, left border only, no top or bottom borders
                            cell.border = {
                                left: { style: 'thin', color: { argb: 'FF000000' } }, // Left border
                            };

                            // Set fill to white (no fill)
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFFFFF' }, // White color
                            };
                        } else {
                            // Other cells: borders on all sides
                            cell.border = {
                                top: { style: 'thin', color: { argb: 'FF000000' } },
                                left: { style: 'thin', color: { argb: 'FF000000' } },
                                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                                right: { style: 'thin', color: { argb: 'FF000000' } },
                            };
                        }
                    }
                }

                // Adjust borders for the last column where cells contain "total"
                const totalCells = ["实收欧元", "今日总营业额"];
                totalCells.forEach(cellName => {
                    const colIndex = headers.indexOf(cellName) + 1;
                    const colLetter = String.fromCharCode(65 + colIndex);
                    for (let rowIndex = 3; rowIndex <= numRows + 3; rowIndex++) { // Include sum row
                        const cell = worksheet.getCell(`${colLetter}${rowIndex}`);
                        // Apply top (aligned with header), right, and bottom borders
                        cell.border = {
                            top: { style: 'thin', color: { argb: 'FF000000' } },
                            right: { style: 'thin', color: { argb: 'FF000000' } },
                            bottom: { style: 'thin', color: { argb: 'FF000000' } },
                        };
                    }
                });

                // Assuming sum row is added at numRows + 4 (adjust based on your original code)
                dailySumRows[day] = numRows + 4;

                indexSheet.addRow([
                    day,
                    {
                        text: `${day}号营收`,
                        hyperlink: `#'${day}号营收'!A1`, // Add # and proper quoting
                        tooltip: `跳转到${day}号营收`
                    }
                ]);
            }

            // Style index sheet links
            indexSheet.columns = [
                { header: "Day", width: 10 },
                { header: "Link", width: 20 }
            ];

            indexSheet.getRow(2).eachCell(cell => {
                cell.font = { color: { argb: 'FF0000FF' }, underline: true };
            });
                
            // Create Summary Sheet
            const summarySheet = workbook.addWorksheet('本月总营收');

            // Add and style the main title
            summarySheet.mergeCells('A1:S1');
            summarySheet.getCell('A1').value = '每日订单营收统计';
            summarySheet.getCell('A1').font = { bold: true, size: 12 };
            summarySheet.getCell('A1').alignment = { horizontal: 'center', wrapText: true };

            const headers = this.$tm('management.analyticsTab.excelRevenueColumns');
            const adjustedHeaders = ['', ...headers];
            summarySheet.addRow(adjustedHeaders);

            summarySheet.getRow(2).font = { bold: true, size: 10 };
            summarySheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            summarySheet.getRow(2).height = 31.25;

             // Find the index of "外卖系统" (Takeout System) in headers
            const takeoutSystemIndex = headers.indexOf("外卖系统") + 1; // Adjust for the NUM column

            numRows = 31;

            for (let day = 1; day <= daysInMonth; day++) {
                const rowData = [day];
                headers.forEach((header, index) => {
                    const colLetter = String.fromCharCode(66 + index); // Starts at 'B'
                    rowData.push({
                        formula: `'${day}号营收'!${colLetter}${dailySumRows[day]}`
                    });
                });
                summarySheet.addRow(rowData);
            }

            // Add a blank row for spacing
            summarySheet.addRow([]);

            const sumRow = adjustedHeaders.map((header, colIndex) => {
                if (colIndex === 0) {
                    return null; // Skip the NUM column
                } else {
                    const columnLetter = String.fromCharCode(65 + colIndex);
                    return { formula: `SUM(${columnLetter}3:${columnLetter}${numRows + 2})` };
                }
            });

            // Add total formula to the last cell of the sum row
            // const totalCols = adjustedHeaders.length - 1;
            // const totalFormula = `SUM(B${numRows + 3}:${String.fromCharCode(65 + totalCols - 1)}${numRows + 3})`;
            // sumRow.push({ formula: totalFormula });

            // Add the sum row to the worksheet and style it
            summarySheet.addRow(sumRow).font = { bold: true };

            // Add combined totals for "呱呱合计" and "Glove合计"
            const guaguaIndex = headers.indexOf("呱呱系统") + 1;
            const gloveIndex = headers.indexOf("Glove店内现金") + 1;
            const cardIndex = headers.indexOf("堂食现金") + 1;

            const combinedSummaryRow = Array(adjustedHeaders.length).fill(null);
            combinedSummaryRow[guaguaIndex] = "呱呱合计";
            combinedSummaryRow[guaguaIndex + 1] = { formula: `SUM(B3:B${numRows + 2}, C3:C${numRows + 2})` };
            combinedSummaryRow[gloveIndex] = "Glove合计";
            combinedSummaryRow[gloveIndex + 1] = { formula: `SUM(D3:D${numRows + 2}, E3:E${numRows + 2}, F3:F${numRows + 2})` };
            combinedSummaryRow[cardIndex] = "刷卡pos合计";
            combinedSummaryRow[cardIndex + 1] = { formula: `SUM(F3:F${numRows + 2}, I3:I${numRows + 2})` };
            summarySheet.addRow(combinedSummaryRow);

            // Apply distinct background colors to the summary cells
            const lastRowNum = summarySheet.lastRow.number;

            // "呱呱合计" cells styling
            summarySheet.getCell(`${String.fromCharCode(65 + guaguaIndex)}${lastRowNum}`).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFD700' }, // Gold
            };
            summarySheet.getCell(`${String.fromCharCode(65 + guaguaIndex + 1)}${lastRowNum}`).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFD700' }, // Gold
            };

            // "Glove合计" cells styling
            summarySheet.getCell(`${String.fromCharCode(65 + gloveIndex)}${lastRowNum}`).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '87CEFA' }, // Light blue
            };
            summarySheet.getCell(`${String.fromCharCode(65 + gloveIndex + 1)}${lastRowNum}`).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '87CEFA' }, // Light blue
            };

            // "刷卡合计" cells styling
            summarySheet.getCell(`${String.fromCharCode(65 + cardIndex)}${lastRowNum}`).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'CCC0DA' }, // Light purple
            };
            summarySheet.getCell(`${String.fromCharCode(65 + cardIndex + 1)}${lastRowNum}`).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'CCC0DA' }, // Light purple
            };

            // Apply background colors to specific columns
            const realEuroIndex = headers.indexOf("实收欧元") + 1;
            const todayIncomeIndex = headers.indexOf("今日总营业额") + 1;

            for (let rowIndex = 3; rowIndex <= numRows + 2; rowIndex++) {
                // "外卖系统" column styling
                summarySheet.getCell(`${String.fromCharCode(65 + takeoutSystemIndex)}${rowIndex}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFCC' }, // Light yellow
                };
                // "实收欧元" column styling
                summarySheet.getCell(`${String.fromCharCode(65 + realEuroIndex)}${rowIndex}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'D1E7DD' }, // Light green
                };
                // "今日总营业额" column styling
                summarySheet.getCell(`${String.fromCharCode(65 + todayIncomeIndex)}${rowIndex}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFD6E7' }, // Light pink
                };
            }

            // Apply background color to the header row
            summarySheet.getRow(2).eachCell(cell => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'C6E0B4' }, // Light green
                };
            });

            // Set default column widths
            summarySheet.columns.forEach(column => {
                column.width = 12;
            });
            summarySheet.getColumn(1).width = 2.71; // Width for NUM column

            // Hide gridlines to make outside cells appear blank
            summarySheet.views = [{ showGridLines: false }];

            // Apply borders to all cells in the data area to make data readable
            const firstDataRow = 1;
            const lastDataRow = summarySheet.lastRow.number;
            const firstDataCol = 1;
            const lastDataCol = summarySheet.columnCount;

            for (let row = firstDataRow; row <= lastDataRow; row++) {
                for (let col = firstDataCol; col <= lastDataCol; col++) {
                    const cell = summarySheet.getCell(row, col);

                    cell.border = {
                        top: { style: 'thin', color: { argb: 'FF000000' } },
                        left: { style: 'thin', color: { argb: 'FF000000' } },
                        bottom: { style: 'thin', color: { argb: 'FF000000' } },
                        right: { style: 'thin', color: { argb: 'FF000000' } },
                    };
                }
            }

            // Adjust borders for the last column where cells contain "total"
            const totalCells = ["实收欧元", "今日总营业额"];
            totalCells.forEach(cellName => {
                const colIndex = headers.indexOf(cellName) + 1;
                const colLetter = String.fromCharCode(65 + colIndex);
                for (let rowIndex = 3; rowIndex <= numRows + 3; rowIndex++) { // Include sum row
                    const cell = summarySheet.getCell(`${colLetter}${rowIndex}`);
                    // Apply top (aligned with header), right, and bottom borders
                    cell.border = {
                        top: { style: 'thin', color: { argb: 'FF000000' } },
                        right: { style: 'thin', color: { argb: 'FF000000' } },
                        bottom: { style: 'thin', color: { argb: 'FF000000' } },
                    };
                }
            });

            indexSheet.addRow([
                "本月总营收",
                {
                    text: `本月总营收`,
                    hyperlink: `#'本月总营收'!A1`,
                    tooltip: `跳转到本月总营收`
                }
            ]);

            // Save and download the workbook
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${this.restaurantProcessor.restaurant.name}-${this.fromDate.getMonth() + 1}-${this.fromDate.getFullYear()}.xlsx`;
            link.click();
        },

        async createOrderRevenueExcel () {
            // Prepare column data for the Excel sheet
            const columnData = {
                NUM: [],
                GUAGUA: this.guaguaPlatformPaymentOrders.map(order => order.totalPrice || this.getOrderTotal(order)),
                GUAGUA_CASH: this.guaguaCashPaymentOrders.map(order => order.totalPrice || this.getOrderTotal(order)),
                GLOVO: this.glovoPlatformPaymentOrders.map(order => order.totalPrice || this.getOrderTotal(order)),
                GLOVO_CASH: this.glovoCashPaymentOrders.map(order => order.totalPrice || this.getOrderTotal(order)),
                GLOVO_CARD: this.glovoCardPaymentOrders.map(order => order.totalPrice || this.getOrderTotal(order)),
                THIRD_PARTY: [],
                CASH: [ ...this.cashPaymentOrders, ...this.inRestaurantMixedClosedOrders].map(order => order.totalPrice || this.getOrderDetailTotal(order, OrderPaymentMethod.CASH)),
                CARD: [ ...this.cardPaymentOrders, ...this.inRestaurantMixedClosedOrders].map(order => order.totalPrice || this.getOrderDetailTotal(order, OrderPaymentMethod.CARD)),
                WEB_PICKUP: this.WebPickUpOrders.map(order => order.totalPrice || this.getOrderTotal(order)),
                KIOSK_WECHATPAY: this.kioskWechatPayOrders.map(order => order.totalPrice || this.getOrderTotal(order)),
                PERSONAL_WECHAT_CNY: [],
                CONVERTED_EUR: [],
                TOTAL_CASH: [],
                TODAY_INCOME: [],
            };

            // Create a new Excel workbook and add a worksheet
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('订单营收统计');

            // Add and style the main title
            worksheet.mergeCells('A1:S1');
            worksheet.getCell('A1').value = '每日订单营收统计';
            worksheet.getCell('A1').font = { bold: true, size: 12 };
            worksheet.getCell('A1').alignment = { horizontal: 'center', wrapText: true };

            // Retrieve headers and add them to the worksheet
            const headers = this.$tm('management.analyticsTab.excelRevenueColumns');
            const adjustedHeaders = ['', ...headers];
            worksheet.addRow(adjustedHeaders);

            // Style the header row
            worksheet.getRow(2).font = { bold: true, size: 10 };
            worksheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getRow(2).height = 31.25;

            // Find the index of "外卖系统" (Takeout System) in headers
            const takeoutSystemIndex = headers.indexOf("外卖系统") + 1; // Adjust for the NUM column

            // Determine the number of rows to populate
            const numRows = Math.max(...Object.values(columnData).map(col => col.length));

            // Populate data row by row
            for (let i = 0; i < numRows; i++) {
                const rowData = adjustedHeaders.map((header, colIndex) => {
                    const key = Object.keys(columnData)[colIndex]; // Get corresponding key

                    if (colIndex === 0) {
                        // Populate the NUM column with row numbers
                        return i + 1;
                    } else if (colIndex < takeoutSystemIndex) {
                        // Populate regular data columns
                        return columnData[key]?.[i] ?? null;
                    } else if (colIndex === takeoutSystemIndex) {
                        // Add formula to sum previous columns in "外卖系统"
                        const startColumn = 'B';
                        const endColumn = String.fromCharCode(65 + takeoutSystemIndex - 1);
                        return { formula: `SUM(${startColumn}${i + 3}:${endColumn}${i + 3})` };
                    } else if (header === "微信下单     折合欧元") {
                        // Convert PERSONAL_WECHAT_CNY to EUR
                        const personalWechatCol = String.fromCharCode(66 + headers.indexOf("手机微信下单       人民币"));
                        return { formula: `${personalWechatCol}${i + 3} / 8` };
                    } else if (header === "实收欧元") {
                        // Sum specific cash-related columns
                        const cashCols = ["呱呱到付", "Glove店内现金", "堂食现金", "微信下单     折合欧元"];
                        const cashLetters = cashCols.map(col => String.fromCharCode(66 + headers.indexOf(col)));
                        const sumFormula = cashLetters.map(letter => `${letter}${i + 3}`).join('+');
                        return { formula: sumFormula };
                    } else if (header === "今日总营业额") {
                        // Sum all income columns
                        const incomeCols = [
                            "呱呱系统",
                            "呱呱到付",
                            "Glove系统",
                            "Glove店内现金",
                            "Glove店内刷卡",
                            "堂食现金",
                            "堂食POS机",
                            "网站自提      网上银行",
                            "点餐机     微信支付",
                            "微信下单     折合欧元",
                        ];
                        const incomeLetters = incomeCols.map(col => String.fromCharCode(66 + headers.indexOf(col)));
                        const sumFormula = incomeLetters.map(letter => `${letter}${i + 3}`).join('+');
                        return { formula: sumFormula };
                    } else {
                        // Populate remaining columns
                        return columnData[key]?.[i] ?? null;
                    }
                });

                worksheet.addRow(rowData);
            }

            // Add a blank row for spacing
            worksheet.addRow([]);

            // Add a sum row at the end of each column (excluding NUM column)
            const sumRow = adjustedHeaders.map((header, colIndex) => {
                if (colIndex === 0) {
                    return null; // Skip the NUM column
                } else {
                    const columnLetter = String.fromCharCode(65 + colIndex);
                    return { formula: `SUM(${columnLetter}3:${columnLetter}${numRows + 2})` };
                }
            });

            // Add total formula to the last cell of the sum row
            const totalCols = adjustedHeaders.length - 1;
            const totalFormula = `SUM(B${numRows + 3}:${String.fromCharCode(65 + totalCols - 1)}${numRows + 3})`;
            sumRow.push({ formula: totalFormula });

            // Add the sum row to the worksheet and style it
            worksheet.addRow(sumRow).font = { bold: true };

            // Add combined totals for "呱呱合计" and "Glove合计"
            const guaguaIndex = headers.indexOf("呱呱系统") + 1;
            const gloveIndex = headers.indexOf("Glove店内现金") + 1;
            const cardIndex = headers.indexOf("堂食现金") + 1;

            const combinedSummaryRow = Array(adjustedHeaders.length).fill(null);
            combinedSummaryRow[guaguaIndex] = "呱呱合计";
            combinedSummaryRow[guaguaIndex + 1] = { formula: `SUM(B3:B${numRows + 2}, C3:C${numRows + 2})` };
            combinedSummaryRow[gloveIndex] = "Glove合计";
            combinedSummaryRow[gloveIndex + 1] = { formula: `SUM(D3:D${numRows + 2}, E3:E${numRows + 2}, F3:F${numRows + 2})` };
            combinedSummaryRow[cardIndex] = "刷卡pos合计";
            combinedSummaryRow[cardIndex + 1] = { formula: `SUM(F3:F${numRows + 2}, I3:I${numRows + 2})` };
            worksheet.addRow(combinedSummaryRow);

            // Apply distinct background colors to the summary cells
            const lastRowNum = worksheet.lastRow.number;

            // "呱呱合计" cells styling
            worksheet.getCell(`${String.fromCharCode(65 + guaguaIndex)}${lastRowNum}`).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFD700' }, // Gold
            };
            worksheet.getCell(`${String.fromCharCode(65 + guaguaIndex + 1)}${lastRowNum}`).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFD700' }, // Gold
            };

            // "Glove合计" cells styling
            worksheet.getCell(`${String.fromCharCode(65 + gloveIndex)}${lastRowNum}`).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '87CEFA' }, // Light blue
            };
            worksheet.getCell(`${String.fromCharCode(65 + gloveIndex + 1)}${lastRowNum}`).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '87CEFA' }, // Light blue
            };

            // "刷卡合计" cells styling
            worksheet.getCell(`${String.fromCharCode(65 + cardIndex)}${lastRowNum}`).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'CCC0DA' }, // Light purple
            };
            worksheet.getCell(`${String.fromCharCode(65 + cardIndex + 1)}${lastRowNum}`).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'CCC0DA' }, // Light purple
            };

            // Apply background colors to specific columns
            const realEuroIndex = headers.indexOf("实收欧元") + 1;
            const todayIncomeIndex = headers.indexOf("今日总营业额") + 1;

            for (let rowIndex = 3; rowIndex <= numRows + 2; rowIndex++) {
                // "外卖系统" column styling
                worksheet.getCell(`${String.fromCharCode(65 + takeoutSystemIndex)}${rowIndex}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFCC' }, // Light yellow
                };
                // "实收欧元" column styling
                worksheet.getCell(`${String.fromCharCode(65 + realEuroIndex)}${rowIndex}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'D1E7DD' }, // Light green
                };
                // "今日总营业额" column styling
                worksheet.getCell(`${String.fromCharCode(65 + todayIncomeIndex)}${rowIndex}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFD6E7' }, // Light pink
                };
            }

            // Apply background color to the header row
            worksheet.getRow(2).eachCell(cell => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'C6E0B4' }, // Light green
                };
            });

            // Set default column widths
            worksheet.columns.forEach(column => {
                column.width = 12;
            });
            worksheet.getColumn(1).width = 2.71; // Width for NUM column

            // Hide gridlines to make outside cells appear blank
            worksheet.views = [{ showGridLines: false }];

            // Apply borders to all cells in the data area to make data readable
            const firstDataRow = 1;
            const lastDataRow = worksheet.lastRow.number;
            const firstDataCol = 1;
            const lastDataCol = worksheet.columnCount;

            for (let row = firstDataRow; row <= lastDataRow; row++) {
                for (let col = firstDataCol; col <= lastDataCol; col++) {
                    const cell = worksheet.getCell(row, col);

                    if (col === lastDataCol) {
                        // Last column: white background, left border only, no top or bottom borders
                        cell.border = {
                            left: { style: 'thin', color: { argb: 'FF000000' } }, // Left border
                        };

                        // Set fill to white (no fill)
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFFFFFF' }, // White color
                        };
                    } else {
                        // Other cells: borders on all sides
                        cell.border = {
                            top: { style: 'thin', color: { argb: 'FF000000' } },
                            left: { style: 'thin', color: { argb: 'FF000000' } },
                            bottom: { style: 'thin', color: { argb: 'FF000000' } },
                            right: { style: 'thin', color: { argb: 'FF000000' } },
                        };
                    }
                }
            }

            // Adjust borders for the last column where cells contain "total"
            const totalCells = ["实收欧元", "今日总营业额"];
            totalCells.forEach(cellName => {
                const colIndex = headers.indexOf(cellName) + 1;
                const colLetter = String.fromCharCode(65 + colIndex);
                for (let rowIndex = 3; rowIndex <= numRows + 3; rowIndex++) { // Include sum row
                    const cell = worksheet.getCell(`${colLetter}${rowIndex}`);
                    // Apply top (aligned with header), right, and bottom borders
                    cell.border = {
                        top: { style: 'thin', color: { argb: 'FF000000' } },
                        right: { style: 'thin', color: { argb: 'FF000000' } },
                        bottom: { style: 'thin', color: { argb: 'FF000000' } },
                    };
                }
            });

            // Save the workbook and trigger the download
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${this.restaurantProcessor.restaurant.name}-${this.fromDate.getDate()}-${this.fromDate.getMonth() + 1}-${this.fromDate.getFullYear()}.xlsx`;
            link.click();
        },


        async load (restaurantId) {
            this.hasLoadedData = false;
            this.restaurantId = restaurantId;
            this.restaurantPresentation = await server.getRestaurantPresentation(restaurantId, true);
            this.orders = await server.getRestaurantOrdersByTimestamp({
                id: this.restaurantId,
                fromTimestamp: Date.parse(this.fromDate.toString()),
                toTimestamp: Date.parse(this.toDate.toString()),
                sceneId: this.selectedScene,
            });
            this.products = this.restaurantPresentation.products;
            this.fixedMenus = this.restaurantPresentation.fixedMenus;
            this.scenes = this.restaurantPresentation.scenes;
            this.hasLoadedData = true;
            this.$nextTick(() => {
                this.$refs.DatePicker.set(this.range);
            });
        },

         async setDailyTimeframe () {
            this.fromDate = getMidnightDate();
            this.toDate = new Date(this.fromDate.getTime() + DAY - 1);
            this.range = [this.fromDate, this.toDate];
            this.$refs.DatePicker.set(this.range);

            await this.updateOrders();
        },

        async setWeeklyTimeframe () {
            const date = new Date();

            date.setHours(0, 0, 0, 0);

            this.fromDate = new Date(date.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)));
            this.toDate = new Date (new Date(date.setDate(date.getDate() - date.getDay() + 7)).getTime() + DAY - 1);
            this.range = [this.fromDate, this.toDate];
            this.$refs.DatePicker.set(this.range);

            await this.updateOrders();
        },

        async setMonthlyTimeframe () {
            const date = new Date();

            date.setHours(0, 0, 0, 0);

            this.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
            this.toDate = new Date((new Date(date.getFullYear(), date.getMonth() + 1, 0)).getTime() + DAY - 1);
            this.range = [this.fromDate, this.toDate];
            this.$refs.DatePicker.set(this.range);

            await this.updateOrders();
        },

        getOrderDiscountPercentage (order) {
            return Math.min(order.discounts.reduce((percentage, discount) => percentage + discount.percentage, 0), 100);
        },

         getDiscountedProductPrice (orderProduct) {
            if (!orderProduct.discountStrategies) {
                return 0;
            }
            const volume = Number.parseInt(orderProduct.requestedVolume);
            let totalPrice = 0;

            if (orderProduct.discountStrategies.includes(DiscountStrategies.onePlusOne.id)) {
                totalPrice -= DiscountStrategies.onePlusOne.applyDiscount(
                    volume,
                    orderProduct.unitPrice,
                );
            }
                
            const fixedDiscount = orderProduct.discountStrategies.find((s) =>
                String(s).startsWith("-")
            );
                const fixed = Boolean(fixedDiscount);
            if (fixed) {
                const fixedValue = fixedDiscount.substring(1);
                totalPrice -= DiscountStrategies.fixed.applyDiscount(
                    volume,
                    orderProduct.unitPrice,
                    fixedValue
                );
            }

            const percentageDiscount = orderProduct.discountStrategies.find((s) =>
            String(s).startsWith("%")
            );
            const percentage = Boolean(percentageDiscount);
            if (percentage) {
                const percentageValue = percentageDiscount.substring(1);
                totalPrice -= DiscountStrategies.percentage.applyDiscount(
                    volume,
                    orderProduct.unitPrice,
                    percentageValue
                );
            }

            return totalPrice;
        },

        getOrderSubtotal (order) {
            return order.products.reduce(
                (subtotal, orderProduct) =>
                    (this.restaurantProcessor.productIsCustomProduct(orderProduct.id) ? 1 : orderProduct.requestedVolume)
                    * (this.restaurantProcessor.productIsCustomProduct(orderProduct.id) ? this.getCustomProductPrice(orderProduct) : orderProduct.unitPrice)
                    + this.getProductVariationsTotalPrice(orderProduct) + this.getDiscountedProductPrice(orderProduct)
                    + subtotal, 0
            ) + this.orderAdditionsTotal(order) + this.orderDeductionsTotal(order);
        },

        getProductVariationsTotalPrice (orderProduct) {
            const productVariations = orderProduct.variations;
            let totalPrice = 0;
            totalPrice += productVariations.reduce((total, variation) => total + this.restaurantProcessor.getVariationById(variation.id) ? this.restaurantProcessor.getVariationById(variation.id).price : 0, 0);

            return totalPrice;
        },

        orderAdditionsTotal (order) {
            return order.additions.reduce((total, addition) => total + addition.value, 0);
        },

        orderDeductionsTotal (order) {
            return order.deductions.reduce((total, deduction) => total + deduction.value, 0);
        },

        getOrderDiscount (order) {
            const discountPercentage = this.getOrderDiscountPercentage(order);

            if (discountPercentage === 0) {
                return 0;
            }

            return discountPercentage * this.getOrderSubtotal(order) / 100;
        },

        getOrderTotal (order) {
            return Math.max(0, this.getOrderSubtotal(order) - this.getOrderDiscount(order));
        },

        getOrderDetailTotal (order, paymentMethod) {
            if (order.paymentDetail && order.paymentDetail.length > 0) {
                const detail = order.paymentDetail.find((pd) => pd.paymentMethod === paymentMethod);
                return detail ? detail.amount : 0;
            }
            return this.getOrderTotal(order);
        },

        async updateOrders () {
            if (this.isWaitingServerResponseForOrders) {
                return false;
            }

            this.isWaitingServerResponseForOrders = true;

            this.orders = await server.getRestaurantOrdersByTimestamp({
                id: this.restaurantId,
                fromTimestamp: Date.parse(this.fromDate.toString()),
                toTimestamp: Date.parse(this.toDate.toString()),
                sceneId: this.selectedScene,
            });

            const unexpectedOrders = this.closedOrders.filter((order) => order.origin === OrderOrigin.THIRD_PARTY && !Number.isFinite(order.totalPrice));
            if (unexpectedOrders.length > 0) {
                console.log("List of third party orders without price: ", unexpectedOrders);
                notificationCenter?.sendFailureNotification({ text: unexpectedOrders.length + " " + this.$t(`notification.skipErrorOrders`), });
            }

            this.isWaitingServerResponseForOrders = false;
        },

        onSceneSelectboxOptionsLoad () {
            const firstOption = this.sceneSelectboxOptions[0];
            const selectedScene = this.selectedScene || firstOption?.value;
            if (firstOption) {
                this.$refs.sceneSelectbox.selectOptionByValue(String(selectedScene));
            }
            else {
                this.selectedScene = String(selectedScene);
            }
        },
    },
    computed: {
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        timeframeOrders () {
            return this.orders.filter((order) => new Date(order.creationTimestamp) >= this.fromDate && new Date(order.creationTimestamp) <= this.toDate);
        },

        timeframeThirdPartyOrders () {
            return this.timeframeOrders.filter((order) => order.origin === OrderOrigin.THIRD_PARTY);
        },

        timeframeStandardOrders () {
            return this.timeframeOrders.filter((order) => order.origin !== OrderOrigin.THIRD_PARTY);
        },

        timeframeDays () {
            return (this.toDate.getTime() - this.fromDate.getTime()) / DAY;
        },

        timeframeEffectiveDays () {
            return Object.keys(this.dailyProfits).length;
        },

        closedOrders () {
            return this.timeframeOrders.filter((order) => order.status === OrderStatus.CLOSED || order.status === OrderStatus.DELIVERED);
        },

        thirdPartyClosedOrders () {
            return this.closedOrders.filter((order) => this.thirdPartyPayment.includes(order.paymentMethod));
        },

        inRestaurantClosedOrders () {
            return this.closedOrders.filter((order) => this.inRestaurantPayment.includes(order.paymentMethod));
        },

        onlineClosedOrders () {
            return this.closedOrders.filter((order) => this.onlinePayment.includes(order.paymentMethod));
        },

        stripeClosedOrders () {
            return this.onlineClosedOrders.filter((order) => this.stripePayment.includes(order.paymentMethod));
        },

        inRestaurantCashClosedOrders () {
            return this.inRestaurantClosedOrders.filter((order) => this.inRestaurantCashPayment.includes(order.paymentMethod));
        },

        inRestaurantCardClosedOrders () {
            return this.inRestaurantClosedOrders.filter((order) => this.inRestaurantCardPayment.includes(order.paymentMethod));
        },

        closedThirdPartyOrders () {
            return this.closedOrders.filter((order) => order.origin === OrderOrigin.THIRD_PARTY && Number.isFinite(order.totalPrice));
        },

        closedStandardOrders () {
            return this.closedOrders.filter((order) => order.origin !== OrderOrigin.THIRD_PARTY);
        },

        inRestaurantMixedClosedOrders () {
            return this.closedOrders.filter((order) => this.mixedPayment.includes(order.paymentMethod));
        },

        relevantHours () {
            return [...new Set(
            this.closedOrders.map(order => new Date(order.creationTimestamp).getHours())
            )].sort((a, b) => a - b);
        },
    
        avgOrdersPerHourPerDaySeries () {
            let restaurant = Array(24).fill(0);
            let takeAway = Array(24).fill(0);
            let days = new Set();
            
            this.closedOrders.forEach(order => {
                let date = new Date(order.creationTimestamp);
                let hour = date.getHours();
                let day = date.toISOString().split('T')[0];
                days.add(day);
                
                if (order.deliveryLocation === OrderDeliveryLocation.RESTAURANT) {
                    restaurant[hour]++;
                } else if (order.deliveryLocation === OrderDeliveryLocation.TAKE_AWAY) {
                    takeAway[hour]++;
                }
            });

            const totalDays = days.size || 1;
            const relevantHours = this.relevantHours;
            const restaurantAvg = relevantHours.map(hour => (restaurant[hour] / totalDays).toFixed(2));
            const takeAwayAvg = relevantHours.map(hour => (takeAway[hour] / totalDays).toFixed(2));

            return [
                { name: this.$t("order.deliveryLocationMap.0"), data: restaurantAvg },
                { name: this.$t("order.deliveryLocationMap.1"), data: takeAwayAvg }
            ];
        },

        avgOrdersPerHourPerDayChartOptions () {
            return {
                chart: {
                    type: 'bar',
                    height: 350,
                },
                title: {
                    text: `${this.$t("management.analyticsTab.avgOrdersPerHourPerDay")} ${this.fromDate.toLocaleDateString()} ${this.fromDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - ${this.toDate.toLocaleDateString()} ${this.toDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`,
                    align: 'center',
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold'
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '70%',
                        endingShape: 'rounded'
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    categories: this.relevantHours.map(hour => `${hour.toString().padStart(2, '0')}:00`),
                },
                yaxis: {
                    title: {
                        text: this.$t("management.analyticsTab.avgOrdersPerHourPerDay"),
                    }
                },
                colors: ["#008FFB", "#FEB019"],
                tooltip: {
                    y: {
                        formatter: (val) => {
                            return val + this.$t("management.analyticsTab.orders");
                        }
                    }
                }
            };
        },

        profitByDayChartOptions () {
            return {
                chart: {
                    toolbar: {
                        show: false, // 隐藏工具栏
                    },
                },
                stroke: {
                    curve: "smooth",
                    width: 2,
                },
                xaxis: {
                    categories: Object.keys(this.dailyProfits).map((key) => new Date(key).toLocaleDateString("it")),
                    title: {
                        text: "Date",
                    },
                },
                yaxis: [
                    {
                        title: {
                            text: "Income (€)",
                        },
                        labels: {
                            formatter: (val) => `€ ${val.toFixed(2)}`,
                        },
                    },
                    {
                        opposite: true, // 右侧轴
                        title: {
                            text: "Orders",
                        },
                        labels: {
                            formatter: (val) => `${val.toFixed(0)}`,
                        },
                    },
                ],
                colors: ["#008FFB", "#FEB019"], // 蓝色和橙色
                markers: {
                    size: 4,
                },
                legend: {
                    position: "top",
                },
            }
        },

        cashChartOptions () {
            return {
                chart: {
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        horizontal: true, 
                        columnWidth: '50%',
                    },
                },
                xaxis: {
                    categories: this.$tm(`management.analyticsTab.inRestaurantCashPaymentMethodChartCategories`),
                },
                colors: ['#008FFB', '#FEB019'], // 蓝色表示收入，橙色表示订单数
                legend: {
                    position: 'top',
                },
            };
        },

        cashChartSeries () {
            const orders = this.inRestaurantCashClosedOrders;
            const mixed = this.inRestaurantMixedClosedOrders;
            const cashPaymentOrders = [ ...orders.filter((order) => order.paymentMethod === OrderPaymentMethod.CASH), ...mixed, ];
            const deliverooPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.DELIVEROO_CASH);
            const guaguaPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.GUAGUA_CASH);
            const glovoPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.GLOVO_CASH);
            const uberEatsPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.UBEREATS_CASH);
            const phoneCallPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.PHONECALL_CASH);
            const justEatPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.JUSTEAT_CASH);
            const otherPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.OTHER_CASH);
            return [
                {
                    name: 'Income (€)',
                    data: [
                        Number.parseFloat(cashPaymentOrders.reduce((total, order) => this.getOrderDetailTotal(order, OrderPaymentMethod.CASH) + total, 0).toFixed(2)),
                        Number.parseFloat(deliverooPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                        Number.parseFloat(guaguaPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                        Number.parseFloat(glovoPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                        Number.parseFloat(uberEatsPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                        Number.parseFloat(justEatPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                        Number.parseFloat(phoneCallPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                        Number.parseFloat(otherPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                    ]
                },
                {
                    name: 'Order',
                    data: [
                        cashPaymentOrders.length - mixed.length,
                        deliverooPaymentOrders.length,
                        guaguaPaymentOrders.length,
                        glovoPaymentOrders.length,
                        uberEatsPaymentOrders.length,
                        justEatPaymentOrders.length,
                        phoneCallPaymentOrders.length,            
                        otherPaymentOrders.length,
                    ]
                },
            ];
        },

        cardChartOptions () {
            return {
                chart: {
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        horizontal: true, 
                        columnWidth: '50%',
                    },
                },
                xaxis: {
                    categories: this.$tm(`management.analyticsTab.inRestaurantCardPaymentMethodChartCategories`),
                },
                colors: ['#008FFB', '#FEB019'], // 蓝色表示收入，橙色表示订单数
                legend: {
                    position: 'top',
                },
            };
        },

        cardChartSeries () {
            const orders = this.inRestaurantCardClosedOrders;
            const mixed = this.inRestaurantMixedClosedOrders;
            const cardPaymentOrders = [ ...orders.filter((order) => order.paymentMethod === OrderPaymentMethod.CARD), ...mixed, ];
            const deliverooPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.DELIVEROO_CARD);
            const guaguaPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.GUAGUA_CARD);
            const glovoPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.GLOVO_CARD);
            const uberEatsPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.UBEREATS_CARD);
            const phoneCallPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.PHONECALL_CARD);
            const justEatPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.JUSTEAT_CARD);
            const otherPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.OTHER_CARD);
            return [
                {
                    name: 'Income (€)',
                    data: [
                        Number.parseFloat(cardPaymentOrders.reduce((total, order) => this.getOrderDetailTotal(order, OrderPaymentMethod.CARD) + total, 0).toFixed(2)),
                        Number.parseFloat(deliverooPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                        Number.parseFloat(guaguaPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                        Number.parseFloat(glovoPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                        Number.parseFloat(uberEatsPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                        Number.parseFloat(justEatPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                        Number.parseFloat(phoneCallPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                        Number.parseFloat(otherPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                    ]
                },
                {
                    name: 'Order',
                    data: [
                        cardPaymentOrders.length - mixed.length,
                        deliverooPaymentOrders.length,
                        guaguaPaymentOrders.length,
                        glovoPaymentOrders.length,
                        uberEatsPaymentOrders.length,
                        justEatPaymentOrders.length,
                        phoneCallPaymentOrders.length,            
                        otherPaymentOrders.length,
                    ]
                },
            ];
        },

        onlineChartOptions () {
            return {
                chart: {
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        horizontal: true, 
                        columnWidth: '50%',
                    },
                },
                xaxis: {
                    categories: this.$tm(`management.analyticsTab.onlinePaymentMethodChartCategories`),
                },
                colors: ['#008FFB', '#FEB019'], // 蓝色表示收入，橙色表示订单数
                legend: {
                    position: 'top',
                },
            };
        },

        onlineChartSeries () {
            const orders = this.onlineClosedOrders;
            const stripeCardPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.STRIPE_CARD);
            const stripeWechatPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.WECHAT_PAY);
            const deliverooPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.DELIVEROO);
            const guaguaPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.GUAGUA);
            const glovoPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.GLOVO);
            const uberEatsPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.UBEREATS);
            const phoneCallPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.PHONECALL);
            const justEatPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.JUSTEAT);
            const otherPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.OTHER);
            const prebillingPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.PREBILLING);
            const personalWechatPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.PERSONAL_WECHAT_PAY);

            return [
                {
                    name: 'Income (€)',
                    data: [
                        Number.parseFloat(stripeCardPaymentOrders.reduce((total, order) => this.getOrderTotal(order) + total, 0).toFixed(2)),
                        Number.parseFloat(stripeWechatPaymentOrders.reduce((total, order) => this.getOrderTotal(order) + total, 0).toFixed(2)),
                        Number.parseFloat(deliverooPaymentOrders.reduce((total, order) => (order.totalPrice ? order.totalPrice : this.getOrderTotal(order)) + total, 0).toFixed(2)),
                        Number.parseFloat(guaguaPaymentOrders.reduce((total, order) => (order.totalPrice ? order.totalPrice : this.getOrderTotal(order)) + total, 0).toFixed(2)),
                        Number.parseFloat(glovoPaymentOrders.reduce((total, order) => (order.totalPrice ? order.totalPrice : this.getOrderTotal(order)) + total, 0).toFixed(2)),
                        Number.parseFloat(uberEatsPaymentOrders.reduce((total, order) => (order.totalPrice ? order.totalPrice : this.getOrderTotal(order))+ total, 0).toFixed(2)),
                        Number.parseFloat(justEatPaymentOrders.reduce((total, order) => (order.totalPrice ? order.totalPrice : this.getOrderTotal(order)) + total, 0).toFixed(2)),
                        Number.parseFloat(phoneCallPaymentOrders.reduce((total, order) => (order.totalPrice ? order.totalPrice : this.getOrderTotal(order)) + total, 0).toFixed(2)),
                        Number.parseFloat(otherPaymentOrders.reduce((total, order) => (order.totalPrice ? order.totalPrice : this.getOrderTotal(order)) + total, 0).toFixed(2)),
                        Number.parseFloat(prebillingPaymentOrders.reduce((total, order) => this.getOrderTotal(order) + total, 0).toFixed(2)),
                        Number.parseFloat(personalWechatPaymentOrders.reduce((total, order) => (order.totalPrice ? order.totalPrice : this.getOrderTotal(order)) + total, 0).toFixed(2)),
                    ]
                },
                {
                    name: 'Order',
                    data: [
                        stripeCardPaymentOrders.length,
                        stripeWechatPaymentOrders.length,
                        deliverooPaymentOrders.length,
                        guaguaPaymentOrders.length,
                        glovoPaymentOrders.length,
                        uberEatsPaymentOrders.length,
                        justEatPaymentOrders.length,
                        phoneCallPaymentOrders.length,            
                        otherPaymentOrders.length,
                        prebillingPaymentOrders.length,            
                        personalWechatPaymentOrders.length,
                    ]
                },
            ];
        },

        thirdPartyChartOptions () {
            return {
                chart: {
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        horizontal: true, 
                        columnWidth: '50%',
                    },
                },
                xaxis: {
                    categories: this.$tm(`management.analyticsTab.thirdPartyChartCategories`),
                },
                colors: ['#008FFB', '#FEB019'], // 蓝色表示收入，橙色表示订单数
                legend: {
                    position: 'top',
                },
            };
        },

        thirdPartyChartSeries () {
            const orders = this.thirdPartyClosedOrders;
            const deliverooPaymentOrders = orders.filter((order) => this.deliverooPayment.includes(order.paymentMethod));
            const guaguaPaymentOrders = orders.filter((order) => this.guaguaPayment.includes(order.paymentMethod));
            const glovoPaymentOrders = orders.filter((order) => this.glovoPayment.includes(order.paymentMethod));
            const uberEatsPaymentOrders = orders.filter((order) => this.ubereatsPayment.includes(order.paymentMethod));
            const justEatPaymentOrders = orders.filter((order) => this.justeatPayment.includes(order.paymentMethod));
            const otherPaymentOrders = orders.filter((order) => this.otherPayment.includes(order.paymentMethod));

            return [
                {
                    name: 'Income (€)',
                    data: [
                        Number.parseFloat(deliverooPaymentOrders.reduce((total, order) => (order.totalPrice ? order.totalPrice : this.getOrderTotal(order)) + total, 0).toFixed(2)),
                        Number.parseFloat(guaguaPaymentOrders.reduce((total, order) => (order.totalPrice ? order.totalPrice : this.getOrderTotal(order)) + total, 0).toFixed(2)),
                        Number.parseFloat(glovoPaymentOrders.reduce((total, order) => (order.totalPrice ? order.totalPrice : this.getOrderTotal(order)) + total, 0).toFixed(2)),
                        Number.parseFloat(uberEatsPaymentOrders.reduce((total, order) => (order.totalPrice ? order.totalPrice : this.getOrderTotal(order))+ total, 0).toFixed(2)),
                        Number.parseFloat(justEatPaymentOrders.reduce((total, order) => (order.totalPrice ? order.totalPrice : this.getOrderTotal(order)) + total, 0).toFixed(2)),
                        Number.parseFloat(otherPaymentOrders.reduce((total, order) => (order.totalPrice ? order.totalPrice : this.getOrderTotal(order)) + total, 0).toFixed(2)),
                    ]
                },
                {
                    name: 'Order',
                    data: [
                        deliverooPaymentOrders.length,
                        guaguaPaymentOrders.length,
                        glovoPaymentOrders.length,
                        uberEatsPaymentOrders.length,
                        justEatPaymentOrders.length,      
                        otherPaymentOrders.length,
                    ]
                },
            ];
        },

        // Orders
        stripeCardPaymentOrders () {
            return this.stripeClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.STRIPE_CARD);
        },
        stripeWechatPaymentOrders () {
            return this.stripeClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.WECHAT_PAY);
        },
        stripePaymentOrders () {
            return this.stripeClosedOrders.filter((order) => this.stripePayment.includes(order.paymentMethod));
        },
        deliverooPlatformPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.DELIVEROO);
        },
        deliverooCardPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.DELIVEROO_CARD);
        },
        deliverooCashPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.DELIVEROO_CASH);
        },
        deliverooPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => this.deliverooPayment.includes(order.paymentMethod));
        },
        guaguaPlatformPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.GUAGUA);
        },
        guaguaCardPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.GUAGUA_CARD);
        },
        guaguaCashPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.GUAGUA_CASH);
        },
        guaguaPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => this.guaguaPayment.includes(order.paymentMethod));
        },
        glovoPlatformPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.GLOVO);
        },
        glovoCardPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.GLOVO_CARD);
        },
        glovoCashPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.GLOVO_CASH);
        },
        glovoPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => this.glovoPayment.includes(order.paymentMethod));
        },
        ubereatsPlatformPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.UBEREATS);
        },
        ubereatsCardPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.UBEREATS_CARD);
        },
        ubereatsCashPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.UBEREATS_CASH);
        },
        ubereatsPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => this.ubereatsPayment.includes(order.paymentMethod));
        },
        justeatPlatformPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.JUSTEAT);
        },
        justeatCardPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.JUSTEAT_CARD);
        },
        justeatCashPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.JUSTEAT_CASH);
        },
        justeatPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => this.justeatPayment.includes(order.paymentMethod));
        },
        otherPlatformPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.OTHER);
        },
        otherCardPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.OTHER_CARD);
        },
        otherCashPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.OTHER_CASH);
        },
        otherPaymentOrders () {
            return this.thirdPartyClosedOrders.filter((order) => this.otherPayment.includes(order.paymentMethod));
        },
        phoneCallPlatformPaymentOrders () {
            return this.closedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.PHONECALL);
        },
        phoneCallCardPaymentOrders () {
            return this.closedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.PHONECALL_CARD);
        },
        phoneCallCashPaymentOrders () {
            return this.closedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.PHONECALL_CASH);
        },
        personalWechatPaymentOrders () {
            return this.closedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.PERSONAL_WECHAT_PAY);
        },
        prebillingPaymentOrders () {
            return this.closedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.PREBILLING);
        },
        cashPaymentOrders () {
            return this.inRestaurantCashClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.CASH);
        },
        cardPaymentOrders () {
            return this.inRestaurantCardClosedOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.CARD);
        },

        WebPickUpOrders () {
            return this.closedOrders.filter((order) => order.receiver !== null && order.origin === OrderOrigin.WEBSITE && (order.paymentMethod === OrderPaymentMethod.STRIPE_CARD || order.paymentMethod === OrderPaymentMethod.WECHAT_PAY));
        },
        kioskWechatPayOrders () {
            return this.closedOrders.filter((order) => order.origin === OrderOrigin.KIOSK && order.paymentMethod === OrderPaymentMethod.WECHAT_PAY);
        },

        // profitByDayChartOptions () {
        //     return {
        //         xaxis: {
        //             categories: Object.keys(this.dailyProfits).map((key) => new Date(key).toLocaleDateString("it")),
        //         },
        //     };
        // },

        ordersByDayChartOptions () {
            return {
                xaxis: {
                    categories: Object.keys(this.dailyOrders).map((key) => new Date(key).toLocaleDateString("it")),
                },
            };
        },

        profitByDaySeries () {
            return [{
                name: "Income",
                data: Object.values(this.dailyProfits).map((value) => Number.parseFloat(value.toFixed(2))),
            }, {
                name: "Orders",
                data: Object.values(this.dailyOrders).map((value) => Number.parseInt(value)),
            }];
        },

        ordersByDaySeries () {
            return [{
                name: "PAID ORDERS",
                data: Object.values(this.dailyOrders),
            }];
        },

        dailyProfits () {
            const dailyProfits = {};
            const closedThirdPartyOrders = this.closedThirdPartyOrders;
            const closedStandardOrders = this.closedStandardOrders;
            const timeframeDays = this.timeframeDays;
            const actualDate = new Date();

            for (let i = 0; i < timeframeDays; ++i) {
                const previousDate = new Date(this.fromDate.getTime() + i * DAY);
                const dayDate = new Date(this.fromDate.getTime() + ((i + 1) * DAY));

                if (actualDate < previousDate) {
                    break;
                }

                const standardOrders = closedStandardOrders.filter((order) => new Date(order.creationTimestamp) >= previousDate && new Date(order.creationTimestamp) <= dayDate);
                const thirdPartyOrders = closedThirdPartyOrders.filter((order) => new Date(order.creationTimestamp) >= previousDate && new Date(order.creationTimestamp) <= dayDate);
                let dailyProfit = 0;

                standardOrders.forEach((dailyOrder) => {
                    dailyProfit += this.getOrderTotal(dailyOrder);
                });

                thirdPartyOrders.forEach((dailyOrder) => {
                    dailyProfit += dailyOrder.totalPrice;
                });

                dailyProfits[previousDate.toISOString()] = dailyProfit;
            }

            return dailyProfits;
        },

        dailyOrders () {
            const dailyOrders = {};
            const orders = this.closedOrders;
            const timeframeDays = this.timeframeDays;
            const actualDate = new Date();

            for (let i = 0; i < timeframeDays; ++i) {
                const previousDate = new Date(this.fromDate.getTime() + i * DAY);
                const dayDate = new Date(this.fromDate.getTime() + ((i + 1) * DAY));

                if (actualDate < previousDate) {
                    break;
                }

                dailyOrders[previousDate.toISOString()] = orders.filter(
                    (order) => new Date(order.creationTimestamp) >= previousDate && new Date(order.creationTimestamp) <= dayDate
                ).length;
            }

            return dailyOrders;
        },

        totalGrossProfit () {
            const standardOrders = this.closedStandardOrders;
            const thirdPartyOrders = this.closedThirdPartyOrders;
            let grossProfit = 0;

            for (const order of standardOrders) {
                grossProfit += this.getOrderTotal(order);
            }

            for (const order of thirdPartyOrders) {
                grossProfit += order.totalPrice;
            }

            return grossProfit;
        },

        totalThirdPartyGrossProfit () {
            const standardOrders = this.closedStandardOrders;
            const thirdPartyOrders = this.closedThirdPartyOrders;
            let grossProfit = 0;

            for (const order of standardOrders) {
                if (this.thirdPartyPayment.includes(order.paymentMethod)) {
                    grossProfit += this.getOrderTotal(order);
                }
            }

            for (const order of thirdPartyOrders) {
                if (this.thirdPartyPayment.includes(order.paymentMethod)) {
                    grossProfit += order.totalPrice;
                }
            }

            return grossProfit;
        },

        totalInRestaurantGrossProfit () {
            const standardOrders = this.closedStandardOrders;
            const thirdPartyOrders = this.closedThirdPartyOrders;
            let grossProfit = 0;

            for (const order of standardOrders) {
                if (this.inRestaurantPayment.includes(order.paymentMethod)) {
                    grossProfit += this.getOrderTotal(order);
                }
            }

            for (const order of thirdPartyOrders) {
                if (this.inRestaurantPayment.includes(order.paymentMethod)) {
                    grossProfit += order.totalPrice;
                }
            }

            return grossProfit;
        },

        totalInRestaurantCashGrossProfit () {
            const standardOrders = this.closedStandardOrders;
            const thirdPartyOrders = this.closedThirdPartyOrders;
            let grossProfit = 0;

            for (const order of standardOrders) {
                if (this.inRestaurantCashPayment.includes(order.paymentMethod) || this.mixedPayment.includes(order.paymentMethod)) {
                    grossProfit += this.getOrderDetailTotal(order, OrderPaymentMethod.CASH);
                }
            }

            for (const order of thirdPartyOrders) {
                if (this.inRestaurantCashPayment.includes(order.paymentMethod)) {
                    grossProfit += order.totalPrice;
                }
            }
            return grossProfit;
        },

        totalInRestaurantCardGrossProfit () {
            const standardOrders = this.closedStandardOrders;
            const thirdPartyOrders = this.closedThirdPartyOrders;
            let grossProfit = 0;

            for (const order of standardOrders) {
                if (this.inRestaurantCardPayment.includes(order.paymentMethod) || this.mixedPayment.includes(order.paymentMethod)) {
                    grossProfit += this.getOrderDetailTotal(order, OrderPaymentMethod.CARD);
                }
            }

            for (const order of thirdPartyOrders) {
                if (this.inRestaurantCardPayment.includes(order.paymentMethod)) {
                    grossProfit += order.totalPrice;
                }
            }

            return grossProfit;
        },

        totalOnlineGrossProfit () {
            const standardOrders = this.closedStandardOrders;
            const thirdPartyOrders = this.closedThirdPartyOrders;
            let grossProfit = 0;

            for (const order of standardOrders) {
                if (this.onlinePayment.includes(order.paymentMethod)) {
                    grossProfit += this.getOrderTotal(order);
                }
            }

            for (const order of thirdPartyOrders) {
                if (this.onlinePayment.includes(order.paymentMethod)) {
                    grossProfit += order.totalPrice;
                }
            }

            return grossProfit;
        },

        totalStripeGrossProfit () {
            const standardOrders = this.closedStandardOrders;
            const thirdPartyOrders = this.closedThirdPartyOrders;
            let grossProfit = 0;

            for (const order of standardOrders) {
                if (this.stripePayment.includes(order.paymentMethod)) {
                    grossProfit += this.getOrderTotal(order);
                }
            }

            for (const order of thirdPartyOrders) {
                if (this.stripePayment.includes(order.paymentMethod)) {
                    grossProfit += order.totalPrice;
                }
            }

            return grossProfit;
        },

        totalNetProfit () {
            return this.totalGrossProfit - this.valueAddedTax * this.totalGrossProfit / 100;
        },

        originSeries () {
            const standardOrders = this.closedStandardOrders;
            const thirdPartyOrders = this.closedThirdPartyOrders;
            const websiteOrders = standardOrders.filter((order) => order.origin === OrderOrigin.WEBSITE);
            const kioskOrders = standardOrders.filter((order) => order.origin === OrderOrigin.KIOSK);
            const cashierOrders = standardOrders.filter((order) => order.origin === OrderOrigin.CASHIER);
            const deliverooOrders = thirdPartyOrders.filter((order) => order.thirdPartyOrigin === ThirdPartyOrigin.DELIVEROO);
            const guaguaOrders = thirdPartyOrders.filter((order) => order.thirdPartyOrigin === ThirdPartyOrigin.GUAGUA);
            const glovoOrders = thirdPartyOrders.filter((order) => order.thirdPartyOrigin === ThirdPartyOrigin.GLOVO);
            const uberEatsOrders = thirdPartyOrders.filter((order) => order.thirdPartyOrigin === ThirdPartyOrigin.UBEREATS);
            const phoneCallOrders = thirdPartyOrders.filter((order) => order.thirdPartyOrigin === ThirdPartyOrigin.PHONECALL);
            const wechatOrders = thirdPartyOrders.filter((order) => order.thirdPartyOrigin === ThirdPartyOrigin.WECHAT);
            const justEatOrders = thirdPartyOrders.filter((order) => order.thirdPartyOrigin === ThirdPartyOrigin.JUSTEAT);
            const otherOrders = thirdPartyOrders.filter((order) => order.thirdPartyOrigin === ThirdPartyOrigin.OTHER);

            return [
                websiteOrders.length,
                kioskOrders.length,
                cashierOrders.length,
                deliverooOrders.length,
                guaguaOrders.length,
                glovoOrders.length,
                uberEatsOrders.length,
                phoneCallOrders.length,
                wechatOrders.length,
                justEatOrders.length,
                otherOrders.length,
            ];
        },

        paymentMethodSeries () {
            const orders = this.closedOrders;
            const cashPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.CASH);
            const cardPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.CARD);
            const deliverooPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.DELIVEROO);
            const guaguaPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.GUAGUA);
            const glovoPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.GLOVO);
            const uberEatsPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.UBEREATS);
            const phoneCallPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.PHONECALL);
            const wechatPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.WECHAT_PAY);
            const justEatPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.JUSTEAT);
            const otherPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.OTHER);
            const prebillingPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.PREBILLING);
            const mixedPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.MIXED);

            return [
                cashPaymentOrders.length,
                cardPaymentOrders.length,
                deliverooPaymentOrders.length,
                guaguaPaymentOrders.length,
                glovoPaymentOrders.length,
                uberEatsPaymentOrders.length,
                phoneCallPaymentOrders.length,
                wechatPaymentOrders.length,
                justEatPaymentOrders.length,
                otherPaymentOrders.length,
                prebillingPaymentOrders.length,
                mixedPaymentOrders.length,
            ];
        },

        profitByPaymentMethodSeries () {
            const thirdPartyOrders = this.closedThirdPartyOrders;
            const standardOrders = this.closedStandardOrders;
            const cashPaymentOrders = standardOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.CASH || order.paymentMethod === OrderPaymentMethod.MIXED);
            const cardPaymentOrders = standardOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.CARD || order.paymentMethod === OrderPaymentMethod.MIXED);
            const deliverooPaymentOrders = thirdPartyOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.DELIVEROO);
            const guaguaPaymentOrders = thirdPartyOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.GUAGUA);
            const glovoPaymentOrders = thirdPartyOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.GLOVO);
            const uberEatsPaymentOrders = thirdPartyOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.UBEREATS);
            const phoneCallPaymentOrders = thirdPartyOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.PHONECALL);
            const thirdPartyWechatPaymentOrders = thirdPartyOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.WECHAT_PAY);
            const standardWechatPaymentOrders = standardOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.WECHAT_PAY);
            const justEatPaymentOrders = thirdPartyOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.JUSTEAT);
            const otherPaymentOrders = thirdPartyOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.OTHER);
            const prebillingPaymentOrders = standardOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.PREBILLING);

            return [
                Number.parseFloat(cashPaymentOrders.reduce((total, order) => this.getOrderDetailTotal(order, OrderPaymentMethod.CASH) + total, 0).toFixed(2)),
                Number.parseFloat(cardPaymentOrders.reduce((total, order) => this.getOrderDetailTotal(order, OrderPaymentMethod.CARD) + total, 0).toFixed(2)),
                Number.parseFloat(deliverooPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                Number.parseFloat(guaguaPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                Number.parseFloat(glovoPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                Number.parseFloat(uberEatsPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                Number.parseFloat(phoneCallPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                Number.parseFloat(
                    Number.parseFloat(
                        standardWechatPaymentOrders.reduce((total, order) => this.getOrderTotal(order) + total, 0).toFixed(2) +
                        thirdPartyWechatPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)
                    ).toFixed(2)
                ),
                Number.parseFloat(justEatPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                Number.parseFloat(otherPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                Number.parseFloat(prebillingPaymentOrders.reduce((total, order) => this.getOrderTotal(order) + total, 0).toFixed(2)),
            ];
        },

        profitStripeSeries () {
            const orders = this.onlineClosedOrders;
            const cardPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.STRIPE_CARD);
            const standardWechatPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.WECHAT_PAY);

            return [
                Number.parseFloat(standardWechatPaymentOrders.reduce((total, order) => this.getOrderTotal(order) + total, 0).toFixed(2)),
                Number.parseFloat(cardPaymentOrders.reduce((total, order) => this.getOrderTotal(order) + total, 0).toFixed(2)),
            ];
        },

        calendarToDisabledDates () {
            return {
                predicate: (date) => date < this.fromDate,
            }
        },

        calendarLanguage () {
            return this[`calendarLanguage${this.$i18n.locale.toLocaleUpperCase()}`];
        },

        averageDailyRevenue () {
            return this.totalGrossProfit / this.timeframeEffectiveDays;
        },

        averageOrderTotal () {
            return (this.totalGrossProfit / this.timeframeEffectiveDays / Number.parseFloat((this.closedOrders.length / this.timeframeEffectiveDays).toFixed(0)))
        },

        averageInRestaurantOrderTotal () {
            return (this.totalInRestaurantGrossProfit / this.timeframeEffectiveDays / Number.parseFloat((this.inRestaurantClosedOrders.length / this.timeframeEffectiveDays).toFixed(0)))
        },

        averageOnlineOrderTotal () {
            return (this.totalOnlineGrossProfit / this.timeframeEffectiveDays / Number.parseFloat((this.onlineClosedOrders.length / this.timeframeEffectiveDays).toFixed(0)))
        },

        averageStripeOrderTotal () {
            return (this.totalStripeGrossProfit / this.timeframeEffectiveDays / Number.parseFloat((this.stripeClosedOrders.length / this.timeframeEffectiveDays).toFixed(0)))
        },

        averageThirdPartyOrderTotal () {
            return (this.totalThirdPartyGrossProfit / this.timeframeEffectiveDays / Number.parseFloat((this.thirdPartyClosedOrders.length / this.timeframeEffectiveDays).toFixed(0)))
        },

        sceneSelectboxOptions () {
            let options = [{
                text: this.$t("generic.allScene").toLocaleUpperCase(),
                value: "all",
            }];

            for (const scene of this.scenes) {
                options = [ ...options, {
                    text: scene.localizations?.find((localization) => localization.languageIso === i18n.global.locale).name.toLocaleUpperCase(),
                    value: scene.id,
                }, ];
            }

            return options;
        },

        selected () {
            return this.scenes.find((scene) => String(scene.id) === String(this.selectedScene));
        }
    },
    watch: {
        range (newValue) {
            this.fromDate = newValue[0];
            this.toDate = newValue[1];
            this.updateOrders();
        }
    },
    mounted () {
        const filters = getUserSelectedFilters(this.$options.name);
        if (filters) {
            this.fromDate = new Date(filters.fromDate);
            this.toDate = new Date(filters.toDate);
            this.selectedScene = filters.selectedScene;
        } else {
            this.fromDate = getMidnightDate();
            this.toDate = new Date(getMidnightDate().getTime() + DAY - 1); 
        }
        this.range = [this.fromDate, this.toDate];
    },
    unmounted () {
        const filters = {};
        filters.fromDate = this.fromDate.getTime();
        filters.toDate = this.toDate.getTime();
        filters.selectedScene = this.selectedScene;
        setUserSelectedFilters(this.$options.name, filters);
    }
};
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";

.add-category-button {
    cursor: pointer;

    margin: 50px 0;
    padding: 10px 20px;

    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.022), 0 0 6px 0 rgba(0, 0, 0, 0.034);
    border-radius: 6px;

    &__text {
        font-size: 14px;
        font-weight: 500;
        color: rgb(40, 40, 40);
    }
    &:hover &__text {
        color: rgb(78, 199, 26);
    }

    &__icon {
        width: 12px;
        margin-left: 5px;

        fill: rgb(60, 60, 60);
    }
    &:hover &__icon {
        fill: rgb(78, 199, 26);
    }
}

.restaurant-order {
    margin: 25px;

    width: 45%;
}

.order-list {
    background-color: rgb(245, 245, 245);
    border-radius: 12px;
    box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);
}

.control-box {
    margin: 0 0 50px 0;
}

.no-orders {
    font-size: 20px;
    font-weight: 500;
    color: rgb(80, 80, 80);
}

.title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.06rem;
    color: rgb(33, 33, 33);
}

.separator__vertical {
     font-size: 28px;
     margin: 0 30px;
     color: #ccc;
}

.total-gross-profit {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0.06rem;
    color: rgb(33, 33, 33);

    &__title {
        margin-right: 5px;

        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.06rem;
        color: rgb(33, 33, 33);
    }
}

.total-net-profit {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0.06rem;
    color: rgb(33, 33, 33);

    &__title {
        margin-right: 5px;

        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.06rem;
        color: rgb(33, 33, 33);
    }
}

.half-box {
    width: calc(50% - 60px);
    margin: 30px;
    padding: 50px;

    background-color: rgb(255, 255, 255);
    border-radius: 6px;
    box-shadow: 0 0 50px 1px rgba(0, 0, 0, 0.05);

    & + & {
        width: calc(50% - 30px);
        margin-left: 0;
    }
}

.common-timeframes-box {
    :deep(.simple-button) {
        margin: 0;

        border-radius: 0;
        border-left: 1px solid rgb(48, 48, 48);
    }
    :deep(.simple-button:first-child) {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        border-left: none;
    }
    :deep(.simple-button:last-child) {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }

}

.date-picker-box {
    margin-top: 28px;
    padding: 0;
    gap: 20px;
}

.restaurant-order-list-tab {
    .apexcharts-toolbar {
        display: none !important;
    }
}

.date-picker-input {
    width: 200px;
    margin: 15px 20px!important;
}

.scene-select-box {
    width: 300px;
}

.scene-box {
    margin-top: 28px;
    padding: 0;
}

</style>
